import { inject, injectable } from 'inversify';
import { PurchaseUrl, PurchaseUrlGeneratorService } from '../../common/purchase/PurchaseUrlGeneratorService';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { BasketService } from '../../../services/BasketService/BasketService';
import { LogUtil } from '../../../utils/Logging.Util';
import { ShopifyDependencyTypes } from '../di/ShopifyDependencyTypes';
import { BasketMode } from '../../../services/BasketService/BasketMode';
import { CloudshelfTrackedURLService } from '../../../services/TrackedURLService/CloudshelfTrackedURLService';
import { ConfigurationService } from '../../../services/ConfigurationService/ConfigurationService';

@injectable()
export class ShopifyCheckoutPurchaseUrlGenerator implements PurchaseUrlGeneratorService {
    private readonly cartLink: string;
    private static readonly ITEMS_PLACEHOLDER = '{{items}}';

    constructor(
        @inject(DependencyType.ApolloClient) private readonly _apolloClient: ApolloClient<NormalizedCacheObject>,
        @inject(DependencyType.ConfigurationService) private readonly _configService: ConfigurationService,
        @inject(DependencyType.BasketService) private readonly _basketService: BasketService,
        @inject(DependencyType.CloudshelfTrackedURLService)
        private readonly _trackedURLService: CloudshelfTrackedURLService,
        @inject(ShopifyDependencyTypes.ShopifyStore) shopifyStore: any,
    ) {
        this.cartLink = `https://${shopifyStore.domain}/cart/${ShopifyCheckoutPurchaseUrlGenerator.ITEMS_PLACEHOLDER}?access_token=${shopifyStore.storefrontToken}`;
    }

    async generateBasketPurchaseUrl(): Promise<PurchaseUrl | undefined> {
        LogUtil.Log('Generating url');
        if (!this._basketService.basket) {
            return undefined;
        }

        if (this._basketService.basketMode === BasketMode.ShopifyPermalink) {
            const permalink = this.generatePermalink();
            if (!permalink) {
                return undefined;
            }
            if (this._configService.isUsingCachedConfig) {
                LogUtil.Log(`[Shopify Checkout Gen] Untracked permalink URL: ${permalink}`);

                return {
                    url: permalink,
                    isTracked: false,
                };
            } else {
                const trackedUrl = await this._trackedURLService.generateTrackedURL(permalink);
                if (!trackedUrl) {
                    LogUtil.LogObject([`[Shopify Checkout Gen] Untracked permalink URL:`, trackedUrl]);

                    return {
                        url: permalink,
                        isTracked: false,
                    };
                } else {
                    LogUtil.LogObject([`[Shopify Checkout Gen] Tracked permalink URL:`, trackedUrl]);
                    return {
                        url: trackedUrl.url,
                        isTracked: true,
                        id: trackedUrl.id,
                    };
                }
            }
        } else {
            const checkout = await this._basketService.convertToCheckout();
            LogUtil.LogObject({ checkout });
            const checkoutUrl = checkout ? checkout.url : this._basketService.getCheckoutUrl();
            const trackedUrl = await this._trackedURLService.generateTrackedURL(checkoutUrl, checkout?.ourId);
            if (!trackedUrl) {
                LogUtil.LogObject([`[Shopify Checkout Gen] Untracked URL:`, trackedUrl]);

                return {
                    url: checkoutUrl,
                    isTracked: false,
                };
            } else {
                LogUtil.LogObject([`[Shopify Checkout Gen] Tracked URL:`, trackedUrl]);
                return {
                    url: trackedUrl.url,
                    isTracked: true,
                    id: trackedUrl.id,
                };
            }
        }
    }

    generatePermalink(): string | undefined {
        const basket = this._basketService.basket;
        if (this._basketService.totalQuantity() === 0 || !basket) {
            return undefined;
        }
        const itemsPathParam = basket?.lineItems
            .map(
                lineItem =>
                    `${ShopifyCheckoutPurchaseUrlGenerator.extractVariantId(lineItem.productVariant.id)}:${
                        lineItem.quantity
                    }`,
            )
            .join(',');
        const url = this.cartLink.replace(ShopifyCheckoutPurchaseUrlGenerator.ITEMS_PLACEHOLDER, itemsPathParam);
        LogUtil.Log(`[Permalink Gen] Url: ${url}`);
        return url;
    }

    private static extractVariantId(variantStorefrontId: string): string {
        LogUtil.Log(variantStorefrontId);
        return variantStorefrontId.substring(variantStorefrontId.lastIndexOf('/') + 1);
    }
}
