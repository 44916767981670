import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { usePromise } from 'react-use';
import ProductCard, { BannerType } from '../../ProductCard/ProductCard';
import { useInjection } from '../../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../../dependancyInjection/DependencyType';
import AppConfig from '../../../../../../types/AppConfig';
import PriceService from '../../../../../../services/PriceService/PriceService';
import _ from 'lodash';
import Chip from '../../Chip/Chip';
import * as Sentry from '@sentry/react';
import {
    FilterSelection,
    ProductFilteringService,
    ProductsFetchOptions,
    ProductsFetchResult,
} from '../../../../../../services/ProductServices/ProductFilteringService';
import CloudshelfBranding from '../../../../../shared/CloudshelfBranding/CloudshelfBranding';
import { ProductSearchResultWithCursor } from '../../../../../../provider/cloudshelf/common/ProductSearchResultWithCursor';
import './ProductsPage.scss';
import ProductOpenerUtil from '../../ProductDetailsView/utils/ProductViewOpener';
import { ConfigurationService } from '../../../../../../services/ConfigurationService/ConfigurationService';
import { Category } from '../../../../../../services/CategoryService/entities/Category';
import { getSizedImageURL } from '../../../../../../utils/ImageURL.Util';
import { MenuService, MenuStyle } from '../../../../../../services/MenuService/MenuService';
import { getMenuHeight } from '../../Menu/Menu';
import { useComputedStyle } from '../../../../../../hooks/UseComputedStyle';
import ResponsiveGrid, {
    generateGridProperties,
    ResponsiveGridItemWithKey,
} from '../../../../../shared/ResponsiveGrid/ResponsiveGrid';
import { getDeviceDPI } from '../../../../../../index';
import { CloudshelfEngineFilter } from '../../../../../../services/ConfigurationService/types/filters/CloudshelfEngineFilter';
import { VirtualizeList } from '../../VirtualizeList/VirtualizeList';
import { CloudshelfProductsSearchResult } from '../../../../../../provider/cloudshelf/filter/remote/CloudshelfProductsSearchResult';
import ChevronIcon from '../../../../../icons/chevron';

const limit = AppConfig.PRODUCTS.PRODUCTS_PER_PAGE;

export interface ProductsPageProps {
    category?: Category;
    handleFetchProducts: (
        filterSelection: FilterSelection[],
        options: ProductsFetchOptions,
        setMeaningful: boolean,
    ) => Promise<CloudshelfProductsSearchResult>;
    productDetailsUrl: (product: ProductSearchResultWithCursor) => string;
    productDetailsRoute: string;
    productListingRoute: string;
    loading?: boolean;
}

export interface FilterCount {
    productIds: string[];
    filter: FilterSelection;
}

export const ProductsPage: React.FC<ProductsPageProps> = ({
    handleFetchProducts,
    productDetailsRoute,
    productDetailsUrl,
    category,
}) => {
    const [hasSlidIn, setHasSlidIn] = useState(false);
    const mounted = usePromise();
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const priceService = useInjection<PriceService>(DependencyType.PriceService);
    const filteringService = useInjection<ProductFilteringService>(DependencyType.ProductFilteringService);
    const menuService = useInjection<MenuService>(DependencyType.MenuService);
    const history = useHistory();
    const [hasMore, setHasMore] = useState(true);
    const [filterSelectionHash, setFilterSelectionHash] = useState<string>('');
    const [products, setProducts] = useState<ProductSearchResultWithCursor[]>([]);
    const [currentFilterSelection, setCurrentFilterSelection] = useState(filteringService.getCurrentSelection());
    const pageRef = useRef<HTMLDivElement>(null);
    const pageHeight = useComputedStyle(pageRef, 'height');
    const wrapperSize = `calc(${pageHeight}px - ${getMenuHeight()}px)`;
    const gridParentDivRef = useRef<HTMLDivElement>(null);
    const gridSizes = generateGridProperties(gridParentDivRef, getDeviceDPI());

    const contentStyles: CSSProperties = {
        transform: hasSlidIn ? 'unset' : 'translateY(100%)',
        height: `${wrapperSize}`,
        overflow: 'scroll',
    };

    useEffect(() => {
        if (!hasSlidIn) {
            setHasSlidIn(true);
        }
        setHasSlidIn(true);
    }, [hasSlidIn]);

    useEffect(() => {
        const filterStateObserver = filteringService.observeFilterSelectionState();

        const filterStateObserverSubscription = filterStateObserver.subscribe(
            async (filterSelection: FilterSelection[]) => {
                setCurrentFilterSelection(filterSelection);

                //create a quick hash of the filter selection
                const hash = filterSelection
                    .map(filter => {
                        return `${filter.name}:${filter.values.join(',')}`;
                    })
                    .join(',');

                setFilterSelectionHash(hash);

                handleFetchProducts(filterSelection, { limit }, true)
                    .then(result => {
                        setHasMore(result.hasMore);
                        setProducts(result.products);
                    })
                    .catch(() => {
                        // Nothing to do here, we keep the existing products displayed.
                    });
            },
        );

        return () => filterStateObserverSubscription.unsubscribe();
    }, []);

    useEffect(() => {
        menuService.setMenuStyle(MenuStyle.BAR_AND_FILTER_BUTTON);
        menuService.setFilterButtonVisible(true);
    }, [menuService]);

    const loadMoreProducts = useCallback(async () => {
        if (!hasMore) {
            return;
        }

        let cursor = _.last(products)?.cursor;

        if (_.isNumber(cursor)) {
            cursor = cursor + 1;
        }
        const options = { limit, cursor };

        mounted(handleFetchProducts(currentFilterSelection, options, true))
            .then(result => {
                setHasMore(result.hasMore);
                setProducts(products.concat(result.products));
            })
            .catch(err => {
                Sentry.captureException(err, {
                    extra: {
                        operationName: 'loadMoreProducts',
                    },
                });
                console.log('error in loadMoreProducts', err);
                // Nothing to do here, we keep the existing products displayed.
            });
    }, [currentFilterSelection, handleFetchProducts, hasMore, mounted, products]);

    const items = useMemo(
        (): ResponsiveGridItemWithKey[] =>
            products.map(product => {
                const url = productDetailsUrl(product);
                const onSale =
                    parseFloat(product.minPriceOriginal.toString()) > parseFloat(product.minPrice.toString()) ||
                    parseFloat(product.maxPriceOriginal.toString()) > parseFloat(product.maxPrice.toString());

                let originalPrice: string | undefined = undefined;
                if (onSale) {
                    originalPrice = priceService.getPriceRange(product.minPriceOriginal, product.maxPriceOriginal);
                }
                const price = priceService.getPriceRange(product.minPrice, product.maxPrice);

                let bannerType: BannerType | undefined = undefined;
                if (!product.availableForSale) {
                    bannerType = 'soldOut';
                } else if (product.limitedAvailability) {
                    bannerType = 'limitedAvailability';
                } else if (product.orderOnly) {
                    bannerType = 'onOrder';
                } else if (product.availableForSale) {
                    bannerType = 'inStock';
                }

                let imageUrl = product.featuredImage;
                const imageSizeFloored = Math.floor(gridSizes.columnSize);
                if (imageUrl) {
                    imageUrl = getSizedImageURL(
                        imageUrl,
                        imageSizeFloored,
                        imageSizeFloored,
                        configService.imageAnchor,
                    );
                }

                return {
                    element: (
                        <ProductCard
                            key={product.id}
                            handle={product.handle}
                            imageUrl={imageUrl}
                            title={product.title}
                            price={price}
                            originalPrice={originalPrice}
                            onClicked={() => {
                                history.push(url);
                            }}
                            bannerType={bannerType}
                        />
                    ),
                    flipId: product.id,
                };
            }),
        [history, priceService, productDetailsUrl, products], //allFilterItems
    );

    const allFilterItems = filteringService.getBreadcrumbFilterItems();

    const filterChips = useMemo(() => {
        const selectedFilterChips = _.compact(
            _.map(allFilterItems, filterSelection => {
                const valuesWithDisplayNames: string[] = [];

                _.map(filterSelection.values, value => {
                    const displayValue = filteringService.getChipDisplayValue(filterSelection.definitionId, value);

                    if (displayValue) {
                        valuesWithDisplayNames.push(displayValue);
                    }
                });

                const valueString = valuesWithDisplayNames.join(' | ');
                return (
                    <Chip
                        className={'CategoryProducts__ContentWrapper__FilterContainer__Selected__Chip'}
                        renderStyle={'retailer'}
                        key={`filterchip-${filterSelection.name}-${valueString}`}
                        text={valueString}
                        trailing={
                            <div
                                className={'CategoryProducts__ContentWrapper__FilterContainer__Selected__Chip__Chevron'}
                            >
                                <ChevronIcon />
                            </div>
                        }
                        onClick={() => {
                            filteringService.removeAfterSpecificDefinition(filterSelection.mergeDefinitionId);

                            filteringService.commitSelection();
                        }}
                        translate={false}
                    />
                );
            }),
        );

        return selectedFilterChips;
    }, [allFilterItems]);

    const getSubcategoryCount = async (filter: CloudshelfEngineFilter, value: string) => {
        const selection = filteringService.getCurrentSelection();

        const mergedChildDefinitions = filteringService.findChildDefinitionsByParentAndValue(filter.id, value);

        for (const childDefinition of mergedChildDefinitions) {
            selection.push({
                mergeDefinitionId: filter.id,
                definitionId: childDefinition.id,
                name: childDefinition.ecommProviderFieldName,
                type: childDefinition.type,
                values: [value],
            });
        }

        selection.push({
            mergeDefinitionId: filter.id,
            definitionId: filter.id,
            name: filter.ecommProviderFieldName,
            type: filter.type,
            values: [value],
        });

        return (
            await filteringService.countMatchingProducts(
                'ProductPage -> getSubcategoryCount',
                category,
                selection,
                false,
            )
        ).toString();
    };

    const subcategoryfilterChips = useMemo(() => {
        if (products.length === 0) {
            return [];
        }
        const getSubcategoryFilter = filteringService.getSubcategoryFilterItem;

        if (!getSubcategoryFilter) {
            return [];
        }

        const attributes = getSubcategoryFilter?.attributeValues ?? [];

        const selectableFilterChips = _.compact(
            _.map(attributes, attr => {
                return (
                    <Chip
                        className={'CategoryProducts__ContentWrapper__FilterContainer__Options__Chip'}
                        renderStyle={'subcategory'}
                        key={`filterchip-${getSubcategoryFilter.displayName}-${attr.value}`}
                        text={`${filteringService.getChipDisplayValue(getSubcategoryFilter.id, attr.value)}`}
                        resetHash={filterSelectionHash}
                        badgeText={() => getSubcategoryCount(getSubcategoryFilter, attr.value)}
                        onClick={() => {
                            const mergedChildDefinitions = filteringService.findChildDefinitionsByParentAndValue(
                                getSubcategoryFilter.id,
                                attr.value,
                            );

                            for (const childDefinition of mergedChildDefinitions) {
                                filteringService.toggleValue(
                                    getSubcategoryFilter.id,
                                    childDefinition.id,
                                    childDefinition.ecommProviderFieldName,
                                    childDefinition.type,
                                    attr.value,
                                );
                            }

                            filteringService.toggleValue(
                                getSubcategoryFilter.id,
                                getSubcategoryFilter.id,
                                getSubcategoryFilter?.ecommProviderFieldName,
                                getSubcategoryFilter.type,
                                attr.value,
                            );

                            filteringService.commitSelection();
                        }}
                        translate={false}
                    />
                );
            }),
        );

        return selectableFilterChips;
    }, [currentFilterSelection, filterSelectionHash, products]);

    const filterChipStyle: CSSProperties = {};

    if (filterChips.length === 0) {
        filterChipStyle.height = '0px';
    }

    useEffect(() => {
        loadMoreProducts()
            .then(() => _.noop())
            .catch();
    }, []);

    return (
        <div className={'CategoryProducts'} ref={pageRef}>
            <div
                id="CategoryProducts__ContentWrapper"
                className={'CategoryProducts__ContentWrapper'}
                style={contentStyles}
            >
                <div className={'CategoryProducts__ContentWrapper__FilterContainer'}>
                    <div
                        className={'CategoryProducts__ContentWrapper__FilterContainer__Selected'}
                        style={filterChipStyle}
                    >
                        {filterChips}
                    </div>
                    <div className={'CategoryProducts__ContentWrapper__FilterContainer__Options'}>
                        <VirtualizeList
                            heightRule={
                                subcategoryfilterChips.length === 0
                                    ? '0'
                                    : 'calc(var(--responsive-reference-point) * 0.48)'
                            }
                            elements={subcategoryfilterChips}
                        />
                    </div>
                </div>
                <div className={'CategoryProducts__ContentWrapper__GridContainer'} ref={gridParentDivRef}>
                    <ResponsiveGrid
                        gridSizes={gridSizes}
                        items={items}
                        hasMoreItems={hasMore}
                        onMoreItemsRequested={loadMoreProducts}
                        shouldUseProductAnimations={configService.shouldUseProductAnimations}
                        scrollableTarget="CategoryProducts__ContentWrapper"
                    />
                </div>
            </div>
            <Switch>
                <Route exact path={productDetailsRoute}>
                    <ProductOpenerUtil />
                </Route>
            </Switch>
        </div>
    );
};
