import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { useMeasure } from 'react-use';
import _ from 'lodash';
import {
    CloudshelfThemeTileAnimation,
    PowerTileBackgroundType,
    PowerTileType,
} from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import {
    TileAnimationOrchestratorService,
    TileItem,
} from '../../../../../services/TileAnimationOrchestratorService/TileAnimationOrchestratorService';
import DependencyType from '../../../../../dependancyInjection/DependencyType';

import './AnimatedFlipCard.scss';
import { getSizedImageURL } from '../../../../../utils/ImageURL.Util';
import CatalogIcon from '../../../../icons/catalog';

export interface AnimatedFlipCardProps {
    originalItem: TileItem;
    animationStyle?: 'none' | CloudshelfThemeTileAnimation;
    flipOrientation?: 'vertical' | 'horizontal';
    stop: boolean;
    animationMultiplier?: number;
    index: number;
    textSizeModifier?: number;
    imageAnchor: string;
}

const AnimatedFlipCard: FC<AnimatedFlipCardProps> = props => {
    const tileAnimationOrchestrator = useInjection<TileAnimationOrchestratorService>(
        DependencyType.TileAnimationOrchestratorService,
    );
    const [cardRef, { width: height }] = useMeasure<HTMLDivElement>();
    const style: CSSProperties = {
        height,
    };
    const [animatedCount, setAnimatedCount] = useState(0);
    const [hasFlippedOnce, setHasFlippedOnce] = useState(false);
    const [displayBack, setDisplayBack] = useState(false);
    const [paused, setPaused] = useState(false);
    const [primaryItem, setPrimaryItem] = useState<TileItem | undefined>(undefined);
    const [secondaryItem, setSecondaryItem] = useState<TileItem | undefined>(undefined);
    const cardClassNames: string[] = ['AnimatedFlipCard__card'];
    const cardClassStyle: CSSProperties = {};
    const frontClassStyle: CSSProperties = {};
    const backClassStyle: CSSProperties = {};
    let primaryItemBranding: JSX.Element | undefined = undefined;
    let primaryItemCTA: JSX.Element | undefined = undefined;
    let secondaryItemBranding: JSX.Element | undefined = undefined;
    let secondaryItemCTA: JSX.Element | undefined = undefined;
    const frontClassNames: string[] = ['AnimatedFlipCard__content'];
    const backClassNames: string[] = ['AnimatedFlipCard__content'];

    const ctaFontStyle: CSSProperties = {
        fontSize: `${(props.textSizeModifier ?? 50) * 0.12}px`,
        lineHeight: `${(props.textSizeModifier ?? 50) * 0.12}px`,
    };

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (displayBack) {
            secondaryItem?.onClicked(e);
        } else {
            primaryItem?.onClicked(e);
        }
    };

    useEffect(() => {
        //set the original primary item
        if (props.originalItem.id !== primaryItem?.id) {
            setPrimaryItem(props.originalItem);
        }
        //Set the original secondary item
        if (props.originalItem.id !== secondaryItem?.id) {
            setSecondaryItem(props.originalItem);
        }
    }, []);

    useEffect(() => {
        const newSub = tileAnimationOrchestrator.observe().subscribe(screenState => {
            if (!screenState || Object.keys(screenState).length <= props.index) {
                return;
            }
            if (
                screenState[props.index].isShowingBack !== displayBack &&
                !paused &&
                !props.stop &&
                props.animationStyle !== 'none'
            ) {
                if (screenState[props.index].front.id !== primaryItem?.id) {
                    setPrimaryItem(screenState[props.index].front);
                }
                if (screenState[props.index].back.id !== secondaryItem?.id) {
                    setSecondaryItem(screenState[props.index].back);
                }
                if (screenState[props.index].isShowingBack !== displayBack) {
                    setDisplayBack(screenState[props.index].isShowingBack);
                }

                setAnimatedCount(animatedCount + 1);
                if (!hasFlippedOnce) {
                    setHasFlippedOnce(true);
                }
            }
        });

        if (props.stop) {
            if (hasFlippedOnce) {
                if (displayBack) {
                    if (secondaryItem?.id !== props.originalItem.id) {
                        if (props.originalItem.id !== primaryItem?.id) {
                            setPrimaryItem(props.originalItem);
                        }
                        if (displayBack) {
                            setDisplayBack(false);
                        }
                        setAnimatedCount(animatedCount + 1);
                    }
                } else {
                    if (primaryItem?.id !== props.originalItem.id) {
                        if (props.originalItem.id !== secondaryItem?.id) {
                            setSecondaryItem(props.originalItem);
                        }
                        if (!displayBack) {
                            setDisplayBack(true);
                        }
                        setAnimatedCount(animatedCount + 1);
                    }
                }
            }
        }

        return () => {
            if (newSub) {
                newSub.unsubscribe();
            }
        };
    }, [props.stop, props.originalItem, displayBack, secondaryItem, primaryItem, animatedCount, paused]);

    if (props.animationStyle === CloudshelfThemeTileAnimation.Flip) {
        frontClassNames.push('AnimatedFlipCard__content__front');

        if (props.flipOrientation === 'vertical') {
            backClassNames.push('AnimatedFlipCard__content__back__verticalFlip');
        } else {
            backClassNames.push('AnimatedFlipCard__content__back__horizontalFlip');
        }
        cardClassStyle.transform = `rotateX(${180 * animatedCount}deg)`;
    } else if (props.animationStyle === CloudshelfThemeTileAnimation.Fade) {
        if (displayBack) {
            backClassNames.push('AnimatedFlipCard__card__transition__fadeIn');
            frontClassNames.push('AnimatedFlipCard__card__transition__fadeOut');
        } else {
            backClassNames.push('AnimatedFlipCard__card__transition__fadeOut');
            frontClassNames.push('AnimatedFlipCard__card__transition__fadeIn');
        }
    }

    if (primaryItem) {
        if (primaryItem.backgroundType === PowerTileBackgroundType.SolidColour) {
            frontClassStyle.backgroundColor = primaryItem.backgroundPrimaryColor;
        } else if (primaryItem.backgroundType === PowerTileBackgroundType.Gradient) {
            frontClassStyle.background = `linear-gradient(${primaryItem.backgroundPrimaryColor}, ${primaryItem.backgroundSecondaryColor})`;
        } else if (primaryItem.backgroundType === PowerTileBackgroundType.Image) {
            if (
                !primaryItem.isAllCollectionTile ||
                (primaryItem.isAllCollectionTile && primaryItem.useImage && primaryItem.backgroundImage)
            ) {
                if (primaryItem.backgroundImage) {
                    const url = getSizedImageURL(primaryItem.backgroundImage, height, height, props.imageAnchor);
                    frontClassStyle.backgroundImage = `url(${url})`;
                }
            } else {
                frontClassStyle.backgroundColor = 'var(--primary-color)';
            }
        } else if (primaryItem.backgroundType === PowerTileBackgroundType.Transparent) {
            frontClassStyle.backgroundColor = 'transparent';
        }

        if (primaryItem.type === PowerTileType.Category) {
            primaryItemCTA = <>{primaryItem.title}</>;
        } else if (primaryItem.type === PowerTileType.Qrcode) {
            if (primaryItem.useIcon) {
                primaryItemBranding = <i className={`AnimatedFlipCard__branding__fontAwesome ${primaryItem.icon}`} />;
            } else {
                primaryItemBranding = <>{primaryItem.title}</>;
            }
            primaryItemCTA = <div className="AnimatedFlipCard__cta__button">{primaryItem.callToAction}</div>;
        }
    }

    if (secondaryItem) {
        if (secondaryItem.backgroundType === PowerTileBackgroundType.SolidColour) {
            backClassStyle.backgroundColor = secondaryItem.backgroundPrimaryColor;
        } else if (secondaryItem.backgroundType === PowerTileBackgroundType.Gradient) {
            backClassStyle.background = `linear-gradient(${secondaryItem.backgroundPrimaryColor}, ${secondaryItem.backgroundSecondaryColor})`;
        } else if (secondaryItem.backgroundType === PowerTileBackgroundType.Image) {
            if (
                !secondaryItem.isAllCollectionTile ||
                (secondaryItem.isAllCollectionTile && secondaryItem.useImage && secondaryItem.backgroundImage)
            ) {
                if (secondaryItem.backgroundImage) {
                    const url = getSizedImageURL(secondaryItem.backgroundImage, height, height, props.imageAnchor);
                    backClassStyle.backgroundImage = `url(${url})`;
                }
            } else {
                backClassStyle.backgroundColor = 'var(--primary-color)';
            }
        } else if (secondaryItem.backgroundType === PowerTileBackgroundType.Transparent) {
            backClassStyle.backgroundColor = 'transparent';
        }

        if (secondaryItem.type === PowerTileType.Category) {
            secondaryItemCTA = <>{secondaryItem.title}</>;
        } else if (secondaryItem.type === PowerTileType.Qrcode) {
            if (secondaryItem.useIcon) {
                secondaryItemBranding = (
                    <i className={`AnimatedFlipCard__branding__fontAwesome ${secondaryItem.icon}`} />
                );
            } else {
                secondaryItemBranding = <>{secondaryItem.title}</>;
            }
            secondaryItemCTA = <div className="AnimatedFlipCard__cta__button">{secondaryItem.callToAction}</div>;
        }
    }

    return (
        <div
            style={style}
            ref={cardRef}
            className="AnimatedFlipCard"
            onMouseOver={() => {
                setPaused(true);
            }}
            onMouseOut={() => {
                setPaused(false);
            }}
            onClick={handleClick}
        >
            <div className={_.join(cardClassNames, ' ')} style={cardClassStyle}>
                <div className={_.join(frontClassNames, ' ')} style={frontClassStyle}>
                    {primaryItemBranding && <div className="AnimatedFlipCard__branding">{primaryItemBranding}</div>}
                    <div className="AnimatedFlipCard__overlay" />
                    <div className="AnimatedFlipCard__ctaContainer">
                        <span className="AnimatedFlipCard__cta" style={ctaFontStyle}>
                            {primaryItemCTA}
                        </span>
                    </div>
                </div>
                {props.animationStyle !== 'none' && (
                    <div className={_.join(backClassNames, ' ')} style={backClassStyle}>
                        {secondaryItemBranding && (
                            <div className="AnimatedFlipCard__branding">{secondaryItemBranding}</div>
                        )}
                        <div className="AnimatedFlipCard__overlay" />
                        <div className="AnimatedFlipCard__ctaContainer">
                            <span className="AnimatedFlipCard__cta" style={ctaFontStyle}>
                                {secondaryItemCTA}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

AnimatedFlipCard.defaultProps = {
    animationStyle: 'none',
    animationMultiplier: 1,
};

export default AnimatedFlipCard;
