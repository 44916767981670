import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { PurchaseCallback } from '../Purchase/PurchaseCallback';
import { BasketService } from '../../../../../services/BasketService/BasketService';
import { Basket } from '../../../../../services/BasketService/Basket.type';
import './CheckoutTotalOverlay.scss';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import Button from '../../../../shared/inputs/Button/Button';

export interface CheckoutTotalOverlayProps {
    onPurchase: PurchaseCallback;
    loading: boolean;
}

export const CheckoutTotalOverlay: React.FC<CheckoutTotalOverlayProps> = ({ onPurchase, loading }) => {
    const { t } = useTranslation();
    const basketService = useInjection<BasketService>(DependencyType.BasketService);
    const [basket, setBasket] = useState<Basket>();

    useEffect(() => {
        const subscriber = basketService.observeBasket().subscribe(updatedBasket => {
            setBasket(updatedBasket);
        });
        return () => {
            subscriber.unsubscribe();
        };
    }, [basketService]);

    const totalPrice = basket !== undefined ? basketService.totalPrice() : 0;
    const subTotalPrice = basket !== undefined ? basketService.subTotalPrice() : 0;
    const discount = basket !== undefined ? basketService.discount() : 0;
    const tax = basket !== undefined ? basketService.estimatedTax() : 0;

    const handleCheckout = useCallback(() => {
        if (!basket) {
            return;
        }
        onPurchase(basket);
    }, [basket, onPurchase]);

    console.log('prices', subTotalPrice, totalPrice, discount);
    return (
        <div className="CheckoutTotalOverlay">
            <div className={'CheckoutTotalOverlay__summary'}>
                {!discount && subTotalPrice !== totalPrice && (
                    <div className="CheckoutTotalOverlay__totalContainer">
                        <StyledText
                            className="CheckoutTotalOverlay__totalLabel"
                            style={TextStyle.Subheading}
                            size={TextSize.Medium}
                            translate
                        >
                            Summary
                        </StyledText>

                        <StyledText
                            className="CheckoutTotalOverlay__totalLabel"
                            style={TextStyle.Subheading}
                            size={TextSize.Medium}
                        >
                            {subTotalPrice}
                        </StyledText>
                    </div>
                )}
                {discount && (
                    <>
                        <div className="CheckoutTotalOverlay__totalContainer">
                            <StyledText
                                className="CheckoutTotalOverlay__totalLabel"
                                style={TextStyle.Subheading}
                                size={TextSize.Medium}
                                translate
                            >
                                Summary
                            </StyledText>

                            <StyledText
                                className="CheckoutTotalOverlay__subTotalPrice"
                                style={TextStyle.Subheading}
                                size={TextSize.Medium}
                            >
                                {subTotalPrice}
                            </StyledText>
                        </div>
                        {discount && (
                            <div className="CheckoutTotalOverlay__totalContainer">
                                <StyledText
                                    className="CheckoutTotalOverlay__totalLabel"
                                    style={TextStyle.Body}
                                    size={TextSize.Small}
                                    translate
                                >
                                    <span>Discount:</span>
                                </StyledText>
                                <StyledText
                                    className="CheckoutTotalOverlay__discount"
                                    style={TextStyle.Subheading}
                                    size={TextSize.Medium}
                                >
                                    <span>{discount}</span>
                                </StyledText>
                            </div>
                        )}
                    </>
                )}
                {/*<br />*/}
                {tax && (
                    <div className="CheckoutTotalOverlay__totalContainer">
                        <StyledText
                            className="CheckoutTotalOverlay__totalLabel"
                            style={TextStyle.Body}
                            size={TextSize.Small}
                            translate
                        >
                            <span>Estimated Tax:</span>
                        </StyledText>
                        <StyledText
                            className="CheckoutTotalOverlay__totalLabel"
                            style={TextStyle.Subheading}
                            size={TextSize.Medium}
                        >
                            <span>{tax}</span>
                        </StyledText>
                    </div>
                )}
                <div className="CheckoutTotalOverlay__totalContainer">
                    <StyledText
                        className="CheckoutTotalOverlay__totalLabel"
                        style={TextStyle.Body}
                        size={TextSize.Small}
                        translate
                    >
                        Total (excluding delivery)
                    </StyledText>

                    <StyledText
                        className="CheckoutTotalOverlay__totalLabel"
                        style={TextStyle.Subheading}
                        size={TextSize.Medium}
                        translate
                    >
                        {totalPrice}
                    </StyledText>
                </div>
            </div>
            <Button
                className="CheckoutTotalOverlay__checkoutButton"
                onClick={handleCheckout}
                loading={loading}
                showLoader
                translate
            >
                {t('checkout_view.phone_checkout')}
            </Button>
        </div>
    );
};
