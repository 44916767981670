import React, { FC, useEffect } from 'react';
import StarsBackground from '../../../shared/StarsBackground/StarsBackground';
import { useHistory } from 'react-router-dom';
import { useDeviceInfo } from '../../../../hooks/UseDeviceInfo';
import { useInjection } from '../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../dependancyInjection/DependencyType';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import * as Sentry from '@sentry/react';
import { Routes } from '../../../../services/RoutesService/Routes';
import { DeviceService } from '../../../../services/ConfigurationService/DeviceService';
import { DotWave } from '@uiball/loaders';
import '../PairingApp.scss';
import NonResponsiveStyledText, {
    NonResponsiveTextSize,
    NonResponsiveTextStyle,
} from '../../../shared/StyledText/NonResponsiveStyledText';
import ShareAppleIcon from '../../../icons/share_apple';

const PairDeviceComponent: FC = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const [localDeviceInfo, setDeviceInfo] = useDeviceInfo();
    const [hasDoneRequest, setHasDoneRequest] = React.useState(false);
    const DeviceService = useInjection<DeviceService>(DependencyType.DeviceService);

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                const remoteDeviceInfo = await DeviceService.getDeviceInfo(localDeviceInfo.id);

                if (!remoteDeviceInfo) {
                    setDeviceInfo({
                        id: '',
                        code: '',
                        registered: false,
                        bigFormatDisplay: false,
                    });
                    history.replace(Routes.ROOT);
                    return;
                }
                if (remoteDeviceInfo.registered) {
                    setDeviceInfo(remoteDeviceInfo);
                    window.location.pathname = Routes.ROOT;
                } else {
                    setDeviceInfo(remoteDeviceInfo);
                    setHasDoneRequest(true);
                }
            } catch (err: any) {
                if (err.message && _.includes(err.message, 'No device found')) {
                    setDeviceInfo({
                        id: '',
                        code: '',
                        registered: false,
                        bigFormatDisplay: false,
                    });
                } else {
                    Sentry.captureException(err, {
                        extra: {
                            operationName: 'pairDevice useEffect',
                        },
                    });
                    throw err;
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [localDeviceInfo]);

    const isChrome = () => {
        const isChromium = (window as any).chrome;
        const winNav = window.navigator;
        const vendorName = winNav.vendor;
        const isOpera = typeof (window as any).opr !== 'undefined';
        const isIEedge = winNav.userAgent.indexOf('Edg') > -1;
        const isIOSChrome = winNav.userAgent.match('CriOS');
        const userAgentData = (window.navigator as any).userAgentData;
        const hasChromeBrand =
            (userAgentData && _.some(userAgentData.brands, brand => brand.brand.toLowerCase() === 'google chrome')) ??
            false;
        const isBrave = (winNav as any).brave ?? false;

        if (isIOSChrome || hasChromeBrand) {
            return true;
        }

        if (isBrave) {
            return false;
        }

        return (
            isChromium !== null &&
            typeof isChromium !== 'undefined' &&
            vendorName === 'Google Inc.' &&
            !isOpera &&
            !isIEedge
        );
    };

    const w = window.navigator as any;
    const isIos =
        _.includes(['iPhone', 'iPad', 'iPod'], window.navigator.platform) ||
        (window.navigator.platform === 'MacIntel' && w.maxTouchPoints === 5);
    const isAndroid = /Android/.test(window.navigator.userAgent);
    const isStandalone = w.standalone || window.matchMedia('(display-mode: standalone)').matches;

    const isMobileSafari = () => {
        const hasSafariUserAgent = /^((?!chrome|android|edg).)*safari/i.test(navigator.userAgent);
        const hasApplePay = typeof (window as any).ApplePaySession !== 'undefined';
        return hasSafariUserAgent && hasApplePay && isIos;
    };

    const isFullyKiosk = (window as any).FullyKiosk !== undefined;

    const isAndroidKiosk = navigator.userAgent.startsWith('Cloudshelf-AndroidKiosk');

    const isTizen4OrAbove = navigator.userAgent.includes('Tizen 4.');

    const isChromeWebview = navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('wv');

    const isAPermittedNonMobileBrowser =
        isStandalone || isFullyKiosk || isAndroidKiosk || isTizen4OrAbove || isChromeWebview;

    return (
        <StarsBackground>
            <div className="title">
                <NonResponsiveStyledText
                    style={NonResponsiveTextStyle.CloudshelfBold}
                    size={NonResponsiveTextSize.Large}
                    center
                    translate
                    className={'white'}
                >
                    {t('pairing.pair.title')}
                </NonResponsiveStyledText>
                {((!isIos && !isAndroid) || isAPermittedNonMobileBrowser) && (
                    <>
                        <NonResponsiveStyledText
                            style={NonResponsiveTextStyle.Cloudshelf}
                            size={NonResponsiveTextSize.Small}
                            center
                            translate
                            className={'white'}
                        >
                            {t('pairing.pair.info_one')}
                        </NonResponsiveStyledText>
                        <NonResponsiveStyledText
                            style={NonResponsiveTextStyle.Cloudshelf}
                            size={NonResponsiveTextSize.Small}
                            center
                            translate
                            className={'white'}
                        >
                            {t('pairing.pair.info_two')}
                        </NonResponsiveStyledText>
                        <h1 className="deviceCode">
                            {hasDoneRequest ? localDeviceInfo.code : <DotWave color="white" size={140} />}
                        </h1>
                        <NonResponsiveStyledText
                            style={NonResponsiveTextStyle.Cloudshelf}
                            size={NonResponsiveTextSize.Small}
                            center
                            translate
                            className={'white'}
                        >
                            {t('pairing.pair.info_three')}
                        </NonResponsiveStyledText>
                    </>
                )}
                {!isStandalone && isIos && !isMobileSafari() && (
                    <NonResponsiveStyledText
                        style={NonResponsiveTextStyle.Cloudshelf}
                        size={NonResponsiveTextSize.Small}
                        center
                        translate
                        className={'white'}
                    >
                        {t('pairing.pair.not_safari_ios')}
                    </NonResponsiveStyledText>
                )}
                {!isStandalone && isIos && isMobileSafari() && (
                    <>
                        <NonResponsiveStyledText
                            style={NonResponsiveTextStyle.Cloudshelf}
                            size={NonResponsiveTextSize.Small}
                            center
                            translate
                            className={'white'}
                        >
                            {t('pairing.pair.warning')}
                        </NonResponsiveStyledText>
                        <br />
                        <br />
                        <NonResponsiveStyledText
                            style={NonResponsiveTextStyle.Cloudshelf}
                            size={NonResponsiveTextSize.Small}
                            center
                            translate
                            className={'white'}
                        >
                            {t('pairing.pair.help_title')}
                        </NonResponsiveStyledText>
                        <br />
                        <br />
                        <NonResponsiveStyledText
                            style={NonResponsiveTextStyle.Cloudshelf}
                            size={NonResponsiveTextSize.Small}
                            center
                            translate
                            className={'white'}
                        >
                            {t('pairing.pair.safari_ios_one_left')}
                            <ShareAppleIcon />
                            {t('pairing.pair.safari_ios_one_right')}
                        </NonResponsiveStyledText>
                        <NonResponsiveStyledText
                            style={NonResponsiveTextStyle.Cloudshelf}
                            size={NonResponsiveTextSize.Small}
                            center
                            translate
                            className={'white'}
                        >
                            {t('pairing.pair.safari_ios_two')}
                        </NonResponsiveStyledText>
                        <NonResponsiveStyledText
                            style={NonResponsiveTextStyle.Cloudshelf}
                            size={NonResponsiveTextSize.Small}
                            center
                            translate
                            className={'white'}
                        >
                            {t('pairing.pair.safari_ios_three')}
                        </NonResponsiveStyledText>
                        <NonResponsiveStyledText
                            style={NonResponsiveTextStyle.Cloudshelf}
                            size={NonResponsiveTextSize.Small}
                            center
                            translate
                            className={'white'}
                        >
                            {t('pairing.pair.safari_ios_four')}
                        </NonResponsiveStyledText>
                    </>
                )}
                {!isStandalone && isAndroid && !isFullyKiosk && !isChrome() && !isAndroidKiosk && !isTizen4OrAbove && (
                    <NonResponsiveStyledText
                        style={NonResponsiveTextStyle.Cloudshelf}
                        size={NonResponsiveTextSize.Small}
                        center
                        translate
                        className={'white'}
                    >
                        {t('pairing.pair.not_chrome_android')}
                    </NonResponsiveStyledText>
                )}
                {!isStandalone && isAndroid && isChrome() && (
                    <>
                        <NonResponsiveStyledText
                            style={NonResponsiveTextStyle.Cloudshelf}
                            size={NonResponsiveTextSize.Small}
                            center
                            translate
                            className={'white'}
                        >
                            {t('pairing.pair.warning')}
                        </NonResponsiveStyledText>
                        <br />
                        <br />
                        <NonResponsiveStyledText
                            style={NonResponsiveTextStyle.Cloudshelf}
                            size={NonResponsiveTextSize.Small}
                            center
                            translate
                            className={'white'}
                        >
                            {t('pairing.pair.help_title')}
                        </NonResponsiveStyledText>
                        <br />
                        <br />
                        <NonResponsiveStyledText
                            style={NonResponsiveTextStyle.Cloudshelf}
                            size={NonResponsiveTextSize.Small}
                            center
                            translate
                            className={'white'}
                        >
                            {t('pairing.pair.chrome_android_one')}
                        </NonResponsiveStyledText>
                        <NonResponsiveStyledText
                            style={NonResponsiveTextStyle.Cloudshelf}
                            size={NonResponsiveTextSize.Small}
                            center
                            translate
                            className={'white'}
                        >
                            {t('pairing.pair.chrome_android_two')}
                        </NonResponsiveStyledText>
                        <NonResponsiveStyledText
                            style={NonResponsiveTextStyle.Cloudshelf}
                            size={NonResponsiveTextSize.Small}
                            center
                            translate
                            className={'white'}
                        >
                            {t('pairing.pair.chrome_android_three')}
                        </NonResponsiveStyledText>
                        <NonResponsiveStyledText
                            style={NonResponsiveTextStyle.Cloudshelf}
                            size={NonResponsiveTextSize.Small}
                            center
                            translate
                            className={'white'}
                        >
                            {t('pairing.pair.chrome_android_four')}
                        </NonResponsiveStyledText>
                    </>
                )}
            </div>
        </StarsBackground>
    );
};

export default PairDeviceComponent;
