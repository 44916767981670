import { AsyncContainerModule } from 'inversify';
import { dependenciesContainer } from '../dependancyInjection/DependenciesInitializer';
import DependencyType from '../dependancyInjection/DependencyType';
import { CloudshelfEngineConfig } from '../services/ConfigurationService/types/config/CloudshelfEngineConfig';
import { ConfigurationService } from '../services/ConfigurationService/ConfigurationService';

const ProviderUtils = {
    async providerModule(): Promise<AsyncContainerModule> {
        const config = await this.providerConfiguration();
        if (!config) {
            throw new Error('Config not loaded');
        }
        const { provider } = config;
        const imported = await import(`./${provider.toLowerCase()}/Module`);
        return imported.default;
    },

    /**
     * The configuration is returned as is. A provider, once identified thanks to the `configurationProvider` method,
     * will be responsible for converting the configuration to a Cloudshelf. Consequently, the configuration may have a
     * different format between providers (URL, serialized JSON, etc.).
     */
    async providerConfiguration<T extends CloudshelfEngineConfig | undefined>(): Promise<T> {
        const configurationService = dependenciesContainer.get<ConfigurationService>(
            DependencyType.ConfigurationService,
        );
        const config = configurationService.config();
        if (!config) {
            throw new Error('Config not loaded');
        }

        return config as T;
    },
};

export default ProviderUtils;
