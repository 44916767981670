import React, { FC } from 'react';

const ShoppingCartIcon: FC = props => {
    return (
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.611328 2.74997C0.611328 3.92636 1.57383 4.88886 2.75022 4.88886H4.8891L12.5891 21.123L9.7016 26.3419C8.14021 29.208 10.1935 32.6944 13.4447 32.6944H36.9724C38.1488 32.6944 39.1113 31.7319 39.1113 30.5555C39.1113 29.3791 38.1488 28.4166 36.9724 28.4166H13.4447L15.7974 24.1389H31.7322C33.3363 24.1389 34.748 23.2619 35.4752 21.9358L43.1324 8.05442C43.9238 6.64275 42.8972 4.88886 41.2716 4.88886H9.61605L8.18299 1.83025C7.84077 1.08164 7.07077 0.611084 6.25799 0.611084H2.75022C1.57383 0.611084 0.611328 1.57358 0.611328 2.74997ZM13.4444 34.8335C11.0917 34.8335 9.18806 36.7585 9.18806 39.1113C9.18806 41.4641 11.0917 43.3891 13.4444 43.3891C15.7972 43.3891 17.7222 41.4641 17.7222 39.1113C17.7222 36.7585 15.7972 34.8335 13.4444 34.8335ZM30.5769 39.1113C30.5769 36.7585 32.4806 34.8335 34.8333 34.8335C37.1861 34.8335 39.1111 36.7585 39.1111 39.1113C39.1111 41.4641 37.1861 43.3891 34.8333 43.3891C32.4806 43.3891 30.5769 41.4641 30.5769 39.1113Z"
                fill="white"
            />
        </svg>
    );
};
export default ShoppingCartIcon;
