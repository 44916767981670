import StyledText, { TextSize, TextStyle } from '../../../../../shared/StyledText/StyledText';
import _ from 'lodash';
import { FC } from 'react';
import DOMPurify from 'dompurify';

import styles from './ProductDescription.module.scss';

export interface ProductDescriptionProps {
    content?: string;
    removeThemeShortcodes: boolean;
}

const ProductDescription: FC<ProductDescriptionProps> = props => {
    const cleanDescription = (description?: string): string => {
        let desc = description ?? '';
        if (props.removeThemeShortcodes) {
            desc = _.replace(desc, /(\[.*?\])/g, '');
        }

        return desc;
    };

    const cleaned = DOMPurify.sanitize(cleanDescription(props.content));

    if (_.isEmpty(cleaned)) {
        return null;
    }

    return (
        <div className={styles.ProductDescription}>
            <StyledText
                style={TextStyle.Body}
                size={TextSize.Small}
                className={styles.ProductDescription__AllowDefaultStyles}
            >
                <span dangerouslySetInnerHTML={{ __html: cleaned }} />
            </StyledText>
        </div>
    );
};

export default ProductDescription;
