import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useStateRef from 'react-usestateref';
import { useHistory } from 'react-router-dom';
import ProductTitle from './buildingBlocks/ProductTitle';
import _ from 'lodash';
import ProductImages from './buildingBlocks/ProductImages';
import ProductCartOptions from './buildingBlocks/ProductCartOptions';
import * as Sentry from '@sentry/react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import { ProductDetails } from '../../../../../services/ProductServices/variant/ProductVariantService';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { EventsService } from '../../../../../services/EventsService/EventsService';
import ProductVariant from '../../../../../services/ProductServices/variant/ProductVariant';
import ProductSKU from './buildingBlocks/ProductSKU';
import ProductVendor from './buildingBlocks/ProductVendor';
import PriceService from '../../../../../services/PriceService/PriceService';
import ProductCustomiserSection, { CustomAttributes } from './buildingBlocks/customisation/ProductCustomiserSection';
import { ProductFilteringService } from '../../../../../services/ProductServices/ProductFilteringService';
import ProductVariants from './buildingBlocks/variants/ProductVariants';
import ProductAvailability from './buildingBlocks/ProductAvailability';
import { Skeleton } from '../../../../shared/Skeleton/Skeleton';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import ProductPrice from './buildingBlocks/ProductPrice';
import styles from './ProductDisplayView.module.scss';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { SlidingModal, SlidingModalOrigin } from '../SlidingModal/SlidingModal';
import { Routes } from '../../../../../services/RoutesService/Routes';
import { RoutesHelperService } from '../../../../../services/RoutesService/RoutesHelperService';
import { SessionManagementService } from '../../../../../services/SessionManagementService/SessionManagementService';
import { SessionEvent, SessionEventType } from '../../../../../services/SessionManagementService/SessionEvent';
import { smoothScrollWrapper } from '../../../../../utils/ScrollIntoView.Util';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import {
    PdpBlockProductDataType,
    PdpBlockStyle,
} from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import Accordion from '../../../../shared/Accordion/Accordion';
import { ConditionalWrapper } from '../../../../shared/ConditionalWrapper';
import ProductDescription from './buildingBlocks/ProductDescription';
import ProductMetafield from './buildingBlocks/ProductMetafield';
import { BackButtonMode, MenuService } from '../../../../../services/MenuService/MenuService';
import { useRouteMatch } from 'react-router';
import { LocalProductImage } from '../../../../../services/ProductServices/LocalProduct';
import ProductBookThatAppSection from './buildingBlocks/bta/ProductBookThatAppSection';

export interface ProductDisplayViewProps {
    blocks?: string[];
    handoffCustomisationFieldId?: number;
    hideCartOptions?: boolean;
}

const ProductDetailsView: FC<ProductDisplayViewProps> = React.memo(props => {
    const menuService = useInjection<MenuService>(DependencyType.MenuService);
    const routeMatch = useRouteMatch(Routes.CATEGORY_PRODUCT);
    const [isOpen, setIsOpen, isOpenRef] = useStateRef(false);
    const history = useHistory();
    const { t } = useTranslation();
    const [openProductHandle, setOpenProductHandle] = React.useState<string>();
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);
    const priceService = useInjection<PriceService>(DependencyType.PriceService);
    const filterService = useInjection<ProductFilteringService>(DependencyType.ProductFilteringService);
    const sessionManagementService = useInjection<SessionManagementService>(DependencyType.SessionManagementService);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const [productDetails, setProductDetails] = useState<ProductDetails>();
    const [selectedVariant, setSelectedVariant] = useState<ProductVariant>();
    const [loading, setLoading] = useState<boolean>(true);
    const [formValid, setFormValid] = useState<boolean>(true);
    const [btaFormValid, setBTAFormValid] = useState<boolean>(true);
    const [forceShowWarnings, setForceShowWarnings] = useState<boolean>(false);
    const [, setCustomOptions, customOptions] = useStateRef<CustomAttributes>({});
    const [, setBTAOptions, btaOptions] = useStateRef<CustomAttributes>({});
    const [, setProductCustomiserPriceModifier, productCustomiserPriceModifier] = useStateRef<number>(0);
    const variantOptionsRef = useRef<HTMLElement>(null);
    const customiserRef = useRef<HTMLElement>(null);
    const btaRef = useRef<HTMLElement>(null);
    const imagesRef = useRef<HTMLElement>(null);
    const [imageIndex, setImageIndex] = useState(0);
    const [galleryExpanded, setGalleryExpanded] = useState<boolean>(false);
    const [maxPurchaseQuantity, setMaxPurchaseQuantity] = useState<number>(Number.MAX_VALUE);

    const mergedCustomAttributes: CustomAttributes = { ...customOptions.current, ...btaOptions.current };

    const handleSetMaxPurchaseQuantity = (quantity: number) => {
        setMaxPurchaseQuantity(quantity);
    };

    const handleSetImageIndex = (idx: number) => {
        setImageIndex(idx);
    };

    const [fixedBuildingBlockOrder] = useState(
        props.blocks ?? [
            'BLOCK:PRODUCT_TITLE',
            'BLOCK:PRODUCT_PRICE',
            'BLOCK:PRODUCT_AVAILABILITY',
            'BLOCK:PRODUCT_VARIANTS',
            'BLOCK:PRODUCT_CUSTOMISER',
            'BLOCK:PRODUCT_BOOK_THAT_APP',
        ],
    );

    const [configProvidedBuildingBlockOrder] = useState(
        _.orderBy(configService.config()?.pdpBlocks ?? [], b => b.position),
    );

    useEffect(() => {
        const sessionObserver = sessionManagementService.observe();

        const sessionObserverSubscription = sessionObserver.subscribe((event: SessionEvent) => {
            if (event.type === SessionEventType.Ended) {
                if (isOpenRef.current) {
                    setIsOpen(!isOpenRef.current);
                    setProductDetails(undefined);
                    setSelectedVariant(undefined);
                    eventsService.setOpenProduct(undefined);
                    history.push(Routes.CATEGORIES);
                }
            }
        });

        return () => sessionObserverSubscription.unsubscribe();
    }, []);

    const metafields = React.useMemo(() => {
        if (openProductHandle) {
            return filterService.getProductVariantByHandle(openProductHandle)?.productMetafields ?? [];
        }
        return [];
    }, [openProductHandle]);

    const images: LocalProductImage[] = useMemo(() => {
        if (!productDetails) {
            return [];
        }

        const productImages: LocalProductImage[] = productDetails.images;
        if (productDetails.featuredImage) {
            productImages.unshift({ url: productDetails.featuredImage });
        }
        return _.chain(productImages)
            .filter(imageInfo => !!imageInfo.url && imageInfo.url !== '')
            .uniqBy(imageInfo => imageInfo.url)
            .value();
    }, [productDetails]);

    let originalPriceString: string | undefined;
    let priceString: string | undefined;
    if (selectedVariant) {
        // We have a selected variant so need to determine if it's on sale
        if (selectedVariant.isOnSale) {
            originalPriceString = priceService.getOriginalPrice(selectedVariant);
        }
        priceString = priceService.getPrice(selectedVariant);
    } else {
        // We don't have a selected variant, so need to show price ranges
        const variants = productDetails?.variants.valueSeq().toArray() ?? [];
        if (_.some(variants, variant => variant.isOnSale)) {
            originalPriceString = priceService.getPriceRange(
                _.min(_.map(variants, variant => variant.originalPrice)) ?? 0,
                _.max(_.map(variants, variant => variant.originalPrice)) ?? 0,
            );
        }
        priceString = priceService.getPriceRange(
            _.min(_.map(variants, variant => variant.price)) ?? 0,
            _.max(_.map(variants, variant => variant.price)) ?? 0,
        );
    }

    const closeProductDetailsView = useCallback(() => {
        if (!isOpenRef.current) {
            return;
        }

        menuService.setBackButtonMode(BackButtonMode.BACK);
        menuService.setFilterButtonVisible(true);
        setIsOpen(!isOpenRef.current);
        setProductDetails(undefined);
        setSelectedVariant(undefined);
        eventsService.setOpenProduct(undefined);
        const openCategory = eventsService.openCategory;
        if (openCategory) {
            history.push(RoutesHelperService.toCategoryProducts(openCategory));
        } else {
            history.push(Routes.CATEGORIES);
        }
    }, [setIsOpen, menuService]);

    const handleSetVariant = (variant: ProductVariant | undefined) => {
        setSelectedVariant(variant);
        setMaxPurchaseQuantity(Number.MAX_VALUE);
    };

    useEffect(() => {
        (async () => {
            if (openProductHandle) {
                try {
                    setIsOpen(true);
                    setLoading(true);

                    const productVariantDetails = filterService.getProductDetailsByHandle(openProductHandle, true);

                    if (!productVariantDetails) {
                        // Product could not be found
                        closeProductDetailsView();
                        return;
                    }

                    setProductDetails(productVariantDetails);
                    const { variants } = productVariantDetails;
                    if (variants.size === 1) {
                        const firstVariant = variants.first();
                        handleSetVariant(firstVariant);
                    }
                    setLoading(false);
                } catch (e) {
                    Sentry.captureException(e, {
                        extra: {
                            operationName: 'PDP useeffect',
                        },
                    });
                    closeProductDetailsView();
                }
            }
        })();
    }, [openProductHandle]);

    useEffect(() => {
        const subscriber = menuService.observeBackTapped().subscribe(() => {
            closeProductDetailsView();
        });
        return () => {
            subscriber?.unsubscribe();
        };
    }, [menuService, closeProductDetailsView]);

    useEffect(() => {
        if (isOpen) {
            if (routeMatch?.isExact) {
                menuService.setBackButtonMode(BackButtonMode.CLOSE);
                menuService.setFilterButtonVisible(false);
            }
        }
    }, [menuService, isOpen, routeMatch]);

    useEffect(() => {
        const openProductObservable = eventsService.observeOpenProduct();
        const subscriber = openProductObservable.subscribe(handle => {
            setForceShowWarnings(false);
            setOpenProductHandle(handle);
            setImageIndex(0);
            setGalleryExpanded(false);
            setMaxPurchaseQuantity(Number.MAX_VALUE);
        });

        const currentHandle = eventsService.openProduct;
        if (currentHandle) {
            setForceShowWarnings(false);
            setOpenProductHandle(currentHandle);
            setImageIndex(0);
            setGalleryExpanded(false);
        }

        return () => {
            subscriber?.unsubscribe();
        };
    }, []);

    const handleScroll = (to: 'variants' | 'customiser' | 'images') => {
        setTimeout(() => {
            if (to === 'variants') {
                smoothScrollWrapper(variantOptionsRef.current, { behavior: 'smooth', block: 'end' });
                toast(() => (
                    <StyledText
                        style={TextStyle.Subheading}
                        size={TextSize.Small}
                        className={styles.productDisplayView__toast}
                        translate
                    >
                        <i className={`fa-solid fa-triangle-exclamation`} />
                        <p>{t('product_view.toasts.variant_warning')}</p>
                    </StyledText>
                ));
            } else if (to === 'customiser') {
                smoothScrollWrapper(customiserRef.current, { behavior: 'smooth', block: 'end' });
                toast(() => (
                    <StyledText
                        style={TextStyle.Subheading}
                        size={TextSize.Small}
                        className={styles.productDisplayView__toast}
                        translate
                    >
                        <i className={`fa-solid fa-triangle-exclamation`} />
                        <p>{t('product_view.toasts.customiser_warning')}</p>
                    </StyledText>
                ));
            } else if (to === 'images') {
                smoothScrollWrapper(imagesRef.current, { behavior: 'smooth', block: 'end' });
            }
        }, 50);

        return;
    };

    const handleSetSelectedVar = (selectedVar: ProductVariant | undefined) => {
        handleSetVariant(selectedVar);
        let index = imageIndex;
        if (selectedVar) {
            if (selectedVar.image) {
                index = images.findIndex(image => image.url === selectedVar?.image);
            }
        }

        if (imageIndex !== index) {
            //Avoid setting the state if it is not needed
            setImageIndex(index);
        }
    };

    const handleSetSelectedPartialVar = (selectedVar: ProductVariant | undefined) => {
        let index = imageIndex;
        if (selectedVar) {
            if (selectedVar.image) {
                index = images.findIndex(image => image.url === selectedVar?.image);
            }
            handleSetVariant(selectedVar);
        }

        if (imageIndex !== index) {
            //Avoid setting the state if it is not needed
            setImageIndex(index);
        }
    };

    const viewContent = useMemo(() => {
        return (
            <>
                {_.compact(
                    fixedBuildingBlockOrder.map(buildingBlock => {
                        if (_.startsWith(buildingBlock, 'BLOCK:')) {
                            if (buildingBlock === 'BLOCK:PRODUCT_TITLE') {
                                return (
                                    <ProductTitle
                                        key={buildingBlock + (productDetails?.handle ?? '')}
                                        content={productDetails?.title}
                                    />
                                );
                            } else if (buildingBlock === 'BLOCK:PRODUCT_PRICE') {
                                return (
                                    <ProductPrice
                                        key={buildingBlock + (productDetails?.handle ?? '')}
                                        priceString={priceString}
                                        originalPriceString={originalPriceString}
                                    />
                                );
                            } else if (buildingBlock === 'BLOCK:PRODUCT_VARIANTS') {
                                return (
                                    <ProductVariants
                                        key={buildingBlock + (productDetails?.handle ?? '')}
                                        variants={productDetails?.variants.toArray() ?? []}
                                        onVariantSelected={handleSetSelectedVar}
                                        onMatchingPartialVariant={handleSetSelectedPartialVar}
                                        ref={variantOptionsRef}
                                    />
                                );
                            } else if (buildingBlock === 'BLOCK:PRODUCT_CUSTOMISER') {
                                return (
                                    <ProductCustomiserSection
                                        key={buildingBlock + (productDetails?.handle ?? '')}
                                        productHandle={openProductHandle ?? ''}
                                        metafields={metafields}
                                        onValidate={isValid => setFormValid(isValid)}
                                        onChange={(options, priceModifier) => {
                                            setCustomOptions(options);
                                            setProductCustomiserPriceModifier(priceModifier);
                                        }}
                                        showWarning={!formValid}
                                        ref={customiserRef}
                                        handoffFieldId={props.handoffCustomisationFieldId}
                                        showWarningsWithPristineData={forceShowWarnings}
                                    />
                                );
                            } else if (buildingBlock === 'BLOCK:PRODUCT_BOOK_THAT_APP') {
                                return (
                                    <ProductBookThatAppSection
                                        key={buildingBlock + (productDetails?.handle ?? '')}
                                        productHandle={openProductHandle ?? ''}
                                        metafields={metafields}
                                        onValidate={isValid => setBTAFormValid(isValid)}
                                        onChange={options => {
                                            setBTAOptions(options);
                                        }}
                                        showWarning={!formValid}
                                        ref={btaRef}
                                        showWarningsWithPristineData={forceShowWarnings}
                                        variantId={selectedVariant?.id}
                                        onLimitPurchaseQuantity={value => handleSetMaxPurchaseQuantity(value)}
                                    />
                                );
                            } else if (buildingBlock === 'BLOCK:PRODUCT_AVAILABILITY') {
                                return (
                                    <ProductAvailability
                                        key={buildingBlock + (productDetails?.handle ?? '')}
                                        selectedVariant={selectedVariant}
                                        productDetails={productDetails}
                                        allVariantsUnavailable={!productDetails?.availableForSale}
                                    />
                                );
                            }
                        }
                    }),
                )}
                {_.compact(
                    _.map(configProvidedBuildingBlockOrder, (buildingBlock, index) => {
                        if (buildingBlock.unionTypeName === 'PDPBlockSpacer') {
                            return (
                                <StyledText
                                    style={TextStyle.Body}
                                    size={TextSize.Small}
                                    key={`PDPBlockSpacer:${index}` + (productDetails?.handle ?? '')}
                                >
                                    <br />
                                </StyledText>
                            );
                        } else if (buildingBlock.unionTypeName === 'PDPBlockDescription') {
                            return (
                                <ConditionalWrapper
                                    key={'PDPBlockDescription' + productDetails?.handle}
                                    existenceCondition={true}
                                    wrapperCondition={buildingBlock.style !== PdpBlockStyle.Static}
                                    wrapper={children => (
                                        <Accordion
                                            title={buildingBlock.displayText}
                                            openByDefault={buildingBlock.style === PdpBlockStyle.Collapsible}
                                        >
                                            {children}
                                        </Accordion>
                                    )}
                                >
                                    <>
                                        {buildingBlock.style === PdpBlockStyle.Static && (
                                            <StyledText style={TextStyle.Subheading} size={TextSize.Small}>
                                                {buildingBlock.displayText}
                                            </StyledText>
                                        )}
                                        <ProductDescription
                                            content={productDetails?.descriptionHtml}
                                            removeThemeShortcodes={buildingBlock.removeThemeShortcodes}
                                        />
                                    </>
                                </ConditionalWrapper>
                            );
                        } else if (buildingBlock.unionTypeName === 'PDPBlockProductData') {
                            if (buildingBlock.productDataType === PdpBlockProductDataType.Sku) {
                                return (
                                    <ConditionalWrapper
                                        key={'PDPBlockProductData:SKU' + productDetails?.handle}
                                        existenceCondition={!_.isEmpty(selectedVariant?.sku)}
                                        wrapperCondition={buildingBlock.style !== PdpBlockStyle.Static}
                                        wrapper={children => (
                                            <Accordion
                                                title={buildingBlock.displayText}
                                                openByDefault={buildingBlock.style === PdpBlockStyle.Collapsible}
                                            >
                                                {children}
                                            </Accordion>
                                        )}
                                    >
                                        <>
                                            {buildingBlock.style === PdpBlockStyle.Static && (
                                                <StyledText style={TextStyle.Subheading} size={TextSize.Small}>
                                                    {buildingBlock.displayText}
                                                </StyledText>
                                            )}
                                            <ProductSKU content={selectedVariant?.sku} />
                                        </>
                                    </ConditionalWrapper>
                                );
                            } else if (buildingBlock.productDataType === PdpBlockProductDataType.Vendor) {
                                return (
                                    <ConditionalWrapper
                                        key={'PDPBlockProductData:Vendor' + productDetails?.handle}
                                        existenceCondition={!_.isEmpty(productDetails?.vendor)}
                                        wrapperCondition={buildingBlock.style !== PdpBlockStyle.Static}
                                        wrapper={children => (
                                            <Accordion
                                                title={buildingBlock.displayText}
                                                openByDefault={buildingBlock.style === PdpBlockStyle.Collapsible}
                                            >
                                                {children}
                                            </Accordion>
                                        )}
                                    >
                                        <>
                                            {buildingBlock.style === PdpBlockStyle.Static && (
                                                <StyledText style={TextStyle.Subheading} size={TextSize.Small}>
                                                    {buildingBlock.displayText}
                                                </StyledText>
                                            )}
                                            <ProductVendor content={productDetails?.vendor} />
                                        </>
                                    </ConditionalWrapper>
                                );
                            }
                        } else if (buildingBlock.unionTypeName === 'PDPBlockMetafield') {
                            const metafieldForBlock = _.find(
                                metafields,
                                metafield =>
                                    metafield.namespace === buildingBlock.namespace &&
                                    metafield.key === buildingBlock.key,
                            );
                            return (
                                <ConditionalWrapper
                                    key={
                                        `PDPBlockMetafield:${buildingBlock.namespace}:${buildingBlock.key}` +
                                        productDetails?.handle
                                    }
                                    existenceCondition={metafieldForBlock !== undefined}
                                    wrapperCondition={buildingBlock.style !== PdpBlockStyle.Static}
                                    wrapper={children => (
                                        <Accordion
                                            title={buildingBlock.displayText}
                                            openByDefault={buildingBlock.style === PdpBlockStyle.Collapsible}
                                        >
                                            {children}
                                        </Accordion>
                                    )}
                                >
                                    <>
                                        {buildingBlock.style === PdpBlockStyle.Static && (
                                            <StyledText style={TextStyle.Subheading} size={TextSize.Small}>
                                                {buildingBlock.displayText}
                                            </StyledText>
                                        )}
                                        <ProductMetafield
                                            content={metafieldForBlock?.value}
                                            displayType={buildingBlock.metafieldDisplayType}
                                        />
                                    </>
                                </ConditionalWrapper>
                            );
                        }
                    }),
                )}
            </>
        );
    }, [fixedBuildingBlockOrder, productDetails, selectedVariant, metafields, forceShowWarnings]);

    return (
        <SlidingModal
            className={styles.productDisplayView__zIndex}
            isOpen={isOpen}
            origin={SlidingModalOrigin.BOTTOM}
            fullHeight
            fullWidth
        >
            <Toaster
                position="bottom-center"
                toastOptions={{
                    style: {
                        backgroundColor: '#FB9600',
                        border: '1px solid #E8E8E8',
                        borderRadius: 100,
                        padding: '1rem',
                    },
                    duration: 2500,
                }}
                containerClassName={styles.productDisplayView__toasterContainer}
                containerStyle={{
                    bottom: props.hideCartOptions ? 32 : 140,
                }}
            />
            <div
                className={`${styles.productDisplayView} ${
                    galleryExpanded ? styles.productDisplayView__vertical__noScroll : ''
                }`}
            >
                {loading ? (
                    <div className={styles.productDisplayView__images}>
                        <Skeleton type={'rectangle'} />
                    </div>
                ) : (
                    <>
                        {images.length > 0 ? (
                            <div
                                className={`${styles.productDisplayView__images} ${
                                    galleryExpanded ? styles.productDisplayView__images__fullscreen : ''
                                }`}
                            >
                                <ProductImages
                                    images={images}
                                    currentImageIndex={imageIndex}
                                    setImageIndex={handleSetImageIndex}
                                    isExpanded={galleryExpanded}
                                    setIsExpanded={isExpanded => {
                                        if (loading) {
                                            return;
                                        }
                                        menuService.setBackButtonMode(
                                            isExpanded ? BackButtonMode.NONE : BackButtonMode.CLOSE,
                                        );
                                        setGalleryExpanded(isExpanded);
                                    }}
                                    ref={imagesRef}
                                    scroll={handleScroll}
                                    loading={loading}
                                />
                            </div>
                        ) : (
                            <div
                                className={`${styles.productDisplayView__images} ${styles.productDisplayView__images__empty}`}
                            >
                                <StyledText
                                    style={TextStyle.Body}
                                    size={TextSize.Small}
                                    className={styles.productDisplayView__images__empty__text}
                                >
                                    This product has no images
                                </StyledText>
                            </div>
                        )}
                    </>
                )}

                <div
                    className={`${styles.productDisplayView__content} ${
                        galleryExpanded ? styles.productDisplayView__content__hidden : ''
                    }`}
                >
                    {loading ? (
                        <>
                            <Skeleton type={'text'} padding />
                            <Skeleton type={'multiline'} padding />
                            <Skeleton type={'text'} padding />
                            <Skeleton type={'multiline'} padding />
                            <Skeleton type={'text'} padding />
                            <Skeleton type={'multiline'} padding />
                        </>
                    ) : (
                        <>
                            {viewContent}
                            <div className={styles.productDisplayView__cartOptions__spacer} />
                        </>
                    )}
                    {loading || props.hideCartOptions ? null : (
                        <ProductCartOptions
                            className={`${styles.productDisplayView__cartOptions} ${
                                galleryExpanded && styles.productDisplayView__cartOptions__hidden
                            }`}
                            productDetails={productDetails}
                            selectedVariant={selectedVariant}
                            allVariantsUnavailable={!productDetails?.availableForSale}
                            scroll={to => handleScroll(to)}
                            formValid={formValid && btaFormValid}
                            customOptions={mergedCustomAttributes}
                            productCustomiserPriceModifier={productCustomiserPriceModifier.current}
                            onCheckoutPressed={() => setForceShowWarnings(true)}
                            maxQuantity={maxPurchaseQuantity}
                        />
                    )}
                </div>
            </div>
        </SlidingModal>
    );
});

export default ProductDetailsView;
