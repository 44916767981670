import { CSSProperties, FC, memo, useEffect, useRef, useState } from 'react';
import './Accordion.scss';
import StyledText, { TextSize, TextStyle } from '../StyledText/StyledText';
import { useTimeout } from 'react-use';

export interface AccordionProps {
    title: string;
    titleTextStyle?: TextStyle;
    openByDefault: boolean;
}

const Accordion: FC<AccordionProps> = memo(props => {
    const [isOpen, setIsOpen] = useState(props.openByDefault);
    const divRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(0);

    const panelStyle: CSSProperties = {
        maxHeight: isOpen ? (height === 0 ? 'unset' : height) : 0,
    };

    useEffect(() => {
        recalcHeight();
    }, [divRef]);

    const recalcHeight = () => {
        if (!divRef.current) {
            return;
        }

        if (divRef.current.clientHeight === 0) {
            return;
        }

        const newHeight = divRef.current.clientHeight + 20;
        setHeight(newHeight);
    };

    const handleOpenClose = () => {
        if (height === 0) {
            recalcHeight();
            setTimeout(() => {
                setIsOpen(prev => !prev);
            }, 100);
        } else {
            setIsOpen(prev => !prev);
        }
    };

    return (
        <>
            <button className={`accordion ${isOpen ? 'accordion__active' : ''}`} onClick={handleOpenClose}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <StyledText style={props.titleTextStyle ?? TextStyle.Subheading} size={TextSize.Medium}>
                        {props.title}
                    </StyledText>
                    <StyledText style={props.titleTextStyle ?? TextStyle.Subheading} size={TextSize.Medium}>
                        {isOpen ? '-' : '+'}
                    </StyledText>
                </div>
            </button>
            <div className={`accordion__panel ${isOpen ? 'accordion__panel__open' : ''}`} style={panelStyle}>
                <div style={{ margin: '10px 10px 10px 0px' }} ref={divRef}>
                    {props.children}
                </div>
            </div>
        </>
    );
});

export default Accordion;
