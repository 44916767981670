import React, { FC } from 'react';

const InStockIcon: FC = props => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.2222 12.2857H7.77778L6.26667 4.85714H21L17.2222 12.2857Z" fill="white" />
            <path
                d="M4 3H5.88889L6.26667 4.85714M7.77778 12.2857H17.2222L21 4.85714H6.26667M7.77778 12.2857L6.26667 4.85714M7.77778 12.2857L5.61227 14.4148C5.0173 14.9998 5.43868 16 6.28009 16H17.2222"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <circle cx="6" cy="20" r="2" fill="white" />
            <circle cx="16" cy="20" r="2" fill="white" />
        </svg>
    );
};
export default InStockIcon;
