import { injectable } from 'inversify';
import { Observable, Subject } from 'rxjs';
import _ from 'lodash';
import { addMilliseconds } from 'date-fns';
import { TileConfig } from '../ConfigurationService/types/config/TileConfig';
import { ConfigurationService } from '../ConfigurationService/ConfigurationService';
import React from 'react';

export interface TileItem extends TileConfig {
    onClicked: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export type TileState = {
    back: TileItem;
    front: TileItem;
    isShowingBack: boolean;
};

export type ScreenState = { [index: number]: TileState };

@injectable()
export class TileAnimationOrchestratorService {
    private configService: ConfigurationService;
    private static MIN_FLIP_TIME = 1500;
    private static MAX_FLIP_TIME = 4000;
    private _nextFlipAt = addMilliseconds(new Date(), TileAnimationOrchestratorService.MIN_FLIP_TIME + 5000);
    private _columnCount = 3;
    private _animatableTilesIndices: number[] = [];
    private _tileItems: TileItem[] = [];
    private _subject: Subject<ScreenState> = new Subject();
    private _lastIndex = -1;
    private _screenState: ScreenState = {};
    private _paused = false;

    private static _interval: NodeJS.Timeout | undefined;

    constructor(private _configurationService: ConfigurationService) {
        this.configService = _configurationService;
        if (TileAnimationOrchestratorService._interval) {
            clearInterval(TileAnimationOrchestratorService._interval);
        }

        TileAnimationOrchestratorService._interval = setInterval(() => {
            if (!this._paused) {
                if (new Date() > this._nextFlipAt) {
                    const nextFlipMs =
                        Math.random() *
                            (TileAnimationOrchestratorService.MAX_FLIP_TIME -
                                TileAnimationOrchestratorService.MIN_FLIP_TIME) +
                        TileAnimationOrchestratorService.MIN_FLIP_TIME;
                    this._nextFlipAt = addMilliseconds(new Date(), nextFlipMs);
                    if (this._tileItems.length > 0 && this._tileItems.length >= this._columnCount) {
                        // Get visible items from the screenstate
                        const visibleItems = _.map(Object.values(this._screenState), (tileState: TileState) => {
                            return tileState.isShowingBack ? tileState.back : tileState.front;
                        });
                        // Get not visible items
                        const notVisibleItems = _.differenceBy(this._tileItems, visibleItems, 'id');

                        // Get random item from not visible items
                        const randomItem = _.sample(notVisibleItems);
                        if (!randomItem) {
                            return;
                        }

                        if (this._animatableTilesIndices.length === 0) {
                            //Cannot animate at all, as all the tiles are pinned
                            return;
                        }

                        let tileIndex = this._lastIndex;
                        do {
                            const animatableIndices = Math.floor(Math.random() * this._animatableTilesIndices.length);
                            tileIndex = this._animatableTilesIndices[animatableIndices];

                            if (this._animatableTilesIndices.length === 1) {
                                // Extra code to break out of the while loop and allow to keep flipping a single tile
                                // if only one is not pinned
                                break;
                            }
                        } while (tileIndex === this._lastIndex);

                        this._lastIndex = tileIndex;

                        // Choose tile index randomly as long as it is not the last index
                        // let tileIndex = Math.floor(Math.random() * this._columnCount);
                        // while (tileIndex === this._lastIndex) {
                        //     tileIndex = Math.floor(Math.random() * this._columnCount);
                        // }
                        // this._lastIndex = tileIndex;

                        // If tile is showing back, change front, then flip
                        const currentTileState = this._screenState[tileIndex];
                        if (currentTileState.isShowingBack) {
                            this._screenState[tileIndex] = {
                                front: randomItem,
                                back: currentTileState.back,
                                isShowingBack: false,
                            };
                        } else {
                            this._screenState[tileIndex] = {
                                front: currentTileState.front,
                                back: randomItem,
                                isShowingBack: true,
                            };
                        }

                        this._subject.next(this._screenState);
                    }
                }
            }
        }, 1000);
    }

    setColumnCount(columnCount: number) {
        this._columnCount = columnCount;
        for (let i = 0; i < this._columnCount; i++) {
            if (this._tileItems.length > i) {
                this._screenState[i] = {
                    back: this._tileItems[i],
                    front: this._tileItems[i],
                    isShowingBack: false,
                };
            }
        }

        const pinnedSlots = this.configService.pinnedPowerTileIndices;
        const newAnimatableTilesIndices: number[] = [];
        for (let index = 0; index < columnCount; index++) {
            if (!pinnedSlots.includes(index)) {
                newAnimatableTilesIndices.push(index);
            }
            this._animatableTilesIndices = newAnimatableTilesIndices;
        }
    }

    setTileItems(tileItems: TileItem[]) {
        this._tileItems = _.orderBy(tileItems, i => i.priority);
        for (let i = 0; i < this._columnCount; i++) {
            if (this._tileItems.length > i) {
                this._screenState[i] = {
                    back: this._tileItems[i],
                    front: this._tileItems[i],
                    isShowingBack: false,
                };
            }
        }
    }

    setPaused(isPaused: boolean) {
        this._paused = isPaused;
    }

    isPaused(): boolean {
        return this._paused;
    }

    observe(): Observable<ScreenState> {
        return this._subject.asObservable();
    }
}
