import React, { useEffect, useRef } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router-dom';
import { useInjection } from '../../../dependancyInjection/DependencyContext';
import { SessionManagementService } from '../SessionManagementService';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { BasketService } from '../../BasketService/BasketService';
import Modal from '../../../components/shared/Modal/Modal';
import Button, { ButtonVariant } from '../../../components/shared/inputs/Button/Button';
import { DEFAULT_TIMEOUT } from './SessionManager.constants';
import StyledText, { TextSize, TextStyle } from '../../../components/shared/StyledText/StyledText';
import { useTranslation } from 'react-i18next';
import './SessionManager.scss';
import useStateRef from 'react-usestateref';
import { ConfigurationService } from '../../ConfigurationService/ConfigurationService';
import { StorageKey } from '../../StorageService/StorageKeys.enum';
import { StorageService } from '../../StorageService/StorageService';

export const SessionManager: React.FC = ({ children }) => {
    const sessionManagementService = useInjection<SessionManagementService>(DependencyType.SessionManagementService);
    const basketService = useInjection<BasketService>(DependencyType.BasketService);
    const configurationService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const storageService = useInjection<StorageService>(DependencyType.StorageService);

    const lastInteractions = useRef(0);
    const lastSalesAssistantId = useRef<string | undefined>(undefined);
    const history = useHistory();
    const [remaining, setRemaining] = useStateRef(0);
    const expiringSoon = sessionManagementService.isSessionActive && remaining < 10000 && remaining !== 0;
    const { t } = useTranslation();
    const { inactivityTimeout } = configurationService.config() || {};
    const oneDay = 60 * 1000 * 60 * 24;

    const timer = useIdleTimer({
        eventsThrottle: 500,
        onIdle: () => {
            if (sessionManagementService.isSessionActive) {
                void sessionManagementService.endSession();
                basketService.empty();
                setRemaining(0);
                console.log('Session Ended, routing to "/"');
                history.replace('/');
            }
        },
        onAction: async (e: Event) => {
            setRemaining(
                process.env.REACT_APP_DISABLE_SESSIONS === `true` ? oneDay : inactivityTimeout ?? DEFAULT_TIMEOUT,
            );
            if (!sessionManagementService.currentSessionId) {
                await sessionManagementService.newSession();
            }
            if (e.type === 'mousedown') {
                // mousedown even for touch devices, apparently!
                sessionManagementService.onInteract();
            }
        },
        onActive: () => {
            void sessionManagementService.newSession();
        },
        timeout: process.env.REACT_APP_DISABLE_SESSIONS === `true` ? oneDay : inactivityTimeout ?? DEFAULT_TIMEOUT,
    });

    useEffect(() => {
        const interval = setInterval(async () => {
            if (sessionManagementService.isSessionActive) {
                const salesAssistantId = storageService.get(StorageKey.SALES_ASSOCIATE_ID, true);
                const lastInteraction = sessionManagementService.interactionCount();

                if (
                    lastInteractions.current !== lastInteraction ||
                    lastSalesAssistantId.current !== salesAssistantId ||
                    true
                ) {
                    await sessionManagementService.updateSession();
                    lastInteractions.current = lastInteraction;
                    lastSalesAssistantId.current = salesAssistantId;
                }
                setRemaining(timer.getRemainingTime());
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [timer, sessionManagementService]);

    return (
        <>
            {children}

            {expiringSoon && (
                <Modal topMost isOpen displayVariant="compact">
                    <div className="SessionModal">
                        <StyledText style={TextStyle.Subheading} size={TextSize.Large} translate>
                            Your selection will clear in{' '}
                            <span className="NeverTranslate">{(timer.getRemainingTime() / 1000).toFixed(0)}</span>
                        </StyledText>
                        <Button
                            className="Session__continue"
                            text={t('session.continue_button')}
                            variant={ButtonVariant.PRIMARY}
                            translate
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};
