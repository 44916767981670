import { Record, RecordOf } from 'immutable';
import { ImageInfo } from '../../types/Types';
import { LocalProductImage } from './LocalProduct';

export type ProductParams = {
    cursor: string | number;
    id: string;
    handle: string;
    vendor: string;
    featuredImage: string;
    images: LocalProductImage[];
    title: string;
    minPrice: number;
    maxPrice: number;
    minPriceOriginal: number;
    maxPriceOriginal: number;
    availableForSale: boolean;
    limitedAvailability: boolean;
    productType: string;
    orderOnly: boolean;
    totalInventory: number;
};

export type Product = RecordOf<ProductParams>;

export const ProductDefaultValues = {
    id: '',
    cursor: '' as string | number,
    handle: '',
    vendor: '',
    featuredImage: '',
    images: <LocalProductImage[]>[],
    maxPrice: 0,
    minPrice: 0,
    maxPriceOriginal: 0,
    minPriceOriginal: 0,
    title: '',
    availableForSale: <boolean>false,
    limitedAvailability: <boolean>false,
    productType: '',
    orderOnly: false,
    totalInventory: 0,
};
export const makeProduct: Record.Factory<ProductParams> = Record(ProductDefaultValues);
