import React, { useEffect } from 'react';
import Quantity from '../../Quantity/Quantity';
import _ from 'lodash';
import ProductVariant from '../../../../../../services/ProductServices/variant/ProductVariant';
import { useInjection } from '../../../../../../dependancyInjection/DependencyContext';
import { BasketService } from '../../../../../../services/BasketService/BasketService';
import DependencyType from '../../../../../../dependancyInjection/DependencyType';
import useStateRef from 'react-usestateref';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSize, ButtonVariant } from '../../../../../shared/inputs/Button/Button';
import { ProductVariantAvailability } from '../../../../../../services/ProductServices/ProductVariantAvailability';
import { usePurchase } from '../../../../../../hooks/contexts/Purchase/Purchase';
import { ProductDetails } from '../../../../../../services/ProductServices/variant/ProductVariantService';
import { CartLineItem } from '../../../../../../services/BasketService/Basket.type';
import { CustomAttributes } from './customisation/ProductCustomiserSection';

import styles from './ProductCartOptions.module.scss';
import { ConfigurationService } from '../../../../../../services/ConfigurationService/ConfigurationService';

export interface ProductCartOptionsProps {
    className?: string;
    productDetails?: ProductDetails;
    selectedVariant?: ProductVariant;
    allVariantsUnavailable: boolean;
    scroll: (to: 'variants' | 'customiser' | 'images') => void;
    formValid: boolean;
    customOptions: CustomAttributes;
    productCustomiserPriceModifier: number;
    onCheckoutPressed?: () => void;
    maxQuantity?: number;
}

const ProductCartOptions: React.FC<ProductCartOptionsProps> = props => {
    const { t } = useTranslation();
    const purchaseContext = usePurchase();
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const basketService = useInjection<BasketService>(DependencyType.BasketService);
    const [loading, setLoading] = React.useState(false);
    const [, setQuantity, quantityRef] = useStateRef(1);
    const [showAddedLabel, setShowAddedLabel] = React.useState(false);

    useEffect(() => {
        const maxAllowed = props.maxQuantity ?? Number.MAX_VALUE;
        if (quantityRef.current > maxAllowed) {
            setQuantity(maxAllowed);
        }
    }, [props.maxQuantity]);

    useEffect(() => {
        if (props.selectedVariant) {
            if (quantityRef.current !== 1) {
                setQuantity(1);
            }
        }
    }, [props.selectedVariant]);

    useEffect(() => {
        //subscribe to basket changes
        const sub = basketService.observeBasket().subscribe(basket => {
            if (basket && basket.lineItems) {
                const lineItem = _.find(
                    basket?.lineItems,
                    li =>
                        li.productVariant.id === props.selectedVariant?.id &&
                        basketService.attributesMatch(props.customOptions, li.attributes),
                );

                if (lineItem) {
                    if (quantityRef.current !== lineItem.quantity) {
                        setQuantity(lineItem.quantity);
                    }
                }
            }
        });
        return () => {
            sub.unsubscribe();
        };
    }, [props.selectedVariant, props.customOptions]);

    const isInBasket = (lineItems: CartLineItem[], customOptions: CustomAttributes) => {
        const ret = _.find(
            lineItems,
            li =>
                li.productVariant.id === props.selectedVariant?.id &&
                basketService.attributesMatch(customOptions, li.attributes),
        );
        return ret !== undefined;
    };

    //Yes this is needed
    const inBasket = isInBasket(basketService.basket?.lineItems ?? [], props.customOptions);
    let buttonLabel = t('product_view.buy_buttons.add_to_basket');
    if (showAddedLabel) {
        buttonLabel = '✓ ' + t('product_view.buy_buttons.added');
    } else if (inBasket) {
        buttonLabel = t('product_view.buy_buttons.checkout');
    }

    const handleQuantityChange = async (quantity: number) => {
        // const isInBasket = _.some(
        //     basketService.basket?.lineItems,
        //     li => li.productVariant.id === props.selectedVariant?.id,
        // );
        const inBasket = isInBasket(basketService.basket?.lineItems ?? [], props.customOptions);
        if (inBasket && props.selectedVariant) {
            setLoading(true);
            await basketService.setItemQuantity(
                props.selectedVariant,
                quantity,
                props.customOptions,
                undefined,
                props.maxQuantity,
            );
            await handleProductCustomiserPriceModifier(
                props.productDetails,
                props.selectedVariant,
                quantity,
                props.customOptions,
            );

            setLoading(false);
        } else {
            setQuantity(quantity);
        }
    };

    const handleProductCustomiserPriceModifier = async (
        realProductDetails: ProductDetails | undefined,
        realItemVariant: ProductVariant,
        quantity = 1,
        options: CustomAttributes = {},
    ) => {
        const itemCustomiserVariant = configService.productCustomiserPriceModifierVariant;
        if (props.productCustomiserPriceModifier <= 0 || !itemCustomiserVariant) {
            return;
        }

        let customisationFor = realItemVariant.name;
        if (props.productDetails) {
            customisationFor = `${props.productDetails.title} (${realItemVariant.name})`;
        }

        customisationFor = `Customisation for ${customisationFor}`;
        await basketService.setItemQuantity(
            itemCustomiserVariant,
            props.productCustomiserPriceModifier * quantity,
            options,
            customisationFor,
            props.maxQuantity,
        );
    };

    const handleAddToBasket = async () => {
        props.onCheckoutPressed?.();

        if (!props.selectedVariant) {
            props.scroll('variants');
            return;
        }

        if (!props.formValid) {
            props.scroll('customiser');
            return;
        }

        const inBasket = isInBasket(basketService.basket?.lineItems ?? [], props.customOptions);
        if (inBasket) {
            purchaseContext.checkout();
        } else {
            setLoading(true);
            const existingQuantity = await basketService.getItemQuantity(props.selectedVariant.id, props.customOptions);
            try {
                await basketService.setItemQuantity(
                    props.selectedVariant,
                    existingQuantity + quantityRef.current,
                    props.customOptions,
                    props.productDetails?.title ?? props.selectedVariant.name,
                    props.maxQuantity,
                );
                await handleProductCustomiserPriceModifier(
                    props.productDetails,
                    props.selectedVariant,
                    existingQuantity + quantityRef.current,
                    props.customOptions,
                );
                setShowAddedLabel(true);
                setTimeout(() => setShowAddedLabel(false), 2000);
            } catch {
            } finally {
                setLoading(false);
            }
        }
    };

    const readyToAddToBasket = !!props.selectedVariant && props.formValid;
    const buttonDisabled =
        props.allVariantsUnavailable || props.selectedVariant?.availability === ProductVariantAvailability.Unavailable;

    return (
        <div className={props.className}>
            <div className={styles.ProductCardOptions}>
                <Quantity
                    initialQuantity={quantityRef.current}
                    className={styles.ProductCardOptions__quantity}
                    onQuantityChanged={handleQuantityChange}
                    loading={loading}
                    variant={inBasket ? 'numInBasket' : 'inline'}
                    overrideQuantity={quantityRef.current}
                    maxQuantity={props.maxQuantity}
                />
                <div className={styles.ProductCardOptions__spacer} />
                <Button
                    className={
                        readyToAddToBasket
                            ? styles.ProductCardOptions__buttonBuyNow
                            : styles.ProductCardOptions__buttonAddToBasket
                    }
                    text={buttonLabel}
                    size={ButtonSize.MD}
                    onClick={handleAddToBasket}
                    disabled={buttonDisabled}
                    variant={ButtonVariant.GREENFLAT}
                    loading={loading}
                    showLoader
                    useTransition
                    translate
                />
            </div>
        </div>
    );
};

export default ProductCartOptions;
