import { CloudshelfFiltersDto } from '@cloudshelf/common';

export const CATEGORY_FILTER = 'Category Handle';
export const CATEGORY_FILTER_ID = '00000000-0000-0000-0000-000000000001';
export const NAME_FILTER = 'name';
export const NAME_FILTER_ID = '00000000-0000-0000-0000-000000000002';
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';
export const CLEAR_ALL_FILTERS_ID = '00000000-0000-0000-0000-000000000003';

export type LocalProductsFilterName = typeof CATEGORY_FILTER | typeof NAME_FILTER;

export type LocalFilterNames = keyof CloudshelfFiltersDto['common'] | LocalProductsFilterName;
