export function convertRemToPixels(rem: number) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export function calculateDPI(
    screenSizeInInches: number,
    screenWidthInPixels: number,
    screenHeightInPixels: number,
): number {
    const diagonalInPixels = Math.sqrt(screenWidthInPixels ** 2 + screenHeightInPixels ** 2);
    const dpi = diagonalInPixels / screenSizeInInches;

    (window as any).DEVICE_SCREEN_SIZE_INCHES = screenSizeInInches;
    (window as any).DEVICE_DPI = dpi;
    (window as any).DEVICE_WIDTH_PX = screenWidthInPixels;
    (window as any).DEVICE_HEIGHT_PX = screenHeightInPixels;
    return dpi;
}
export function getOneVh() {
    return window.innerHeight * 0.01;
}

export function getOneVw() {
    return window.innerWidth * 0.01;
}

export function setupOneVh(reason = 'Unknown') {
    const style = 'background-color: purple; color: white; font-style: italic;';
    const realOneVh = getOneVh();
    console.log(`%c[Cloudshelf] Fixing Mobile Viewport Height Unit. One VH is '${realOneVh}' Reason: ${reason}`, style);
    document.documentElement.style.setProperty('--vh', `${realOneVh}px`);
}

export function getRefRule(dpiValue?: number): { minHeight: number; smallestDimension: number } {
    const dpiStr = document.documentElement.style.getPropertyValue('--device-dpi');

    const dpi = dpiValue ?? parseFloat(dpiStr);

    const minTouchPoint = (getMinTouchPointValue() * dpi) / 2.54; // convert 1cm to pixels //0.845
    const minHeight = minTouchPoint / 0.567; // 0.567 is the ratio of the wrapper to the touch point
    //in this case, the reference point becomes 1.763668430335097cm

    const realOneVh = getOneVh();
    const realOneVw = getOneVw();

    let smallestDimension = realOneVh;
    if (realOneVw < smallestDimension) {
        smallestDimension = realOneVw;
    }

    return { minHeight: minHeight, smallestDimension: smallestDimension * getSmallestDimensionValue() };
}

export function setupResponsiveUnits(dpi: number, reason = 'Unknown') {
    const style = 'background-color: purple; color: white; font-style: italic;';
    //Setting the DPI
    document.documentElement.style.setProperty('--device-dpi', `${dpi}`);
    console.log(`%c[Cloudshelf] Device DPI: '${dpi}' Reason: ${reason}`, style);

    const ruleValues = getRefRule(dpi);
    const refRule = `max(${ruleValues.minHeight}px, ${ruleValues.smallestDimension}px)`;
    document.documentElement.style.setProperty('--responsive-reference-point', refRule);
    console.log(`%c[Cloudshelf] Responsive Reference Point: '${refRule}' Reason: ${reason}`, style);
}

export function getResponsiveRuleMinMaxValue(): string {
    //if we have local storage
    if (typeof localStorage !== 'undefined') {
        const minOrMax = localStorage.getItem('ResponsiveRuleMinMax');
        if (minOrMax) {
            return minOrMax;
        }
    }

    return 'max';
}

export function setResponsiveRuleMinMaxValue(value: string) {
    if (typeof localStorage !== 'undefined') {
        localStorage.setItem('ResponsiveRuleMinMax', value);
    }
}

export function getMinTouchPointValue(): number {
    //if we have local storage
    if (typeof localStorage !== 'undefined') {
        const minTouchPoint = localStorage.getItem('minTouchPoint');
        if (minTouchPoint) {
            return parseFloat(minTouchPoint);
        }
    }

    return 0.8;
}

export function removeMinTouchPointValue() {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem('minTouchPoint');
    }
}
export function setMinTouchPointValue(value: number) {
    if (typeof localStorage !== 'undefined') {
        localStorage.setItem('minTouchPoint', value.toString());
    }
}

export function removeSmallTouchPointValue() {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem('smallestDimension');
    }
}
export function getSmallestDimensionValue(): number {
    //if we have local storage
    if (typeof localStorage !== 'undefined') {
        const smallestDimension = localStorage.getItem('smallestDimension');
        if (smallestDimension) {
            return parseFloat(smallestDimension);
        }
    }

    return 9;
}

export function setSmallestDimensionValue(value: number) {
    if (typeof localStorage !== 'undefined') {
        localStorage.setItem('smallestDimension', value.toString());
    }
}

export function removeGridMinWidthInCMValue() {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem('gridMinWidthInCM');
    }
}

export function getGridMinWidthInCMValue(): number {
    //if we have local storage
    if (typeof localStorage !== 'undefined') {
        const gridMinWidthInCM = localStorage.getItem('gridMinWidthInCM');
        if (gridMinWidthInCM) {
            return parseFloat(gridMinWidthInCM);
        }
    }

    return 3.5;
}

export function setGridMinWidthInCMValue(value: number) {
    if (typeof localStorage !== 'undefined') {
        localStorage.setItem('gridMinWidthInCM', value.toString());
    }
}

export function removeGridSmallestDimensionValue() {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem('gridSmallestDimension');
    }
}

export function getGridSmallestDimensionValue(): number {
    //if we have local storage
    if (typeof localStorage !== 'undefined') {
        const gridSmallestDimension = localStorage.getItem('gridSmallestDimension');
        if (gridSmallestDimension) {
            return parseFloat(gridSmallestDimension);
        }
    }

    return 30;
}

export function setGridSmallestDimensionValue(value: number) {
    if (typeof localStorage !== 'undefined') {
        localStorage.setItem('gridSmallestDimension', value.toString());
    }
}

export function removeGridGutterValue() {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem('gridGutter');
    }
}
export function getGridGutterValue(): number {
    //if we have local storage
    if (typeof localStorage !== 'undefined') {
        const gridGutter = localStorage.getItem('gridGutter');
        if (gridGutter) {
            return parseFloat(gridGutter);
        }
    }

    return 0.08;
}

export function setGridGutterValue(value: number) {
    if (typeof localStorage !== 'undefined') {
        localStorage.setItem('gridGutter', value.toString());
    }
}

export function canUseAspectRatio(): boolean {
    return window.CSS && window.CSS.supports && window.CSS.supports('aspect-ratio: 1');
}
