import { FC, useCallback, memo } from 'react';
import { animated } from 'react-spring';
import { OnClickedCallback } from '../../../../../types/Types';
import './SlidingModal.scss';

export enum SlidingModalOrigin {
    TOP = 'TOP',
    RIGHT = 'RIGHT',
    BOTTOM = 'BOTTOM',
    LEFT = 'LEFT',
}

export interface SlidingModalProps {
    fullHeight?: boolean;
    fullWidth?: boolean;
    isOpen: boolean;
    onBackgroundClicked?: OnClickedCallback;
    origin: SlidingModalOrigin;
    rounded?: boolean;
    transparent?: boolean;
    transparentBackground?: boolean;
    className?: string;
}

export const SlidingModal: FC<SlidingModalProps> = memo(props => {
    const parentClassNames = [props.className ?? '', 'SlidingModal'];
    const bodyClassNames = ['SlidingModal__body'];

    if (props.origin === SlidingModalOrigin.TOP) {
        bodyClassNames.push('SlidingModal__body__startLocation__top');
    } else if (props.origin === SlidingModalOrigin.BOTTOM) {
        bodyClassNames.push('SlidingModal__body__startLocation__bottom');
    } else if (props.origin === SlidingModalOrigin.LEFT) {
        bodyClassNames.push('SlidingModal__body__startLocation__left');
    } else if (props.origin === SlidingModalOrigin.RIGHT) {
        bodyClassNames.push('SlidingModal__body__startLocation__right');
    }

    if (props.isOpen) {
        parentClassNames.push('SlidingModal__open');

        if (props.origin === SlidingModalOrigin.TOP) {
            bodyClassNames.push('SlidingModal__body__endLocation__top');
        } else if (props.origin === SlidingModalOrigin.BOTTOM) {
            bodyClassNames.push('SlidingModal__body__endLocation__bottom');
        } else if (props.origin === SlidingModalOrigin.LEFT) {
            bodyClassNames.push('SlidingModal__body__endLocation__left');
        } else if (props.origin === SlidingModalOrigin.RIGHT) {
            bodyClassNames.push('SlidingModal__body__endLocation__right');
        }
    }

    if (props.rounded) {
        if (props.origin === SlidingModalOrigin.TOP) {
            bodyClassNames.push('SlidingModal__rounded__bottom');
        } else if (props.origin === SlidingModalOrigin.BOTTOM) {
            bodyClassNames.push('SlidingModal__rounded__top');
        } else if (props.origin === SlidingModalOrigin.LEFT) {
            bodyClassNames.push('SlidingModal__rounded__right');
        } else if (props.origin === SlidingModalOrigin.RIGHT) {
            bodyClassNames.push('SlidingModal__rounded__left');
        }
    }

    if (props.transparent) {
        parentClassNames.push('SlidingModal__transparent');
    }
    if (props.fullWidth) {
        bodyClassNames.push('SlidingModal__body__fullwidth');
    }

    if (props.fullHeight) {
        bodyClassNames.push('SlidingModal__body__fullHeight');
    }

    if (props.transparentBackground) {
        bodyClassNames.push('SlidingModal__body__transparentBackground');
    }
    const handleBackgroundClick = useCallback(() => {
        if (typeof props.onBackgroundClicked === 'function') {
            props.onBackgroundClicked();
        }
    }, [props.onBackgroundClicked]);

    return (
        <animated.div className={parentClassNames.join(' ')} onMouseDown={handleBackgroundClick}>
            <animated.div
                className={bodyClassNames.join(' ')}
                onMouseDown={event => {
                    event.stopPropagation();
                }}
            >
                {props.children}
            </animated.div>
        </animated.div>
    );
});
