export function capitalize(value: string): string {
    if (!value || !value.length) {
        return value;
    }
    return value.charAt(0).toUpperCase() + value.substring(1);
}

export function stringifyError(e: Error): string {
    return JSON.stringify(e, Object.getOwnPropertyNames(e), 2);
}

export function stringifySafe(obj: any): string {
    return JSON.stringify(obj, obj, 2);
}

function escapeRegex(str: string): string {
    return str.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function generateMatchingAllInputRegex(input: string): RegExp {
    input = input.replace(/[\p{Pi}\p{Pf}]/gu, '"');
    const punctuationRegex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
    const words = input.trim().match(/(?:"[^"]+"|\S+)/g);
    if (!words) {
        //Idk what to do here
        return new RegExp('');
    }

    const patterns = words
        .map(word => {
            if (word.startsWith('"') && word.endsWith('"')) {
                const quotedWord = word.slice(1, -1).trim();
                const wordWithoutPunctuation = quotedWord.replace(punctuationRegex, '');
                const escaped = escapeRegex(wordWithoutPunctuation);
                return `(?=.*\\b${escaped}\\b)`;
            } else {
                const wordWithoutPunctuation = word.replace(punctuationRegex, '');
                const escaped = escapeRegex(wordWithoutPunctuation);
                return `(?=.*(?:\\b|\\w)${escaped}(?:\\b|\\w))`;
            }
        })
        .join('');

    return new RegExp(`^${patterns}.*$`, 'gi');

    // const allInput = input.trim();
    // const punctuationRegex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
    //
    // if (allInput.startsWith('"') && allInput.endsWith('"')) {
    //     // If the input is wrapped in quotes, we want to match the exact phrase
    //     const phrase = allInput.substring(1, allInput.length - 1);
    //     return new RegExp(phrase, 'i');
    // } else {
    //     const allInputWithoutPunctuation = allInput.replace(punctuationRegex, '');
    //     const words = allInputWithoutPunctuation.trim().split(/\s+/);
    //     const patterns = words.map(word => `(?=.*(?:\\b|\\w)${word}(?:\\b|\\w))`).join('');
    //     return new RegExp(`^${patterns}.*$`, 'gi');
    // }
}
