import { FC, useCallback, useEffect, useState } from 'react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import {
    InteractiveDisplayState,
    InteractiveOrchestratorService,
} from '../../../../../services/InteractiveOrchestratorService/InteractiveOrchestratorService';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { SessionManagementService } from '../../../../../services/SessionManagementService/SessionManagementService';
import { SessionEvent, SessionEventType } from '../../../../../services/SessionManagementService/SessionEvent';
import ElegantBannerPane from '../../../../shared/ElegantBannerPane/ElegantBannerPane';
import './BannerPlayer.scss';
import { EventsService } from '../../../../../services/EventsService/EventsService';

const BannerPlayer: FC = () => {
    const interactiveOrchestrator = useInjection<InteractiveOrchestratorService>(
        DependencyType.InteractiveOrchestratorService,
    );
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);
    const sessionManagementService = useInjection<SessionManagementService>(DependencyType.SessionManagementService);
    const [displayState, setDisplayState] = useState<InteractiveDisplayState>();
    const [, updateRenderState] = useState<unknown>();
    const forceUpdate = useCallback(() => updateRenderState({}), []);
    const [forceCloseBanner, setForceCloseBanner] = useState<boolean>(false);

    const handlePreviewTaps = () => {
        setForceCloseBanner(true);
    };

    useEffect(() => {
        const bannersCloseSub = eventsService.observeBannerShouldClose().subscribe(() => {
            setForceCloseBanner(true);
        });
        const sessionSubscription = sessionManagementService.observe().subscribe((sessionEvent: SessionEvent) => {
            if (sessionEvent.type === SessionEventType.Started) {
                interactiveOrchestrator.stop();
                setForceCloseBanner(true);
            } else if (sessionEvent.type === SessionEventType.Ended) {
                interactiveOrchestrator.start();
                setForceCloseBanner(false);
            }
        });

        const interactiveOrchestratorSubscription = interactiveOrchestrator.observe().subscribe(() => {
            const newDisplayState = interactiveOrchestrator.displayState;
            if (newDisplayState !== undefined) {
                setDisplayState(newDisplayState);
                forceUpdate();
            }
        });

        void interactiveOrchestrator.start();

        return () => {
            if (interactiveOrchestratorSubscription) {
                interactiveOrchestratorSubscription.unsubscribe();
            }

            if (sessionSubscription) {
                sessionSubscription.unsubscribe();
            }

            if (bannersCloseSub) {
                bannersCloseSub.unsubscribe();
            }

            void interactiveOrchestrator.stop();
        };
    }, []);

    if (displayState && displayState.availableBanners.length === 0) {
        return null;
    }

    // We want to return the banner player here, this will be rendered on top of the display app,
    // and will not render when there is a session in progress.
    return (
        <div
            className={`BannerPlayer ${
                forceCloseBanner || !displayState?.shouldShowBanner ? 'BannerPlayer__out' : 'BannerPlayer__in'
            }`}
            style={{
                pointerEvents: forceCloseBanner || !displayState?.shouldShowBanner ? 'none' : 'all',
                backgroundColor: forceCloseBanner || !displayState?.shouldShowBanner ? 'transparent' : 'black',
                opacity: forceCloseBanner || !displayState?.shouldShowBanner ? 0 : 1,
                zIndex: 158,
            }}
        >
            {displayState &&
                displayState.availableBanners &&
                displayState.availableBanners.map((banner, bannerIndex) => {
                    return (
                        <ElegantBannerPane
                            key={`Player-BannerBane-${bannerIndex}`}
                            banner={banner}
                            interactive
                            active={
                                !forceCloseBanner &&
                                bannerIndex === displayState.bannerIndex &&
                                displayState.shouldShowBanner
                            }
                            onTapped={handlePreviewTaps}
                            className={'BannerPlayer__full'}
                        />
                    );
                })}
        </div>
    );
};

export default BannerPlayer;
