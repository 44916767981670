import React, { FC, memo, useEffect } from 'react';
import { DisplayOnlyCategory } from '../../../../../services/DisplayOnlyOrchestratorService/DisplayOnlyOrchestratorService';
import DisplayOnlyQR from '../DisplayOnlyQR/DisplayOnlyQR';
import { CSSTransition } from 'react-transition-group';
import TimeBar from '../TimeBar/TimeBar';
import './CategoryPane.scss';
import _ from 'lodash';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { DisplayOnlyTrackedHandleType } from '../../../../../services/TrackedURLService/DisplayOnlyTrackedHandleType.enum';
import { getSizedImageURL } from '../../../../../utils/ImageURL.Util';
import { Textfit } from 'react-textfit';

export interface CategoryPaneProps {
    category: DisplayOnlyCategory;
    active: boolean;
    className?: string;
}

const CategoryPane: FC<CategoryPaneProps> = memo(props => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const isLandscape = window.innerHeight < window.innerWidth;

    const shouldReduceMarginOfQR = () => {
        const config = configService.displayOnlyConfig;
        return isLandscape && !config.includeProductName && !config.includePrice && !config.includeStock;
    };

    const isOverflown = ({ clientWidth, clientHeight, scrollWidth, scrollHeight }: HTMLElement) => {
        return scrollWidth > clientWidth || scrollHeight > clientHeight;
    };

    const resizeText = ({ element, elements, minSize = 10, maxSize = 512, step = 1, unit = 'px' }: any) => {
        (elements || [element]).forEach((el: any): any => {
            let i = minSize;
            let overflow = false;

            const parent = el.parentNode;

            while (!overflow && i < maxSize) {
                el.style.fontSize = `${i - step}${unit}`;
                el.style.lineHeight = `${i - step}${unit}`;
                overflow = isOverflown(parent);

                if (!overflow) {
                    i += step;
                }
            }

            // revert to last state where no overflow happened
            el.style.fontSize = `${i - step - 1}${unit}`;
            el.style.lineHeight = `${i - step}${unit}`;
        });
    };

    const windowHeight = window.innerHeight;
    const minFontSize = windowHeight / 20;
    const maxFontSize = windowHeight / 5;

    const handleResize = () => {
        const screenHeight = window.innerHeight;
        const maxFontSize = screenHeight / 6;
        resizeText({
            elements: document.querySelectorAll('#autosizePlz'),
            step: 0.5,
            maxSize: maxFontSize,
        });
    };

    useEffect(() => {
        // Call resizeText when window is resized
        window.addEventListener('resize', () => {
            handleResize();
        });

        handleResize();
    }, []);

    useEffect(() => {
        handleResize();
    }, [props.active]);

    return (
        <CSSTransition
            in={props.active}
            timeout={500}
            appear
            unmountOnExit
            mountOnEnter
            classNames={`${props.className} CategoryPane CategoryPane`}
        >
            <div className="CategoryPane__wrapper">
                <TimeBar
                    numberOfBlocks={1}
                    millisecondsPerBlock={configService.displayOnlyConfig.timePerPhoto * 1000}
                    activeBlock={0}
                    forceFullWidth
                />
                {configService.displayOnlyConfig.displayLogo && !_.isEmpty(configService.config()?.theme.logoUrl) && (
                    <div className="CategoryPane__Logo">
                        <img
                            src={getSizedImageURL(configService.config()?.theme.logoUrl, 400, 400)}
                            alt="logo"
                            className={`CategoryPane__Logo__${(
                                configService.displayOnlyConfig.logoSize || ''
                            ).toLowerCase()}`}
                        />
                    </div>
                )}
                <img
                    src={getSizedImageURL(props.category.featuredImage)}
                    alt="Category Image"
                    className="CategoryPane__background"
                />
                <div className="CategoryPane__content">
                    <div className="CategoryPane__content__title">
                        <Textfit min={minFontSize} max={maxFontSize}>
                            <p>{props.category.title}</p>
                        </Textfit>
                    </div>
                    <DisplayOnlyQR
                        type={DisplayOnlyTrackedHandleType.CATEGORY}
                        handle={props.category.handle}
                        reducedMargin={shouldReduceMarginOfQR()}
                    />
                </div>
            </div>
        </CSSTransition>
    );
});

export default CategoryPane;
