import { FC, memo, useEffect } from 'react';
import DisplayOnlySetupWrapper from './components/DisplayOnlySetupWrapper/DisplayOnlySetupWrapper';
import Player from './components/Player/Player';
import { useInjection } from '../../../dependancyInjection/DependencyContext';
import { DisplayOnlyOrchestratorService } from '../../../services/DisplayOnlyOrchestratorService/DisplayOnlyOrchestratorService';
import DependencyType from '../../../dependancyInjection/DependencyType';
import useStateRef from 'react-usestateref';

const DisplayApp: FC = memo(() => {
    const displayOnlyOrchestrator = useInjection<DisplayOnlyOrchestratorService>(
        DependencyType.DisplayOnlyOrchestratorService,
    );
    const [, setRenderKey, renderKeyRef] = useStateRef<number>(0);

    useEffect(() => {
        const displayOnlyOrchestratorSubscription = displayOnlyOrchestrator.observe().subscribe(() => {
            setRenderKey(renderKeyRef.current + 1);
        });

        return () => {
            if (displayOnlyOrchestratorSubscription) {
                displayOnlyOrchestratorSubscription.unsubscribe();
            }
        };
    });

    return (
        <DisplayOnlySetupWrapper>
            {/*<BrandingOverlay in={true} />*/}
            <Player />
        </DisplayOnlySetupWrapper>
    );
});

export default DisplayApp;
