import { ApolloClient, createHttpLink, from, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

export function buildCloudshelfApolloClient(backendUrl: string): ApolloClient<NormalizedCacheObject> {
    const timeoutLink = new ApolloLinkTimeout(60 * 1000); // 60 second default timeout

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) =>
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
                ),
            );
        if (networkError) {
            console.log(`[Network error]: ${networkError} `);
        }
    });

    const httpLink = createHttpLink({
        uri: `${backendUrl}/graphql`,
    });

    const combinedLink = timeoutLink.concat(httpLink);

    const retryLink = new RetryLink({
        delay: {
            initial: 500,
            max: Infinity,
            jitter: true,
        },
        attempts: {
            max: 5,
            retryIf: (error, _operation) => {
                if (!!error) {
                    console.log(
                        _operation.operationName + ' failed with error: ' + JSON.stringify(error) + ' - retrying',
                    );
                    return true;
                }
                return false;
            },
        },
    });

    return new ApolloClient<NormalizedCacheObject>({
        link: from([retryLink, errorLink, combinedLink]),
        cache: new InMemoryCache(),
    });
}
