import { FC } from 'react';

const VersionOverlayComponent: FC = () => {
    const version = process.env.REACT_APP_PACKAGE_VERSION ?? 'Unknown';
    const releaseType = process.env.REACT_APP_RELEASE_TYPE ?? 'Unknown';

    let shortReleaseType = 'Unk';
    if (releaseType === 'production') {
        shortReleaseType = 'prod';
    } else if (releaseType === 'development') {
        shortReleaseType = 'dev';
    }
    return (
        <p>
            v: {version} ({shortReleaseType})
        </p>
    );
};

export default VersionOverlayComponent;
