import { FC } from 'react';
import StarsBackground from '../../../../shared/StarsBackground/StarsBackground';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import ProductsLoader from '../../../../shared/ProductsLoader/ProductsLoader';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import { DisplayOnlyOrchestratorService } from '../../../../../services/DisplayOnlyOrchestratorService/DisplayOnlyOrchestratorService';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import styles from './HandOffLoadingScreen.module.scss';

export interface HandOffLoadingScreenProps {
    onSetupComplete?: () => void;
    explicitHandle?: string;
}

const HandOffLoadingScreen: FC<HandOffLoadingScreenProps> = props => {
    const displayOnlyOrchestrator = useInjection<DisplayOnlyOrchestratorService>(
        DependencyType.DisplayOnlyOrchestratorService,
    );

    return (
        <StarsBackground>
            <StyledText style={TextStyle.Heading} size={TextSize.Large} className={styles.HandOffLoadingScreen__text}>
                Just a moment
            </StyledText>
            <div style={{ marginTop: '3vh' }}>
                <ProductsLoader
                    loadLocalCache={false}
                    explicitHandle={props.explicitHandle}
                    onComplete={() => props.onSetupComplete?.()}
                    onCachePopulated={() => {
                        displayOnlyOrchestrator
                            .populateDisplayState()
                            .then(() => {})
                            .catch(() => {});
                    }}
                />
            </div>
        </StarsBackground>
    );
};

export default HandOffLoadingScreen;
