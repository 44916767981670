import { FC } from 'react';
import StarsBackground from '../../../../shared/StarsBackground/StarsBackground';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import Loader from '../../../../shared/Loader/Loader';
import styles from './DisplayOnlyLoadingScreen.module.scss';
import ProductsLoader from '../../../../shared/ProductsLoader/ProductsLoader';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import { DisplayOnlyOrchestratorService } from '../../../../../services/DisplayOnlyOrchestratorService/DisplayOnlyOrchestratorService';
import DependencyType from '../../../../../dependancyInjection/DependencyType';

export interface DisplayOnlyLoadingScreenProps {
    type: 'products' | 'state';
    onSetupComplete?: () => void;
}

const DisplayOnlyLoadingScreen: FC<DisplayOnlyLoadingScreenProps> = props => {
    const displayOnlyOrchestrator = useInjection<DisplayOnlyOrchestratorService>(
        DependencyType.DisplayOnlyOrchestratorService,
    );

    return (
        <StarsBackground>
            <StyledText
                style={TextStyle.Heading}
                size={TextSize.Large}
                className={styles.DisplayOnlyLoadingScreen__text}
            >
                Just a moment
            </StyledText>
            <StyledText
                style={TextStyle.Subheading}
                size={TextSize.Small}
                className={`${styles.DisplayOnlyLoadingScreen__text} ${styles.DisplayOnlyLoadingScreen__info}`}
            >
                {props.type === 'products' && <>Cloudshelf Display is loading your products</>}
                {props.type === 'state' && <>Cloudshelf Display is populating the display state</>}
            </StyledText>
            {props.type === 'state' && <Loader variant="light" className={styles.DisplayOnlyLoadingScreen__loader} />}
            {props.type === 'products' && (
                <div style={{ marginTop: '3vh' }}>
                    <ProductsLoader
                        onComplete={() => props.onSetupComplete?.()}
                        onCachePopulated={() => {
                            displayOnlyOrchestrator
                                .populateDisplayState()
                                .then(() => {})
                                .catch(() => {});
                        }}
                    />
                </div>
            )}
        </StarsBackground>
    );
};

export default DisplayOnlyLoadingScreen;
