import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PairDeviceComponent from './components/PairDevice';
import { Routes } from '../../../services/RoutesService/Routes';
import CheckCompatibilityComponent from './components/CheckCompatibility';

const PairingApp: FC = () => {
    return (
        <Switch>
            <Route exact path={Routes.DEVICE_COMPATIBILITY}>
                <CheckCompatibilityComponent />
            </Route>
            <Route exact path={Routes.NEW_DEVICE}>
                <Redirect to={Routes.DEVICE_COMPATIBILITY} />
            </Route>
            <Route exact path={Routes.PAIR_DEVICE}>
                <PairDeviceComponent />
            </Route>
            <Route exact path={Routes.ROOT}>
                <Redirect to={Routes.DEVICE_COMPATIBILITY} />
            </Route>
            <Route>
                <Redirect to={Routes.DEVICE_COMPATIBILITY} />
            </Route>
        </Switch>
    );
};

export default PairingApp;
