import React, { useEffect } from 'react';
import makeTrashable from 'trashable';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import CurrencyService, { DEFAULT_CURRENCY_CODE } from '../../../../../services/CurrencyService/CurrencyService';
import * as Sentry from '@sentry/react';
import { StoreService } from '../../../../../services/StoreService/StoreService';

const CloudshelfSettings: React.FC = () => {
    // const currencyService = useInjection<CurrencyService>(DependencyType.CurrencyService);
    const storeService = useInjection<StoreService>(DependencyType.StoreService);

    const currencyService = new CurrencyService();
    useEffect(() => {
        const promise = makeTrashable(storeService.fetchStoreSettings());

        promise
            .then(settings => {
                CurrencyService.currencyCode = settings.paymentSettings.currencyCode;
            })
            .catch(err => {
                Sentry.captureException(err, {
                    extra: {
                        operationName: 'cloudshelfSettings useEffect',
                    },
                });
                CurrencyService.currencyCode = DEFAULT_CURRENCY_CODE;
            });

        return promise.trash;
    }, [currencyService, storeService]);

    return <></>;
};

export default CloudshelfSettings;
