import React, { FC, memo } from 'react';
import { Redirect, Route as ReactRouterRoute, Switch } from 'react-router';
import { PageViewTracker } from '../../../services/Analytics/components/PageViewTracker';
import { CategoryProductsPage } from './components/pages/products/CategoryProductsPage';
import { PowerTilePage } from './components/pages/powertile/PowerTilePage';
import { FiltersView } from '../../../services/ProductServices/filters/FiltersView';
import ProductDetailsView from './components/ProductDetailsView/ProductDetailsView';
import * as Sentry from '@sentry/react';
import { Routes } from '../../../services/RoutesService/Routes';
import { SessionManager } from '../../../services/SessionManagementService/components/SessionManager';
import SetupWrapper from './components/SetupWrapper/SetupWrapper';
import { BrandingPage } from './components/pages/brandingPage/BrandingPage';
import BannerPlayer from './components/BannerPlayer/BannerPlayer';
import { Menu } from './components/Menu/Menu';
import AttractSearch from './components/AttractSearch/AttractSearch';
import NewCategoriesPage from './components/pages/categories/CategoriesPage';
import SalesPersonPanelNew from './components/SalesPersonPanel/SalesPersonPanelNew';
import SalesPersonPanel from './components/SalesPersonPanel/SalesPersonPanel';

const InteractiveApp: FC = memo(() => {
    const Route = Sentry.withSentryRouting(ReactRouterRoute);

    return (
        <SetupWrapper>
            <Menu />
            <SalesPersonPanelNew />
            {/*<SalesPersonPanel />*/}
            <FiltersView />
            <ProductDetailsView />
            <SessionManager>
                <PageViewTracker>
                    <Switch>
                        <Route exact path={Routes.BY_CLOUDSHELF}>
                            <BrandingPage />
                        </Route>
                        <Route exact path={Routes.POWER_TILE}>
                            <PowerTilePage />
                        </Route>
                        <Route exact path={Routes.CATEGORIES}>
                            <NewCategoriesPage />
                        </Route>
                        <Route exact path={[Routes.CATEGORY_PRODUCTS, Routes.CATEGORY_PRODUCT]}>
                            <CategoryProductsPage />
                        </Route>
                        <Route exact path={Routes.ROOT}>
                            <Redirect to={Routes.CATEGORIES} />
                        </Route>
                    </Switch>
                </PageViewTracker>
                <BannerPlayer />
                <AttractSearch />
            </SessionManager>
        </SetupWrapper>
    );
});

export default InteractiveApp;
