import React from 'react';
import { ProductCustomiserField } from '../ProductCustomiserSection';
import Button, {
    ButtonOutlineVariant,
    ButtonSize,
    ButtonVariant,
} from '../../../../../../../shared/inputs/Button/Button';
import { useFilePicker } from 'use-file-picker';
import _ from 'lodash';
import { useInjection } from '../../../../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../../../../dependancyInjection/DependencyType';
import { useTranslation } from 'react-i18next';
import { MobileHandoff } from '../../../../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { HandoffService } from '../../../../../../../../services/HandoffService/HandoffService';
import QRCode from 'qrcode.react';
import StyledText, { TextSize, TextStyle } from '../../../../../../../shared/StyledText/StyledText';
import Loader from '../../../../../../../shared/Loader/Loader';
import { useWindowSize } from 'react-use';
import Confetti from 'react-confetti';

import './CustomisationFields.scss';
import { LogUtil } from '../../../../../../../../utils/Logging.Util';
import { ConfigurationService } from '../../../../../../../../services/ConfigurationService/ConfigurationService';

export interface ImageUploadProps {
    field: ProductCustomiserField;
    onChange: (value: string) => void;
    mode: 'handoff' | 'device';
    productHandle: string;
    errorText?: string;
}

const ImageUpload: React.FC<ImageUploadProps> = props => {
    const [openFileSelector, { filesContent, errors, clear }] = useFilePicker({
        multiple: false,
        readAs: 'DataURL',
        accept: ['image/png', 'image/jpeg', 'image/jpg'],
        maxFileSize: 25,
    });
    const [dataUrl, setDataUrl] = React.useState<string | undefined>(undefined);
    const [handoffImageUrl, setHandoffImageUrl] = React.useState<string | undefined>(undefined);
    const [error, setError] = React.useState<string | undefined>(undefined);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [handoff, setHandoff] = React.useState<MobileHandoff>();
    const [showConfetti, setShowConfetti] = React.useState<boolean>(false);
    const [uploaded, setUploaded] = React.useState<boolean>(false);
    const handoffService = useInjection<HandoffService>(DependencyType.HandoffService);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const { t } = useTranslation();

    const clearImage = async () => {
        setUploaded(false);
        await handoffService.setHandoffImageUrl(handoffService.getHandoffId(), '');
    };

    React.useEffect(() => {
        if (filesContent.length > 0 && filesContent[0].content.length > 0) {
            setLoading(true);
            setError(undefined);
            const dataurl = filesContent[0].content;
            // get mimetype from dataurl
            handoffService
                .uploadImageForHandoff(dataurl)
                .then(url => {
                    setShowConfetti(true);
                    setTimeout(() => {
                        setShowConfetti(false);
                    }, 500);
                    setDataUrl(dataurl);
                    setUploaded(true);
                    props.onChange(url);
                })
                .catch(() => {
                    setError(t('product_view.customisation.error_uploading'));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [filesContent]);

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (!handoff) {
                return;
            }
            if (handoff.isCanceled) {
                handoffService
                    .createHandoff(configService.cloudshelfId ?? '', props.productHandle, props.field.product_option_id)
                    .then(newHandoff => {
                        setHandoff(newHandoff);
                    })
                    .catch(() => {
                        setError(t('product_view.customisation.handoff_failed'));
                    });
            }
            handoffService
                .getHandoff(handoff.id)
                .then(ho => {
                    LogUtil.LogObject({ ho });
                    setHandoff(ho);
                    setHandoffImageUrl(ho.uploadedImageUrl ?? undefined);
                    props.onChange(ho.uploadedImageUrl ?? '');
                })
                .catch(() => {});
        }, 1000);

        return () => clearInterval(interval);
    }, [handoff]);

    React.useEffect(() => {
        if (props.mode === 'device' && props.productHandle.length > 0 && configService.cloudshelfId) {
            handoffService
                .createHandoff(configService.cloudshelfId, props.productHandle, props.field.product_option_id)
                .then(newHandoff => {
                    setHandoff(newHandoff);
                })
                .catch(() => {
                    setError('Failed to create mobile hand-off');
                });
        }
    }, [props.productHandle]);

    const imageTooLarge = errors.length > 0 && _.some(errors, error => error.fileSizeToolarge);
    const imageNotLoaded = errors.length > 0 && _.some(errors, error => error.imageNotLoaded);
    const { width, height } = useWindowSize();

    return (
        <div className="ImageUploadField__container">
            {dataUrl && (
                <div className="ImageUploadField__imageContainer">
                    <span
                        className="ImageUploadField__closeButton"
                        onClick={async () => {
                            props.onChange('');
                            setDataUrl(undefined);
                            clear();
                            await clearImage();
                        }}
                    >
                        &times;
                    </span>
                    <img src={filesContent[0].content} alt="Uploaded Image" className="ImageUploadField__image" />
                </div>
            )}
            {props.mode === 'device' && (
                <div className={'ImageUploadField__handoffContainer'}>
                    {handoff && (
                        <>
                            {!handoff.uploadedImageUrl?.length && (
                                <div className={'ImageUploadField__qrContainer'}>
                                    {handoff.isScanned && !handoff.isCanceled && (
                                        <div className={'ImageUploadField__overlay'}>
                                            <Loader variant={'dark'} className={'ImageUploadField__loader'} />
                                        </div>
                                    )}
                                    <QRCode
                                        value={(
                                            process.env.REACT_APP_HANDOFF_URL_TEMPLATE ??
                                            'https://handoff.cloudshelf.app/{ID}'
                                        ).replace('{ID}', handoff.id)}
                                        renderAs="svg"
                                        bgColor={'transparent'}
                                        width={'25vw'}
                                        height={'auto'}
                                        className={'ImageUploadField__qrCode'}
                                    />
                                </div>
                            )}
                            <StyledText
                                style={TextStyle.Body}
                                size={TextSize.Small}
                                className={'ImageUploadField__handoffText'}
                            >
                                {!handoff.isScanned && t('product_view.customisation.scan_qr')}
                                {handoff.isScanned &&
                                    !handoff.isCanceled &&
                                    !handoff.uploadedImageUrl?.length &&
                                    t('product_view.customisation.upload_message')}
                            </StyledText>
                            {handoff.isScanned && !handoff.isCanceled && !handoff.uploadedImageUrl?.length && (
                                <Button
                                    variant={ButtonVariant.PRIMARY}
                                    outline={ButtonOutlineVariant.PRIMARY}
                                    text="Cancel"
                                    className={'ImageUploadField__cancelButton'}
                                    size={ButtonSize.SM}
                                    onClick={async () => {
                                        try {
                                            const canceled = await handoffService.cancelHandoff(handoff.id);
                                            setHandoff(canceled);
                                        } catch {}
                                    }}
                                />
                            )}
                            {handoffImageUrl?.length && (
                                <div className="ImageUploadField__imageContainer">
                                    <img
                                        src={`${handoffImageUrl}?v=${new Date().getTime()}`}
                                        alt="Uploaded Image"
                                        className="ImageUploadField__image"
                                        key={handoffImageUrl}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
            {props.mode === 'handoff' && (
                <>
                    <Confetti
                        width={width}
                        height={height}
                        onConfettiComplete={confetti => {
                            setShowConfetti(false);
                            confetti?.reset();
                        }}
                        numberOfPieces={showConfetti ? 500 : 0}
                        recycle={false}
                    />
                    {uploaded && (
                        <StyledText
                            style={TextStyle.Body}
                            size={TextSize.Small}
                            className={'ImageUploadField__infoText'}
                        >
                            {t('product_view.customisation.uploaded')}
                        </StyledText>
                    )}
                    <Button
                        onClick={openFileSelector}
                        className="ImageUploadField__button"
                        loading={loading}
                        disabled={loading}
                        showLoader
                    >
                        {uploaded
                            ? t('product_view.customisation.change_photo')
                            : t('product_view.customisation.choose_photo')}
                    </Button>
                    {props.errorText && (
                        <div className="ImageUploadField__errorText">
                            <i className="fa-solid fa-triangle-exclamation" />
                            {props.errorText}
                        </div>
                    )}
                    {error && (
                        <div className="ImageUploadField__errorText">
                            <i className="fa-solid fa-triangle-exclamation" />
                            {error}
                        </div>
                    )}
                    {imageTooLarge && (
                        <div className="ImageUploadField__errorText">
                            <i className="fa-solid fa-triangle-exclamation" />
                            {t('product_view.customisation.too_large')}
                        </div>
                    )}
                    {imageNotLoaded && (
                        <div className="ImageUploadField__errorText">
                            <i className="fa-solid fa-triangle-exclamation" />
                            {t('product_view.customisation.could_not_load')}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ImageUpload;
