import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseButtonCallback, PanelHeader } from '../PanelHeader/PanelHeader';
import { PurchaseQrCode } from './PurchaseQrCode';
import { Basket } from '../../../../../services/BasketService/Basket.type';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import { BasketService } from '../../../../../services/BasketService/BasketService';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { BasketPaymentMethod } from '../../../../../services/BasketService/Basket.PaymentMethods.type';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';

import './PurchaseView.scss';

export interface PurchaseViewProps {
    basket: Basket | null | undefined;
    onCloseButtonClicked: CloseButtonCallback;
}

export const PurchaseView: React.FC<PurchaseViewProps> = ({ basket, onCloseButtonClicked }) => {
    const { t } = useTranslation();
    const [animationComplete, setAnimationComplete] = React.useState(false);
    const [hasScanned, setHasScanned] = React.useState(false);
    const basketService = useInjection<BasketService>(DependencyType.BasketService);
    const [paymentMethods, setPaymentMethods] = useState<BasketPaymentMethod[]>([]);

    useEffect(() => {
        basketService
            .getPaymentMethods()
            .then(result => setPaymentMethods(result))
            .catch();
    }, []);

    const handleScan = () => setHasScanned(true);

    if (!basket) {
        return null;
    }

    return (
        <div className="PurchaseView">
            <PanelHeader
                onCloseButtonClicked={onCloseButtonClicked}
                title={hasScanned ? t('purchase_view.header.now_pay') : t('purchase_view.header.title')}
            />
            <div className="PurchaseView__body">
                <StyledText
                    className="PurchaseView__totalPrice"
                    style={TextStyle.Heading}
                    size={TextSize.Large}
                    translate
                >
                    Total:{''}
                    <span className="NeverTranslate">{basketService.totalPrice()}</span>
                </StyledText>
                {!animationComplete && <p className={'PurchaseView__helpText'}>&nbsp;</p>}
                {animationComplete && (
                    <>
                        {!hasScanned && (
                            <StyledText
                                style={TextStyle.Body}
                                size={TextSize.Small}
                                className={'PurchaseView__helpText'}
                                translate
                            >
                                {t('purchase_view.qrcode_help_text')}
                            </StyledText>
                        )}
                    </>
                )}

                <div style={{ display: 'flex', flex: 1 }}>
                    <PurchaseQrCode
                        className="PurchaseView__qrCode"
                        basket={basket}
                        onScan={handleScan}
                        onCancelClearSession={onCloseButtonClicked}
                        onScanAnimationComplete={() => setAnimationComplete(true)}
                    />
                </div>
                <div>
                    <StyledText
                        style={TextStyle.Cloudshelf}
                        size={TextSize.Small}
                        className={'PurchaseView__checkoutText'}
                        translate
                    >
                        {hasScanned ? t('purchase_view.footer_postscan') : t('purchase_view.footer_prescan')}
                    </StyledText>
                    {hasScanned && (
                        <img
                            className="PurchaseView__branding"
                            src="/logos/simple_logo_dark.svg"
                            alt="Cloudshelf Logo"
                        />
                    )}
                    {!hasScanned && (
                        <div className="PurchaseView__paymentIcons">
                            <>
                                {paymentMethods.length === 0 && (
                                    <img src="/paymentMethods/bogus.png" alt="Bogus" width={50} />
                                )}
                                {paymentMethods.includes(BasketPaymentMethod.AmericanExpress) && (
                                    <img src="/paymentMethods/american.png" alt="American Express" />
                                )}
                                {paymentMethods.includes(BasketPaymentMethod.DinersClub) && (
                                    <img src="/paymentMethods/diners.png" alt="Diners Club International" />
                                )}
                                {paymentMethods.includes(BasketPaymentMethod.Discover) && (
                                    <img src="/paymentMethods/discover.png" alt="Discover Network" />
                                )}
                                {paymentMethods.includes(BasketPaymentMethod.JCB) && (
                                    <img src="/paymentMethods/jcb.png" alt="JCB" />
                                )}
                                {paymentMethods.includes(BasketPaymentMethod.Mastercard) && (
                                    <img src="/paymentMethods/master.png" alt="Mastercard" />
                                )}
                                {paymentMethods.includes(BasketPaymentMethod.Visa) && (
                                    <img src="/paymentMethods/visa.png" alt="Visa" />
                                )}
                                {paymentMethods.includes(BasketPaymentMethod.ShopPay) && (
                                    <img src="/paymentMethods/shoppay.png" alt="Shop Pay" />
                                )}
                                {paymentMethods.includes(BasketPaymentMethod.ApplePay) && (
                                    <img src="/paymentMethods/apple.png" alt="Apple Pay" />
                                )}
                                {paymentMethods.includes(BasketPaymentMethod.GooglePay) && (
                                    <img src="/paymentMethods/google.png" alt="Google Pay" />
                                )}
                                {paymentMethods.includes(BasketPaymentMethod.AndroidPay) && (
                                    <img src="/paymentMethods/android.png" alt="Android Pay" />
                                )}
                            </>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
