import React, { FC } from 'react';

const FilterIcon: FC = props => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 12L4 12M6 12C6 13.1046 6.89543 14 8 14C9.10457 14 10 13.1046 10 12M6 12C6 10.8954 6.89543 10 8 10C9.10457 10 10 10.8954 10 12M18 18C18 16.8954 17.1046 16 16 16C14.8954 16 14 16.8954 14 18M18 18C18 19.1046 17.1046 20 16 20C14.8954 20 14 19.1046 14 18M18 18L20 18M14 18L4 18M10 12L20 12M18 6C18 4.89543 17.1046 4 16 4C14.8954 4 14 4.89543 14 6M18 6C18 7.10457 17.1046 8 16 8C14.8954 8 14 7.10457 14 6M18 6L20 6M14 6L4 6"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
export default FilterIcon;
