import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import {
    DisplayOnlyOrchestratorService,
    DisplayState,
} from '../../../../../services/DisplayOnlyOrchestratorService/DisplayOnlyOrchestratorService';
import CategoryPane from '../CategoryPane/CategoryPane';
import ProductPane from '../ProductPane/ProductPane';
import './Player.scss';
import FullPageErrorComponent from '../../../../shared/FullPageError/FullPageError';
import { useTranslation } from 'react-i18next';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import DisplayOnlyLoadingScreen from '../DisplayOnlyLoadingScreen/DisplayOnlyLoadingScreen';
import BannerPane from '../../../../shared/BannerPane/BannerPane';

const Player: FC = memo(() => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const displayOnlyOrchestrator = useInjection<DisplayOnlyOrchestratorService>(
        DependencyType.DisplayOnlyOrchestratorService,
    );
    const [, updateRenderState] = useState<unknown>();
    const forceUpdate = useCallback(() => updateRenderState({}), []);
    const [displayState, setDisplayState] = useState<DisplayState>();
    const [hasProducts, setHasProducts] = useState<boolean>();
    const { t } = useTranslation();

    useEffect(() => {
        const displayOnlyOrchestratorSubscription = displayOnlyOrchestrator.observe().subscribe(() => {
            const newDisplayState = displayOnlyOrchestrator.displayState;
            if (newDisplayState !== undefined) {
                setDisplayState(newDisplayState);
                console.log('newDisplayState', newDisplayState);
                forceUpdate();
            }
            setHasProducts(displayOnlyOrchestrator.canDisplayProducts());
        });

        void displayOnlyOrchestrator.start();

        return () => {
            if (displayOnlyOrchestratorSubscription) {
                displayOnlyOrchestratorSubscription.unsubscribe();
            }

            void displayOnlyOrchestrator.stop();
        };
    }, []);

    if (displayState === undefined) {
        return <DisplayOnlyLoadingScreen type="state" />;
    }

    if (hasProducts === false) {
        return (
            <FullPageErrorComponent
                variant="custom"
                title={t('display_mode.noProductsTitle')}
                subtitle={t('display_mode.noProductsSubtitle')}
            />
        );
    }

    return (
        <div className="Player">
            {displayState &&
                displayState.availableCategories &&
                displayState.availableCategories.map((category, categoryIndex) => {
                    return category.products.map((product, productIndex) => {
                        return (
                            <ProductPane
                                className={'Player__full'}
                                key={`Player-CategoryPane-${categoryIndex}-Product-${productIndex}`}
                                product={product}
                                active={
                                    productIndex === category.currentProductIndex &&
                                    categoryIndex === displayState.currentCategoryIndex &&
                                    !displayState.shouldShowBanner
                                }
                                config={configService.displayOnlyConfig}
                            />
                        );
                    });
                })}

            {displayState &&
                displayState.availableCategories &&
                displayState.availableCategories.map((category, categoryIndex) => {
                    return (
                        <CategoryPane
                            className={'Player__full'}
                            key={`Player-CategoryPane-${categoryIndex}`}
                            category={category}
                            active={
                                categoryIndex === displayState.currentCategoryIndex &&
                                category.products[category.currentProductIndex] &&
                                category.products[category.currentProductIndex].currentProductImageIndex === -1 &&
                                !displayState.shouldShowBanner
                            }
                        />
                    );
                })}

            {displayState &&
                displayState.availableBanners &&
                displayState.availableBanners.map((banner, bannerIndex) => {
                    return (
                        <BannerPane
                            className={'Player__full'}
                            key={`Player-BannerBane-${bannerIndex}`}
                            banner={banner}
                            active={bannerIndex === displayState.bannerIndex && displayState.shouldShowBanner}
                        />
                    );
                })}
        </div>
    );
});

export default Player;
