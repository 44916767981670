import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode.react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import {
    PurchaseUrl,
    PurchaseUrlGeneratorService,
} from '../../../../../provider/common/purchase/PurchaseUrlGeneratorService';
import Button, { ButtonVariant } from '../../../../shared/inputs/Button/Button';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import scanJson from './animations/scan.json';
import confettiJson from './animations/confetti.json';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import { Basket } from '../../../../../services/BasketService/Basket.type';
import { CloudshelfTrackedURLService } from '../../../../../services/TrackedURLService/CloudshelfTrackedURLService';
import './PurchaseQrCode.scss';
import { SessionManagementService } from '../../../../../services/SessionManagementService/SessionManagementService';
import { SentryUtil } from '../../../../../utils/Sentry.Util';
import { convertRemToPixels } from '../../../../../utils/Responsive.Util';

export interface PurchaseQrCodeProps {
    basket: Basket;
    onScan: () => void;
    onCancelClearSession: () => void;
    onScanAnimationComplete: () => void;
    className?: string;
}

export const PurchaseQrCode: React.FC<PurchaseQrCodeProps> = ({
    className = '',
    basket,
    onScan,
    onCancelClearSession,
    onScanAnimationComplete,
}) => {
    const providerPurchaseUrlGeneratorService = useInjection<PurchaseUrlGeneratorService>(
        DependencyType.ProviderPurchaseUrlGeneratorService,
    );
    const cloudshelfTrackedUrlService = useInjection<CloudshelfTrackedURLService>(
        DependencyType.CloudshelfTrackedURLService,
    );
    const qrCodeContainer = useRef<HTMLDivElement>(null);
    const [qrValue, setQrValue] = useState<PurchaseUrl>();
    const [showQr, setShowQr] = useState<boolean>(false);
    const [qrIsScanned, setQrIsScanned] = useState<boolean>(false);
    const [countdown, setCountdown] = useState(-1);
    const sessionService = useInjection<SessionManagementService>(DependencyType.SessionManagementService);
    const { t } = useTranslation();

    let qrCodeSize = Math.max(document.documentElement.clientWidth, window.innerWidth || 0) * 0.33;
    const minQrSize = convertRemToPixels(8);
    const maxQrSize = convertRemToPixels(15);
    if (qrCodeSize > maxQrSize) {
        qrCodeSize = maxQrSize;
    } else if (qrCodeSize < minQrSize) {
        qrCodeSize = minQrSize;
    }
    qrCodeSize -= 20;

    useEffect(() => {
        const { newTransaction } = SentryUtil.StartTransaction('PurchaseQrCode.Generate', false);
        providerPurchaseUrlGeneratorService
            .generateBasketPurchaseUrl()
            .then(urlObject => {
                setQrValue(urlObject);
            })
            .catch()
            .finally(() => {
                SentryUtil.EndSpan(newTransaction);
            });
    }, [basket]);

    useEffect(() => {
        let interval: NodeJS.Timeout;

        // We can only track cloudshelf QR code scans
        if (qrValue && qrValue.url !== '' && qrValue.isTracked && !qrIsScanned) {
            interval = setInterval(() => {
                const transaction = SentryUtil.StartTransaction('PurchaseQrCode.IsScanned', false);
                cloudshelfTrackedUrlService
                    .isTrackedUrlQRScanned(qrValue.id ?? '')
                    .then(isScanned => {
                        if (isScanned) {
                            setShowQr(false);
                            setQrIsScanned(true);
                            clearInterval(interval);
                            onScan();
                        }
                    })
                    .catch()
                    .finally(() => {
                        SentryUtil.EndSpan(transaction.newTransaction);
                    });
            }, 1000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [qrValue]);

    useEffect(() => {
        if (countdown === 0) {
            void sessionService.endSession();
            window.location.replace(
                `${window.location.origin}${window.location.pathname.substring(
                    0,
                    window.location.pathname.indexOf('/', 1),
                )}`,
            );
        }

        if (countdown !== -1) {
            const timeout = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);

            return () => clearTimeout(timeout);
        }
    }, [countdown]);

    return (
        <div style={{ width: 'fit-content', height: '100%', maxHeight: '300px', alignSelf: 'center' }}>
            {/*<>*/}
            {/*    Debug Info:*/}
            {/*    <br />*/}
            {/*    Show QR: {showQr ? 'Yes' : 'No'}*/}
            {/*    <br />*/}
            {/*    QR Is Scanned: {qrIsScanned ? 'Yes' : 'No'}*/}
            {/*    <br />*/}
            {/*    Countdown: {countdown}*/}
            {/*    <br />*/}
            {/*    QrValue Undefined: {qrValue === undefined ? 'Yes' : 'No'}*/}
            {/*    <br />*/}
            {/*    QrValue URL: {qrValue?.url}*/}
            {/*    <br />*/}
            {/*    Lottie Animation Finished: {onAnimationFinished ? 'Yes' : 'No'}*/}
            {/*</>*/}
            {!showQr && !qrIsScanned && (
                <Lottie
                    animationData={scanJson}
                    onComplete={() => {
                        setShowQr(true);
                        onScanAnimationComplete();
                    }}
                    play
                    style={{
                        width: '100%',
                        height: '100%',
                        transform: 'scale(1.5)',
                        marginTop: '-17.5%',
                        maxHeight: '300px',
                    }}
                    loop={false}
                />
            )}
            {!showQr && qrIsScanned && countdown === -1 && (
                <Lottie
                    animationData={confettiJson}
                    onComplete={() => {
                        setTimeout(() => setCountdown(10), 5000);
                    }}
                    play
                    style={{ width: '100%', height: '100%' }}
                    loop={false}
                />
            )}
            {countdown !== -1 && (
                <div className={'PurchaseQrCode__countdown'}>
                    <StyledText style={TextStyle.Subheading} size={TextSize.Large}>
                        {t('session.expiring_soon', {
                            remaining: countdown,
                        })}
                    </StyledText>
                    <br />
                    <Button
                        text={t('session.continue_button')}
                        onClick={onCancelClearSession}
                        variant={ButtonVariant.PRIMARY}
                    />
                </div>
            )}
            {showQr && qrValue && qrValue.url !== '' && (
                <div className={`PurchaseQrCode ${className}`}>
                    <div className="PurchaseQrCode__container" ref={qrCodeContainer}>
                        <QRCode
                            className="PurchaseQrCode_qrCode"
                            renderAs="svg"
                            value={qrValue.url}
                            size={qrCodeSize}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
