import React, { FC } from 'react';
const SearchIcon: FC = props => {
    return (
        <svg width="52" height="56" viewBox="0 0 52 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M33.9198 32.1552H35.8832L46.4211 42.7428C47.4401 43.7618 47.4401 45.4269 46.4211 46.4459C45.4021 47.4649 43.7369 47.4649 42.7179 46.4459L32.1552 35.8832V33.9198L31.4842 33.2239C28.0047 36.2063 23.2577 37.7472 18.2125 36.9022C11.3032 35.7341 5.78578 29.9681 4.94076 23.0092C3.64839 12.4962 12.4962 3.64839 23.0092 4.94076C29.9681 5.78578 35.7341 11.3032 36.9022 18.2125C37.7472 23.2577 36.2063 28.0047 33.2239 31.4842L33.9198 32.1552ZM9.78809 20.9709C9.78809 27.1594 14.7836 32.1549 20.9721 32.1549C27.1606 32.1549 32.1561 27.1594 32.1561 20.9709C32.1561 14.7824 27.1606 9.78689 20.9721 9.78689C14.7836 9.78689 9.78809 14.7824 9.78809 20.9709Z"
                    fill="black"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d"
                    x="-3.42212"
                    y="0.577881"
                    width="58.8445"
                    height="58.8445"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};
export default SearchIcon;
