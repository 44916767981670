import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';

export interface IVirtualizeListProps {
    elements: ReactNode[];
    heightRule: string;
}

export const VirtualizeList: FC<IVirtualizeListProps> = props => {
    const parentRef = React.useRef<HTMLDivElement | null>(null);
    const [items, setItems] = useState(props.elements);

    useEffect(() => {
        setItems(props.elements);
    }, [props.elements]);

    useEffect(() => {
        if (items.length > 0) {
            virtualizer.scrollToIndex(0);
        }
    }, [items]);

    const virtualizer = useVirtualizer({
        horizontal: true,
        count: items.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 5000,
        overscan: 3,
    });

    return (
        <div ref={parentRef} className="List" style={{ width: '100%', height: props.heightRule, overflowY: 'auto' }}>
            <div
                style={{
                    width: virtualizer.getTotalSize(),
                    height: '100%',
                    position: 'relative',
                }}
            >
                {virtualizer.getVirtualItems().map(virtualColumn => (
                    <div
                        key={virtualColumn.key}
                        data-index={virtualColumn.index}
                        ref={virtualizer.measureElement}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            transform: `translateX(${virtualColumn.start}px)`,
                        }}
                    >
                        <div
                            style={{
                                marginLeft: virtualColumn.index === 0 ? '0' : '0.52vw',
                                marginRight: virtualColumn.index === items.length - 1 ? '0' : '0.52vw',
                            }}
                        >
                            {items[virtualColumn.index]}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
