import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StarsBackground from '../StarsBackground/StarsBackground';
import { useInjection } from '../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../dependancyInjection/DependencyType';
import _ from 'lodash';

import styles from './FullPageError.module.scss';
import { ConfigurationService } from '../../../services/ConfigurationService/ConfigurationService';
import NonResponsiveStyledText, {
    NonResponsiveTextSize,
    NonResponsiveTextStyle,
} from '../StyledText/NonResponsiveStyledText';

export interface ErrorPageProps {
    title?: string;
    subtitle?: string;
    name?: string;
    variant:
        | 'notFoundCloudshelf'
        | 'deviceNoCloudshelf'
        | 'noLocation'
        | 'custom'
        | 'unknown'
        | 'noConnection'
        | 'frozen'
        | 'maintenance';
}

const FullPageErrorComponent: FC<ErrorPageProps> = props => {
    const { t } = useTranslation();

    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);

    let title = '';
    const subtitles: string[] = [];
    if (props.variant === 'noLocation') {
        title = t('error_page.titleNoLocation');
        subtitles.push(t('error_page.subtitleNoLocation'));
    } else if (props.variant === 'notFoundCloudshelf') {
        title = t('error_page.titleNotFound');
        subtitles.push(t('error_page.subtitleNotFound'));
    } else if (props.variant === 'deviceNoCloudshelf') {
        title = t('error_page.titleDeviceNoCloudshelf');
        subtitles.push(t('error_page.subtitleDeviceNoCloudshelf'));
    } else if (props.variant === 'noConnection') {
        title = t('error_page.titleNoConnection');
        subtitles.push(t('error_page.subtitleNoConnection'));
    } else if (props.variant === 'custom') {
        title = props.title ?? '';
        subtitles.push(props.subtitle ?? '');
    } else if (props.variant === 'frozen') {
        title = t('error_page.titleFrozen');
        subtitles.push(t('error_page.subtitleFrozen'));
    } else if (props.variant === 'maintenance') {
        title = t('error_page.titleMaintenance');
        subtitles.push(t('error_page.subtitleMaintenance'));
        subtitles.push(t('error_page.subtitle2Maintenance'));
    } else {
        title = t('error_page.title');
        subtitles.push(t('error_page.subtitle'));
    }

    return (
        <StarsBackground>
            <div className={styles.error_title}>
                {!_.isEmpty(configService.config()?.device?.owner.name ?? '') && (
                    <div className={styles.error_retailer}>
                        <NonResponsiveStyledText
                            style={NonResponsiveTextStyle.Cloudshelf}
                            size={NonResponsiveTextSize.Small}
                            center
                            className={styles.white}
                        >
                            {configService.config()?.device?.owner.name}
                        </NonResponsiveStyledText>
                    </div>
                )}

                <NonResponsiveStyledText
                    style={NonResponsiveTextStyle.CloudshelfBold}
                    size={NonResponsiveTextSize.Large}
                    center
                    translate
                    className={styles.white}
                >
                    <div dangerouslySetInnerHTML={{ __html: title }}></div>
                </NonResponsiveStyledText>

                {_.map(subtitles, subtitle => (
                    <NonResponsiveStyledText
                        style={NonResponsiveTextStyle.Cloudshelf}
                        size={NonResponsiveTextSize.Small}
                        center
                        translate
                        className={styles.white}
                    >
                        <div dangerouslySetInnerHTML={{ __html: subtitle }}></div>
                    </NonResponsiveStyledText>
                ))}
            </div>
        </StarsBackground>
    );
};

export default FullPageErrorComponent;
