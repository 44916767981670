import React, { FC, useEffect, useRef, useState, ReactElement, CSSProperties } from 'react';
import { useHistory } from 'react-router-dom';
import { useInjection } from '../../../../../../dependancyInjection/DependencyContext';
import { ConfigurationService } from '../../../../../../services/ConfigurationService/ConfigurationService';
import { BackButtonMode, MenuService, MenuStyle } from '../../../../../../services/MenuService/MenuService';
import BrandLogo from '../../../../../shared/BrandLogo/BrandLogo';
import { TileAnimationOrchestratorService } from '../../../../../../services/TileAnimationOrchestratorService/TileAnimationOrchestratorService';
import DependencyType from '../../../../../../dependancyInjection/DependencyType';
import { TileClicksService } from '../../../../../../services/SessionManagementService/TileClicksService';
import {
    CloudshelfThemeAlignment,
    CloudshelfThemeTileAnimation,
    CloudshelfThemeTouchIndicator,
    PowerTileType,
} from '../../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { CategoryService } from '../../../../../../services/CategoryService/CategoryService';
import { Category } from '../../../../../../services/CategoryService/entities/Category';
import { RoutesHelperService } from '../../../../../../services/RoutesService/RoutesHelperService';
import _ from 'lodash';
import './CategoriesPage.scss';
import { BackgroundsSwitcher } from '../../BackgroundSwitcher/BackgroundsSwitcher';
import AnimatedFlipCard from '../../AnimatedFlipCard/AnimatedFlipCard';
import ResponsiveGrid, { generateGridProperties } from '../../../../../shared/ResponsiveGrid/ResponsiveGrid';
import { useMeasure } from 'react-use';
import { CallToAction } from '../../CallToAction/CallToAction';
import TouchIndicator from '../../TouchIndicator/TouchIndicator';
import { useComputedStyle } from '../../../../../../hooks/UseComputedStyle';
import { getDeviceDPI } from '../../../../../../index';
import { getMenuHeight } from '../../Menu/Menu';

const NewCategoriesPage: FC = () => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const categoriesService = useInjection<CategoryService>(DependencyType.CategoryService);
    const menuService = useInjection<MenuService>(DependencyType.MenuService);
    const tileAnimationOrchestrator = useInjection<TileAnimationOrchestratorService>(
        DependencyType.TileAnimationOrchestratorService,
    );
    const tileClicksService = useInjection<TileClicksService>(DependencyType.TileClicksService);
    const history = useHistory();
    const tileAnimationStyle =
        configService.config()?.theme.attractScreen.tileAnimation ?? CloudshelfThemeTileAnimation.Flip;
    const [tileItems, setTileItems] = useState<ReactElement[]>([]);
    const [firstItemRef, { width: singleRowHeight }] = useMeasure<HTMLDivElement>();
    const gridParentDivRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const pageRef = useRef<HTMLDivElement>(null);
    const [hasSlidIn, setHasSlidIn] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const pageHeight = useComputedStyle(pageRef, 'height');
    const gridSizes = generateGridProperties(gridParentDivRef, getDeviceDPI());
    const numberRows = tileItems.length / gridSizes.numGridColumns;
    const numberRowsRounded = Math.ceil(numberRows);

    const expandedSizeGridArea =
        numberRowsRounded * singleRowHeight + Math.ceil(numberRowsRounded - 1) * gridSizes.gutterSize;

    const expandedRulePartOne = pageHeight - getMenuHeight() - (headerRef.current?.clientHeight ?? 0);

    const expandedRule = `min(calc(${expandedRulePartOne}px), ${expandedSizeGridArea}px)`;

    const gridStyles: CSSProperties = {
        height: !isExpanded ? `${singleRowHeight}px` : expandedRule,
        overflow: isExpanded ? 'scroll' : 'hidden',
        paddingBottom: isExpanded ? '2.2vw' : '0px',
    };

    const drawerStyle: CSSProperties = {
        transform: hasSlidIn ? 'unset' : 'translateY(100%)',
        padding: !isExpanded ? '0px 2.2vw 2.2vw' : '0px 2.2vw',
    };

    if (!configService.config()?.theme.attractScreen.displayFrame) {
        drawerStyle.boxShadow = 'none';
        drawerStyle.background = 'transparent';
    }

    const touchIndicatorClassName = () => {
        const classNames: string[] = [];

        if (
            configService.config()?.theme.attractScreen.touchIndicator ===
            CloudshelfThemeTouchIndicator.ClassicHandPointer
        ) {
            classNames.push('CategoriesPage__handIcon');

            if (configService.config()?.theme.attractScreen.callToActionAlignment === CloudshelfThemeAlignment.Right) {
                classNames.push('CategoriesPage__handIcon__left');
            }
        }

        return _.join(classNames, ' ');
    };

    useEffect(() => {
        if (singleRowHeight !== 0 && !hasSlidIn) {
            setHasSlidIn(true);
        }
        setHasSlidIn(true);
    }, [singleRowHeight, hasSlidIn]);

    useEffect(() => {
        menuService.setBackButtonMode(BackButtonMode.NONE);
        menuService.setMenuStyle(MenuStyle.BUTTON_EXPANDABLE_SEARCH);
        menuService.setFilterButtonVisible(true);
    }, [menuService]);

    useEffect(() => {
        const cardItems = _.compact(
            _.map(configService.powerTiles, tile => {
                if (tile.type === PowerTileType.Category) {
                    const category: Category | undefined = categoriesService.getByHandle(tile.handle ?? '');
                    return {
                        ...tile,
                        onClicked: async (e: React.MouseEvent<HTMLDivElement>) => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            await tileClicksService.reportTileClicks(tile.id);
                            if (category) {
                                const categoryProductsRoute = RoutesHelperService.toCategoryProducts(category);
                                history.push(categoryProductsRoute);
                            }
                        },
                    };
                } else if (tile.type === PowerTileType.Qrcode) {
                    return {
                        ...tile,
                        onClicked: async (e: React.MouseEvent<HTMLDivElement>) => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            await tileClicksService.reportTileClicks(tile.id);
                            const powerTileRoute = RoutesHelperService.toPowerTile(tile);
                            history.push(powerTileRoute);
                        },
                    };
                }
            }),
        );

        const items = cardItems.map((powerTile, index) => {
            const shouldStopAnimation = isExpanded;

            const isOffScreen = index + 1 > gridSizes.numGridColumns;
            return (
                <AnimatedFlipCard
                    key={`categories-list-item-${powerTile.id}`}
                    originalItem={powerTile}
                    flipOrientation={'vertical'}
                    animationStyle={tileAnimationStyle}
                    stop={isOffScreen || shouldStopAnimation}
                    animationMultiplier={gridSizes.numGridColumns}
                    index={index}
                    textSizeModifier={singleRowHeight}
                    imageAnchor={configService.imageAnchor}
                />
            );
        });

        setTileItems(items);

        tileAnimationOrchestrator.setTileItems(cardItems);
        tileAnimationOrchestrator.setColumnCount(gridSizes.numGridColumns);
    }, [
        singleRowHeight,
        gridSizes.numGridColumns,
        tileAnimationOrchestrator,
        tileClicksService,
        history,
        configService,
        isExpanded,
    ]);

    const handleToggleExpanded = () => {
        let ruleToUse = expandedRulePartOne;

        if (expandedSizeGridArea < ruleToUse) {
            ruleToUse = expandedSizeGridArea;
        }

        if (ruleToUse > singleRowHeight) {
            setIsExpanded(!isExpanded);
        } else {
            setIsExpanded(false);
        }
    };
    return (
        <div className={'CategoriesPage'} onClick={handleToggleExpanded} ref={pageRef}>
            <BrandLogo />
            <BackgroundsSwitcher />
            <div className={'CategoriesPage__drawer'} style={drawerStyle}>
                <div className="CategoriesPage__touchIndicatorWrapper">
                    <TouchIndicator
                        style={configService.config()?.theme.attractScreen.touchIndicator}
                        className={touchIndicatorClassName()}
                        isHidden={
                            numberRows <= 1 ||
                            (configService.config()?.theme.attractScreen.touchIndicator ===
                                CloudshelfThemeTouchIndicator.ClassicHandPointer &&
                                isExpanded)
                        }
                        rotate={isExpanded ? 180 : 0}
                    />
                </div>
                <div className="CategoriesPage__drawer__header" ref={headerRef}>
                    <CallToAction
                        className={`CategoriesPage__drawer__header__alignment__${
                            configService.config()?.theme.attractScreen.callToActionAlignment
                        }`}
                        title={configService.config()?.theme.attractScreen.callToAction}
                    />
                </div>
                <div className="CategoriesPage__drawer__content" ref={gridParentDivRef} style={gridStyles}>
                    <ResponsiveGrid
                        gridSizes={gridSizes}
                        firstNoneHeroItemRef={firstItemRef}
                        items={_.map(tileItems, (item, index) => {
                            return {
                                element: item,
                            };
                        })}
                    />
                </div>
            </div>
        </div>
    );
};

export default NewCategoriesPage;
