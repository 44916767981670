import React, { FC } from 'react';

const RemoveShoppingCartIcon: FC = props => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6" cy="20" r="2" fill="black" />
            <circle cx="16" cy="20" r="2" fill="black" />
            <path d="M7.77778 12.2857L6.62661 6.62661L12.2857 12.2857H7.77778Z" fill="black" />
            <path d="M21 4.85714L17.2222 12.2857L9.79365 4.85714H21Z" fill="black" />
            <path
                d="M5.61227 14.4148L4.91118 13.7018L4.91118 13.7018L5.61227 14.4148ZM2.20711 0.792893C1.81658 0.402369 1.18342 0.402369 0.792893 0.792893C0.402369 1.18342 0.402369 1.81658 0.792893 2.20711L2.20711 0.792893ZM21.2929 22.7071C21.6834 23.0976 22.3166 23.0976 22.7071 22.7071C23.0976 22.3166 23.0976 21.6834 22.7071 21.2929L21.2929 22.7071ZM17.2222 12.2857L16.5151 12.9928C16.7416 13.2193 17.0628 13.3236 17.379 13.2733C17.6953 13.2231 17.9684 13.0245 18.1136 12.739L17.2222 12.2857ZM21 4.85714L21.8914 5.31044C22.049 5.00043 22.0343 4.63078 21.8524 4.33431C21.6706 4.03784 21.3478 3.85714 21 3.85714V4.85714ZM9.79365 4.85714V3.85714C9.38919 3.85714 9.02455 4.10079 8.86977 4.47446C8.71499 4.84813 8.80054 5.27825 9.08654 5.56425L9.79365 4.85714ZM6.31336 15.1279L8.47887 12.9988L7.07669 11.5726L4.91118 13.7018L6.31336 15.1279ZM6.28009 15C6.27485 15 6.27718 14.9994 6.28455 15.0017C6.29167 15.0039 6.29944 15.0076 6.30662 15.0126C6.32019 15.022 6.32626 15.0318 6.32909 15.0385C6.33192 15.0452 6.33613 15.06 6.33212 15.0828C6.33004 15.0946 6.32609 15.1061 6.32092 15.1159C6.31563 15.126 6.31149 15.1297 6.31336 15.1279L4.91118 13.7018C3.6523 14.9395 4.57989 17 6.28009 17V15ZM0.792893 2.20711L5.9195 7.33372L7.33372 5.9195L2.20711 0.792893L0.792893 2.20711ZM5.64668 6.82595L6.79785 12.4851L8.75771 12.0864L7.60654 6.42727L5.64668 6.82595ZM5.9195 7.33372L11.5786 12.9928L12.9928 11.5786L7.33372 5.9195L5.9195 7.33372ZM7.77778 13.2857H12.2857V11.2857H7.77778V13.2857ZM11.5786 12.9928L15.2929 16.7071L16.7071 15.2929L12.9928 11.5786L11.5786 12.9928ZM15.2929 16.7071L21.2929 22.7071L22.7071 21.2929L16.7071 15.2929L15.2929 16.7071ZM6.28009 17H16V15H6.28009V17ZM18.1136 12.739L21.8914 5.31044L20.1086 4.40384L16.3309 11.8324L18.1136 12.739ZM17.9293 11.5786L10.5008 4.15004L9.08654 5.56425L16.5151 12.9928L17.9293 11.5786ZM9.79365 5.85714H21V3.85714H9.79365V5.85714Z"
                fill="black"
            />
        </svg>
    );
};
export default RemoveShoppingCartIcon;
