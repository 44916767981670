import React, { useState } from 'react';

import './FilterInputWrapper.scss';
import { Flipped } from 'react-flip-toolkit';

export interface IFilterInputWrapperProps {
    label: string;
    subLabel?: string;
    expandedByDefault?: boolean;
    flipId: string;
}

export const FilterInputWrapper: React.FC<IFilterInputWrapperProps> = ({
    flipId,
    expandedByDefault,
    label,
    children,
}) => {
    // Suppress the next error as we've temp disabled code that doesn't work properly, but don't want to lose it
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isExpanded, setIsExpanded] = useState<boolean>(expandedByDefault ? expandedByDefault : false);
    return (
        <Flipped flipId={flipId}>
            <li className="FilterWrapper">
                <div className="FilterWrapper__header">
                    <h2 className="FilterWrapper__header__name">{label}</h2>
                    {/*<Button className="FilterWrapper__header__chevron" onClick={() => setIsExpanded(!isExpanded)}>
                    <img src={isExpanded ? arrow_up : arrow_down} />
                </Button>*/}
                </div>

                <div className={`FilterWrapper__body ${isExpanded ? 'FilterWrapper__body__expanded' : ''}`}>
                    {children}
                </div>
            </li>
        </Flipped>
    );
};

FilterInputWrapper.defaultProps = {
    label: '',
    subLabel: '',
    expandedByDefault: false,
};
