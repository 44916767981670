import { FC } from 'react';
import useStateRef from 'react-usestateref';
import HandOffLoadingScreen from '../HandOffLoadingScreen/HandOffLoadingScreen';

export interface HandOffSetupWrapperProps {
    explicitHandle?: string;
}

const HandOffSetupWrapper: FC<HandOffSetupWrapperProps> = props => {
    const [setupComplete, setSetupComplete] = useStateRef(false);

    return (
        <>
            {setupComplete ? (
                props.children
            ) : (
                <>
                    <HandOffLoadingScreen
                        onSetupComplete={() => setSetupComplete(true)}
                        explicitHandle={props.explicitHandle}
                    />
                </>
            )}
        </>
    );
};

export default HandOffSetupWrapper;
