import { FilterableProductVariant, LocalProduct } from '../services/ProductServices/LocalProduct';
import _ from 'lodash';
import { gzipDecompress } from './Compression.Util';
import { CloudshelfEngineFilter } from '../services/ConfigurationService/types/filters/CloudshelfEngineFilter';
import { AttributeValueOverride, FilterType } from '../provider/cloudshelf/graphql/generated/cloudshelf_types';

export function decompressProductBinaryAndMapToFilterableProductVariants(
    compressedProductBinary: string,
    filters: CloudshelfEngineFilter[],
): FilterableProductVariant[] {
    const decompressedBin = gzipDecompress(compressedProductBinary);
    const convertedData = JSON.parse(decompressedBin) as LocalProduct[];

    return MapToFilterableProductVariants(convertedData, filters);
}

export function MapToFilterableProductVariants(
    products: LocalProduct[],
    filters: CloudshelfEngineFilter[],
): FilterableProductVariant[] {
    const mappedVariants = _.map(products, (p): FilterableProductVariant[] =>
        _.map(p.variants, (v): FilterableProductVariant => {
            let options = v.options;

            if (options === null || options === undefined) {
                options = JSON.parse(v.optionsJson);
            }

            const mappedOptions = _.map(options, (val, key) => `${key}:${val}`);

            _.map(options, (val, key) => {
                const filterForOption = filters.filter(f => f.ecommProviderFieldName === key);
                if (filterForOption.length > 0) {
                    _.forEach(filterForOption, (filter: CloudshelfEngineFilter) => {
                        _.forEach(filter.valueOverrides, (override: AttributeValueOverride) => {
                            if (override.originalValue === val) {
                                mappedOptions.push(`${key}:${override.displayValue}`);
                            }
                        });
                    });
                }
            });

            const filterForVendor = filters.filter(f => f.type === FilterType.Vendor);
            if (filterForVendor.length > 0) {
                mappedOptions.push(`Vendor:${p.vendor}`);
                _.forEach(filterForVendor, (filter: CloudshelfEngineFilter) => {
                    _.forEach(filter.valueOverrides, (override: AttributeValueOverride) => {
                        if (override.originalValue === p.vendor) {
                            mappedOptions.push(`Vendor:${override.displayValue}`);
                        }
                    });
                });
            }

            const filterForProductType = filters.filter(f => f.type === FilterType.ProductType);
            if (filterForProductType.length > 0) {
                mappedOptions.push(`Product Type:${p.type}`);
                _.forEach(filterForProductType, (filter: CloudshelfEngineFilter) => {
                    _.forEach(filter.valueOverrides, (override: AttributeValueOverride) => {
                        if (override.originalValue === p.type) {
                            mappedOptions.push(`Product Type:${override.displayValue}`);
                        }
                    });
                });
            }

            const filterForTag = filters.filter(f => f.type === FilterType.Tag);
            if (filterForTag.length > 0) {
                mappedOptions.push(..._.map(p.tags, tag => `Tags:${tag}`));

                _.forEach(filterForTag, (filter: CloudshelfEngineFilter) => {
                    _.forEach(filter.valueOverrides, (override: AttributeValueOverride) => {
                        if (_.includes(p.tags, override.originalValue)) {
                            mappedOptions.push(`Tags:${override.displayValue}`);
                        }
                    });
                });
            }
            const metafieldValues: string[] = [];
            const originalMetafieldValues = _.map(p.metafields, m => m.value);
            const filterForMetafields = filters.filter(
                f =>
                    f.type === FilterType.ShopifyMetafield &&
                    _.find(p.metafields, mf => mf.namespace === f.metafieldNamespace && mf.key === f.metafieldKey),
            );

            _.forEach(originalMetafieldValues, (value: string) => {
                metafieldValues.push(value);
            });

            _.forEach(filterForMetafields, (filter: CloudshelfEngineFilter) => {
                _.forEach(filter.valueOverrides, (override: AttributeValueOverride) => {
                    metafieldValues.push(override.displayValue);
                });
            });

            return {
                shopifyVariantId: v.shopifyGid,
                shopifyProductId: p.shopifyId,
                productHandle: p.handle,
                productTitle: p.title,
                productDescription: p.descriptionHtml,
                categoryHandles: p.categoryHandles,
                categoryIds: p.categoryIds,
                productType: p.type,
                productVendor: p.vendor,
                productTags: p.tags,
                remoteUpdatedAt: p.remoteUpdatedAt,
                productAvailableForSale: p.availableForSale,
                featuredImage: p.featuredImageUrl,
                images: p.images,
                mappedOptions,
                metafieldValues,
                productMetafields: p.metafields,
                ...v,
                options,
            };
        }),
    );

    return _.flatten(mappedVariants);
}
