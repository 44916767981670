import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import { EventsService } from '../../../../../services/EventsService/EventsService';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import React, { FC, useEffect, useState } from 'react';
import { RoutesHelperService } from '../../../../../services/RoutesService/RoutesHelperService';
import styles from './BrandingBar.module.scss';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import { History } from 'history';

export interface BrandingBarProps {
    history: History;
}

export const BrandingBar: FC<BrandingBarProps> = props => {
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const shouldDisplayBranding = configService.config()?.showCloudshelfBranding;
    const [inProp, setInProp] = useState(shouldDisplayBranding);
    const [aboutCloudshelfCanOpen, setAboutCloudshelfCanOpen] = useState(false);

    useEffect(() => {
        const interactiveLoadingSubscriber = eventsService.observeLoadingComplete().subscribe(() => {
            setAboutCloudshelfCanOpen(true);
        });
        return () => {
            interactiveLoadingSubscriber.unsubscribe();
        };
    }, [eventsService]);

    return (
        <div
            className={`${styles.brandingBar} ${inProp ? styles.brandingBar__in : styles.brandingBar__out}`}
            onClick={() => {
                if (aboutCloudshelfCanOpen) {
                    props.history.push(RoutesHelperService.toCloudshelfBranding());
                }
            }}
        >
            <div className={styles.brandingBar__logoWrapper}>
                <StyledText
                    className={styles.brandingBar__logoWrapper__poweredBy}
                    style={TextStyle.Heading}
                    size={TextSize.Small}
                >
                    Powered by
                </StyledText>
                <img src={'/logos/pink-logo.svg'} alt="Cloudshelf" className={styles.brandingBar__logoWrapper__logo} />
                <StyledText
                    className={styles.brandingBar__logoWrapper__logoText}
                    style={TextStyle.Heading}
                    size={TextSize.Small}
                >
                    Cloudshelf
                </StyledText>
            </div>
        </div>
    );
};
