import { CSSProperties, FC, memo } from 'react';
import _ from 'lodash';

import styles from './TimeBar.module.scss';

export interface TimeBarProps {
    //The total number of blocks to be included in the timer bar
    numberOfBlocks: number;
    //The index (based at 0) of the block with is actively animating
    activeBlock: number;
    //The length of time to animate each block
    millisecondsPerBlock: number;
    forceFullWidth?: boolean;
}

const TimeBar: FC<TimeBarProps> = memo(props => {
    const activeBlock = props.activeBlock + 1;
    const totalBlocks = props.numberOfBlocks + 1;
    const animationDuration = props.millisecondsPerBlock;
    return (
        <div className={`${styles.TimeBar} ${props.forceFullWidth && styles.TimeBar__forceFullWidth}`}>
            <div className={styles.TimeBar__markers}>
                {_.range(1, totalBlocks).map(index => {
                    let innerClassName = '';
                    const innerStyle: CSSProperties = {};
                    if (index === activeBlock) {
                        innerClassName = styles.TimeBar__markers__marker__animate;
                        // innerStyle.transitionDuration = `${animationDuration}ms`;
                        // innerStyle.width = '100%';
                        innerStyle.animationDuration = `${animationDuration}ms`;
                        innerStyle.animationPlayState = 'running';
                    } else if (index < activeBlock) {
                        innerClassName = styles.TimeBar__markers__marker__complete;
                    }

                    return (
                        <div key={index} className={styles.TimeBar__markers__marker}>
                            <div className={innerClassName} style={innerStyle} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
});

TimeBar.defaultProps = {
    forceFullWidth: false,
};
export default TimeBar;
