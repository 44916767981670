import { FC, useEffect, useRef, useState } from 'react';
import { DisplayOnlyProduct } from '../../../../../services/DisplayOnlyOrchestratorService/DisplayOnlyOrchestratorService';
import { CSSTransition } from 'react-transition-group';
import DisplayOnlyQR from '../DisplayOnlyQR/DisplayOnlyQR';
import { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import ProductAvailability from '../../../../shared/ProductAvailability/ProductAvailability';
import DisplayOnlyGallery from './components/DisplayOnlyGallery/DisplayOnlyGallery';
import { DisplayOnlyTrackedHandleType } from '../../../../../services/TrackedURLService/DisplayOnlyTrackedHandleType.enum';
import { DisplayOnlyEngineConfig } from '../../../../../services/ConfigurationService/types/config/CloudshelfEngineConfig';
import './ProductPane.scss';
import AutoSizedStyledText, { AutoSizedValues } from '../../../../shared/StyledText/AutoSizedStyledText';
export interface ProductPaneProps {
    config: Pick<DisplayOnlyEngineConfig, 'includeProductName' | 'includePrice' | 'includeStock' | 'includeQRCode'>;
    product: DisplayOnlyProduct;
    active: boolean;
    className?: string;
}

const ProductPane: FC<ProductPaneProps> = props => {
    const contentAreaRef = useRef<HTMLDivElement>(null);
    const [enforcedAutoSizedText, setEnforcedAutoSizedText] = useState<AutoSizedValues | undefined>(undefined);
    const [rowGap, setRowGap] = useState<number>(8);
    let isLandscape = window.innerHeight < window.innerWidth;

    useEffect(() => {
        window.addEventListener('resize', () => {
            setEnforcedAutoSizedText(undefined);
            calculateContentFontSize();
        });
    }, []);

    useEffect(() => {
        calculateContentFontSize();
    }, [contentAreaRef.current, props.active]);

    const calculateContentFontSize = () => {
        isLandscape = window.innerHeight < window.innerWidth;
        if (contentAreaRef.current) {
            const contentRefPoint = isLandscape
                ? contentAreaRef.current.clientWidth
                : contentAreaRef.current.clientHeight;
            const fontPercent = isLandscape ? 9 : 15;
            const lineHeightPercent = isLandscape ? 13.5 : 21.5;
            const gapPercent = 3;

            const enforcedAutoSizedText: AutoSizedValues = {
                fontSize: (contentRefPoint / 100) * fontPercent,
                lineHeight: (contentRefPoint / 100) * lineHeightPercent,
            };

            setEnforcedAutoSizedText(enforcedAutoSizedText);
            setRowGap((contentRefPoint / 100) * gapPercent);
        }
    };

    return (
        <CSSTransition
            in={props.active}
            timeout={500}
            appear
            unmountOnExit
            mountOnEnter
            classNames={`${props.className} ProductPane ProductPane`}
        >
            <div className="ProductPane__wrapper">
                {(props.config.includeProductName || props.config.includePrice || props.config.includeStock) && (
                    <div className="ProductPane__wrapper__productInfo">
                        <div className="ProductPane__wrapper__productInfo__content" ref={contentAreaRef}>
                            <div
                                className={`ProductPane__wrapper__productInfo__content__autoSizeArea`}
                                style={{
                                    gap: rowGap,
                                }}
                            >
                                {props.config.includeProductName && (
                                    <>
                                        <AutoSizedStyledText
                                            style={TextStyle.Subheading}
                                            enforcedOnly={true}
                                            enforce={enforcedAutoSizedText}
                                            clamp
                                        >
                                            {props.product.title}
                                        </AutoSizedStyledText>
                                    </>
                                )}
                                {props.config.includePrice && (
                                    <>
                                        <AutoSizedStyledText
                                            style={TextStyle.Subheading}
                                            enforce={enforcedAutoSizedText}
                                            enforcedOnly={true}
                                        >
                                            {props.product.previousPrices && (
                                                <span className="ProductPane__wrapper__productInfo__content__previousPrice">
                                                    {props.product.previousPrices}
                                                </span>
                                            )}
                                            {props.product.currentPrices}
                                        </AutoSizedStyledText>
                                    </>
                                )}
                                {props.config.includeStock && (
                                    <>
                                        <ProductAvailability
                                            limitedAvailability={props.product.limitedAvailability}
                                            orderOnly={props.product.orderOnly}
                                            availableForSale={props.product.availableForSale}
                                            displayStockCount={false}
                                            enforcedTextSize={enforcedAutoSizedText}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {props.config.includeQRCode && (
                    <DisplayOnlyQR
                        type={DisplayOnlyTrackedHandleType.PRODUCT}
                        handle={props.product.handle}
                        reducedMargin={
                            isLandscape &&
                            !props.config.includeProductName &&
                            !props.config.includePrice &&
                            !props.config.includeStock
                        }
                    />
                )}
                <div className="ProductPane__wrapper__images">
                    <DisplayOnlyGallery
                        config={props.config}
                        images={props.product.images}
                        activeImage={props.product.currentProductImageIndex}
                    />
                </div>
            </div>
        </CSSTransition>
    );
};

export default ProductPane;
