import React, { FC, useEffect, useState } from 'react';
import styles from './SalesPersonPanel.module.scss';
import useStateRef from 'react-usestateref';
import { SlidingModal, SlidingModalOrigin } from '../SlidingModal/SlidingModal';
import { PanelHeader } from '../PanelHeader/PanelHeader';
import _ from 'lodash';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import { StorageService } from '../../../../../services/StorageService/StorageService';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { StorageKey } from '../../../../../services/StorageService/StorageKeys.enum';
import { TeamMember } from '../../../../../services/ConfigurationService/types/config/CloudshelfEngineConfig';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import { ClearSalesPersonRule } from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import { useTranslation } from 'react-i18next';
import { EventsService } from '../../../../../services/EventsService/EventsService';
import { MenuService } from '../../../../../services/MenuService/MenuService';

const SalesPersonPanelNew: FC = () => {
    const storageService = useInjection<StorageService>(DependencyType.StorageService);
    const configurationService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);
    const menuService = useInjection<MenuService>(DependencyType.MenuService);
    const translationService = useTranslation();
    const activateAtTaps = 3;
    const [, setTapCount, tapCountRef] = useStateRef(0);
    const [isSelectionOpen, setIsSelectionOpen] = useState(false);
    const [selectedSalesAssistant, setSelectedSalesAssistant, selectedSalesAssistantRef] = useStateRef<
        string | undefined
    >();
    const [listOfTeamMembers, setListOfTeamMembers] = useState<TeamMember[]>([]);
    const [blockTaps, setBlockTaps] = useState<boolean>(false);
    const parsedQuery = new URLSearchParams(window.location.search);

    const showVisibleTouchArea =
        parsedQuery.get('debugOverlay') !== null ||
        configurationService.config()?.forceDebugOverlay ||
        configurationService.config()?.device?.debugMode;

    useEffect(() => {
        const subscriber = eventsService.observeIsFilterPanelOpen().subscribe((isOpen: boolean) => {
            setBlockTaps(isOpen);
        });

        const subscriber2 = menuService.observeExpandedSearchChange().subscribe((isOpen: boolean) => {
            setBlockTaps(isOpen);
        });

        const subscriber3 = eventsService.observeSalesPersonAllocationOpen().subscribe((isOpen: boolean) => {
            setIsSelectionOpen(isOpen);
        });

        return () => {
            if (subscriber) {
                subscriber.unsubscribe();
            }
            if (subscriber2) {
                subscriber2.unsubscribe();
            }
            if (subscriber3) {
                subscriber3.unsubscribe();
            }
        };
    });

    useEffect(() => {
        const interval = setInterval(() => {
            const storedAssociate = storageService.get(StorageKey.SALES_ASSOCIATE_ID, true);

            if (storedAssociate !== selectedSalesAssistantRef.current) {
                setSelectedSalesAssistant(storedAssociate);
            }
        }, 250);

        setListOfTeamMembers(configurationService.config()?.teamMembers || []);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        const tapCheckInterval = setInterval(() => {
            if (tapCountRef.current >= activateAtTaps) {
                setIsSelectionOpen(true);
                setTapCount(0);
            }
        }, 50);

        const tapRemovalInterval = setInterval(() => {
            if (tapCountRef.current > 0) {
                setTapCount(0);
            }
        }, 1000);

        return () => {
            clearInterval(tapCheckInterval);
            clearInterval(tapRemovalInterval);
        };
    }, [listOfTeamMembers]);

    const handleRadioClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        let selectedAssistant = (event.target as any).defaultValue ?? undefined;

        if (_.trim(selectedAssistant.toString()) === '') {
            selectedAssistant = undefined;
        }

        setSelectedSalesAssistant(selectedAssistant);
        if (selectedAssistant) {
            storageService.put(StorageKey.SALES_ASSOCIATE_ID, selectedAssistant);
        } else {
            storageService.delete(StorageKey.SALES_ASSOCIATE_ID);
        }

        const date = new Date();
        date.setDate(date.getDate() + 1);
        const midnight = new Date(date.getFullYear(), date.getMonth(), date.getDate());

        if (configurationService.config()?.retailerRules.clearSalesPerson === ClearSalesPersonRule.Daily) {
            storageService.put(StorageKey.SALES_ASSOCIATE_EXPIRY, midnight.getTime().toString());
        } else {
            storageService.delete(StorageKey.SALES_ASSOCIATE_EXPIRY);
        }
    };

    if (blockTaps) {
        //if blocked, don't render anything this allows the filter panel to be interacted with
        return null;
    }

    return (
        <>
            <div
                className={`${styles.tapArea} ${isSelectionOpen ? styles.tapAreaInactive : ''} ${
                    showVisibleTouchArea ? styles.tapArea__visible : ''
                }`}
                onClick={() => {
                    if (!isSelectionOpen) {
                        setTapCount(tapCountRef.current + 1);
                    }
                }}
            />

            <SlidingModal transparentBackground origin={SlidingModalOrigin.TOP} isOpen={isSelectionOpen} rounded>
                <div className={styles.SelectionView__wrapper}>
                    <div className={styles.SelectionView__content}>
                        <PanelHeader
                            className={styles.SelectionView__content__header}
                            onCloseButtonClicked={() => setIsSelectionOpen(false)}
                            bottomBorder
                            title={'Sales Assistant'}
                        ></PanelHeader>
                        <div className={styles.SelectionView__content__body}>
                            {configurationService.isInPreviewMode ? (
                                <StyledText style={TextStyle.Body} size={TextSize.Small} center>
                                    {translationService.t('salesPerson.allocationInPreview')}
                                </StyledText>
                            ) : (
                                <>
                                    {!configurationService.config()?.retailerRules
                                        .allocateSalesToAssignedSalesPerson ? (
                                        <StyledText style={TextStyle.Body} size={TextSize.Small} center>
                                            {translationService.t('salesPerson.allocationTurnedOff')}
                                        </StyledText>
                                    ) : (
                                        <>
                                            {listOfTeamMembers.length === 0 ? (
                                                <StyledText style={TextStyle.Body} size={TextSize.Small} center>
                                                    {translationService.t('salesPerson.noTeamMembers')}
                                                </StyledText>
                                            ) : (
                                                <>
                                                    <label className={styles.RadioContainer} key={'clear'}>
                                                        <StyledText style={TextStyle.Body} size={TextSize.Small}>
                                                            {translationService.t('salesPerson.unassigned')}
                                                        </StyledText>
                                                        <input
                                                            type="radio"
                                                            name="saRadio"
                                                            value={undefined}
                                                            onClick={handleRadioClick}
                                                            onChange={() => {}}
                                                            checked={selectedSalesAssistant === undefined}
                                                        />
                                                        <span className={styles.RadioContainer__checkmark}></span>
                                                    </label>
                                                    {_.map(listOfTeamMembers, assistant => (
                                                        <label className={styles.RadioContainer} key={assistant.id}>
                                                            <StyledText style={TextStyle.Body} size={TextSize.Small}>
                                                                {assistant.displayName}
                                                            </StyledText>
                                                            <input
                                                                type="radio"
                                                                name="saRadio"
                                                                value={assistant.id}
                                                                onClick={handleRadioClick}
                                                                onChange={() => {}}
                                                                checked={selectedSalesAssistant === assistant.id}
                                                            />
                                                            <span className={styles.RadioContainer__checkmark}></span>
                                                        </label>
                                                    ))}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </SlidingModal>
        </>
    );
};

export default SalesPersonPanelNew;
