import { FC } from 'react';
import StarsBackground from '../StarsBackground/StarsBackground';
import './Loader.scss';

export type LoaderVariant = 'light' | 'dark' | 'stars';

export interface LoaderProps {
    variant?: LoaderVariant;
    className?: string;
    text?: string;
}

const Loader: FC<LoaderProps> = ({ variant = 'light', className = '', text }) => {
    return variant === 'stars' ? (
        <StarsBackground showLogo>
            <div className={`Loader Loader--light ${className}`}>
                <div className="Loader__block" />
                <div className="Loader__block" />
                <div className="Loader__block" />
                <div className="Loader__block" />
                <div className="Loader__block" />
            </div>
            {text && <p className={'Loader__text'}>{text}</p>}
        </StarsBackground>
    ) : (
        <div className={`Loader Loader--${variant} ${className}`}>
            <div className="Loader__block" />
            <div className="Loader__block" />
            <div className="Loader__block" />
            <div className="Loader__block" />
            <div className="Loader__block" />
        </div>
    );
};

export default Loader;
