import { CloudshelfTrackerDto } from '@cloudshelf/common/dist/common/tracking/dto/CloudshelfTrackerDto';
import { List } from 'immutable';
import { AnalyticsTracker, makeAnalyticsTracker } from '../entities/AnalyticsTracker';

/**
 * Extremely simple mapper for now. Will become more complex once different tracker
 * types support different options.
 */
export class AnalyticsTrackerMapperService {
    static toTrackers(trackersDtos: CloudshelfTrackerDto[]): List<AnalyticsTracker> {
        return List(trackersDtos.map(AnalyticsTrackerMapperService.toTracker));
    }

    static toTracker(trackerDto: CloudshelfTrackerDto): AnalyticsTracker {
        return makeAnalyticsTracker({
            id: trackerDto.id,
            name: trackerDto.name,
        });
    }
}
