import { CSSProperties, FC, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useInjection } from '../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { MenuService } from '../../../services/MenuService/MenuService';
import { ConfigurationService } from '../../../services/ConfigurationService/ConfigurationService';
import './BrandLogo.scss';

const BrandLogo: FC = () => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const menuService = useInjection<MenuService>(DependencyType.MenuService);
    const [logoVisible, setLogoVisible] = useState(true);

    const logoStyles: CSSProperties = {
        opacity: logoVisible ? 1 : 0,
        transition: '0.5s opacity ease',
    };

    useEffect(() => {
        const sub = menuService.observeExpandedSearchChange().subscribe(expanded => {
            setLogoVisible(!expanded);
        });

        return () => {
            sub.unsubscribe();
        };
    }, [menuService]);

    return (
        <div className="BrandLogoWrapper">
            {configService.config()?.theme.attractScreen.displayLogo &&
                !_.isEmpty(configService.config()?.theme.logoUrl) && (
                    <div className="BrandLogoWrapper__BrandLogo" style={logoStyles}>
                        <img
                            src={configService.config()?.theme.logoUrl}
                            alt="logo"
                            className={`BrandLogoWrapper__BrandLogo__sized`}
                        />
                    </div>
                )}
        </div>
    );
};

export default BrandLogo;
