import { CloudshelfEngineConfig } from '../services/ConfigurationService/types/config/CloudshelfEngineConfig';
import { generateGoogleFontsUrl } from './GoogleFonts.Util';

export function configureCloudshelfTheme(config: CloudshelfEngineConfig) {
    //Colours
    document.documentElement.style.setProperty('--primary-color', config.theme.primaryColor);
    document.documentElement.style.setProperty('--main-text-color', config.theme.mainTextColor);
    document.documentElement.style.setProperty('--purchase-text-color', config.theme.purchaseTextColor);

    //Radius
    document.documentElement.style.setProperty('--drawer-radius', `${config.theme.radius.drawer}rem`);
    document.documentElement.style.setProperty('--Modal-radius', `${config.theme.radius.modal}rem`);
    document.documentElement.style.setProperty('--input-radius', `${config.theme.radius.inputs}rem`);
    document.documentElement.style.setProperty('--tiles-radius', `${config.theme.radius.tiles}rem`);

    //Fonts
    //Font Links
    let headingFontURL = generateGoogleFontsUrl(
        config.theme.headingsFont.fontFamily,
        config.theme.headingsFont.fontWeight,
    );
    if (config.theme.headingsFont.isCustomFont && config.theme.headingsFont.cdn) {
        headingFontURL = config.theme.headingsFont.cdn;
    }
    document.querySelector('#heading-font')?.setAttribute('href', headingFontURL);

    let subheadingFontURL = generateGoogleFontsUrl(
        config.theme.subheadingsFont.fontFamily,
        config.theme.subheadingsFont.fontWeight,
    );
    if (config.theme.subheadingsFont.isCustomFont && config.theme.subheadingsFont.cdn) {
        subheadingFontURL = config.theme.subheadingsFont.cdn;
    }
    document.querySelector('#subheading-font')?.setAttribute('href', subheadingFontURL);

    let bodyFontURL = generateGoogleFontsUrl(config.theme.bodyFont.fontFamily, config.theme.bodyFont.fontWeight);
    if (config.theme.bodyFont.isCustomFont && config.theme.bodyFont.cdn) {
        bodyFontURL = config.theme.bodyFont.cdn;
    }
    document.querySelector('#body-font')?.setAttribute('href', bodyFontURL);

    // Font CSS
    document.documentElement.style.setProperty('--headings-font', `${config.theme.headingsFont.fontFamily}, serif`);
    document.documentElement.style.setProperty(
        '--subheadings-font',
        `${config.theme.subheadingsFont.fontFamily}, serif`,
    );
    document.documentElement.style.setProperty('--body-font', `${config.theme.bodyFont.fontFamily}, serif`);

    //Font Weights
    document.documentElement.style.setProperty('--headings-font-weight', config.theme.headingsFont.fontWeight);

    document.documentElement.style.setProperty('--subheadings-font-weight', config.theme.subheadingsFont.fontWeight);

    document.documentElement.style.setProperty('--body-font-weight', config.theme.bodyFont.fontWeight);
}
