import { FC } from 'react';
import { useInjection } from '../../../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../../../dependancyInjection/DependencyType';
import { ConfigurationService } from '../../../../../../../services/ConfigurationService/ConfigurationService';

const OtherOverlayComponent: FC = () => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    return <>Image Anchor: {configService.imageAnchor}</>;
};

export default OtherOverlayComponent;
