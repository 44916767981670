import { injectable } from 'inversify';
import { SelectedVariantOptions, VariantSettings } from '../../../types/Types';
import SortingService from '../../SortingService/SortingService';
import ProductVariant from './ProductVariant';
import ProductVariantOption from './ProductVariantOption';
import _ from 'lodash';

@injectable()
export default class ProductVariantHelperService {
    getVariantSettings(variants: ProductVariant[]): VariantSettings {
        return variants
            .map(variant => variant.options)
            .flat()
            .reduce((variantSettings: VariantSettings, { name, value }: ProductVariantOption) => {
                const variantOptions = variantSettings[name];
                if (!variantOptions) {
                    variantSettings[name] = [value];
                    return variantSettings;
                }
                variantSettings[name] = _.uniq([...variantOptions, value]); //.sort(SortingService.sortAsc);
                return variantSettings;
            }, {});
    }

    getSelectedVariant(
        variants: ProductVariant[],
        selectedOptions: SelectedVariantOptions,
    ): ProductVariant | undefined {
        return variants.find(variant => variant.options.every(option => selectedOptions[option.name] === option.value));
    }

    getVariantsBySelectedOption(variants: ProductVariant[], selectedOptions: SelectedVariantOptions): ProductVariant[] {
        return variants.filter(variant =>
            Object.keys(selectedOptions).every(selectedOptionName =>
                this.hasVariantOptions(variant.options, selectedOptionName, selectedOptions[selectedOptionName]),
            ),
        );
    }

    hasVariant(variants: ProductVariant[], variantName: string, variantOption: string): boolean {
        return !!variants.find(variant => this.hasVariantOptions(variant.options, variantName, variantOption));
    }

    private hasVariantOptions(options: ProductVariantOption[], variantName: string, variantOption: string): boolean {
        return !!options.find(option => this.hasVariantOption(option, variantName, variantOption));
    }

    private hasVariantOption(option: ProductVariantOption, variantName: string, variantOption: string): boolean {
        return option.name === variantName && option.value === variantOption;
    }
}
