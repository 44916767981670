import React, { ReactElement } from 'react';
import Button, { ButtonSize, ButtonVariant } from '../Button/Button';

import './ButtonIcon.scss';

export interface IconButtonProps {
    size: ButtonSize;
    icon: ReactElement;
    className?: string;
    onClick?: () => void;
    loading?: boolean;
    disabled?: boolean;
    variant?: ButtonVariant;
}

const IconButton: React.FC<IconButtonProps> = props => {
    return (
        <Button
            disabled={props.disabled}
            loading={props.loading}
            size={props.size}
            className={props.className}
            onClick={props.onClick}
            variant={props.variant}
        >
            <span className={`ButtonIcon ButtonIcon--${props.size}`}>{props.icon}</span>
        </Button>
    );
};

IconButton.defaultProps = {
    variant: ButtonVariant.NONE,
};
export default IconButton;
