import React, { FC } from 'react';
import styles from './DisplayOnlyQR.module.scss';
import QRCode from 'qrcode.react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { DisplayOnlyTrackedHandleType } from '../../../../../services/TrackedURLService/DisplayOnlyTrackedHandleType.enum';
import { CloudshelfTrackedURLService } from '../../../../../services/TrackedURLService/CloudshelfTrackedURLService';

export interface QRProps {
    type: DisplayOnlyTrackedHandleType;
    link?: string;
    handle?: string;
    reducedMargin?: boolean;
}

const DisplayOnlyQR: FC<QRProps> = props => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const trackedURLService = useInjection<CloudshelfTrackedURLService>(DependencyType.CloudshelfTrackedURLService);
    if (!configService.providerConfig()?.domain) {
        return null;
    }

    if (!props.link && !props.handle) {
        console.warn('DisplayOnlyQR incorrectly configured. No link or handle provided.');
        return null;
    }

    let qrSize = 0;
    if (window.visualViewport.width > window.visualViewport.height) {
        const whiteBlockSize = window.visualViewport.width * 0.2;
        qrSize = whiteBlockSize * 0.8;
    } else {
        const whiteBlockSize = window.visualViewport.height * 0.15;
        qrSize = whiteBlockSize * 0.8;
    }

    //Try and make a tracked URL
    let embeddedURL: string | undefined = undefined;
    if (props.handle) {
        embeddedURL = trackedURLService.generateDisplayOnlyURL(props.type, props.handle);
        if (!embeddedURL) {
            if (props.type === DisplayOnlyTrackedHandleType.PRODUCT) {
                embeddedURL = `${configService.providerConfig()?.domain}/products/${props.handle}`;
            } else if (props.type === DisplayOnlyTrackedHandleType.CATEGORY) {
                embeddedURL = `${configService.providerConfig()?.domain}/collections/${props.handle}`;
            } else {
                //Final fallback, do to route domain
                embeddedURL = `${configService.providerConfig()?.domain}`;
            }
        }
    } else if (props.link) {
        embeddedURL = trackedURLService.generateDisplayOnlyURL(props.type, props.link);
        if (!embeddedURL) {
            embeddedURL = props.link;
        }
    }

    if (!embeddedURL) {
        console.warn('embeddedURL is undefined');
        return null;
    }

    return (
        <div className={`${styles.QRWrapper} ${props.reducedMargin && styles.QRWrapper__reducedMargin}`}>
            <QRCode renderAs="svg" value={embeddedURL} size={qrSize} />
        </div>
    );
};

DisplayOnlyQR.defaultProps = {
    reducedMargin: false,
};

export default DisplayOnlyQR;
