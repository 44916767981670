import React, { useEffect, useRef } from 'react';
import { Skeleton } from '../../../../../shared/Skeleton/Skeleton';
import styles from './ProductImages.module.scss';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import _ from 'lodash';
import { LocalProductImage } from '../../../../../../services/ProductServices/LocalProduct';
import StyledText, { TextSize, TextStyle } from '../../../../../shared/StyledText/StyledText';

export interface ProductImagesProps {
    images: LocalProductImage[];
    currentImageIndex: number;
    setImageIndex: (index: number) => void;
    isExpanded: boolean;
    setIsExpanded: (isExpanded: boolean) => void;
    scroll: (to: 'variants' | 'customiser' | 'images', blockMessage: boolean) => void;
    loading: boolean;
}

const ProductImages = React.forwardRef<HTMLElement, ProductImagesProps>((props, reference) => {
    const oldIndex = useRef<number>(props.currentImageIndex);
    const [firstImageLoaded, setFirstImageLoaded] = React.useState<boolean>(false);
    const [imagesCouldNotBeLoaded, setImagesCouldNotBeLoaded] = React.useState<boolean>(false);

    useEffect(() => {
        oldIndex.current = props.currentImageIndex;

        if (props.currentImageIndex > props.images.length) {
            props.setImageIndex(props.images.length - 1);
        }
    }, [props.currentImageIndex]);

    return (
        <section
            className={`${styles.ProductImages} ${props.isExpanded && styles.ProductImages__expanded}`}
            ref={reference}
        >
            {imagesCouldNotBeLoaded ? (
                <StyledText style={TextStyle.Subheading} size={TextSize.Medium}>
                    Sorry, images could not be loaded at this time.
                </StyledText>
            ) : (
                <div className={styles.ProductImages}>
                    {props.loading || !firstImageLoaded ? (
                        <>
                            <Skeleton type={'rectangle'} />
                            {_.first(props.images) !== undefined && (
                                <img
                                    alt=""
                                    src={_.first(props.images)?.url}
                                    style={{ display: 'none' }}
                                    onLoad={() => setFirstImageLoaded(true)}
                                    onError={() => {
                                        setImagesCouldNotBeLoaded(true);
                                    }}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            <div
                                className={`${styles.ProductImages__expandIcon} ${
                                    props.isExpanded && styles.ProductImages__expandIcon__expanded
                                }`}
                            >
                                {props.isExpanded ? (
                                    <i className="fa-solid fa-down-left-and-up-right-to-center" />
                                ) : (
                                    <i className="fa-solid fa-up-right-and-down-left-from-center" />
                                )}
                            </div>
                            {props.isExpanded && <div className={styles.ProductImages__expandIcon__pulse} />}

                            <div className={styles.ProductImages__images}>
                                <Carousel
                                    className={`${styles.ProductImages__carousel} ${
                                        props.isExpanded && styles.ProductImages__carousel__expanded
                                    }`}
                                    swipeable
                                    emulateTouch
                                    infiniteLoop={true}
                                    showThumbs={false}
                                    showArrows={false}
                                    showStatus={false}
                                    selectedItem={props.currentImageIndex}
                                    onClickItem={() => {
                                        props.setIsExpanded(!props.isExpanded);
                                        props.scroll('images', props.isExpanded);
                                    }}
                                    renderIndicator={(clickHandler, isSelected, index) => {
                                        if (props.images.length > 1) {
                                            return (
                                                <div
                                                    key={`imageDot_${index}`}
                                                    className={`${styles.ProductImages__navArea__spot} ${
                                                        isSelected ? styles.ProductImages__navArea__spot__current : ''
                                                    }`}
                                                />
                                            );
                                        } else {
                                            return null;
                                        }
                                    }}
                                >
                                    {props.images.map(image => {
                                        return (
                                            <img
                                                key={image.url}
                                                alt=""
                                                src={image.url}
                                                className={`${styles.ProductImages__image}`}
                                            />
                                        );
                                    })}
                                </Carousel>
                            </div>
                        </>
                    )}
                </div>
            )}
        </section>
    );
});

export default ProductImages;
