import React, { FC } from 'react';

const ShareAppleIcon: FC = props => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.5442 6.57599L12.0002 4.03199L9.45618 6.57599L8.78418 5.90399L12.0002 2.68799L15.2162 5.90399L14.5442 6.57599Z"
                fill="white"
            />
            <path d="M11.52 3.36011H12.48V13.4401H11.52V3.36011Z" fill="white" />
            <path
                d="M16.7998 19.1999H7.19977C6.38377 19.1999 5.75977 18.5759 5.75977 17.7599V9.11993C5.75977 8.30393 6.38377 7.67993 7.19977 7.67993H10.5598V8.63993H7.19977C6.91177 8.63993 6.71977 8.83193 6.71977 9.11993V17.7599C6.71977 18.0479 6.91177 18.2399 7.19977 18.2399H16.7998C17.0878 18.2399 17.2798 18.0479 17.2798 17.7599V9.11993C17.2798 8.83193 17.0878 8.63993 16.7998 8.63993H13.4398V7.67993H16.7998C17.6158 7.67993 18.2398 8.30393 18.2398 9.11993V17.7599C18.2398 18.5759 17.6158 19.1999 16.7998 19.1999Z"
                fill="white"
            />
        </svg>
    );
};
export default ShareAppleIcon;
