import React, { CSSProperties, forwardRef } from 'react';
import { useTabletDesktopMediaQuery } from '../../../../../hooks/UseTabletDesktopMediaQuery';
import { CloudshelfThemeCallToActionSize } from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import './CallToAction.scss';

export interface CallToActionProps {
    className?: string;
    title?: string;
}

export const CallToAction = forwardRef<HTMLHeadingElement, CallToActionProps>(({ className = '', title }, ref) => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const styleOverrides: CSSProperties = {};

    if (!configService.config()?.theme.attractScreen.displayFrame) {
        styleOverrides.color = '#ffffff';
    }

    if (!title) {
        return null;
    }

    return (
        <h1
            className={`CallToAction CallToAction--${
                configService.config()?.theme.attractScreen.callToActionSize
            } ${className}`}
            style={styleOverrides}
        >
            {title}
        </h1>
    );
});

CallToAction.displayName = 'CallToAction';
