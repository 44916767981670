import { FC } from 'react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { CloudshelfThemeTouchIndicator } from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { AnimatedHandComponent } from './components/AnimatedHand/AnimatedHand';
import { BouncingArrowComponent } from './components/BouncingArrow/BouncingArrow';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';

export interface TouchIndicatorProps {
    style?: CloudshelfThemeTouchIndicator;
    className?: string;
    isHidden?: boolean;
    rotate?: number;
}

const TouchIndicator: FC<TouchIndicatorProps> = props => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);

    if (props.isHidden) {
        return null;
    }

    if (configService.config()?.theme.attractScreen.touchIndicator === CloudshelfThemeTouchIndicator.Disabled) {
        return null;
    }

    if (
        configService.config()?.theme.attractScreen.touchIndicator === CloudshelfThemeTouchIndicator.ClassicHandPointer
    ) {
        return <AnimatedHandComponent className={props.className} />;
    }

    if (configService.config()?.theme.attractScreen.touchIndicator === CloudshelfThemeTouchIndicator.BouncingArrow) {
        return <BouncingArrowComponent className={props.className} rotate={props.rotate} />;
    }

    return null;
};

TouchIndicator.defaultProps = {
    style: CloudshelfThemeTouchIndicator.Disabled,
    className: '',
    isHidden: false,
};

export default TouchIndicator;
