import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { DeviceMode } from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import React, { FC, useEffect, useState } from 'react';
import styles from './PreviewBar.module.scss';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import { useTranslation } from 'react-i18next';

export const PreviewBar: FC = props => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const { t } = useTranslation();
    const isPreview = configService.isInPreviewMode;
    const shouldShowPreviewOverlay =
        process.env.REACT_APP_RELEASE_TYPE === 'development' || process.env.REACT_APP_RELEASE_TYPE === 'production';

    const openCloudshelfWebsite = () => {
        window.open('https://cloudshelf.ai', '_blank');
    };

    return isPreview && shouldShowPreviewOverlay ? (
        <div
            className={`${styles.previewBar} ${
                configService.deviceMode === DeviceMode.DisplayOnly && styles.previewMode__bottom
            }`}
        >
            <StyledText
                className={styles.previewBar__previewText}
                style={TextStyle.CloudshelfBold}
                size={TextSize.Large}
                translate
            >
                You are in preview mode
            </StyledText>
            <div className={styles.previewLogoArea} onClick={openCloudshelfWebsite}>
                <img
                    src={'/logos/pink-logo.svg'}
                    alt="Cloudshelf"
                    // height={25}
                    className={styles.previewBar__logoImage}
                />
                <StyledText
                    style={TextStyle.CloudshelfBold}
                    size={TextSize.Large}
                    className={styles.previewBar__logoText}
                >
                    Cloudshelf
                </StyledText>
            </div>
        </div>
    ) : null;
};
