import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonSize, ButtonVariant } from '../../../../shared/inputs/Button/Button';
import ButtonIcon from '../../../../shared/inputs/ButtonIcon/ButtonIcon';
import TextTransition from 'react-text-transition';
import './Quantity.scss';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import MinusIcon from '../../../../icons/minus';
import PlusIcon from '../../../../icons/plus';

export type OnQuantityChangedCallback = (newQuantity: number, oldQuantity: number) => void;

export interface QuantityProps {
    className?: string;
    initialQuantity?: number;
    onQuantityChanged: OnQuantityChangedCallback;
    onRemove?: () => void;
    loading: boolean;
    smaller?: boolean;
    variant: 'inline' | 'numInBasket';
    overrideQuantity?: number;
    maxQuantity?: number;
}

const Quantity: React.FC<QuantityProps> = props => {
    const { onQuantityChanged, initialQuantity = 1, className = '' } = props;
    const { t } = useTranslation();
    const [quantity, setQuantity] = useState<number>(initialQuantity);

    const updateQuantity = useCallback(
        updatedQuantity => {
            const realMax = props.maxQuantity ?? Number.MAX_VALUE;
            if (updatedQuantity > realMax) {
                updatedQuantity = realMax;
            }

            setQuantity(updatedQuantity);
            onQuantityChanged(updatedQuantity, quantity);
        },
        [onQuantityChanged, props.maxQuantity],
    );

    useEffect(() => {
        setQuantity(initialQuantity);
    }, [initialQuantity]);

    const onIncrease = useCallback(() => {
        updateQuantity(quantity + 1);
    }, [quantity, updateQuantity]);

    const onDecrease = useCallback(() => {
        updateQuantity(Math.max(1, quantity - 1));
    }, [quantity, updateQuantity]);

    const inBasketText = React.useMemo(() => {
        return (
            <TextTransition>
                {props.overrideQuantity}&nbsp;{t('quantity.inBasket')}
            </TextTransition>
        );
    }, [props.overrideQuantity]);

    if (props.overrideQuantity && quantity > props.overrideQuantity) {
        setQuantity(props.overrideQuantity);
    }

    return (
        <div className={`Quantity ${className}`}>
            <ButtonIcon
                className="Quantity__button"
                onClick={onDecrease}
                icon={<MinusIcon />}
                size={ButtonSize.SM}
                variant={ButtonVariant.GREY}
                disabled={quantity === 1}
                loading={props.loading}
            />
            {props.variant === 'inline' && (
                <h3 className={`Quantity__count${props.smaller ? ' Quantity__count__smaller' : ''}`}>
                    <StyledText style={TextStyle.Body} size={TextSize.Small} className="Quantity__count" translate>
                        {props.overrideQuantity ? props.overrideQuantity : quantity}
                    </StyledText>
                </h3>
            )}
            {props.variant === 'numInBasket' && <span style={{ marginLeft: 8 }} />}
            <ButtonIcon
                className="Quantity__button"
                onClick={onIncrease}
                icon={<PlusIcon />}
                variant={ButtonVariant.GREY}
                size={ButtonSize.SM}
                loading={props.loading}
                disabled={props.maxQuantity ? quantity >= props.maxQuantity : false}
            />
            {props.onRemove && (
                <button className="Quantity__removeButton" onClick={props.onRemove} disabled={props.loading}>
                    <StyledText style={TextStyle.Body} size={TextSize.Small} translate>
                        {t('quantity.remove')}
                    </StyledText>
                </button>
            )}
            {props.variant === 'numInBasket' && (
                <StyledText style={TextStyle.Body} size={TextSize.Small} className="Quantity__count" translate>
                    {inBasketText}
                </StyledText>
            )}
        </div>
    );
};

export default Quantity;
