import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';

import styles from './SampleButtonOverlay.module.scss';
import React, { useState } from 'react';
import _ from 'lodash';
import Button, { ButtonSize, ButtonVariant } from '../../../../shared/inputs/Button/Button';
import ButtonIcon from '../../../../shared/inputs/ButtonIcon/ButtonIcon';
import ButtonSelectable from '../../../../shared/inputs/ButtonSelectable/ButtonSelectable';
import CloseIcon from '../../../../icons/close';

const SampleButtonOverlay = () => {
    const parsedQuery = new URLSearchParams(window.location.search);
    const shouldShowSampleTest = parsedQuery.get('previewButtons') !== null;

    return shouldShowSampleTest ? (
        <div className={styles.sampleButtons}>
            <StyledText style={TextStyle.CloudshelfBold} size={TextSize.Medium}>
                This is a sample button overlay to help with theme development.
                <br /> There are a three button types, and three sizes which are based on the screen reference point.
                <br />
                Additionally there is an "auto" size(not shown here), which sizes the button to the 100% of the parents
                size.
            </StyledText>
            <hr />
            <StyledText style={TextStyle.CloudshelfBold} size={TextSize.Medium}>
                Standard buttons:
            </StyledText>
            <div className={styles.space} />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1vw',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Button size={ButtonSize.SM} variant={ButtonVariant.PRIMARY}>
                    Small
                </Button>
                <Button size={ButtonSize.MD} variant={ButtonVariant.PRIMARY}>
                    Medium
                </Button>
                <Button size={ButtonSize.LG} variant={ButtonVariant.PRIMARY}>
                    Large
                </Button>
            </div>

            <hr />
            <StyledText style={TextStyle.CloudshelfBold} size={TextSize.Medium}>
                Icon Buttons:
            </StyledText>
            <div className={styles.space} />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1vw',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <ButtonIcon
                    className={styles.icon}
                    icon={<CloseIcon />}
                    size={ButtonSize.SM}
                    variant={ButtonVariant.DARKGREY}
                />
                <ButtonIcon
                    className={styles.icon}
                    icon={<CloseIcon />}
                    size={ButtonSize.MD}
                    variant={ButtonVariant.DARKGREY}
                />
                <ButtonIcon
                    className={styles.icon}
                    icon={<CloseIcon />}
                    size={ButtonSize.LG}
                    variant={ButtonVariant.DARKGREY}
                />
            </div>
            <hr />
            <StyledText style={TextStyle.CloudshelfBold} size={TextSize.Medium}>
                Selectable Buttons:
            </StyledText>
            <div className={styles.space} />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1vw',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <ButtonSelectable
                    size={ButtonSize.SM}
                    name={'Small Selectable'}
                    value={'Small Selectable'}
                    selected={true}
                    label={'Small Selectable'}
                    onSelected={() => _.noop()}
                />
                <ButtonSelectable
                    size={ButtonSize.MD}
                    name={'Medium Selectable'}
                    value={'Medium Selectable'}
                    selected={true}
                    label={'Medium Selectable'}
                    onSelected={() => _.noop()}
                />
                <ButtonSelectable
                    size={ButtonSize.LG}
                    name={'Large Selectable'}
                    value={'Large Selectable'}
                    selected={true}
                    label={'Large Selectable'}
                    onSelected={() => _.noop()}
                />
            </div>
        </div>
    ) : null;
};

export default SampleButtonOverlay;
