import { useParams } from 'react-router';
import { useEffect } from 'react';
import { useInjection } from '../../../../../../dependancyInjection/DependencyContext';
import { EventsService } from '../../../../../../services/EventsService/EventsService';
import DependencyType from '../../../../../../dependancyInjection/DependencyType';
import useStateRef from 'react-usestateref';
import { CategoryProductRouteParams } from '../../../../../../services/RoutesService/Routes';

const ProductOpenerUtil = () => {
    const { product: handle } = useParams<CategoryProductRouteParams>();
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);
    const [openProductHandle, setOpenProductHandle, openProductHandleRef] = useStateRef<string | undefined>(handle);

    useEffect(() => {
        const openProductObservable = eventsService.observeOpenProduct();

        const subscription = openProductObservable.subscribe(handle => {
            setOpenProductHandle(handle);
        });

        return () => {
            subscription.unsubscribe();
        };
    }, []);

    useEffect(() => {
        eventsService.setOpenProduct(openProductHandleRef.current);
    }, [openProductHandle]);

    return null;
};

export default ProductOpenerUtil;
