import React, { KeyboardEventHandler, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NAME_FILTER, NAME_FILTER_ID } from '../../../../provider/cloudshelf/filter/CloudshelfFilters';
import { useInjection } from '../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../dependancyInjection/DependencyType';
import _ from 'lodash';
import { FilterType } from '../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { FilterSelection, ProductFilteringService } from '../../ProductFilteringService';
import useStateRef from 'react-usestateref';
import './NameFilterInput.scss';
import SearchIcon from '../../../../components/icons/search';
import CircledCrossIcon from '../../../../components/icons/circled_cross';

export interface INameFilterInputProps {
    initialValue: string;
    onChange: () => void;
    isOnScreen: boolean;
    short?: boolean;
    commitFilters?: boolean;
    minContentLengthForCommit?: number;
}

export const NameFilterInput: React.FC<INameFilterInputProps> = props => {
    const { t } = useTranslation();
    const filteringService = useInjection<ProductFilteringService>(DependencyType.ProductFilteringService);
    const inputRef = useRef<HTMLInputElement>(null);
    const currentSelection = filteringService.getCurrentSelection();
    const [, setValue, valueRef] = useStateRef(props.initialValue);
    const [, setOnScreen, onScreenRef] = useStateRef(false);
    const [currentNameSelection, setCurrentNameSelection, currentNameSelectionRef] = useStateRef<string>(
        _.find(currentSelection, selection => selection.definitionId === NAME_FILTER_ID)?.values?.[0] ?? '',
    );

    useEffect(() => {
        if (props.initialValue.trim() === '') {
            setValue('');
            if (inputRef.current) {
                inputRef.current.value = '';
            }
        } else {
            if (!onScreenRef.current) {
                // This is the first time the component is rendered
                setValue(props.initialValue);
                if (inputRef.current) {
                    inputRef.current.value = props.initialValue;
                }
                setOnScreen(true);
            }
        }
    }, [props.initialValue]);

    useEffect(() => {
        setOnScreen(props.isOnScreen);
    }, [props.isOnScreen]);

    useEffect(() => {
        const filterViewStateObserver = filteringService.observeFilterViewSelectionState();

        const filterViewStateObserverSubscription = filterViewStateObserver.subscribe(
            async (selection: FilterSelection[]) => {
                const newNameSelection =
                    _.find(selection, selection => selection.definitionId === NAME_FILTER_ID)?.values?.[0] ?? '';

                if (currentNameSelectionRef.current !== newNameSelection) {
                    setCurrentNameSelection(newNameSelection);
                }
                if (valueRef.current !== newNameSelection) {
                    setValue(newNameSelection);
                }
            },
        );

        return () => filterViewStateObserverSubscription.unsubscribe();
    }, []);

    const handleValueUpdate = (updatedValue: string, forceCommit: boolean) => {
        if (updatedValue === '') {
            filteringService.clearSingleFilter(NAME_FILTER_ID);
            setValue('');
            if (inputRef.current) {
                inputRef.current.value = '';
            }
        } else {
            filteringService.setStringValue(NAME_FILTER_ID, NAME_FILTER, FilterType.ProductTitle, updatedValue, true);
            setValue(updatedValue);
            if (inputRef.current) {
                inputRef.current.value = updatedValue;
            }
        }
        if (props.commitFilters && (updatedValue.length >= (props.minContentLengthForCommit ?? 3) || forceCommit)) {
            filteringService.commitSelection();
            console.log('committing');
        }
        props.onChange();
    };

    const handleInputKeyUp: KeyboardEventHandler<HTMLInputElement> = event => {
        if (event.which === 13) {
            inputRef.current?.blur();
        } else {
            const value = inputRef.current?.value ?? '';
            handleValueUpdate(value, false);
        }
    };

    const debouncedHandleInputKeyUp = _.debounce(handleInputKeyUp, 500);

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const { value } = event.target;
        handleValueUpdate(value, true);
    };

    const handleClear = useCallback(() => {
        handleValueUpdate('', true);
    }, [handleValueUpdate]);

    return (
        <div className="NameFilterInput">
            <div className="NameFilterInput__container">
                <input
                    name="NameFilterInput"
                    className={`NameFilterInput__input ${!props.short && 'NameFilterInput__input__expanded'}`}
                    onKeyUp={debouncedHandleInputKeyUp}
                    onBlur={handleOnBlur}
                    placeholder={t('common.search')}
                    ref={inputRef}
                    type="text"
                    enterKeyHint="done"
                    autoComplete="off"
                    autoCorrect="off"
                />

                <div className="NameFilterInput__icon" onClick={() => inputRef.current?.focus()}>
                    {currentNameSelection === '' && <SearchIcon />}
                    {currentNameSelection !== '' && <CircledCrossIcon onClick={handleClear} />}
                </div>
            </div>
        </div>
    );
};

NameFilterInput.defaultProps = {
    minContentLengthForCommit: 3,
};
