import { DeviceInfo } from '../services/ConfigurationService/DeviceService';

export interface CloudshelfInfo {
    isDevice: boolean;
    deviceNeedsPairing: boolean;
    subdomain: string;
    cloudshelfOrDeviceName: string;
}

export function getCloudshelfInfo(localDeviceInfo?: DeviceInfo): CloudshelfInfo {
    if (!window || !window.location) {
        throw new Error('Not running in browser window');
    }

    const hostname = process.env.REACT_APP_OVERRIDE_HOSTNAME ?? window.location.hostname;
    const path = process.env.REACT_APP_OVERRIDE_PATHNAME ?? `${window.location.pathname}/`;
    let subdomain = hostname.substring(0, hostname.indexOf('.'));
    const cloudshelfOrDeviceName = path.indexOf('/', 1) === -1 ? path : path.substring(1, path.indexOf('/', 1));

    if (subdomain === 'rc-device') {
        subdomain = 'device';
    }
    const isDevice = subdomain === 'device';
    const shouldForceAllowPairing = isDevice && cloudshelfOrDeviceName === 'new';
    const nameOrId = isDevice ? localDeviceInfo?.id ?? '' : cloudshelfOrDeviceName;

    return {
        isDevice,
        deviceNeedsPairing: shouldForceAllowPairing ? true : !localDeviceInfo?.registered ?? false,
        subdomain,
        cloudshelfOrDeviceName: nameOrId,
    };
}

export function useCloudshelfInfo(localDeviceInfo?: DeviceInfo): CloudshelfInfo {
    return getCloudshelfInfo(localDeviceInfo);
}
