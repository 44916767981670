import { inject, injectable } from 'inversify';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import DependencyType from '../../dependancyInjection/DependencyType';
import {
    GenerateTrackedUrlDocument,
    GenerateTrackedUrlMutation,
    GenerateTrackedUrlMutationVariables,
    IsTrackedUrlScannedDocument,
    IsTrackedUrlScannedQuery,
    IsTrackedUrlScannedQueryVariables,
} from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { ConfigurationService } from '../ConfigurationService/ConfigurationService';
import { DisplayOnlyTrackedHandleType } from './DisplayOnlyTrackedHandleType.enum';
import { LogUtil } from '../../utils/Logging.Util';

export interface TrackedUrl {
    url: string;
    id: string;
}

@injectable()
export class CloudshelfTrackedURLService {
    constructor(
        @inject(DependencyType.ApolloClient) private readonly _apolloClient: ApolloClient<NormalizedCacheObject>,
        @inject(DependencyType.ConfigurationService) private readonly _configService: ConfigurationService,
    ) {}

    generateDisplayOnlyURL(handleType: DisplayOnlyTrackedHandleType, handleOrLink: string): string | undefined {
        try {
            const managerURL = process.env.REACT_APP_MANAGER_HOST;
            const cloudshelfId = this._configService.cloudshelfId;

            if (!managerURL) {
                LogUtil.LogException(
                    'Unable to create a display transfer tracked URL.',
                    new Error('Missing manager host'),
                );
                return undefined;
            }

            if (!cloudshelfId) {
                LogUtil.LogException(
                    'Unable to create a display transfer tracked URL.',
                    new Error('Missing cloudshelf id'),
                );
                return undefined;
            }

            let url: string | undefined;

            if (handleType === DisplayOnlyTrackedHandleType.PRODUCT) {
                url = `${managerURL}/rest/trackedURLs/displayTransfer/${cloudshelfId}/product/${handleOrLink}`;
            } else if (handleType === DisplayOnlyTrackedHandleType.CATEGORY) {
                url = `${managerURL}/rest/trackedURLs/displayTransfer/${cloudshelfId}/category/${handleOrLink}`;
            } else if (handleType === DisplayOnlyTrackedHandleType.BANNER_LINK) {
                url = `${managerURL}/rest/trackedURLs/displayTransfer/${cloudshelfId}/bannerLink/${encodeURIComponent(
                    handleOrLink,
                )}`;
            }

            return url;
        } catch (err) {
            LogUtil.LogException('Unable to create a display transfer tracked URL.', err);
            return undefined;
        }
    }

    async generateTrackedURL(urlToTrack: string, checkoutId?: string): Promise<TrackedUrl | undefined> {
        try {
            const mutationTuple = await this._apolloClient.mutate<
                GenerateTrackedUrlMutation,
                GenerateTrackedUrlMutationVariables
            >({
                mutation: GenerateTrackedUrlDocument,
                context: { timeout: 4000 },
                variables: {
                    cloudshelfId: this._configService.config()?.id ?? '',
                    url: urlToTrack,
                    checkoutId: checkoutId,
                },
            });

            return mutationTuple.data?.createTrackedURL;
        } catch {
            // Error will throw if timeout occurs. Don't need sentry as timeout is likely to just mean bad wifi.
            return undefined;
        }
    }

    async isTrackedUrlQRScanned(id: string): Promise<boolean> {
        const queryTuple = await this._apolloClient.query<IsTrackedUrlScannedQuery, IsTrackedUrlScannedQueryVariables>({
            query: IsTrackedUrlScannedDocument,
            variables: {
                id,
            },
            fetchPolicy: 'network-only',
        });

        return !(queryTuple.errors || !queryTuple.data.isTrackedURLScanned);
    }
}
