import { ApolloClient, ApolloQueryResult, NormalizedCacheObject } from '@apollo/client';
import { CloudshelfInfo, getCloudshelfInfo } from '../../hooks/UseCloudshelfInfo';
import {
    ClearSalesPersonRule,
    CloudshelfConfigStatus,
    DeviceMode,
    DeviceOwnerType,
    ECommerceProvider,
    FilterType,
    GetCloudshelfConfigDocument,
    GetCloudshelfConfigQuery,
    GetCloudshelfConfigQueryVariables,
    GetLatestVersionDocument,
    GetLatestVersionQuery,
    GetLatestVersionQueryVariables,
    PdpBlockDescription,
    PdpBlockMetafield,
    PdpBlockProductData,
    PdpBlockSpacer,
    PowerTileBackgroundType,
    PowerTileType,
    SalesPersonNameRule,
} from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { DeviceInfo } from './DeviceService';
import {
    Banner,
    CloudshelfEngineConfig,
    CloudshelfPDPBlock,
    Device,
    DisplayOnlyEngineConfig,
    TeamMember,
} from './types/config/CloudshelfEngineConfig';
import { FilterValueType } from './types/filters/FilterValueType';
import { CloudshelfEngineFilter } from './types/filters/CloudshelfEngineFilter';
import { ProviderConfig } from './types/config/ProviderConfig';
import { Provider } from './types/config/Provider';
import { CloudshelfTrackerType } from './types/config/CloudshelfTrackerType';
import { EmptyConfig, EmptyDisplayOnlyConfig } from './EmptyConfig';
import { LogUtil } from '../../utils/Logging.Util';
import DependencyType from '../../dependancyInjection/DependencyType';
import { dependenciesContainer } from '../../dependancyInjection/DependenciesInitializer';
import { CloudshelfEngineAttractScreen } from './types/config/CloudshelfEngineAttractScreen';
import * as Sentry from '@sentry/react';
import { TileConfig } from './types/config/TileConfig';
import { StorageService } from '../StorageService/StorageService';
import { StorageKey } from '../StorageService/StorageKeys.enum';
import { Category } from '../CategoryService/entities/Category';
import { SessionManagementService } from '../SessionManagementService/SessionManagementService';
import { buildOrderByFilter } from '../../utils/EngineFilter.Util';
import { calculateDPI } from '../../utils/Responsive.Util';
import ProductVariant from '../ProductServices/variant/ProductVariant';
import { ProductVariantAvailability } from '../ProductServices/ProductVariantAvailability';

export class ConfigurationService {
    private _config: CloudshelfEngineConfig | undefined;
    private _configCached: CloudshelfEngineConfig | undefined;
    private _shouldReportPageLoad = true;

    private _providerConfig: ProviderConfig | undefined;
    private _providerConfigCached: ProviderConfig | undefined;

    private _previousStatus: CloudshelfConfigStatus | undefined;
    private _status: CloudshelfConfigStatus | undefined;

    private _isDevice: boolean | undefined;

    private _deviceNeedsPairing: boolean | undefined;

    private _configUpdatedSubject: Subject<void> = new Subject<void>();
    private _displayableFilters: CloudshelfEngineFilter[] = [];
    private _productCustomizerPriceModifierVariant: ProductVariant | undefined;

    constructor(
        private readonly _apolloClient: ApolloClient<NormalizedCacheObject>,
        private readonly _storageService: StorageService,
    ) {}

    public setup(localDeviceInfo?: DeviceInfo): CloudshelfInfo {
        const cloudshelfInfo = getCloudshelfInfo(localDeviceInfo);
        this._isDevice = cloudshelfInfo.isDevice;
        this._deviceNeedsPairing = cloudshelfInfo.deviceNeedsPairing;
        return cloudshelfInfo;
    }

    private readCachedConfig() {
        const cachedConfigString = this._storageService.get(StorageKey.CLOUDSHELF_CONFIG);
        const cachedProviderConfigString = this._storageService.get(StorageKey.CLOUDSHELF_PROVIDER_CONFIG);

        let cachedConfig: CloudshelfEngineConfig | undefined;
        let cachedProviderConfig: ProviderConfig | undefined;
        if (cachedConfigString && cachedProviderConfigString) {
            cachedConfig = JSON.parse(cachedConfigString) as CloudshelfEngineConfig;
            cachedProviderConfig = JSON.parse(cachedProviderConfigString) as ProviderConfig;
        }
        this._configCached = cachedConfig;
        this._providerConfigCached = cachedProviderConfig;
    }

    private get hasCachedConfig() {
        return this._configCached !== undefined && this._providerConfigCached !== undefined;
    }

    private useCachedConfig() {
        this._config = this._configCached;
        this._providerConfig = this._providerConfigCached;
        this.setDisplayableFilters(this._config?.filters ?? []);
        this._status = CloudshelfConfigStatus.Cached;
    }

    private setDisplayableFilters(filters: CloudshelfEngineFilter[]) {
        //We dont want hidden ones
        let sortedFilters = _.filter(filters, filter => !filter.isHidden);

        //We dont want merged children
        sortedFilters = _.filter(sortedFilters, filter => !filter.isMergedChild);

        //We dont want stockLevel if there is only one value
        sortedFilters = _.filter(
            sortedFilters,
            filter => !(filter.type === FilterType.StockLevel && filter.attributeValues.length === 1),
        );

        sortedFilters = _.sortBy(sortedFilters, filter => filter.priority, 'asc');
        this._displayableFilters = sortedFilters;
    }

    public async refreshConfig(localDeviceInfo?: DeviceInfo): Promise<void> {
        this._previousStatus = this._status;
        const cloudshelfInfo = this.setup(localDeviceInfo);
        // Handle display mode preview
        const parsedQuery = new URLSearchParams(window.location.search);

        this.readCachedConfig();

        let queryTuple: ApolloQueryResult<GetCloudshelfConfigQuery>;
        try {
            queryTuple = await this._apolloClient.query<GetCloudshelfConfigQuery, GetCloudshelfConfigQueryVariables>({
                variables: {
                    subdomain: cloudshelfInfo.subdomain,
                    cloudshelfName: cloudshelfInfo.cloudshelfOrDeviceName,
                    reportPageLoad: this._shouldReportPageLoad,
                    engineVersion: process.env.REACT_APP_PACKAGE_VERSION ?? 'unknown',
                },
                query: GetCloudshelfConfigDocument,
                fetchPolicy: 'network-only',
            });
            if (queryTuple.errors) {
                // If some error occurred and we have a cached config we can just show that. If we don't have a cached
                // config, we should show an error.
                if (this.hasCachedConfig) {
                    this.useCachedConfig();
                    this._configUpdatedSubject.next();
                    return;
                }
                throw new Error('Failed to fetch config (1)');
            }
        } catch {
            if (this.hasCachedConfig && !this.isUsingCachedConfig) {
                if (
                    this._previousStatus === undefined ||
                    (this._status !== CloudshelfConfigStatus.Cached &&
                        this._previousStatus !== CloudshelfConfigStatus.DeviceWithCloudshelf &&
                        this._previousStatus !== CloudshelfConfigStatus.CloudshelfPreview &&
                        this._previousStatus !== CloudshelfConfigStatus.MobileHandoff)
                ) {
                    this.useCachedConfig();
                    this._configUpdatedSubject.next();
                }
                return;
            } else if (!this.hasCachedConfig) {
                throw new Error('Failed to fetch config (2)');
            }
            return;
        }

        this._status = queryTuple.data.getCloudshelfConfig.status;

        if (this._status === CloudshelfConfigStatus.Notfound) {
            // If not found we will show an error page. We don't even need to try parsing the config.
            this._storageService.delete(StorageKey.CLOUDSHELF_CONFIG);
            this._storageService.delete(StorageKey.CLOUDSHELF_PROVIDER_CONFIG);
            this._config = undefined;
            this._configUpdatedSubject.next();
            return;
        }

        LogUtil.Log('this._status: ' + this._status);
        if (this._status === CloudshelfConfigStatus.Frozen) {
            this._config = undefined;
            this._configUpdatedSubject.next();
            return;
        }

        if (this._status === CloudshelfConfigStatus.DeviceNoCloudshelf) {
            //The device exists, but we dont have a cloudshelf, so we have to remove the config as it's now invalid
            this._storageService.delete(StorageKey.CLOUDSHELF_CONFIG);
            this._storageService.delete(StorageKey.CLOUDSHELF_PROVIDER_CONFIG);
            this._config = {
                ...EmptyConfig,

                device: queryTuple.data.getCloudshelfConfig.device
                    ? {
                          id: queryTuple.data.getCloudshelfConfig.device?.id ?? '',
                          name: queryTuple.data.getCloudshelfConfig.device?.name ?? '',

                          owner: {
                              id: queryTuple.data.getCloudshelfConfig.device?.owner?.id ?? '',
                              name: queryTuple.data.getCloudshelfConfig.device?.owner?.name ?? '',
                          },
                          debugMode: queryTuple.data.getCloudshelfConfig.device?.debugMode ?? false,
                          isCloudshelfInternalDevice:
                              queryTuple.data.getCloudshelfConfig.device?.ownerType ===
                                  DeviceOwnerType.CloudshelfInternal ?? false,
                      }
                    : undefined,
                deviceMode:
                    parsedQuery.get('previewDisplayMode') !== null
                        ? DeviceMode.DisplayOnly
                        : queryTuple.data.getCloudshelfConfig.device?.mode ?? DeviceMode.Interactive,
                inMaintenanceMode: queryTuple.data.getCloudshelfConfig.inMaintenanceMode,
            };
            this._configUpdatedSubject.next();
            return;
        }

        if (this._status === CloudshelfConfigStatus.DeviceWithoutLocation) {
            this._config = {
                ...EmptyConfig,
                device: queryTuple.data.getCloudshelfConfig.device
                    ? {
                          id: queryTuple.data.getCloudshelfConfig.device?.id ?? '',
                          name: queryTuple.data.getCloudshelfConfig.device?.name ?? '',

                          owner: {
                              id: queryTuple.data.getCloudshelfConfig.device?.owner?.id ?? '',
                              name: queryTuple.data.getCloudshelfConfig.device?.owner?.name ?? '',
                          },
                          debugMode: queryTuple.data.getCloudshelfConfig.device?.debugMode ?? false,
                          isCloudshelfInternalDevice:
                              queryTuple.data.getCloudshelfConfig.device?.ownerType ===
                                  DeviceOwnerType.CloudshelfInternal ?? false,
                      }
                    : undefined,
                deviceMode:
                    parsedQuery.get('previewDisplayMode') !== null
                        ? DeviceMode.DisplayOnly
                        : queryTuple.data.getCloudshelfConfig.device?.mode ?? DeviceMode.Interactive,
                inMaintenanceMode: queryTuple.data.getCloudshelfConfig.inMaintenanceMode,
            };
            this._configUpdatedSubject.next();
            return;
        }

        if (this._status === CloudshelfConfigStatus.DeviceRemoved) {
            //The device was deleted (dont remove the saved config, because we want to show the whoops screen)
            this._config = {
                ...EmptyConfig,
                inMaintenanceMode: queryTuple.data.getCloudshelfConfig.inMaintenanceMode,
            };
            this._configUpdatedSubject.next();
            return;
        }

        const remoteDevice = queryTuple.data.getCloudshelfConfig.device;
        const remoteConfig = queryTuple.data.getCloudshelfConfig.config;

        if (!remoteConfig) {
            if (this.hasCachedConfig) {
                this.useCachedConfig();
                this._configUpdatedSubject.next();
                return;
            }
            throw new Error('Failed to fetch config (3)');
        }

        let attractScreen = {
            callToAction: remoteConfig.theme.attractScreen.callToAction,
            callToActionSize: remoteConfig.theme.attractScreen.callToActionSize,
            callToActionAlignment: remoteConfig.theme.attractScreen.callToActionAlignment,
            touchIndicator: remoteConfig.theme.attractScreen.touchIndicator,
            displayLogo: remoteConfig.theme.attractScreen.displayLogo,
            logoSize: remoteConfig.theme.attractScreen.logoSize,
        } as CloudshelfEngineAttractScreen;

        if (remoteConfig.theme.attractScreen.__typename === 'CloudshelfThemeClassicAttractScreen') {
            attractScreen = {
                ...attractScreen,
                tileAnimation: remoteConfig.theme.attractScreen.tileAnimation,
                displayFrame: remoteConfig.theme.attractScreen.displayFrame,
            } as CloudshelfEngineAttractScreen;
        } else if (remoteConfig.theme.attractScreen.__typename === 'CloudshelfThemeMinimalAttractScreen') {
            attractScreen = {
                ...attractScreen,
                actionButton: remoteConfig.theme.attractScreen.actionButton,
            } as CloudshelfEngineAttractScreen;
        }

        const tiles: TileConfig[] = [];
        const collectionIds: string[] = [];

        _.map(remoteConfig.cloudshelf.powerTiles, pt => {
            let tile: TileConfig = {
                id: pt.id,
                priority: pt.priority,
                type: pt.type,
                title: '',
                configurationIssues: pt.configurationIssues,
                pinned: pt.pinned,
            };

            if (pt.definition.__typename === 'CategoryPowerTile') {
                if (!pt.definition.category) {
                    return;
                }
                collectionIds.push(pt.definition.category.id);
                tile = {
                    ...tile,
                    title: pt.definition.title || pt.definition.category.title || '',
                    backgroundImage:
                        (pt.definition.useImage && pt.definition.image ? pt.definition.image : undefined) ||
                        pt.definition.category.image ||
                        undefined,
                    handle: pt.definition.category.handle,
                    isAllCollectionTile: pt.definition.category.handle === 'INTERNAL_ALL',
                    backgroundType: PowerTileBackgroundType.Image,
                    collectionStorefrontId: pt.definition.category.shopifyStorefrontId,
                    collectionId: pt.definition.category.id,
                    useImage: pt.definition.useImage,
                };
            } else if (pt.definition.__typename === 'QRCodePowerTile') {
                tile = {
                    ...tile,
                    backgroundImage: pt.definition.backgroundImage,
                    backgroundType: pt.definition.backgroundType,
                    backgroundPrimaryColor: pt.definition.backgroundPrimaryColor,
                    backgroundSecondaryColor: pt.definition.backgroundSecondaryColor,
                    title: pt.definition.title ?? '',
                    icon: pt.definition.icon,
                    useIcon: pt.definition.useIcon,
                    callToAction: pt.definition.callToAction,
                    qrCodeText: pt.definition.qrCodeText,
                    qrCodeURL: pt.definition.qrCodeURL,
                };
            }

            tiles.push(tile);
        });

        const filters: CloudshelfEngineFilter[] = _.map(
            remoteConfig.cloudshelf.filters,
            (filter): CloudshelfEngineFilter => {
                // Ensure we have a valid filter type. If not, error.
                const filterType = filter.type as FilterType;

                if (!filterType) {
                    throw new Error(`Unknown filter type: ${filter.type}`);
                }

                return {
                    attributeValues: filter.attributeValues,
                    displayName: filter.displayName ?? filter.ecommProviderFieldName,
                    hiddenAttributeValues: filter.hiddenAttributeValues,
                    ecommProviderFieldName: filter.ecommProviderFieldName,
                    expandedByDefault: filter.expandedByDefault,
                    id: filter.id,
                    type: filterType,
                    isHidden: filter.isHidden,
                    isMergedChild: filter.isMergedChild,
                    parentId: filter.parentId ?? undefined,
                    valueType: filterType === FilterType.Price ? FilterValueType.RANGE : FilterValueType.DISCRETE,
                    options: filter.options ?? undefined,
                    valueOverrides: filter.valueOverrides,
                    priority: filter.priority,
                    metafieldNamespace: filter.metafieldNamespace ?? undefined,
                    metafieldKey: filter.metafieldKey ?? undefined,
                };
            },
        );

        filters.push(buildOrderByFilter(collectionIds));

        let device: Device | undefined;

        if (remoteDevice) {
            device = {
                id: remoteDevice.id,
                name: remoteDevice.name,
                owner: {
                    id: remoteDevice.owner?.id ?? '',
                    name: remoteDevice.owner?.name ?? 'Unknown',
                },
                location: remoteDevice.location
                    ? {
                          id: remoteDevice.location.id,
                          name: remoteDevice.location.name,
                      }
                    : undefined,
                isCloudshelfInternalDevice: remoteDevice.ownerType === DeviceOwnerType.CloudshelfInternal ?? false,
                debugMode: remoteDevice.debugMode,
            };
        }

        const teamMembers: TeamMember[] = [];

        _.map(queryTuple.data.getCloudshelfConfig.teamMembers, tm => {
            let displayName = '';

            if (queryTuple.data.getCloudshelfConfig.store?.salesPersonNameRule === SalesPersonNameRule.FirstName) {
                displayName = tm.firstName;
            } else if (
                queryTuple.data.getCloudshelfConfig.store?.salesPersonNameRule === SalesPersonNameRule.FullName
            ) {
                const trimmedLastName = tm.lastName.trim();
                if (trimmedLastName.length > 0) {
                    displayName = `${trimmedLastName.toUpperCase()}, ${tm.firstName}`;
                } else {
                    displayName = tm.firstName;
                }
            } else if (
                queryTuple.data.getCloudshelfConfig.store?.salesPersonNameRule === SalesPersonNameRule.Reference
            ) {
                const trimmedRef = tm.internalReference.trim();
                if (trimmedRef.length > 0) {
                    displayName = trimmedRef;
                } else {
                    displayName = tm.firstName;
                }
            }

            teamMembers.push({
                id: tm.id,
                displayName,
                reportingValue: `${tm.firstName}${_.trim(tm.lastName).length > 0 ? ` ${tm.lastName}` : ''}${
                    _.trim(tm.internalReference).length > 0 ? ` (${tm.internalReference})` : ''
                }`,
                firstName: tm.firstName,
            });
        });

        const clearSalesPersonRule =
            queryTuple.data.getCloudshelfConfig.store?.salesPersonClearingRule ?? ClearSalesPersonRule.Never;

        if (clearSalesPersonRule !== ClearSalesPersonRule.Daily) {
            this._storageService.delete(StorageKey.SALES_ASSOCIATE_EXPIRY);
        } else {
            const expiry = this._storageService.get(StorageKey.SALES_ASSOCIATE_EXPIRY);
            if (expiry) {
                const expiryUnix = parseInt(expiry, 10);
                if (expiryUnix < new Date().getTime()) {
                    this._storageService.delete(StorageKey.SALES_ASSOCIATE_ID);
                }
            }
        }

        const pdpBlocks: CloudshelfPDPBlock[] = [];

        _.map(remoteConfig.pdpBlocks, pdpBlock => {
            if (pdpBlock.unionTypeName === 'PDPBlockDescription') {
                const typedBlock = pdpBlock as PdpBlockDescription;

                const block = {
                    id: typedBlock.id,
                    position: typedBlock.position,
                    style: typedBlock.style,
                    unionTypeName: typedBlock.unionTypeName,
                    displayText: typedBlock.displayText,
                    removeThemeShortcodes: typedBlock.removeThemeShortcodes,
                } as CloudshelfPDPBlock;

                pdpBlocks.push(block);
            } else if (pdpBlock.unionTypeName === 'PDPBlockProductData') {
                const typedBlock = pdpBlock as PdpBlockProductData;

                const block = {
                    id: typedBlock.id,
                    position: typedBlock.position,
                    style: typedBlock.style,
                    unionTypeName: typedBlock.unionTypeName,
                    displayText: typedBlock.displayText,
                    productDataType: typedBlock.productDataType,
                } as CloudshelfPDPBlock;

                pdpBlocks.push(block);
            } else if (pdpBlock.unionTypeName === 'PDPBlockMetafield') {
                const typedBlock = pdpBlock as PdpBlockMetafield;

                const block = {
                    id: typedBlock.id,
                    position: typedBlock.position,
                    style: typedBlock.style,
                    displayText: typedBlock.displayText,
                    unionTypeName: typedBlock.unionTypeName,
                    namespace: typedBlock.namespace,
                    key: typedBlock.key,
                    metafieldDisplayType: typedBlock.metafieldDisplayType,
                } as CloudshelfPDPBlock;

                pdpBlocks.push(block);
            } else if (pdpBlock.unionTypeName === 'PDPBlockSpacer') {
                const typedBlock = pdpBlock as PdpBlockSpacer;

                const block = {
                    id: typedBlock.id,
                    position: typedBlock.position,
                    unionTypeName: typedBlock.unionTypeName,
                } as CloudshelfPDPBlock;

                pdpBlocks.push(block);
            }
        });
        let forcedScreenSizeStr: string | null = null;
        let forcedScreenSize: number | null = null;

        if (this._status === CloudshelfConfigStatus.CloudshelfPreview) {
            forcedScreenSizeStr = parsedQuery.get('forceScreenSize');
            if (!forcedScreenSizeStr) {
                forcedScreenSizeStr = this._storageService.get(StorageKey.STORED_PREVIEW_SCREENSIZE) ?? null;
            } else {
                this._storageService.put(StorageKey.STORED_PREVIEW_SCREENSIZE, forcedScreenSizeStr);
            }
        }

        if (process.env.REACT_APP_OVERRIDE_SCREEN_SIZE) {
            forcedScreenSizeStr = process.env.REACT_APP_OVERRIDE_SCREEN_SIZE;
        }

        if (forcedScreenSizeStr) {
            //convert to a number
            forcedScreenSize = parseFloat(forcedScreenSizeStr);
            if (forcedScreenSize === NaN) {
                forcedScreenSize = null;
            }
        }

        let calculatedDPI = 96;
        if (forcedScreenSize !== null) {
            calculatedDPI = calculateDPI(forcedScreenSize, window.innerWidth, window.innerHeight);
        } else {
            if (remoteDevice?.screenSizeInches !== undefined && remoteDevice?.screenSizeInches !== null) {
                const screenSize = remoteDevice.screenSizeInches;

                //Calculate DPI based on screen size
                calculatedDPI = calculateDPI(screenSize, window.innerWidth, window.innerHeight);
            }
        }

        // let remoteDeviceSize = 15;
        //
        // if (remoteDevice) {
        //     if (!remoteDevice.owner) {
        //         remoteDeviceSize = 7;
        //     } else {
        //         remoteDeviceSize = remoteDevice.screenSizeInches;
        //     }
        // }

        const configScreenSize = forcedScreenSize !== null ? forcedScreenSize : remoteDevice?.screenSizeInches ?? 15;

        const config: CloudshelfEngineConfig = {
            tiles,
            pdpBlocks,
            id: remoteConfig.cloudshelf.id,
            showCloudshelfBranding: !remoteConfig.theme.removeCloudshelfBranding,
            retailerRules: {
                allocateSalesToAssignedSalesPerson:
                    queryTuple.data.getCloudshelfConfig.store?.displaySalesPersonAllocationSelection ?? false,
                salesPersonName:
                    queryTuple.data.getCloudshelfConfig.store?.salesPersonNameRule ?? SalesPersonNameRule.FirstName,
                clearSalesPerson: clearSalesPersonRule,
            },
            bannerDisplayRules: {
                interactive: {
                    displayMode: remoteConfig.bannerConfig.interactiveDisplayMode,
                    duration: remoteConfig.bannerConfig.interactiveShowBannerDurationInSeconds,
                    showEveryMinutes: remoteConfig.bannerConfig.interactiveShowBannerEveryXMinutes,
                },
                display: {
                    displayMode: remoteConfig.bannerConfig.displayOnlyDisplayMode,
                    duration: remoteConfig.bannerConfig.displayOnlyShowBannerDurationInSeconds,
                },
            },
            banners: (
                remoteConfig.cloudshelf.banners?.map(banner => {
                    const mappedBanner: Banner = {
                        backgroundColour: banner.backgroundColour,
                        backgroundImageVertical: banner.backgroundImageVertical ?? undefined,
                        backgroundImageHorizontal: banner.backgroundImageHorizontal ?? undefined,
                        backgroundType: banner.backgroundType,
                        linkCollection: banner.linkCollection ?? undefined,
                        linkProduct: banner.linkProduct ?? undefined,
                        linkText: banner.linkText ?? undefined,
                        linkType: banner.linkType,
                        linkURL: banner.linkURL ?? undefined,
                        position: banner.position,
                        text: banner.text,
                        id: banner.id,
                    };

                    return mappedBanner;
                }) ?? []
            ).sort((a, b) => a.position - b.position),
            teamMembers,
            device,
            deviceMode:
                parsedQuery.get('previewDisplayMode') !== null
                    ? DeviceMode.DisplayOnly
                    : remoteDevice?.mode ?? DeviceMode.Interactive,
            deviceDPI: calculatedDPI,
            screenSize: configScreenSize,
            forceDebugOverlay: remoteConfig.forceDebugOverlay ?? false,
            couponsEnabled: remoteConfig.couponsEnabled,
            inMaintenanceMode: queryTuple.data.getCloudshelfConfig.inMaintenanceMode,
            inactivityTimeout: remoteConfig.inactivityTimeout,
            name: remoteConfig.name,
            normalizedName: cloudshelfInfo.cloudshelfOrDeviceName,
            ownerId: cloudshelfInfo.subdomain,
            ownerName: queryTuple.data.getCloudshelfConfig.deviceOwnersName ?? '',
            provider: Provider.SHOPIFY,
            displayInStockLabel: remoteConfig.displayInStockLabel,
            displayLimitedLabel: remoteConfig.displayLimitedLabel,
            displaySoldOutLabel: remoteConfig.displaySoldOutLabel,
            displayOutOfStockLabel: remoteConfig.displayOutOfStockLabel,
            inStockLabel: remoteConfig.inStockLabel ?? '',
            includeProductsInStock: remoteConfig.includeProductsInStock,
            includeProductsOutOfStock: remoteConfig.includeProductsOutOfStock,
            includeProductsLimitedAvailability: remoteConfig.includeProductsLimitedAvailability,
            limitedAvailabilityLabel: remoteConfig.limitedAvailabilityLabel ?? '',
            outOfStockLabel: remoteConfig.outOfStockLabel ?? '',
            soldOutLabel: remoteConfig.soldOutLabel ?? '',
            showTotalStockCount: remoteConfig.showTotalStockCount,
            displayOnly: {
                displayLogo: remoteConfig.displayOnlyConfig.displayLogo,
                maxPhotosPerProduct: device?.debugMode
                    ? Number.MAX_VALUE
                    : remoteConfig.displayOnlyConfig.maxPhotosPerProduct,
                maxProductsPerCollection: device?.debugMode
                    ? Number.MAX_VALUE
                    : remoteConfig.displayOnlyConfig.maxProductsPerCollection,
                timePerPhoto: device?.debugMode ? 1 : remoteConfig.displayOnlyConfig.timePerPhoto,
                collectionType: remoteConfig.displayOnlyConfig.collectionType,
                logoSize: remoteConfig.displayOnlyConfig.logoSize,
                includeProductName: remoteConfig.displayOnlyConfig.includeProductName,
                includePrice: remoteConfig.displayOnlyConfig.includePrice,
                includeStock: remoteConfig.displayOnlyConfig.includeStock,
                includeQRCode: remoteConfig.displayOnlyConfig.includeQRCode,
            },
            theme: {
                imageAnchor: remoteConfig.theme.imageAnchor,
                tileSizeModifier: remoteConfig.theme.tileSizeModifier,
                attractScreen,
                primaryColor: remoteConfig.theme.primaryColor,
                mainTextColor: remoteConfig.theme.mainTextColor,
                purchaseTextColor: remoteConfig.theme.purchaseTextColor,
                useProductAnimations: remoteConfig.theme.useProductAnimations,
                radius: {
                    inputs: remoteConfig.theme.radius.inputs,
                    drawer: remoteConfig.theme.radius.drawer,
                    modal: remoteConfig.theme.radius.modal,
                    tiles: remoteConfig.theme.radius.tiles,
                },
                headingsFont: {
                    isCustomFont: remoteConfig.theme.headingFont.isCustomFont,
                    fontFamily: remoteConfig.theme.headingFont.fontFamily,
                    fontWeight: remoteConfig.theme.headingFont.fontWeightValue,
                    cdn: remoteConfig.theme.headingFont.fontFamilyCDN ?? undefined,
                },
                subheadingsFont: {
                    isCustomFont: remoteConfig.theme.subheadingFont.isCustomFont,
                    fontFamily: remoteConfig.theme.subheadingFont.fontFamily,
                    fontWeight: remoteConfig.theme.subheadingFont.fontWeightValue,
                    cdn: remoteConfig.theme.subheadingFont.fontFamilyCDN ?? undefined,
                },
                bodyFont: {
                    isCustomFont: remoteConfig.theme.bodyFont.isCustomFont,
                    fontFamily: remoteConfig.theme.bodyFont.fontFamily,
                    fontWeight: remoteConfig.theme.bodyFont.fontWeightValue,
                    cdn: remoteConfig.theme.bodyFont.fontFamilyCDN ?? undefined,
                },
                logoUrl: remoteConfig.theme.logoUrl ?? undefined,
            },
            trackers: remoteConfig.trackers.map(tracker => {
                return {
                    id: tracker.id,
                    name: tracker.name,
                    type: tracker.type as CloudshelfTrackerType,
                };
            }),
            updatedAt: remoteConfig.updatedAt,
            filters,
            scopes: remoteConfig.scopes,
            userType: remoteConfig.userType,
            handoffProductHandle: remoteConfig.handoffProductHandle ?? undefined,
            handoffProductOptionId: remoteConfig.handoffProductOptionId ?? undefined,
        };

        Sentry.setUser({
            username: config.device?.owner.name,
        });

        this._config = config;
        this.setDisplayableFilters(filters);
        if (
            remoteConfig.providerConfig &&
            remoteConfig.providerConfig.eCommerceProvider === ECommerceProvider.Shopify
        ) {
            this._providerConfig = {
                domain: remoteConfig.providerConfig?.item1 ?? '',
                accessToken: remoteConfig.providerConfig?.item2 ?? '',
            };
        }

        (window as any).CLOUDSHELF_CONFIG = this._config;
        (window as any).PROVIDER_CONFIG = this._providerConfig;
        this._shouldReportPageLoad = false;

        // If user has a session, don't update config
        let hasSession = false;
        try {
            // On initialisation of cloudshelf this won't have been bound yet so will throw an exception. In this case
            // we "assume" there is no session as there can't possibly be one during initialisation.
            const sessionManagementService = dependenciesContainer.get<SessionManagementService>(
                DependencyType.SessionManagementService,
            );
            LogUtil.Log('Got session management service');
            hasSession = sessionManagementService.isSessionActive;
        } catch (err) {
            // We don't care about the error as this will only ever happen on initialisation
        }

        const hasConfigChanged =
            this._status !== CloudshelfConfigStatus.MobileHandoff &&
            JSON.stringify(this._configCached) !== JSON.stringify(config);
        const statusIsCached = this._status === CloudshelfConfigStatus.Cached;
        const statusIsHandoff = this._status === CloudshelfConfigStatus.MobileHandoff;
        const hasCachedConfig = this._configCached;
        const hasCachedProviderConfig = this._providerConfigCached;
        const previousStatusWasCached = this._previousStatus === CloudshelfConfigStatus.Cached;
        const statusHasChanged = this._previousStatus !== this._status;
        const willRun =
            !statusIsCached &&
            !hasSession &&
            !statusIsHandoff &&
            (!hasCachedConfig ||
                !hasCachedProviderConfig ||
                hasConfigChanged ||
                (!previousStatusWasCached && statusHasChanged));

        if (willRun) {
            LogUtil.Log('Config updated - performing update');
            this._storageService.put(StorageKey.CLOUDSHELF_CONFIG, JSON.stringify(this._config));
            this._storageService.put(StorageKey.CLOUDSHELF_PROVIDER_CONFIG, JSON.stringify(this._providerConfig));
            this._configUpdatedSubject.next();
        }
    }

    public async getLatestVersionString(): Promise<string> {
        (window as any).ENV_VERSION = process.env.REACT_APP_PACKAGE_VERSION;

        try {
            const queryTuple = await this._apolloClient.query<GetLatestVersionQuery, GetLatestVersionQueryVariables>({
                query: GetLatestVersionDocument,
                fetchPolicy: 'network-only',
            });

            if (queryTuple.errors || !queryTuple.data) {
                return process.env.REACT_APP_PACKAGE_VERSION ?? 'Unknown';
            }

            (window as any).LATEST_VERSION = queryTuple.data.getVersionByType.versionString;

            return queryTuple.data.getVersionByType.versionString;
        } catch (err) {
            Sentry.captureException(err, {
                extra: {
                    operationName: 'getLatestVersion',
                },
            });
            return process.env.REACT_APP_PACKAGE_VERSION ?? 'Unknown';
        }
    }

    public isDevice(): boolean {
        return this._isDevice ?? false;
    }

    public isInternalDevice(): boolean {
        if (!this._isDevice) {
            return false;
        }

        return this._config?.device?.isCloudshelfInternalDevice ?? false;
    }

    public deviceNeedsPairing(): boolean {
        return this._deviceNeedsPairing ?? false;
    }

    public config(): CloudshelfEngineConfig | undefined {
        return this._config;
    }

    public providerConfig(): ProviderConfig | undefined {
        return this._providerConfig;
    }

    public status(): CloudshelfConfigStatus | undefined {
        return this._status;
    }

    public get isUsingCachedConfig(): boolean {
        return this._status === CloudshelfConfigStatus.Cached;
    }

    public observe(): Observable<void> {
        return this._configUpdatedSubject.asObservable();
    }

    public get cloudshelfId(): string | undefined {
        return this._config?.id;
    }

    public get categories(): Category[] {
        return _.compact(
            _.map(this._config?.tiles, tile => {
                if (tile.type !== PowerTileType.Category || !tile.collectionStorefrontId) {
                    return null;
                } else {
                    return {
                        id: tile.collectionStorefrontId,
                        internalId: tile.collectionId ?? '',
                        handle: tile.handle ?? '',
                        image: tile.backgroundImage,
                        title: tile.title,
                        isInternalAllCategory: tile.isAllCollectionTile ?? false,
                        useImageOverride: tile.useImage ?? false,
                    };
                }
            }),
        );
    }

    public get powerTiles(): TileConfig[] {
        return _.compact(
            _.map(this._config?.tiles ?? [], tile => {
                if (tile.configurationIssues.length === 0) {
                    return tile;
                } else {
                    return null;
                }
            }),
        );
    }

    public get pinnedPowerTileIndices(): number[] {
        const indices: number[] = [];

        _.map(this._config?.tiles ?? [], (tile, index) => {
            if (tile.pinned) {
                indices.push(index);
            }
        });

        return indices;
    }

    public get displayableFilters(): CloudshelfEngineFilter[] {
        return this._displayableFilters;
    }

    public get shouldUseProductAnimations(): boolean {
        return this._config?.theme?.useProductAnimations ?? false;
    }

    public get imageAnchor(): string {
        return this._config?.theme.imageAnchor.toLowerCase() ?? 'center';
    }

    public get displayOnlyConfig(): DisplayOnlyEngineConfig {
        return this._config?.displayOnly ?? EmptyDisplayOnlyConfig;
    }
    public get deviceMode(): DeviceMode {
        return this._config?.deviceMode ?? DeviceMode.Interactive;
    }

    public get isInPreviewMode(): boolean {
        return this.status() === CloudshelfConfigStatus.CloudshelfPreview;
    }

    public setProductCustomiserPriceModifierVariant(variant: ProductVariant | undefined): void {
        this._productCustomizerPriceModifierVariant = variant;
    }

    public get productCustomiserPriceModifierVariant(): ProductVariant | undefined {
        return this._productCustomizerPriceModifierVariant;
        // const itemCustomiserVariant: ProductVariant = new ProductVariant({
        //     id: 'gid://shopify/ProductVariant/42704425844934',
        //     name: 'Item Customisations',
        //     price: 0.01,
        //     image: undefined,
        //     options: [],
        //     availability: ProductVariantAvailability.InStock,
        //     altText: '',
        //     originalPrice: 1,
        //     sku: undefined,
        //     inventory: 9999999999999,
        // });
        //
        // return itemCustomiserVariant;
    }
}
