import { FC, memo } from 'react';
import { DependencyProvider } from '../../../dependancyInjection/DependencyContext';
import { dependenciesContainer } from '../../../dependancyInjection/DependenciesInitializer';
import DebugOverlays from '../BaseApp/components/DebugOverlays/DebugOverlays';
import { Router } from 'react-router-dom';
import { History } from 'history';

export interface StrippedAppProps {
    history: History;
}

const StrippedApp: FC<StrippedAppProps> = memo(props => {
    window.document.title = 'Cloudshelf';

    return (
        <DependencyProvider container={dependenciesContainer}>
            <DebugOverlays />
            <Router history={props.history}>{props.children}</Router>
        </DependencyProvider>
    );
});

export default StrippedApp;
