import { FC, useEffect, useRef } from 'react';
import useStateRef from 'react-usestateref';
import { LogUtil } from '../../../utils/Logging.Util';
import { SentryUtil } from '../../../utils/Sentry.Util';
import { useInjection } from '../../../dependancyInjection/DependencyContext';
import { ProductFilteringService } from '../../../services/ProductServices/ProductFilteringService';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { ConfigurationService } from '../../../services/ConfigurationService/ConfigurationService';
import StyledText, { TextSize, TextStyle } from '../StyledText/StyledText';
import styles from './ProductsLoader.module.scss';

export interface ProductsLoaderProps {
    onCachePopulated?: () => void;
    onComplete: () => void;
    explicitHandle?: string;
    loadLocalCache?: boolean;
}

const ProductsLoader: FC<ProductsLoaderProps> = props => {
    const isMounted = useRef(false);
    const [preloadComplete, setPreloadComplete, preloadCompleteRef] = useStateRef(false);
    const [percentComplete, setPercentComplete] = useStateRef(0);
    const [progressTranslationKey, setProgressTranslationKey] = useStateRef('');
    const filteringService = useInjection<ProductFilteringService>(DependencyType.ProductFilteringService);
    const configurationService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    });

    useEffect(() => {
        const lokiObserver = filteringService.observeLokiState();
        setPercentComplete(10);

        const lokiObserverSubscription = lokiObserver.subscribe(async () => {
            if (!filteringService.isCacheValidForCloudshelf) {
                LogUtil.Log(
                    `[Loki Product Cache]: Cache is not valid for Cloudshelf ID ${configurationService.cloudshelfId}.`,
                );
            } else {
                if (filteringService.isLocalCachePopulated) {
                    LogUtil.Log(
                        `[Loki Product Cache]: Ready & Valid for Cloudshelf ${filteringService.lokiCacheForCloudshelfId}`,
                    );

                    if (!preloadCompleteRef.current) {
                        LogUtil.Log('[Loki Product Cache]: Calling props.onCachePopulated');
                        await props.onCachePopulated?.();
                        setTimeout(() => {
                            if (isMounted.current) {
                                LogUtil.Log('[Loki Product Cache]: props.onCachePopulated completed');
                                setPreloadComplete(true);
                            }
                        }, 500);
                    }
                } else {
                    LogUtil.Log(`[Loki Product Cache]: Waiting for loki cache`);
                }
            }
        });

        const trans = SentryUtil.StartTransaction('FilteringService.UpdateLocalData', false);

        filteringService
            .updateLokiCache(
                props.loadLocalCache,
                (reportedProgress: number, translationKey) => {
                    try {
                        if (isMounted.current) {
                            setPercentComplete(reportedProgress);
                            setProgressTranslationKey(translationKey);
                        }
                    } catch {
                        // Ignore
                    }
                },
                props.explicitHandle,
            )
            .catch()
            .finally(() => {
                SentryUtil.EndSpan(trans.newTransaction);
            });

        return () => lokiObserverSubscription.unsubscribe();
    }, []);

    useEffect(() => {
        if (preloadComplete) {
            props.onComplete();
        }
    }, [preloadComplete]);

    return (
        <div className={styles.ProductsLoader__container}>
            <div className={styles.ProductsLoader__bar}>
                <div className={styles.ProductsLoader__bar__fill} style={{ width: `${percentComplete}%` }} />
            </div>
            <StyledText style={TextStyle.Subheading} size={TextSize.Small} className={styles.ProductsLoader__info}>
                {progressTranslationKey}
            </StyledText>
        </div>
    );
};

ProductsLoader.defaultProps = {
    loadLocalCache: true,
};
export default ProductsLoader;
