import React, { FC } from 'react';

const RoundedArrowUpIcon: FC = props => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.59776 12.6163L11.4635 9.84133C11.6236 9.70475 11.8084 9.63754 11.9933 9.63754C12.1782 9.63754 12.3626 9.70529 12.5036 9.84079L15.3693 12.6158C15.5958 12.8136 15.6576 13.1128 15.5459 13.3708C15.4342 13.6288 15.1709 13.8 14.8793 13.8L9.12762 13.8C8.83586 13.8 8.57251 13.6314 8.46068 13.3719C8.34885 13.1124 8.39033 12.8136 8.59776 12.6163Z"
                fill="#A4A4A4"
            />
        </svg>
    );
};
export default RoundedArrowUpIcon;
