import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
    GetDeviceInfoDocument,
    GetDeviceInfoQuery,
    GetDeviceInfoQueryVariables,
} from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { LogLevel, LogUtil } from '../../utils/Logging.Util';
import * as Sentry from '@sentry/react';

export class DeviceService {
    constructor(private readonly _apolloClient: ApolloClient<NormalizedCacheObject>) {}

    public async getDeviceInfo(deviceId: string): Promise<DeviceInfo | null> {
        try {
            const queryTuple = await this._apolloClient.query<GetDeviceInfoQuery, GetDeviceInfoQueryVariables>({
                query: GetDeviceInfoDocument,
                variables: {
                    deviceId,
                },
                fetchPolicy: 'network-only',
            });

            if (queryTuple.errors || !queryTuple.data) {
                LogUtil.LogObject(['Error getting device info: ', queryTuple], LogLevel.Error);
            }

            const device = queryTuple.data.device;
            if (!device) {
                return null;
            }
            return {
                id: device.id,
                code: device.code,
                registered: device.registered,
                bigFormatDisplay: device.bigFormatDisplay,
            };
        } catch (err) {
            Sentry.captureException(err, {
                extra: {
                    operationName: 'getDeviceInfo (deviceService)',
                },
            });
            throw new Error(`Error in getDeviceInfo: ${err}`);
        }
    }
}

export interface DeviceInfo {
    id: string;
    code: string;
    registered: boolean;
    bigFormatDisplay: boolean;
}
