import { createElement, FC } from 'react';
import './NonResponsiveStyledText.scss';

export enum NonResponsiveTextStyle {
    Cloudshelf = 'Cloudshelf',
    CloudshelfBold = 'CloudshelfBold',
}

export enum NonResponsiveTextSize {
    ExtraExtraSmall = 'ExtraExtraSmall',
    ExtraSmall = 'ExtraSmall',
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
}

export interface NonResponsiveStyledTextProps {
    style?: NonResponsiveTextStyle;
    size?: NonResponsiveTextSize;
    className?: string;
    center?: boolean;
    translate?: boolean;
}

const NonResponsiveStyledText: FC<NonResponsiveStyledTextProps> = props => {
    return createElement(
        'p',
        {
            className: `NonResponsiveStyledText__${props.style} NonResponsiveStyledText__${props.size} ${
                props.className
            } ${props.center ? 'StyledText__Center' : ''} ${props.translate ? 'WeglotTranslate' : 'NeverTranslate'}`,
        },
        props.children,
    );
};

NonResponsiveStyledText.defaultProps = {
    style: NonResponsiveTextStyle.Cloudshelf,
    size: NonResponsiveTextSize.Medium,
    center: false,
    translate: false,
};

export default NonResponsiveStyledText;
