import { inject, injectable } from 'inversify';
import { Observable } from 'react-use/lib/useObservable';
import { BehaviorSubject } from 'rxjs';
import DependencyType from '../../dependancyInjection/DependencyType';
import PriceService from '../PriceService/PriceService';
import { Basket } from './Basket.type';
import _ from 'lodash';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import {
    Attribute,
    CardBrand,
    CartAddLineItemDocument,
    CartAddLineItemMutation,
    CartAddLineItemMutationVariables,
    CartCreateDocument,
    CartCreateMutation,
    CartCreateMutationVariables,
    CartDetailsFragment,
    CartRemoveLineItemDocument,
    CartRemoveLineItemMutation,
    CartRemoveLineItemMutationVariables,
    CartUpdateDiscountCodesDocument,
    CartUpdateDiscountCodesMutation,
    CartUpdateDiscountCodesMutationVariables,
    CartUpdateLineItemDocument,
    CartUpdateLineItemMutation,
    CartUpdateLineItemMutationVariables,
    CheckoutLineItemInput,
    CurrencyCode,
    DigitalWallet,
    GetPaymentMethodsDocument,
    GetPaymentMethodsQuery,
    GetPaymentMethodsQueryVariables,
    ShopifyCheckoutCreateDocument,
    ShopifyCheckoutCreateMutation,
    ShopifyCheckoutCreateMutationVariables,
    ShopifyCheckoutDiscountApplyDocument,
    ShopifyCheckoutDiscountApplyMutation,
    ShopifyCheckoutDiscountApplyMutationVariables,
} from '../../provider/shopify/graphql/generated/shopify_types';
import ProductVariant from '../ProductServices/variant/ProductVariant';
import { ShopifyDependencyTypes } from '../../provider/shopify/di/ShopifyDependencyTypes';
import { LogLevel, LogUtil } from '../../utils/Logging.Util';
import CurrencyService from '../CurrencyService/CurrencyService';
import {
    CloudshelfCheckoutCreateDocument,
    CloudshelfCheckoutCreateMutation,
    CloudshelfCheckoutCreateMutationVariables,
    CloudshelfUserType,
} from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { BasketPaymentMethod } from './Basket.PaymentMethods.type';
import * as Sentry from '@sentry/browser';
import { getDeviceInfo } from '../../hooks/UseDeviceInfo';
import { inspect } from 'util';
import { BasketMode } from './BasketMode';
import { CustomAttributes } from '../../components/apps/InteractiveApp/components/ProductDetailsView/buildingBlocks/customisation/ProductCustomiserSection';
import { ConfigurationService } from '../ConfigurationService/ConfigurationService';
import { SessionManagementService } from '../SessionManagementService/SessionManagementService';
import { StorageService } from '../StorageService/StorageService';
import { StorageKey } from '../StorageService/StorageKeys.enum';
import { ProductDetails } from '../ProductServices/variant/ProductVariantService';

@injectable()
export class BasketService {
    static REQUIRED_SCOPES = ['unauthenticated_write_checkouts'];
    private readonly basketSubject = new BehaviorSubject<Basket | undefined>(undefined);
    private _basket: Basket | undefined;
    private _cartInfo: CartDetailsFragment | undefined = undefined;
    private _basketMode: BasketMode | undefined = undefined;
    private _addedToBasket = false;

    constructor(
        @inject(DependencyType.PriceService) private readonly priceService: PriceService,
        @inject(ShopifyDependencyTypes.ShopifyApolloClient) private readonly shopifyClient: ApolloClient<InMemoryCache>,
        @inject(DependencyType.ApolloClient) private readonly cloudshelfClient: ApolloClient<InMemoryCache>,
        @inject(DependencyType.ConfigurationService) private readonly configService: ConfigurationService,
        @inject(DependencyType.SessionManagementService) private readonly sessionService: SessionManagementService,
        @inject(DependencyType.StorageService) private readonly storageService: StorageService,
    ) {}

    private createPermalinkCart() {
        this._basketMode = BasketMode.ShopifyPermalink;
        this._basket = {
            lineItems: [],
            discountCodes: [],
            basketMode: BasketMode.ShopifyPermalink,
        };
    }
    private async createCart(): Promise<boolean> {
        const config = this.configService.config();
        if (!config || !config.scopes) {
            return false;
        }
        if (
            this.configService.isUsingCachedConfig ||
            !_.every(BasketService.REQUIRED_SCOPES, scope => _.includes(config.scopes, scope)) ||
            config.userType !== CloudshelfUserType.ShopifyStoreRetailer
        ) {
            LogUtil.LogObject([
                'Using permalink mode',
                {
                    isCached: this.configService.isUsingCachedConfig,
                    missingScopes: !_.every(BasketService.REQUIRED_SCOPES, scope => _.includes(config.scopes, scope)),
                    isWrongUserType: config.userType !== CloudshelfUserType.ShopifyStoreRetailer,
                },
            ]);
            this.createPermalinkCart();
            return true;
        } else {
            try {
                const { errors, data } = await this.shopifyClient.mutate<
                    CartCreateMutation,
                    CartCreateMutationVariables
                >({
                    mutation: CartCreateDocument,
                    variables: {},
                });

                if (errors || !data?.cartCreate?.cart) {
                    console.log('errors', errors);
                    throw new Error('Errors returned by create cart mutation');
                }

                this._cartInfo = data.cartCreate.cart;
                this._basketMode = BasketMode.ShopifyCart;
                this._basket = {
                    lineItems: [],
                    shopifyCartId: this._cartInfo.id,
                    discountCodes: [],
                    basketMode: BasketMode.ShopifyCart,
                };
            } catch {
                console.log('There was a problem creating the cart with shopify, falling back to permalink mode');
                this.createPermalinkCart();
                return false;
            }
        }
        return true;
    }

    private reduceOptions(options: Array<{ __typename?: 'Attribute' } & Pick<Attribute, 'key' | 'value'>>): {
        [p: string]: string;
    } {
        return _.reduce(
            options,
            (acc: { [key: string]: string }, { value, key }) => {
                if (value && key && value !== '') {
                    return { ...acc, [key]: value };
                }
                return acc;
            },
            {},
        );
    }

    private filterOptionsMap(options: CustomAttributes) {
        return _.reduce(
            options,
            (acc: { [key: string]: string }, value: string, key: string) => {
                if (value && value !== '') {
                    return { ...acc, [key]: value };
                }
                return acc;
            },
            {},
        );
    }

    //                                                    Why null you ask? Just apollo being a dickhead...
    public attributesMatchArr(a: CustomAttributes, b: { key?: string | null; value?: string | null }[]) {
        // We have to filter out "empty" values. For now I am not trimming - as there is a chance that some retailers
        // want untrimmed customisations
        const filteredA = this.filterOptionsMap(a);

        // Check length of arrays is same
        const keys = Object.keys(filteredA);
        const filteredB = b.filter(b => b.key && b.value && b.value.length > 0);
        if (keys.length !== filteredB.length) {
            return false;
        }

        // Check each attribute individually
        for (const key of keys) {
            const value = filteredA[key];
            const found = filteredB.find(b => b.key === key && b.value === value);
            if (!found) {
                return false;
            }
        }

        return true;
    }

    public attributesMatch(a: CustomAttributes, b: CustomAttributes) {
        // Filtering as per above
        const filteredA = this.filterOptionsMap(a);
        const filteredB = this.filterOptionsMap(b);

        // Check lengths
        const keysA = Object.keys(filteredA);
        const keysB = Object.keys(filteredB);
        if (keysA.length !== keysB.length) {
            return false;
        }

        // Check individual attributes
        for (const key of keysA) {
            const value = filteredA[key];
            const matches = key in filteredB && filteredB[key] === value;
            if (!matches) {
                return false;
            }
        }

        return true;
    }

    async getItemQuantity(variantId: string, customAttributes: CustomAttributes): Promise<number> {
        if (!this._basket) {
            return 0;
        }

        const item = this._basket.lineItems.find(
            item => item.productVariant.id === variantId && this.attributesMatch(customAttributes, item.attributes),
        );

        return item ? item.quantity : 0;
    }

    async setItemQuantity(
        variant: ProductVariant,
        quantity: number,
        customOptions: CustomAttributes,
        title?: string,
        maxQuantity?: number,
    ): Promise<void> {
        if (quantity === 0) {
            return;
        }

        if (!this._basket) {
            try {
                console.log('No cart, creating one');
                await this.createCart();
            } catch (e) {
                console.log('Cart could not be created.', e);
            }
        }

        if (!this._basket) {
            console.log('No basket, returning');
            return;
        }

        this._addedToBasket = true;
        // Map custom attributes to a map of key/value pairs
        const attributes = _.map(Object.keys(customOptions), key => ({ key, value: customOptions[key] }));

        // If checkout mode, update the cart on shopify and refresh our local cartinfo
        if (this._basketMode === BasketMode.ShopifyCart) {
            if (!this._cartInfo) {
                return;
            }
            const existingLineItem = this._cartInfo.lines.nodes.find(
                line => line.merchandise.id === variant.id && this.attributesMatchArr(customOptions, line.attributes),
            );
            if (existingLineItem) {
                const { data, errors } = await this.shopifyClient.mutate<
                    CartUpdateLineItemMutation,
                    CartUpdateLineItemMutationVariables
                >({
                    mutation: CartUpdateLineItemDocument,
                    variables: {
                        cartId: this._cartInfo.id,
                        lines: [
                            {
                                attributes,
                                merchandiseId: existingLineItem.merchandise.id,
                                id: existingLineItem.id,
                                quantity,
                            },
                        ],
                    },
                });

                if (!data?.cartLinesUpdate?.cart || errors) {
                    console.error(errors);
                    return;
                }

                this._cartInfo = data.cartLinesUpdate?.cart;
            } else {
                const { data, errors } = await this.shopifyClient.mutate<
                    CartAddLineItemMutation,
                    CartAddLineItemMutationVariables
                >({
                    mutation: CartAddLineItemDocument,
                    variables: {
                        cartId: this._cartInfo.id,
                        lines: [
                            {
                                attributes,
                                merchandiseId: variant.id,
                                quantity,
                            },
                        ],
                    },
                });

                if (!data?.cartLinesAdd?.cart || errors) {
                    console.error(errors);
                    return;
                }

                this._cartInfo = data.cartLinesAdd?.cart;
            }

            if (this._cartInfo) {
                this.synchroniseItems(variant, title, maxQuantity);
            }
        } else {
            const existingLineItem = this._basket.lineItems.find(
                item => item.productVariant.id === variant.id && this.attributesMatch(customOptions, item.attributes),
            );
            if (existingLineItem) {
                existingLineItem.quantity = quantity;
            } else {
                this._basket.lineItems.push({
                    productVariant: variant,
                    quantity,
                    title: title || variant.name,
                    attributes: customOptions,
                    maxQuantity: maxQuantity ?? Number.MAX_VALUE,
                });
            }
        }

        LogUtil.LogObject(this._basket);
        this.propagateChanges();
    }

    private synchroniseItems(variant: ProductVariant, title?: string, maxQuantity?: number) {
        if (!this._basket || !this._cartInfo) {
            return;
        }
        // Ensure this._basket.lineItems matches this._cartInfo.lines.nodes
        const cartItems = this._cartInfo.lines.nodes;
        for (const cartItem of cartItems) {
            const item = this._basket.lineItems.find(
                item =>
                    item.productVariant.id === cartItem.merchandise.id &&
                    this.attributesMatch(this.reduceOptions(cartItem.attributes), item.attributes),
            );
            if (item) {
                item.quantity = cartItem.quantity;
            } else {
                this._basket.lineItems.push({
                    productVariant: variant,
                    attributes: this.reduceOptions(cartItem.attributes),
                    quantity: cartItem.quantity,
                    title: title ?? cartItem.merchandise.product.title,
                    maxQuantity: maxQuantity ?? Number.MAX_VALUE,
                });
            }
        }

        // Remove any items from this._basket.lineItems that are not in this._cartInfo.lines.nodes
        this._basket.lineItems = this._basket.lineItems.filter(
            item =>
                this._cartInfo?.lines.nodes.find(
                    cartItem =>
                        cartItem.merchandise.id === item.productVariant.id &&
                        this.attributesMatch(this.reduceOptions(cartItem.attributes), item.attributes),
                ) !== undefined,
        );
    }

    async removeItem(variant: ProductVariant, customAttributes: CustomAttributes): Promise<void> {
        if (!this._basket) {
            return;
        }

        if (this._basketMode === BasketMode.ShopifyCart) {
            if (!this._cartInfo) {
                return;
            }

            const lineItem = this._cartInfo.lines.nodes.find(
                line =>
                    line.merchandise.id === variant.id && this.attributesMatchArr(customAttributes, line.attributes),
            );

            if (!lineItem) {
                return;
            }

            const { data, errors } = await this.shopifyClient.mutate<
                CartRemoveLineItemMutation,
                CartRemoveLineItemMutationVariables
            >({
                mutation: CartRemoveLineItemDocument,
                variables: {
                    cartId: this._cartInfo.id,
                    lineIds: [lineItem.id],
                },
            });

            if (!data?.cartLinesRemove?.cart || errors) {
                console.error(errors);
                return;
            }

            this._cartInfo = data.cartLinesRemove.cart;

            this.synchroniseItems(variant);
            this.propagateChanges();
        } else {
            // Remove from our local basket info
            const newBasket = _.cloneDeep(this._basket);
            newBasket.lineItems = newBasket.lineItems.filter(
                item =>
                    item.productVariant.id !== variant.id || !this.attributesMatch(customAttributes, item.attributes),
            );
            this.propagateChanges({ basket: newBasket });
        }
    }

    get basket(): Basket | undefined {
        return this._basket;
    }

    async getPaymentMethods(): Promise<BasketPaymentMethod[]> {
        const paymentMethods: BasketPaymentMethod[] = [];

        const { data } = await this.shopifyClient.query<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>({
            query: GetPaymentMethodsDocument,
            variables: {},
        });

        if (data.shop.paymentSettings.acceptedCardBrands.includes(CardBrand.AmericanExpress)) {
            paymentMethods.push(BasketPaymentMethod.AmericanExpress);
        }

        if (data.shop.paymentSettings.acceptedCardBrands.includes(CardBrand.DinersClub)) {
            paymentMethods.push(BasketPaymentMethod.DinersClub);
        }

        if (data.shop.paymentSettings.acceptedCardBrands.includes(CardBrand.Discover)) {
            paymentMethods.push(BasketPaymentMethod.Discover);
        }

        if (data.shop.paymentSettings.acceptedCardBrands.includes(CardBrand.Jcb)) {
            paymentMethods.push(BasketPaymentMethod.JCB);
        }

        if (data.shop.paymentSettings.acceptedCardBrands.includes(CardBrand.Mastercard)) {
            paymentMethods.push(BasketPaymentMethod.Mastercard);
        }

        if (data.shop.paymentSettings.acceptedCardBrands.includes(CardBrand.Visa)) {
            paymentMethods.push(BasketPaymentMethod.Visa);
        }

        if (data.shop.paymentSettings.supportedDigitalWallets.includes(DigitalWallet.ShopifyPay)) {
            paymentMethods.push(BasketPaymentMethod.ShopPay);
        }

        if (data.shop.paymentSettings.supportedDigitalWallets.includes(DigitalWallet.ApplePay)) {
            paymentMethods.push(BasketPaymentMethod.ApplePay);
        }

        if (data.shop.paymentSettings.supportedDigitalWallets.includes(DigitalWallet.GooglePay)) {
            paymentMethods.push(BasketPaymentMethod.GooglePay);
        }

        if (data.shop.paymentSettings.supportedDigitalWallets.includes(DigitalWallet.AndroidPay)) {
            paymentMethods.push(BasketPaymentMethod.AndroidPay);
        }

        return paymentMethods;
    }

    empty(): void {
        this._basket = undefined;
        this._cartInfo = undefined;
        this._addedToBasket = false;
        this.basketSubject.next(this.basket);
    }

    // public method - don't delete
    setBasketAndCartInfo(basket?: Basket, cartInfo?: CartDetailsFragment): void {
        this.propagateChanges({ basket, cartInfo });
    }

    totalQuantity(): number {
        if (!this._basket) {
            return 0;
        }

        const productCustomiserPriceModifierVariant = this.configService.productCustomiserPriceModifierVariant;

        return _.sumBy(this._basket.lineItems, lineItem => {
            if (
                productCustomiserPriceModifierVariant &&
                lineItem.productVariant.id === productCustomiserPriceModifierVariant.id
            ) {
                return 0;
            } else {
                return lineItem.quantity;
            }
        });
    }

    get basketMode(): BasketMode | undefined {
        return this._basketMode;
    }

    private cartLineItemsTotalPrice(): number {
        if (this._basketMode === BasketMode.ShopifyCart && this._cartInfo) {
            return this._cartInfo.estimatedCost.totalAmount.amount;
            // return _.sumBy(this._cartInfo.lines.nodes, lineItem => +lineItem.estimatedCost.totalAmount.amount);
        }

        return 0;
    }

    private cartLineItemsSubTotalPrice(): number {
        if (this._basketMode === BasketMode.ShopifyCart && this._cartInfo) {
            return this._cartInfo.estimatedCost.subtotalAmount.amount;
            // return _.sumBy(this._cartInfo.lines.nodes, lineItem => +lineItem.estimatedCost.subtotalAmount.amount);
        }

        return 0;
    }

    totalPrice(): string {
        if (!this._basket) {
            return '';
        }
        if (this._basketMode === BasketMode.ShopifyCart && this._cartInfo) {
            const totalLineItemCost = this.cartLineItemsTotalPrice();
            return this.priceService.getPriceFromMoneyV2({
                amount: totalLineItemCost,
                currencyCode: this._cartInfo.estimatedCost.totalAmount.currencyCode,
            });
        } else {
            return this.checkoutLineItemTotal();
        }
    }

    sessionInfo(): { price: number; currencyCode: string; addedToBasket: boolean } {
        if (!this._basket) {
            return {
                price: 0,
                currencyCode: '',
                addedToBasket: this._addedToBasket,
            };
        }
        if (this._basketMode === BasketMode.ShopifyCart && this._cartInfo) {
            const totalLineItemCost = this.cartLineItemsTotalPrice();
            return {
                price: totalLineItemCost,
                currencyCode: this._cartInfo.estimatedCost.totalAmount.currencyCode,
                addedToBasket: this._addedToBasket,
            };
        } else {
            return {
                price: this.checkoutLineItemTotalNum(),
                currencyCode: CurrencyService.currencyCode.toString(),
                addedToBasket: this._addedToBasket,
            };
        }
    }

    subTotalPrice(): string {
        if (!this._basket) {
            return '';
        }
        if (this._basketMode === BasketMode.ShopifyCart && this._cartInfo) {
            const subTotalLineItemCost = this.cartLineItemsSubTotalPrice();
            return this.priceService.getPriceFromMoneyV2({
                amount: subTotalLineItemCost,
                currencyCode: this._cartInfo.estimatedCost.subtotalAmount.currencyCode,
            });
        } else {
            return this.checkoutLineItemTotal();
        }
    }

    private checkoutLineItemTotalNum(): number {
        if (!this._basket) {
            return 0;
        }

        // sum prices multiplied by quantities of lineItems in basket
        return _.sumBy(this._basket.lineItems, lineItem => lineItem.quantity * lineItem.productVariant.price);
    }

    private checkoutLineItemTotal() {
        if (!this._basket) {
            return '';
        }

        // sum prices multiplied by quantities of lineItems in basket
        return CurrencyService.format(
            _.sumBy(this._basket.lineItems, lineItem => lineItem.quantity * lineItem.productVariant.price),
        );
    }

    estimatedTax(): string {
        console.log('cart Info', this._cartInfo);
        if (!this._basket) {
            return '';
        }
        if (this._basketMode === BasketMode.ShopifyCart && this._cartInfo) {
            if (this._cartInfo.estimatedCost.totalTaxAmount?.amount > 0) {
                return this.priceService.getPriceFromMoneyV2({
                    amount: Math.abs(this._cartInfo.estimatedCost.totalTaxAmount?.amount ?? 0),
                    currencyCode: this._cartInfo?.estimatedCost.totalAmount.currencyCode ?? CurrencyCode.Gbp,
                });
            }
        }
        return '';
    }

    discount(): string {
        console.log('cart Info', this._cartInfo);
        if (!this._basket) {
            return '';
        }
        if (this._basketMode === BasketMode.ShopifyCart) {
            const diff = this.cartLineItemsSubTotalPrice() - this.cartLineItemsTotalPrice();

            if (diff > 0) {
                return this.priceService.getPriceFromMoneyV2({
                    amount: -Math.abs(diff),
                    currencyCode: this._cartInfo?.estimatedCost.totalAmount.currencyCode ?? CurrencyCode.Gbp,
                });
            }
        }
        return '';
    }

    observeBasket(): Observable<Basket | undefined> {
        return this.basketSubject.asObservable();
    }

    getCheckoutUrl(): string {
        return this._cartInfo?.checkoutUrl;
    }

    getCartInfo(): CartDetailsFragment | undefined {
        return this._cartInfo;
    }

    /**
     * Update the applied coupon code in the cart, and return whether the coupon code was valid.
     * Note: Shopify supports multiple discounts, our design doesn't.
     * @param couponCode the coupon code to set.
     */
    async setCouponCode(couponCode: string): Promise<boolean> {
        if (this._basketMode === BasketMode.ShopifyCart && this._cartInfo) {
            const { data, errors } = await this.shopifyClient.mutate<
                CartUpdateDiscountCodesMutation,
                CartUpdateDiscountCodesMutationVariables
            >({
                mutation: CartUpdateDiscountCodesDocument,
                variables: {
                    cartId: this._cartInfo.id,
                    discountCodes: [couponCode],
                },
            });

            if (errors || !data?.cartDiscountCodesUpdate?.cart?.discountCodes) {
                return false;
            }
            this.propagateChanges({ cartInfo: data?.cartDiscountCodesUpdate.cart });
            LogUtil.LogObject(['check for coupons:', inspect(this._cartInfo?.discountCodes), this._cartInfo]);

            const discounts = data?.cartDiscountCodesUpdate.cart.discountCodes;
            if (discounts.length === 0) {
                return false;
            }

            return discounts[0].applicable;
        }
        return false;
    }

    async convertToCheckout(): Promise<{ id: string; url: string; ourId: string | undefined } | undefined> {
        // Can only convert to checkout if we have a cart
        if (!this._basketMode || this._basketMode !== BasketMode.ShopifyCart || !this._cartInfo) {
            LogUtil.LogObject({ basketmod: this._basketMode, cartinfo: this._cartInfo });
            return;
        }

        // Map cart lineitems to checkout lineitems
        const checkoutLineItems: CheckoutLineItemInput[] = this._cartInfo.lines.nodes.map(lineItem => {
            return {
                variantId: lineItem.merchandise.id,
                quantity: lineItem.quantity,
                customAttributes: _.map(lineItem.attributes, attribute => {
                    return {
                        key: attribute.key,
                        value: attribute.value ?? '',
                    };
                }),
            };
        });

        const discountCodes = _.compact(_.map(this._cartInfo.discountCodes, code => code.code));

        const cloudshelfName: string = this.configService.config()?.name ?? 'UNKNOWN';
        let originatingStore: string | null = null;
        let deviceName: string | null = null;

        if (this.configService.isDevice()) {
            deviceName = this.configService.config()?.device?.name ?? null;
            if (this.configService.config()?.device?.location) {
                originatingStore = this.configService.config()?.device?.location?.name ?? null;
            } else {
                originatingStore = 'UNASSIGNED';
            }
        }

        let salesAssistant = 'UNASSIGNED';

        if (this.configService.config()?.retailerRules.allocateSalesToAssignedSalesPerson) {
            const storedAssociate = this.storageService.get(StorageKey.SALES_ASSOCIATE_ID);
            if (storedAssociate) {
                const salesPerson = this.configService
                    .config()
                    ?.teamMembers.find(member => member.id === storedAssociate);
                salesAssistant = salesPerson ? salesPerson.reportingValue : 'UNASSIGNED';
            }
        }

        const showInternalDeviceWarning = this.configService.isInternalDevice();

        // Create checkout
        const { data, errors } = await this.shopifyClient.mutate<
            ShopifyCheckoutCreateMutation,
            ShopifyCheckoutCreateMutationVariables
        >({
            mutation: ShopifyCheckoutCreateDocument,
            variables: {
                lineItems: checkoutLineItems,
                customAttributes: [
                    { key: 'CS_Cloudshelf', value: cloudshelfName },
                    { key: 'CS_Device', value: deviceName ?? 'N/A - PREVIEW MODE' },
                    { key: 'CS_OriginatingStore', value: originatingStore ?? 'N/A - PREVIEW MODE' },
                    { key: 'CS_SalesAssistant', value: salesAssistant },
                ],
                note: showInternalDeviceWarning
                    ? 'This purchase was contracted on a Cloudshelf test device, please confirm order with customer'
                    : undefined,
            },
        });

        if (!data?.checkoutCreate?.checkout || errors) {
            LogUtil.LogObject('Shopify returned null checkout', LogLevel.Error);

            return;
        }

        if (discountCodes.length > 0) {
            LogUtil.Log('Applying discounts');
            // Apply each code
            for (const discountCode of discountCodes) {
                await this.shopifyClient.mutate<
                    ShopifyCheckoutDiscountApplyMutation,
                    ShopifyCheckoutDiscountApplyMutationVariables
                >({
                    mutation: ShopifyCheckoutDiscountApplyDocument,
                    variables: {
                        checkoutId: data.checkoutCreate.checkout.id,
                        discountCode,
                    },
                });
            }
        }

        if (!data?.checkoutCreate?.checkout || errors) {
            LogUtil.LogObject('Shopify returned null checkout', LogLevel.Error);

            return;
        }

        // Send checkout data to our API
        const config = this.configService.config();
        const deviceInfo = getDeviceInfo();
        let ourCheckoutId: string | undefined = undefined;

        if (this.sessionService.currentSessionId && config && deviceInfo.id) {
            try {
                LogUtil.LogObject('Creating cs checkout', LogLevel.Warn);

                const { data: checkoutData, errors: checkoutErrors } = await this.cloudshelfClient.mutate<
                    CloudshelfCheckoutCreateMutation,
                    CloudshelfCheckoutCreateMutationVariables
                >({
                    mutation: CloudshelfCheckoutCreateDocument,
                    variables: {
                        cloudshelfId: config.id,
                        deviceId: deviceInfo.id,
                        sessionId: this.sessionService.currentSessionId,
                        shopifyCheckoutGid: data.checkoutCreate.checkout.id,
                        checkoutValue: parseFloat(data.checkoutCreate.checkout.totalPrice.amount),
                        currencyCode: data.checkoutCreate.checkout.totalPrice.currencyCode,
                    },
                });

                if (checkoutErrors) {
                    // noinspection ExceptionCaughtLocallyJS
                    throw new Error('Failed to create checkout');
                }

                ourCheckoutId = checkoutData?.createCheckout?.id;
                LogUtil.LogObject(['Created checkout in API', checkoutData?.createCheckout.id]);
            } catch (e) {
                LogUtil.LogObject(e, LogLevel.Error);
                Sentry.captureException(e, {
                    extra: {
                        operationName: 'convertToCheckout',
                    },
                });
            }
        } else {
            LogUtil.Log('No session id - possibly in preview mode', LogLevel.Warn);
        }

        return {
            id: data.checkoutCreate.checkout.id,
            url: data.checkoutCreate.checkout.webUrl,
            ourId: ourCheckoutId,
        };
    }

    propagateChanges(input?: { basket?: Basket; cartInfo?: CartDetailsFragment }) {
        if (input?.basket) {
            this._basket = _.cloneDeep(input.basket);
        } else {
            // Need to change object ref so rxjs can detect change
            this._basket = _.cloneDeep(this._basket);
        }
        if (input?.cartInfo) {
            this._cartInfo = _.cloneDeep(input.cartInfo);
        }

        // Propagate
        this.basketSubject.next(this._basket);
    }
}
