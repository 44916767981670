import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ProductAvailability.module.scss';
import { useInjection } from '../../../dependancyInjection/DependencyContext';
import { ConfigurationService } from '../../../services/ConfigurationService/ConfigurationService';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { ProductVariantAvailability } from '../../../services/ProductServices/ProductVariantAvailability';
import ProductVariant from '../../../services/ProductServices/variant/ProductVariant';
import StockLabel from '../../apps/InteractiveApp/components/StockLabel/StockLabel';
import StyledText, { TextSize, TextStyle } from '../StyledText/StyledText';
import { AutoSizedValues } from '../StyledText/AutoSizedStyledText';

export interface ProductAvailabilityProps {
    availableForSale: boolean;
    limitedAvailability: boolean;
    orderOnly: boolean;
    selectedVariant?: ProductVariant;
    allVariantsUnavailable?: boolean;
    totalInventory?: number;
    displayStockCount?: boolean;
    enforcedTextSize?: AutoSizedValues;
}
const ProductAvailability: FC<ProductAvailabilityProps> = props => {
    const { t } = useTranslation();
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    let availabilityLabel: ProductVariantAvailability;

    let productAvailability: ProductVariantAvailability = ProductVariantAvailability.Unavailable;

    if (!props.availableForSale) {
        productAvailability = ProductVariantAvailability.Unavailable;
    } else if (props.limitedAvailability) {
        productAvailability = ProductVariantAvailability.LimitedAvailability;
    } else if (props.orderOnly) {
        productAvailability = ProductVariantAvailability.OnOrder;
    } else if (props.availableForSale) {
        productAvailability = ProductVariantAvailability.InStock;
    }

    if (props.selectedVariant !== undefined) {
        availabilityLabel = props.selectedVariant.availability;
    } else {
        availabilityLabel = productAvailability;
    }

    const classNames = [styles.ProductAvailability__sectionStock];
    if (props.selectedVariant?.inventory === 0) {
        classNames.push(styles.ProductAvailability__unavailable);
    } else if (
        productAvailability === ProductVariantAvailability.LimitedAvailability &&
        !props.allVariantsUnavailable &&
        props.selectedVariant === undefined
    ) {
        classNames.push(styles.ProductAvailability__limited);
    } else if (props.selectedVariant?.availability === ProductVariantAvailability.InStock) {
        classNames.push(styles.ProductAvailability__inStock);
    } else if (!props.selectedVariant && productAvailability === ProductVariantAvailability.InStock) {
        classNames.push(styles.ProductAvailability__inStock);
    } else if (!props.selectedVariant) {
        classNames.push(styles.ProductAvailability__unavailable);
    } else if (productAvailability === ProductVariantAvailability.OnOrder) {
        classNames.push(styles.ProductAvailability__unavailable);
    }

    return (
        <>
            <span className={styles.ProductAvailability__flex}>
                <StockLabel availability={availabilityLabel} enforcedTextSize={props.enforcedTextSize} />
                {configService.config()?.showTotalStockCount && props.displayStockCount && (
                    <StyledText
                        className={classNames.join(' ')}
                        style={TextStyle.Subheading}
                        size={TextSize.Small}
                        translate
                    >
                        {t('product_view.available', {
                            count: props.selectedVariant ? props.selectedVariant.inventory : props.totalInventory,
                        })}
                    </StyledText>
                )}
            </span>
        </>
    );
};

ProductAvailability.defaultProps = {
    displayStockCount: false,
};

export default ProductAvailability;
