import { FC, useRef, useState } from 'react';
import { useInjection } from '../../../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../../../dependancyInjection/DependencyType';
import { ConfigurationService } from '../../../../../../../services/ConfigurationService/ConfigurationService';
import {
    getGridGutterValue,
    getGridMinWidthInCMValue,
    getGridSmallestDimensionValue,
    getMinTouchPointValue,
    getResponsiveRuleMinMaxValue,
    getSmallestDimensionValue,
    removeGridGutterValue,
    removeGridMinWidthInCMValue,
    removeGridSmallestDimensionValue,
    removeMinTouchPointValue,
    removeSmallTouchPointValue,
    setGridGutterValue,
    setGridMinWidthInCMValue,
    setGridSmallestDimensionValue,
    setMinTouchPointValue,
    setResponsiveRuleMinMaxValue,
    setSmallestDimensionValue,
} from '../../../../../../../utils/Responsive.Util';

const ResponsiveOverlayComponent: FC = () => {
    //Page
    const [minTouchPoint, setSmallestTouchPoint] = useState(getMinTouchPointValue());
    const [smallestDimension, setSmallestDimension] = useState(getSmallestDimensionValue());
    const [minMax, setMinMax] = useState(getResponsiveRuleMinMaxValue());
    //
    const [gridMinWidthCM, setGridMinWidthCM] = useState(getGridMinWidthInCMValue());
    const [gridSmallestDimension, setGridSmallestDimension] = useState(getGridSmallestDimensionValue());
    const [gridGutter, setGridGutter] = useState(getGridGutterValue());

    const handleResetPage = () => {
        removeMinTouchPointValue();
        removeSmallTouchPointValue();
        window.location.reload();
    };

    const handleSubmitPage = () => {
        const minMaxTrimmed = minMax.trim().toLowerCase();
        if (minMaxTrimmed !== 'min' && minMaxTrimmed !== 'max') {
            alert('Responsive Rule Min/Max must be either "min" or "max"');
            return;
        }
        setMinTouchPointValue(minTouchPoint);
        setSmallestDimensionValue(smallestDimension);
        setResponsiveRuleMinMaxValue(minMaxTrimmed);
        window.location.reload();
    };

    const handleResetGrid = () => {
        removeGridMinWidthInCMValue();
        removeGridSmallestDimensionValue();
        removeGridGutterValue();
        window.location.reload();
    };

    const handleSubmitGrid = () => {
        setGridMinWidthInCMValue(gridMinWidthCM);
        setGridSmallestDimensionValue(gridSmallestDimension);
        setGridGutterValue(gridGutter);
        window.location.reload();
    };

    return (
        <div
            style={{ display: 'flex', gap: '1vh', flexDirection: 'column', overflow: 'scroll' }}
            onClick={event => {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
            }}
        >
            <h1>Responsive Debug Overlay</h1>
            <strong>Page Values</strong>
            <div style={{ display: 'flex', gap: '1vw', flexDirection: 'row' }}>
                <p>Smallest Touch Point (Menu Content Height (buttons and search box in CM): </p>
                <input
                    type="number"
                    value={minTouchPoint}
                    step=".01"
                    onChange={e => setSmallestTouchPoint(parseFloat(e.target.value))}
                />
                <p>Smallest Dimension Modifier: </p>
                <input
                    type="number"
                    value={smallestDimension}
                    step=".01"
                    onChange={e => setSmallestDimension(parseFloat(e.target.value))}
                />
                <p>Min or Max?</p>
                <input type="text" value={minMax} onChange={e => setMinMax(e.target.value)} />
                <button onClick={handleSubmitPage}>Change Responsive Page Rule</button>
                <button onClick={handleResetPage}>Reset Page</button>
            </div>
            <strong>Grid Values</strong>
            <div style={{ display: 'flex', gap: '1vw', flexDirection: 'row' }}>
                <p>Grid Item Min Width in CM: </p>
                <input
                    type="number"
                    value={gridMinWidthCM}
                    step=".01"
                    onChange={e => setGridMinWidthCM(parseFloat(e.target.value))}
                />
                <p>Smallest Dimension Modifier: </p>
                <input
                    type="number"
                    value={gridSmallestDimension}
                    step=".01"
                    onChange={e => setGridSmallestDimension(parseFloat(e.target.value))}
                />
                <p>Gutter Modifier: </p>
                <input type="number" value={gridGutter} onChange={e => setGridGutter(parseFloat(e.target.value))} />
                <button onClick={handleSubmitGrid}>Change Responsive Grid Rule</button>
                <button onClick={handleResetGrid}>Reset Grid</button>
            </div>
        </div>
    );
};

export default ResponsiveOverlayComponent;
