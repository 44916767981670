import React, { useCallback } from 'react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import PriceService from '../../../../../services/PriceService/PriceService';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import Quantity from '../Quantity/Quantity';
import { CartLineItem } from '../../../../../services/BasketService/Basket.type';
import ProductVariant from '../../../../../services/ProductServices/variant/ProductVariant';
import { BasketService } from '../../../../../services/BasketService/BasketService';

import './BasketListItem.scss';
import _ from 'lodash';
import { CustomAttributes } from '../ProductDetailsView/buildingBlocks/customisation/ProductCustomiserSection';
import { CloudflareImageService } from '../../../../../services/CloudflareImageService/CloudflareImageService';
import { getSizedImageURL } from '../../../../../utils/ImageURL.Util';

export type OnBasketItemQuantityChanged = (
    variant: ProductVariant,
    customAttributes: CustomAttributes,
    newQuantity: number,
    oldQuantity: number,
) => void;

export type OnBasketItemRemoved = (variant: ProductVariant, customAttributes: CustomAttributes) => void;

export interface BasketListItemProps {
    item: CartLineItem;
    onQuantityChanged: OnBasketItemQuantityChanged;
    onBasketItemRemoved: OnBasketItemRemoved;
    setLoading: (loading: boolean) => void;
    loading: boolean;
    productCustomiserVariant: ProductVariant | undefined;
}

export const BasketListItem: React.FC<BasketListItemProps> = props => {
    const { item, onQuantityChanged, setLoading, onBasketItemRemoved } = props;
    const { productVariant, quantity, title } = item;
    const priceService = useInjection<PriceService>(DependencyType.PriceService);
    const cloudflareImageService = useInjection<CloudflareImageService>(DependencyType.CloudflareImageService);
    const optionsListItem = productVariant.options
        .filter(option => {
            return option.value.toLowerCase() !== productVariant.name.toLowerCase();
        })
        .map(option => (
            <h3 className="BasketListItem__optionsListItem" key={`${productVariant.id}-optionItem-${option.name}`}>
                {option.name}: {option.value}
            </h3>
        ));
    const attributesListItem = _.map(Object.keys(item.attributes), key => (
        <h3 className="BasketListItem__optionsListItem" key={`${productVariant.id}-attribute-${key}`}>
            {key}:{' '}
            {cloudflareImageService.isCloudflareUrl(item.attributes[key]) ? '[Uploaded Image]' : item.attributes[key]}
        </h3>
    ));

    const handleQuantityChange = useCallback(
        async (updatedQuantity: number, oldQuantity: number) => {
            await setLoading(true);
            await onQuantityChanged(item.productVariant, item.attributes, updatedQuantity, oldQuantity);
            await setLoading(false);
        },
        [item, onQuantityChanged],
    );

    const handleRemove = async () => {
        await setLoading(true);
        await onBasketItemRemoved(productVariant, item.attributes);
        await setLoading(false);
    };

    let shouldShowQuantityControl = true;

    if (props.productCustomiserVariant) {
        if (productVariant.id === props.productCustomiserVariant.id) {
            shouldShowQuantityControl = false;
        }
    }

    const price = priceService.getPrice(productVariant, quantity);
    return (
        <li className="BasketListItem">
            {productVariant.image && (
                <div
                    className="BasketListItem__image"
                    style={{ backgroundImage: `url('${getSizedImageURL(productVariant.image, 250, 250)}')` }}
                />
            )}
            <div className="BasketListItem__details">
                <h2 className="BasketListItem__name">
                    {title}
                    {_.trim(_.toLower(productVariant.name)) !== 'default title' && (
                        <span>&nbsp;({productVariant.name})</span>
                    )}
                </h2>
                {optionsListItem.length > 0 && <ul className="BasketListItem__optionsList">{optionsListItem}</ul>}
                {attributesListItem.length > 0 && <ul className="BasketListItem__optionsList">{attributesListItem}</ul>}
                {shouldShowQuantityControl && (
                    <Quantity
                        className="BasketListItem__quantity"
                        initialQuantity={quantity}
                        onQuantityChanged={handleQuantityChange}
                        onRemove={handleRemove}
                        loading={props.loading}
                        variant={'inline'}
                        overrideQuantity={quantity}
                        maxQuantity={item.maxQuantity}
                    />
                )}
            </div>
            <h2 className="BasketListItem__price">{price}</h2>
        </li>
    );
};
