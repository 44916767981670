import React, { FC } from 'react';
const PlusIcon: FC = props => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.5 11.25H11.25V17.5C11.25 18.1875 10.6875 18.75 10 18.75C9.3125 18.75 8.75 18.1875 8.75 17.5V11.25H2.5C1.8125 11.25 1.25 10.6875 1.25 10C1.25 9.3125 1.8125 8.75 2.5 8.75H8.75V2.5C8.75 1.8125 9.3125 1.25 10 1.25C10.6875 1.25 11.25 1.8125 11.25 2.5V8.75H17.5C18.1875 8.75 18.75 9.3125 18.75 10C18.75 10.6875 18.1875 11.25 17.5 11.25Z"
                fill="black"
                stroke="black"
            />
        </svg>
    );
};
export default PlusIcon;
