import React, { ReactElement } from 'react';
import {
    GetHandleProps,
    GetRailProps,
    GetTrackProps,
    Handles,
    Rail,
    Slider as CompoundSlider,
    SliderItem,
    Ticks,
    Tracks,
} from 'react-compound-slider';
import { ReactComponent as HandleIcon } from './slider_handle.svg';

interface SliderRailProps {
    getRailProps: GetRailProps;
}

const SliderRail: React.FC<SliderRailProps> = ({ getRailProps }) => {
    return (
        <>
            <div className="SliderRail__outer" {...getRailProps()} />
            <div className="SliderRail__inner" />
        </>
    );
};

interface HandleProps {
    domain: number[];
    handle: SliderItem;
    getHandleProps: GetHandleProps;
    disabled?: boolean;
    active?: boolean;
}

const Handle: React.FC<HandleProps> = ({
    domain: [min, max],
    handle: { id, value, percent },
    disabled = false,
    active = false,
    getHandleProps,
}) => {
    return (
        <HandleIcon
            className={`SliderHandle ${disabled ? 'SliderHandle--disabled' : ''} ${
                active ? 'SliderHandle--active' : ''
            }`}
            role="slider"
            aria-valuemin={min}
            aria-valuemax={max}
            aria-valuenow={value}
            style={{
                left: `${percent}%`,
            }}
            {...getHandleProps(id)}
        />
    );
};

interface TrackProps {
    source: SliderItem;
    target: SliderItem;
    getTrackProps: GetTrackProps;
    disabled?: boolean;
}

const Track: React.FC<TrackProps> = ({ source, target, getTrackProps, disabled = false }) => {
    return (
        <div
            className={`SliderTrack ${disabled ? 'SliderTrack--disabled' : ''}`}
            style={{
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`,
            }}
            {...getTrackProps()}
        />
    );
};

interface TickProps {
    tick: SliderItem;
    count: number;
    format?: (val: number) => string;
}

const Tick: React.FC<TickProps> = ({ tick, count, format = d => d }) => {
    return (
        <div className="SliderTick">
            <div
                className="SliderTick__tick"
                style={{
                    left: `${tick.percent}%`,
                }}
            />
            <div
                className="SliderTick__label"
                style={{
                    marginLeft: `${-(100 / count) / 2}%`,
                    width: `${100 / count}%`,
                    left: `${tick.percent}%`,
                }}
            >
                {format(tick.value)}
            </div>
        </div>
    );
};

export type SliderChangeCallback = (values: ReadonlyArray<number>) => void;

export interface SliderProps {
    className?: string;
    steps?: number;
    ticks?: number;
    domain: number[];
    onChange?: SliderChangeCallback;
    onUpdate?: SliderChangeCallback;
    values: number[];
}
//steps 0.2 allow decimals in our usual 0.2 standard formatting.
export const Slider: React.FC<SliderProps> = ({
    values,
    className = '',
    domain,
    onChange,
    onUpdate,
    steps = 0.2,
    ticks: ticksCount = 2,
}) => {
    return (
        <CompoundSlider
            mode={1}
            step={steps}
            domain={domain}
            className={`Slider ${className}`}
            onUpdate={onUpdate}
            onChange={onChange}
            values={values}
        >
            <Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
            <Handles>
                {({ handles, getHandleProps, activeHandleID }) => (
                    <div className="Slider__handles">
                        {handles.map(handle => (
                            <Handle
                                key={handle.id}
                                handle={handle}
                                domain={domain}
                                active={handle.id === activeHandleID}
                                getHandleProps={getHandleProps}
                            />
                        ))}
                    </div>
                )}
            </Handles>
            <Tracks left={false} right={false}>
                {({ tracks, getTrackProps }) => (
                    <div className="Slider__tracks">
                        {tracks.map(({ id, source, target }) => (
                            <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                        ))}
                    </div>
                )}
            </Tracks>
            <Ticks count={ticksCount}>
                {({ ticks }) => {
                    if (
                        ticks.length === ticksCount - 2 &&
                        ticks[0].percent !== 0 &&
                        ticks[ticks.length - 1].percent !== 100
                    ) {
                        // Compound Slider adds round values by default, and often the bottom and top of the domain will
                        // be missing; we add them manually.
                        ticks.splice(0, 0, { id: `$$-${domain[0]}`, value: domain[0], percent: 0 });
                        ticks.push({ id: `$$-${domain[1]}`, value: domain[1], percent: 100 });
                    }
                    return (
                        <div className="Slider__ticks">
                            {ticks.map(tick => {
                                return <Tick key={tick.id} tick={tick} count={ticksCount} />;
                            })}
                        </div>
                    );
                }}
            </Ticks>
        </CompoundSlider>
    );
};
