import { FC } from 'react';
import styles from './StarsBackground.module.scss';

export interface StarsBackgroundProps {
    showLogo?: boolean;
}

const StarsBackground: FC<StarsBackgroundProps> = props => {
    return (
        <div className={styles.html}>
            <div className={styles.stars} />
            <div className={styles.stars2} />
            <div className={styles.stars3} />
            <div className={styles.container}>
                <div className={styles.children}>{props.children}</div>
                {props.showLogo && (
                    <div className={styles.logo}>
                        <img src="/logos/cloudshelf_logo_secondary.svg" alt="Cloudshelf" style={{ height: '5vh' }} />
                    </div>
                )}
            </div>
        </div>
    );
};

StarsBackground.defaultProps = {
    showLogo: true,
} as Partial<StarsBackgroundProps>;

export default StarsBackground;
