import TextInput from '../../../../../../../shared/inputs/TextInput/TextInput';
import React from 'react';
import { ProductCustomiserField } from '../ProductCustomiserSection';
import './CustomisationFields.scss';
export interface SingleLineTextProps {
    field: ProductCustomiserField;
    onChange: (value: string) => void;
    errorText?: string;
}

const SingleLineText: React.FC<SingleLineTextProps> = props => {
    const [value, setValue] = React.useState<string>();
    const { field, onChange, errorText } = props;

    const handleChange = (val: string) => {
        setValue(val);
        onChange(val);
    };

    return (
        <div className={'PcGenericWrapper'}>
            <TextInput
                variant="solid"
                maxLength={field.char_count === -1 ? undefined : field.char_count}
                onChange={handleChange}
                placeholder={field.placeholder}
                errorText={errorText}
                onClear={() => handleChange('')}
                suffixLabel={
                    field.char_count !== -1 && value?.length ? `${value?.length}/${field.char_count}` : undefined
                }
            />
        </div>
    );
};

export default SingleLineText;
