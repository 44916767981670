import { FC } from 'react';
import { useInjection } from '../../../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../../../dependancyInjection/DependencyType';
import { ConfigurationService } from '../../../../../../../services/ConfigurationService/ConfigurationService';

const ConfigOverlayComponent: FC = () => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    return (
        <>
            Config Status: {configService.status()} (Is Cached: {configService.isUsingCachedConfig ? 'Yes' : 'No'})
            <br />
            Provider Config: {JSON.stringify(configService.providerConfig())}
            <br />
            Cloudshelf ID: {configService.cloudshelfId ? configService.cloudshelfId : 'No Cloudshelf'}
        </>
    );
};

export default ConfigOverlayComponent;
