import { FC } from 'react';

import styles from './ProductPrice.module.scss';

export interface ProductPriceProps {
    originalPriceString?: string;
    priceString?: string;
}

const ProductPrice: FC<ProductPriceProps> = props => {
    return (
        <>
            {props.originalPriceString && (
                <h2 className={styles.ProductPrice__price}>
                    <span className={styles.ProductPrice__originalPrice}>{props.originalPriceString}</span>
                    <br />
                    <span className={styles.ProductPrice__newPrice}>{props.priceString}</span>
                </h2>
            )}

            {!props.originalPriceString && <h2 className={styles.ProductPrice__price}>{props.priceString}</h2>}
            <hr />
        </>
    );
};

export default ProductPrice;
