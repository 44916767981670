import { createElement, CSSProperties, FC } from 'react';
import './StyledText.css';

export enum TextStyle {
    Cloudshelf = 'Cloudshelf',
    CloudshelfBold = 'CloudshelfBold',
    Body = 'Body',
    Subheading = 'Subheading',
    Heading = 'Heading',
}

export enum TextSize {
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
    Hero = 'Hero',
}

export interface StyledTextProps {
    style?: TextStyle;
    size?: TextSize;
    className?: string;
    center?: boolean;
    translate?: boolean;
    fontSize?: number;
    noWrap?: boolean;
}

const StyledText: FC<StyledTextProps> = props => {
    const fontStyle: CSSProperties = {
        fontSize: props.fontSize + 'px',
        lineHeight: props.fontSize + 'px',
        whiteSpace: props.noWrap ? 'nowrap' : 'normal',
    };

    return createElement(
        props.style === TextStyle.Heading ? 'h1' : 'p',
        {
            className: `StyledText__${props.style} StyledText__${props.size} ${props.className} ${
                props.center ? 'StyledText__Center' : ''
            } ${props.translate ? 'WeglotTranslate' : 'NeverTranslate'}`,
            style: fontStyle,
        },
        props.children,
    );
};

StyledText.defaultProps = {
    style: TextStyle.Body,
    size: TextSize.Small,
    center: false,
    translate: false,
};

export default StyledText;
