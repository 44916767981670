import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { AnalyticsService } from '../../../services/Analytics/AnalyticsService';
import { useInjection } from '../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { CheckoutView } from '../../../components/apps/InteractiveApp/components/Checkout/CheckoutView';
import { PurchaseCallback } from '../../../components/apps/InteractiveApp/components/Purchase/PurchaseCallback';
import { PurchaseView } from '../../../components/apps/InteractiveApp/components/Purchase/PurchaseView';
import { Action, Category } from '../../../services/Analytics/entities/Analytics';
import { BasketService } from '../../../services/BasketService/BasketService';
import { Basket } from '../../../services/BasketService/Basket.type';
import {
    SlidingModal,
    SlidingModalOrigin,
} from '../../../components/apps/InteractiveApp/components/SlidingModal/SlidingModal';
import { SessionManagementService } from '../../../services/SessionManagementService/SessionManagementService';
import { SessionEventType } from '../../../services/SessionManagementService/SessionEvent';

export interface PurchaseProps {
    checkout: () => void;
    purchase: PurchaseCallback;
}

const PurchaseContext = createContext<PurchaseProps | null>(null);

export const PurchaseProvider: React.FC = ({ children }) => {
    const sessionManagementService = useInjection<SessionManagementService>(DependencyType.SessionManagementService);
    const analyticsService = useInjection<AnalyticsService>(DependencyType.AnalyticsService);
    const basketService = useInjection<BasketService>(DependencyType.BasketService);
    const [purchasingVisible, setPurchasingVisible] = useState(false);
    const [checkoutVisible, setCheckoutVisible] = useState(false);
    const [purchasingBasket, setPurchasingBasket] = useState<Basket | null>(null);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        if (sessionManagementService) {
            const observer = sessionManagementService.observe().subscribe(event => {
                if (event.type === SessionEventType.Ended) {
                    setCheckoutVisible(false);
                    setPurchasingVisible(false);
                    setPurchasingBasket(null);
                }
            });

            return () => observer.unsubscribe();
        }
    }, [sessionManagementService]);

    const onPurchase = useCallback(
        (basket: Basket) => {
            if (basket.lineItems.length === 0) {
                // Provided basket is empty, cannot be purchased.
                return;
            }
            setPurchasingBasket(basket);
            setPurchasingVisible(true);
            analyticsService.recordEvent({
                category: Category.PURCHASE,
                action: Action.OPENED,
                label: basketService.totalPrice(),
                value: basketService.totalQuantity(),
            });
        },
        [analyticsService, basketService],
    );
    const onPurchaseClosed = useCallback(() => {
        setPurchasingVisible(false);
        analyticsService.recordEvent({ category: Category.PURCHASE, action: Action.CLOSED });
    }, [analyticsService]);

    const onCheckout = () => {
        setPurchasingVisible(false);
        setCheckoutVisible(true);
        analyticsService.recordEvent({
            category: 'checkout',
            action: Action.OPENED,
            label: purchasingBasket != null ? basketService.totalPrice() : undefined,
            value: purchasingBasket != null ? basketService.totalQuantity() : undefined,
        });
    };

    const onCheckoutClosed = useCallback(() => {
        setCheckoutVisible(false);
        setPurchasingVisible(false);
        analyticsService.recordEvent({ category: 'checkout', action: Action.CLOSED });
    }, [analyticsService]);

    return (
        <PurchaseContext.Provider value={{ purchase: onPurchase, checkout: onCheckout }}>
            <SlidingModal isOpen={checkoutVisible} origin={SlidingModalOrigin.BOTTOM} fullWidth fullHeight>
                <CheckoutView
                    isOpen={checkoutVisible}
                    onClose={onCheckoutClosed}
                    setLoading={setLoading}
                    onPurchase={onPurchase}
                    loading={loading}
                />
            </SlidingModal>
            <SlidingModal
                origin={SlidingModalOrigin.BOTTOM}
                rounded
                isOpen={purchasingVisible}
                transparent={!purchasingVisible}
                fullWidth
            >
                {purchasingVisible && (
                    <PurchaseView basket={purchasingBasket} onCloseButtonClicked={onPurchaseClosed} />
                )}
            </SlidingModal>

            {children}
        </PurchaseContext.Provider>
    );
};

export const usePurchase = (): PurchaseProps => {
    const purchaseContext = useContext(PurchaseContext);
    if (purchaseContext === null) {
        throw new Error('Are you accessing the purchase context outside of its children?');
    }
    return purchaseContext;
};
