import { injectable } from 'inversify';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
    AttributeValue,
    DeviceMode,
    EngineProductWithAdditionalInfoEdge,
    FilterType,
    GetEngineProductsDocument,
    GetEngineProductsQuery,
    GetEngineProductsQueryVariables,
} from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import _ from 'lodash';
import {
    CATEGORY_FILTER,
    CATEGORY_FILTER_ID,
    CLEAR_ALL_FILTERS,
    CLEAR_ALL_FILTERS_ID,
    NAME_FILTER,
    NAME_FILTER_ID,
} from '../../provider/cloudshelf/filter/CloudshelfFilters';
import { List } from 'immutable';
import {
    CloudshelfProductsSearchResult,
    makeSearchResult,
} from '../../provider/cloudshelf/filter/remote/CloudshelfProductsSearchResult';
import { StorageService } from '../StorageService/StorageService';
import * as Sentry from '@sentry/react';
import { Observable, Subject } from 'rxjs';
import { LogUtil } from '../../utils/Logging.Util';
import { StorageKey } from '../StorageService/StorageKeys.enum';
import loki from 'lokijs';
import { FilterableProductVariant, LocalProduct, LocalProductImage } from './LocalProduct';
import { ImageTransformInput } from '../../provider/shopify/graphql/generated/shopify_types';
import { dependenciesContainer } from '../../dependancyInjection/DependenciesInitializer';
import DependencyType from '../../dependancyInjection/DependencyType';
import { ProductSearchResultWithCursor } from '../../provider/cloudshelf/common/ProductSearchResultWithCursor';
import { CloudshelfEngineFilter } from '../ConfigurationService/types/filters/CloudshelfEngineFilter';
import { ConfigurationService } from '../ConfigurationService/ConfigurationService';
import { Category } from '../CategoryService/entities/Category';
import { PRELOAD_IMAGE_SIZE } from '../../hooks/contexts/Dimensions/Dimensions';
import { MapToFilterableProductVariants } from '../../utils/ProductBinary.Util';
import {
    buildBuiltInSortOrder_PriceAsc,
    buildBuiltInSortOrder_PriceDesc,
    buildBuiltInSortOrder_Relevance,
    buildOrderByFilter,
    getPriceRange,
} from '../../utils/EngineFilter.Util';
import { SentryUtil } from '../../utils/Sentry.Util';
import { generateMatchingAllInputRegex } from '../../utils/String.Util';
import bannerPane from '../../components/shared/BannerPane/BannerPane';
import { makeProductDetails, ProductDetails } from './variant/ProductVariantService';
import ShopifyVariantParser from '../../provider/shopify/variant/ShopifyVariantParser';
import ProductVariantOption from './variant/ProductVariantOption';
import { ProductVariantAvailability } from './ProductVariantAvailability';
import ProductVariant, { ProductVariantParams } from './variant/ProductVariant';

export type ProductsFetchOptions = {
    cursor?: number | string;
    limit: number;
    imageTransform?: ImageTransformInput;
    sort?: [keyof FilterableProductVariant, boolean][];
    randomSort?: boolean;
};

export type ProductsFetchResult = {
    // products: List<Product>;
    products: ProductSearchResultWithCursor[];
    hasMore: boolean;
};

export interface FilterSelection {
    mergeDefinitionId: string;
    definitionId: string;
    name: string;
    type?: FilterType;
    values: string[];
}

export const RANGE_FILTER_TYPES = [FilterType.Price];
export const UNFILTERED_COUNT = -1;
export const STOCK_LEVEL_IN_STOCK = 'in stock';
export const STOCK_LEVEL_ORDER_ONLY = 'order only';
export const STOCK_LEVEL_OUT_OF_STOCK = 'out of stock';

@injectable()
export class ProductFilteringService {
    private _matchingCount = -1;
    private _filterSelectionSubject: Subject<FilterSelection[]> = new Subject<FilterSelection[]>();
    private _filterViewSelectionSubject: Subject<FilterSelection[]> = new Subject<FilterSelection[]>();
    private _meaningfulChangedSubject: Subject<void> = new Subject<void>();
    private _lokiStateSubject: Subject<void> = new Subject<void>();
    private _lokiCacheForCloudshelfId: string | undefined = undefined;
    private _lokiCacheProductCount = 0;
    private _lokiDatabase: loki | undefined;
    private _lokiProductCache: Collection<FilterableProductVariant>;
    private _overrideMeaningfulAttributeValues: { filterName: string; visibleAttributeValues: AttributeValue[] }[] = [];
    private _meaningFULFilters: CloudshelfEngineFilter[] = [];

    constructor(
        private readonly _apolloClient: ApolloClient<NormalizedCacheObject>,
        private readonly _configService: ConfigurationService,
        private readonly _storageService: StorageService,
        private filterSelection: FilterSelection[] = [],
        private filterViewSelection: FilterSelection[] = [],
        private _preselection: FilterSelection[] = [],
        private _previousFilterSelection: FilterSelection[] = [],
    ) {
        this.clearSelection(true);
        this._configService.observe().subscribe(() => {
            // Update the preselection if a new config comes in and we don't have any filters selected
            if (this._preselection.length === 0 && this.filterSelection.length === 0) {
                this.clearSelection(true);
            }
        });
        this._lokiDatabase = new loki('cloudshelf.db');
        this._lokiProductCache = this._lokiDatabase.addCollection<FilterableProductVariant>('product_variants');
        this._overrideMeaningfulAttributeValues = [];
        this._meaningFULFilters = this._configService.displayableFilters;
    }

    public clearPrice() {
        // Remove price
        this._overrideMeaningfulAttributeValues = _.filter(
            this._overrideMeaningfulAttributeValues,
            v => v.filterName !== 'Price',
        );
    }

    public get isCacheValidForCloudshelf(): boolean {
        return this._lokiCacheForCloudshelfId === this._configService.cloudshelfId;
    }

    public get isLocalCachePopulated(): boolean {
        return this._lokiCacheProductCount !== 0;
    }

    public get lokiCacheForCloudshelfId(): string | undefined {
        return this._lokiCacheForCloudshelfId;
    }

    public get searchValue(): string {
        //get the filter from the list that has the id: NAME_FILTER_ID
        const nameFilter = this.filterSelection.find(f => f.definitionId === NAME_FILTER_ID);
        if (!nameFilter) {
            return '';
        }

        return nameFilter.values[0];
    }
    public observeFilterSelectionState(): Observable<FilterSelection[]> {
        return this._filterSelectionSubject.asObservable();
    }

    public observeFilterViewSelectionState(): Observable<FilterSelection[]> {
        return this._filterViewSelectionSubject.asObservable();
    }

    public observeMeaningfulFilterState(): Observable<void> {
        return this._meaningfulChangedSubject.asObservable();
    }

    public observeLokiState(): Observable<void> {
        return this._lokiStateSubject.asObservable();
    }

    private findAndSetProductCustomiserPriceModifierVariant() {
        const productDetails = this.getProductDetailsByHandle('product-customizer-item-customizations', true);
        let variant: ProductVariant | undefined = undefined;

        if (productDetails) {
            if (productDetails.variants.count() > 0) {
                variant = productDetails.variants.first();
            }
        }

        this._configService.setProductCustomiserPriceModifierVariant(variant);
    }

    public async updateLokiCache(
        loadFromLocallyCachedVersion = true,
        progressCallback?: (progress: number, translationKey: string, translationOptions?: any) => void,
        explicitProductHandle?: string,
    ): Promise<void> {
        const sendProgress = (progress: number, translationKey: string, translationOptions?: any) => {
            console.info(`[Loki Product Cache]: ${progress}% - ${translationKey}`);
            if (progressCallback) {
                progressCallback(progress, translationKey, translationOptions);
            }
        };

        try {
            const cloudshelfId = this._configService.cloudshelfId;

            if (!cloudshelfId) {
                // Can't get products until the cloudshelf id is known,
                // I don't think this should ever happen... but just to be safe
                LogUtil.Log('No cloudshelf ID');
                this._lokiStateSubject.next();

                return;
            }

            sendProgress(1, 'Loading products from local cache');
            if (loadFromLocallyCachedVersion) {
                const loadTrans = SentryUtil.StartTransaction('FilterService.LoadCacheIntoLokiCache', false);
                this._lokiCacheForCloudshelfId = this._storageService.get(StorageKey.CLOUDSHELF_ID);
                await this.loadCacheIntoLokiCache();
                SentryUtil.EndSpan(loadTrans.newTransaction);
            }
            sendProgress(10, 'Checking for product updates');
            let cursor: string | undefined = undefined;
            let hasMore = true;
            let totalProductsOnBackend = 0;
            let totalLoadedProducts = 0;
            let loadedFilterableProductVariants: FilterableProductVariant[] = [];
            let includeMetafieldNamespaces: string[] = ['product_customizer', 'product_customizer_x', 'bookthatapp'];

            const metafieldFilters = this._configService
                .config()
                ?.filters.filter(f => f.type === FilterType.ShopifyMetafield);

            _.map(metafieldFilters ?? [], f => {
                if (f.metafieldNamespace) {
                    includeMetafieldNamespaces.push(f.metafieldNamespace);
                }
            });

            const pdpMetafields = this._configService
                .config()
                ?.pdpBlocks?.filter(b => b.unionTypeName === 'PDPBlockMetafield');

            _.map(pdpMetafields ?? [], f => {
                if (f.namespace) {
                    includeMetafieldNamespaces.push(f.namespace);
                }
            });

            includeMetafieldNamespaces = _.uniq(includeMetafieldNamespaces);

            do {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const { data, errors } = await this._apolloClient.query<
                    GetEngineProductsQuery,
                    GetEngineProductsQueryVariables
                >({
                    variables: {
                        cloudshelfId,
                        includeMetafieldNamespaces,
                        isDisplayMode: this._configService.deviceMode === DeviceMode.DisplayOnly,
                        explicitProductHandle,
                        first: 250,
                        after: cursor,
                    },
                    query: GetEngineProductsDocument,
                    fetchPolicy: 'network-only',
                });

                cursor = data.engineProducts.pageInfo.endCursor ?? undefined;
                hasMore = data.engineProducts.pageInfo?.hasNextPage ?? false;
                totalProductsOnBackend = data.engineProducts.totalCount;

                const nodes: LocalProduct[] =
                    data.engineProducts.edges?.map((e: EngineProductWithAdditionalInfoEdge) => e.node) ?? [];

                loadedFilterableProductVariants.push(
                    ...MapToFilterableProductVariants(nodes, this._configService.config()?.filters ?? []),
                );
                totalLoadedProducts = totalLoadedProducts + nodes.length;
                sendProgress(
                    10 + (70 * totalLoadedProducts) / totalProductsOnBackend,
                    `Loaded ${totalLoadedProducts}/${totalProductsOnBackend} products so far`,
                );

                if (errors) {
                    LogUtil.LogObject(['Something went wrong', errors]);
                    return;
                }
            } while (hasMore);

            sendProgress(82, `Checking for Product Customiser Product`);

            const { data: prodCustomiserProdData, errors } = await this._apolloClient.query<
                GetEngineProductsQuery,
                GetEngineProductsQueryVariables
            >({
                variables: {
                    cloudshelfId,
                    includeMetafieldNamespaces,
                    isDisplayMode: this._configService.deviceMode === DeviceMode.DisplayOnly,
                    explicitProductHandle: 'product-customizer-item-customizations',
                },
                query: GetEngineProductsDocument,
                fetchPolicy: 'network-only',
            });

            const productCustomiserLocalProducts: LocalProduct[] = [];
            const productCustomiserEdges = prodCustomiserProdData.engineProducts.edges;

            if (productCustomiserEdges) {
                productCustomiserEdges.map(edge => {
                    if (edge.node) {
                        productCustomiserLocalProducts.push(edge.node as LocalProduct);
                    }
                });
            }

            loadedFilterableProductVariants.push(
                ...MapToFilterableProductVariants(
                    productCustomiserLocalProducts,
                    this._configService.config()?.filters ?? [],
                ),
            );

            sendProgress(85, `Saving ${totalProductsOnBackend} to local cache`);
            //Now we should save it to the cache
            const putTrans = SentryUtil.StartTransaction('FilterService.PutProductCache', false);
            //Save this into LocalStorage so it can be loaded for when the backend if offline
            await this._storageService.putProductCache(loadedFilterableProductVariants);
            SentryUtil.EndSpan(putTrans.newTransaction);
            sendProgress(90, 'Saved to local cache, now updating Loki cache');

            //Now we load it into the Loki cache
            const populateTrans = SentryUtil.StartTransaction('FilterService.PopulateLoki', false);
            console.log('Old Loki Cache Product Count: ' + this._lokiProductCache.count());
            this._lokiProductCache.clear();
            console.log('Cleared Loki Cache Product Count: ' + this._lokiProductCache.count());
            this._lokiProductCache.insert(loadedFilterableProductVariants);
            SentryUtil.EndSpan(populateTrans.newTransaction);
            sendProgress(95, 'Loki cache updated');

            //Set the cache product count AND the cloudshelfID so the SetupWrapper knows it can continue
            this._lokiCacheProductCount = this._lokiProductCache.count();
            console.log('New Loki Cache Product Count: ' + this._lokiProductCache.count());
            this._lokiCacheForCloudshelfId = cloudshelfId;
            this._storageService.put(StorageKey.CLOUDSHELF_ID, this._configService.cloudshelfId ?? '');
            LogUtil.Log(
                '[Loki Product Cache] Updated Cache from server. Loki Cache Size: ' + this._lokiCacheProductCount,
            );
            sendProgress(98, 'Wrapping things up');
            loadedFilterableProductVariants = [];
            sendProgress(100, 'Wrapping things up');

            this.findAndSetProductCustomiserPriceModifierVariant();
            this._lokiStateSubject.next();
        } catch (err) {
            Sentry.captureException(err, {
                extra: {
                    operationName: 'updateLokiCache',
                },
            });
        }
    }

    public totalNumberOfProductsInCache(): number {
        return this._lokiCacheProductCount;
    }

    async loadCacheIntoLokiCache() {
        const filterableProductVariants = await this._storageService.getProductCache();
        if (filterableProductVariants.length === 0) {
            return;
        }
        this._lokiProductCache.clear();
        this._lokiProductCache.insert(filterableProductVariants);
        this._lokiCacheProductCount = this._lokiProductCache.count();
        LogUtil.Log(`[Loki Product Cache] Loaded ${filterableProductVariants.length} from IndexedDB via Dexie`);
        this.findAndSetProductCustomiserPriceModifierVariant();
        this._lokiStateSubject.next();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public visibleFilterOptions(): CloudshelfEngineFilter[] {
        return this._meaningFULFilters;
    }

    public get preselection() {
        return _.cloneDeep(this._preselection);
    }

    clearSelection(commit = false) {
        const filters = this._configService.config()?.filters;
        const preselectionToCommit: FilterSelection[] = [];
        if (filters) {
            const stockFilter = _.find(filters, filter => filter.type === FilterType.StockLevel);
            const sortFilter = _.find(filters, filter => filter.type === FilterType.SortOrder);
            if (!stockFilter && !sortFilter) {
                this.filterViewSelection = [];
            } else {
                if (stockFilter) {
                    preselectionToCommit.push({
                        definitionId: stockFilter.id,
                        name: stockFilter.displayName,
                        type: FilterType.StockLevel,
                        values: stockFilter.attributeValues.map(av => av.value),
                        mergeDefinitionId: stockFilter.id,
                    });
                }

                if (sortFilter) {
                    preselectionToCommit.push({
                        definitionId: sortFilter.id,
                        name: sortFilter.displayName,
                        type: FilterType.SortOrder,
                        values: ['relevance'],
                        mergeDefinitionId: sortFilter.id,
                    });
                }

                this._preselection = preselectionToCommit;
                this.filterViewSelection = this.preselection;
            }
        } else {
            this.filterViewSelection = [];
        }
        this._matchingCount = -1;
        if (commit) {
            this.commitSelection();
        }
        this._meaningFULFilters = this._configService.displayableFilters;
        this._previousFilterSelection = [];
        this.debounceFilterViewSelectionSubject();
    }

    commitSelection() {
        this.filterSelection = [...this.filterViewSelection];
        this.debounceFilterSelectionSubject();
    }

    clearSingleFilter(definitionId: string) {
        const currentViewSelection = this.getCurrentViewSelection();
        _.remove(currentViewSelection, filter => filter.definitionId === definitionId);
        this.filterViewSelection = currentViewSelection;
        this.debounceFilterViewSelectionSubject();
    }

    get getSubcategoryFilterItem(): CloudshelfEngineFilter | undefined {
        const currentSelection = this.filterSelection ?? [];
        const filters = _.orderBy(this._meaningFULFilters ?? [], value => value.priority);

        //Find the first filter that is does not have any values in the currentSelection and has at least 2 values
        const subCategoryFilter = _.find(filters, filter => {
            const isBlacklistedType = _.includes(
                [FilterType.Price, FilterType.StockLevel, FilterType.SortOrder],
                filter.type,
            );

            const isMerged = filter.isMergedChild;

            const matchingSelection = _.find(currentSelection, selection => selection.definitionId === filter.id);
            return (
                !isMerged && !isBlacklistedType && matchingSelection === undefined && filter.attributeValues.length > 1
            );
        });

        return subCategoryFilter;
    }

    getProductVariantByHandle(handle: string): FilterableProductVariant | undefined {
        return this._lokiProductCache.findOne({ productHandle: handle }) ?? undefined;
    }

    getProductDetailsByHandle(handle: string, includeProductsOutOfStock: true): ProductDetails | undefined {
        let variants = this._lokiProductCache.find({ productHandle: handle });
        variants = _.compact(
            _.map(variants, variant => {
                if (!includeProductsOutOfStock && variant.currentlyNotInStock && !variant.availableForSale) {
                    return null;
                }
                return variant;
            }),
        );

        if (variants.length === 0) {
            return undefined;
        }

        const firstVariant = variants[0];
        let firstFeatureImage: string | undefined = undefined;

        _.map(variants, variant => {
            if (variant.featuredImage && firstFeatureImage === undefined) {
                firstFeatureImage = variant.featuredImage;
            }
        });

        const availableForSale = _.some(variants, variant => variant.availableForSale);
        const totalInventory = _.sumBy(variants, variant => variant.sellableOnlineQuantity);
        const limitedAvailability = !_.every(variants, variant => variant.availableForSale);
        const orderOnly = _.every(variants, variant => variant.currentlyNotInStock);

        const images: LocalProductImage[] = _.map(firstVariant.images, image => ({
            url: image.url,
            variantId: image.variantId,
        }));

        let productVariants = _.map(variants, variant => this.createProductVariantFromFilterable(variant, images));
        productVariants = _.uniqBy(productVariants, variant => variant.id);

        const details = makeProductDetails({
            title: firstVariant.productTitle,
            availableForSale: availableForSale,
            totalInventory: totalInventory,
            orderOnly: orderOnly,
            limitedAvailability: limitedAvailability,
            vendor: firstVariant.productVendor,
            handle,
            id: firstVariant.shopifyProductId,
            variants: List(productVariants),
            descriptionHtml: firstVariant.productDescription,
            featuredImage: firstFeatureImage ?? '',
            images,
        });

        return details;
    }

    private createProductVariantFromFilterable(
        variant: FilterableProductVariant,
        allImages: LocalProductImage[],
    ): ProductVariant {
        const options: ProductVariantOption[] = [];

        for (const key in variant.options) {
            if (Object.prototype.hasOwnProperty.call(variant.options, key)) {
                const value = variant.options[key];
                const option = new ProductVariantOption({ name: key, value });
                options.push(option);
            }
        }

        let availability = ProductVariantAvailability.InStock;
        if (!variant.availableForSale) {
            availability = ProductVariantAvailability.Unavailable;
        } else if (variant.currentlyNotInStock) {
            availability = ProductVariantAvailability.OnOrder;
        }

        let image: string | undefined = undefined;
        const imageForVariant = _.find(allImages, image => image.variantId === variant.shopifyVariantId);
        if (imageForVariant) {
            image = imageForVariant.url;
        } else {
            image = variant.featuredImage;
        }

        if (!image) {
            if (allImages.length > 0) {
                image = allImages[0].url;
            }
        }

        const params: ProductVariantParams = {
            id: variant.shopifyVariantId,
            name: variant.title,
            price: variant.price,
            originalPrice: variant.originalPrice ?? variant.price,
            image,
            sku: variant.sku,
            options,
            availability,
            inventory: variant.sellableOnlineQuantity,
        };

        return new ProductVariant(params);
    }

    get getSubcategoryFilterSelection(): FilterSelection | undefined {
        const subCategoryFilter = this.getSubcategoryFilterItem;

        if (!subCategoryFilter) {
            return undefined;
        }

        return _.find(
            this.getCurrentSelection(),
            selectedFilter => selectedFilter.definitionId === subCategoryFilter.id,
        );
    }

    get isUsingSubcategoryFilter(): boolean {
        return this.getSubcategoryFilterItem !== undefined;
    }

    getChipDisplayValue(definitionId: string, internalValue: string): string {
        const filters = this._configService.config()?.filters ?? [];

        const matchingFilter = _.find(filters, filter => filter.id === definitionId);
        if (matchingFilter) {
            const matchingValue = _.find(
                matchingFilter.valueOverrides,
                override => override.originalValue === internalValue,
            );

            if (matchingValue && !_.isEmpty(matchingValue.displayValue)) {
                return matchingValue.displayValue;
            }

            return internalValue;
        } else {
            if (definitionId === CATEGORY_FILTER_ID) {
                const categoryByHandle = this._configService.categories.find(c => c.handle === internalValue);
                if (categoryByHandle) {
                    return categoryByHandle.title;
                } else {
                    return internalValue;
                }
            }
        }

        return internalValue;
    }

    findChildDefinitionsByParentAndValue(parentDefinitionId: string, value: string): CloudshelfEngineFilter[] {
        const filters = this._configService.config()?.filters ?? [];

        const childrenOfParent = _.filter(filters, f => f.isMergedChild && f.parentId === parentDefinitionId);

        return _.filter(childrenOfParent, child => _.some(child.attributeValues, av => av.value === value));
    }

    findDefinitions(definitionId: string): CloudshelfEngineFilter[] {
        const filters = this._configService.config()?.filters ?? [];

        return _.filter(filters, filter => filter.id === definitionId);
    }

    toggleValue(mergeDefinitionId: string, definitionId: string, filterName: string, type: FilterType, value: string) {
        let currentViewSelection = this.getCurrentViewSelection();
        const existingFilterSelection = _.find(
            currentViewSelection,
            filterSelection => filterSelection.definitionId === definitionId,
        );
        if (existingFilterSelection && existingFilterSelection.values) {
            if (_.includes(existingFilterSelection.values, value)) {
                existingFilterSelection.values = _.filter(existingFilterSelection.values, eV => eV !== value);
                if (existingFilterSelection.values.length === 0) {
                    currentViewSelection = _.filter(
                        currentViewSelection,
                        filter => filter.definitionId !== definitionId,
                    );
                }
            } else {
                existingFilterSelection.values = _.concat(existingFilterSelection.values, value);
            }
        } else {
            currentViewSelection.push({
                mergeDefinitionId,
                definitionId,
                name: filterName,
                type,
                values: [value],
            });
        }
        this.filterViewSelection = currentViewSelection;
        this.debounceFilterViewSelectionSubject();
    }

    toggleSingleValue(
        mergeDefinitionId: string,
        definitionId: string,
        filterName: string,
        type: FilterType,
        value: string,
    ) {
        const currentViewSelection = this.getCurrentViewSelection();
        const existingFilterSelection = _.find(
            currentViewSelection,
            filterSelection => filterSelection.definitionId === definitionId,
        );
        if (existingFilterSelection && existingFilterSelection.values) {
            if (_.includes(existingFilterSelection.values, value)) {
                this.removeSpecificValue(definitionId, filterName, value);
            } else {
                existingFilterSelection.values = [value];
                this.filterViewSelection = currentViewSelection;
                this.debounceFilterViewSelectionSubject();
            }
        } else {
            currentViewSelection.push({
                mergeDefinitionId,
                definitionId,
                name: filterName,
                type,
                values: [value],
            });
            this.filterViewSelection = currentViewSelection;
            this.debounceFilterViewSelectionSubject();
        }
    }

    setStringValue(definitionId: string, filterName: string, type: FilterType, value: string, pushToFront?: boolean) {
        const currentViewSelection = this.getCurrentViewSelection();
        const existingFilterSelection = _.find(
            currentViewSelection,
            filterSelection => filterSelection.definitionId === definitionId,
        );
        if (existingFilterSelection && existingFilterSelection.values) {
            existingFilterSelection.values = [value];
        } else {
            const newFilter = {
                mergeDefinitionId: definitionId,
                definitionId,
                name: filterName,
                type,
                values: [value],
            };
            if (pushToFront) {
                currentViewSelection.unshift(newFilter);
            } else {
                currentViewSelection.push(newFilter);
            }
        }
        this.filterViewSelection = currentViewSelection;
        this.debounceFilterViewSelectionSubject();
    }

    setRangeValue(definitionId: string, filterName: string, type: FilterType, min: number, max: number) {
        const currentViewSelection = this.getCurrentViewSelection();
        const existingFilterSelection = _.find(
            currentViewSelection,
            filterSelection => filterSelection.definitionId === definitionId,
        );
        if (existingFilterSelection) {
            existingFilterSelection.values = [min.toString(), max.toString()];
        } else {
            currentViewSelection.push({
                mergeDefinitionId: definitionId,
                definitionId,
                name: filterName,
                type,
                values: [min.toString(), max.toString()],
            });
        }
        this.filterViewSelection = currentViewSelection;
        this.debounceFilterViewSelectionSubject();
    }

    refreshViewSelection() {
        this.filterViewSelection = _.cloneDeep(this.filterSelection);
        if (this.filterViewSelection.length === 0 && this.preselection.length > 0) {
            this.filterViewSelection = _.cloneDeep(this.preselection);
        }
        this.debounceFilterViewSelectionSubject();
    }

    getCurrentViewSelection(): FilterSelection[] {
        return _.cloneDeep(this.filterViewSelection);
    }

    getCurrentSelection(): FilterSelection[] {
        return _.cloneDeep(this.filterSelection);
    }

    getSelectedRangeFilters(): FilterSelection[] {
        const cloned = this.getCurrentSelection();
        return _.filter(cloned, filter => _.includes(RANGE_FILTER_TYPES, filter.type));
    }

    getAllFilterItemsAsSingleValues(): FilterSelection[] {
        const allValueFilterItems = _.chain(this.getSelectedValueFilters())
            .map(filter =>
                _.map(filter.values, val => ({
                    mergeDefinitionId: filter.mergeDefinitionId,
                    definitionId: filter.definitionId,
                    name: filter.name,
                    values: [val],
                    type: filter.type,
                })),
            )
            .flatten()
            .value();
        const allRangeFilterItems = _.chain(this.getSelectedRangeFilters())
            .map(filter => ({
                mergeDefinitionId: filter.mergeDefinitionId,
                definitionId: filter.definitionId,
                name: filter.name,
                values: [getPriceRange(filter.values, 2)],
                type: filter.type,
            }))
            .value();

        return _.concat(
            [
                ...(allRangeFilterItems.length > 0 || allValueFilterItems.length > 0
                    ? [
                          {
                              definitionId: CLEAR_ALL_FILTERS_ID,
                              name: CLEAR_ALL_FILTERS,
                              values: ['Clear All'],
                              mergeDefinitionId: CLEAR_ALL_FILTERS_ID,
                              type: undefined,
                          },
                      ]
                    : []),
            ],
            allValueFilterItems,
            allRangeFilterItems,
        );
    }

    getBreadcrumbFilterItems(): FilterSelection[] {
        const preselection = this.preselection;
        const cloned = this.getCurrentSelection();
        const returnable: FilterSelection[] = [];

        _.map(cloned, filter => {
            if (filter.mergeDefinitionId !== filter.definitionId) {
                return null;
            }

            const isClearAll = filter.name === CLEAR_ALL_FILTERS;
            const isSearch = filter.name === NAME_FILTER;

            if (isClearAll || isSearch) {
                return null;
            }

            const preselectedFilter = _.find(preselection, s => s.definitionId === filter.definitionId);
            if (preselectedFilter) {
                return null;
            }

            returnable.push(filter);
        });

        return returnable;
    }

    getSelectedValueFilters(): FilterSelection[] {
        const cloned = this.getCurrentSelection();
        return _.filter(cloned, filter => !_.includes(RANGE_FILTER_TYPES, filter.type) && filter.values.length > 0);
    }

    removeSpecificValue(definitionId: string, filterName: string, value: string) {
        const currentViewSelection = this.getCurrentViewSelection();
        const existingFilterSelection = _.find(
            currentViewSelection,
            filterSelection => filterSelection.definitionId === definitionId,
        );

        if (existingFilterSelection) {
            if (existingFilterSelection.values?.length === 1 && existingFilterSelection.values[0] === value) {
                // Handle single values (e.g. search term) by removing filter entirely
                this.clearSingleFilter(definitionId);
            } else if (_.includes(RANGE_FILTER_TYPES, existingFilterSelection.type)) {
                // Handle range selections by removing filter entirely
                this.clearSingleFilter(definitionId);
            } else {
                // Handle all other cases by only removing the requested value
                existingFilterSelection.values = _.filter(existingFilterSelection.values, val => val !== value);
                this.filterViewSelection = currentViewSelection;
                this.debounceFilterViewSelectionSubject();
            }
        }
    }

    removeSpecificValueAndMerged(mergeDefinitionId: string, value: string) {
        const currentViewSelection = this.getCurrentViewSelection();
        const existingFilterSelections = _.filter(
            currentViewSelection,
            filterSelection => filterSelection.mergeDefinitionId === mergeDefinitionId,
        );

        _.map(existingFilterSelections, selection => {
            if (selection.values?.length === 1 && selection.values[0] === value) {
                // Handle single values (e.g. search term) by removing filter entirely
                this.clearSingleFilter(selection.definitionId);
            } else if (_.includes(RANGE_FILTER_TYPES, selection.type)) {
                // Handle range selections by removing filter entirely
                this.clearSingleFilter(selection.definitionId);
            } else {
                // Handle all other cases by only removing the requested value
                selection.values = _.filter(selection.values, val => val !== value);
                this.filterViewSelection = currentViewSelection;
                this.debounceFilterViewSelectionSubject();
            }
        });
    }

    removeAfterSpecificDefinition(mergeDefinitionId: string) {
        const currentViewSelection = this.getCurrentViewSelection();
        const foundIndex = _.findIndex(
            currentViewSelection,
            filterSelection => filterSelection.mergeDefinitionId === mergeDefinitionId,
        );

        currentViewSelection.splice(foundIndex + 1, currentViewSelection.length - foundIndex);
        this.filterViewSelection = currentViewSelection;
        this.debounceFilterViewSelectionSubject();
    }

    private debounceFilterViewSelectionSubject = _.debounce(() => {
        const selection = this.getCurrentViewSelection();
        this._filterViewSelectionSubject.next(selection);
    }, 200);

    private debounceFilterSelectionSubject = _.debounce(() => {
        const selection = this.getCurrentSelection();
        this._filterSelectionSubject.next(selection);
    }, 200);

    get matchingProductCount() {
        return this._matchingCount;
    }

    set matchingProductCount(val: number) {
        this._matchingCount = val;
    }

    static addCategoryFilter(category: Category | undefined | null, filters: FilterSelection[]): FilterSelection[] {
        if (category) {
            return _.concat(filters, {
                mergeDefinitionId: CATEGORY_FILTER_ID,
                definitionId: CATEGORY_FILTER_ID,
                type: FilterType.CategoryHandle,
                name: 'Category Handle',
                values: [category.handle],
            });
        }
        return filters;
    }

    static addCategoryFilters(categories: List<Category> | undefined, filters: FilterSelection[]): FilterSelection[] {
        if (categories) {
            const handles = _.map(categories.valueSeq().toArray(), category => category.handle);
            return _.concat(filters, {
                mergeDefinitionId: CATEGORY_FILTER_ID,
                definitionId: CATEGORY_FILTER_ID,
                type: FilterType.CategoryHandle,
                name: 'Category Handle',
                values: handles,
            });
        }
        return filters;
    }

    async countMatchingProducts(
        filterReason: string,
        category: Category | undefined | null,
        filters: FilterSelection[],
        setMeaningful: boolean,
    ): Promise<number> {
        const trans = SentryUtil.StartTransaction('FilterService.CountMatching', false);
        const filtersWithCategory = ProductFilteringService.addCategoryFilter(category, filters);
        const matchingProductsResult = await this.filterProducts(
            filterReason,
            filtersWithCategory,
            { limit: 0 },
            setMeaningful,
        );
        this.matchingProductCount = matchingProductsResult.totalCount;
        SentryUtil.EndSpan(trans.newTransaction);

        return matchingProductsResult.totalCount;
    }

    async matchingProducts(
        filterReason: string,
        category: Category | undefined | null,
        filters: FilterSelection[],
        options: ProductsFetchOptions,
        setMeaningful: boolean,
    ): Promise<CloudshelfProductsSearchResult> {
        const trans = SentryUtil.StartTransaction('FilterService.MatchingProducts', false);
        const filtersWithCategory = ProductFilteringService.addCategoryFilter(category, filters);
        const ret = this.filterProducts(filterReason, filtersWithCategory, options, setMeaningful);
        SentryUtil.EndSpan(trans.newTransaction);

        return ret;
    }

    private async filterProducts(
        filterReason: string,
        filters: FilterSelection[],
        options: ProductsFetchOptions,
        setMeaningful: boolean,
    ): Promise<CloudshelfProductsSearchResult> {
        // setMeaningful = false;
        if (!options.sort) {
            //Set the default sort order in order to not break existing functionality
            options.sort = buildBuiltInSortOrder_Relevance();
        }

        //find the filter for ordering
        const sortFilter = _.find(filters, filter => filter.definitionId === 'sort-by');

        if (sortFilter && sortFilter.values && sortFilter.values.length > 0) {
            const sortByKey = sortFilter.values[0];
            if (sortByKey === 'relevance') {
                options.sort = buildBuiltInSortOrder_Relevance();
            } else if (sortByKey === 'price-desc') {
                options.sort = buildBuiltInSortOrder_PriceDesc();
            } else if (sortByKey === 'price-asc') {
                options.sort = buildBuiltInSortOrder_PriceAsc();
            }
        }

        // For each preselection, ensure it is added to filters if not already present
        for (const preselected of this._preselection) {
            if (!_.find(filters, filter => filter.definitionId === preselected.definitionId)) {
                filters.push(preselected);
            }
        }
        let stockFilterFunction: (obj: FilterableProductVariant) => boolean = () => true;
        let randomOrderingFunction:
            | ((obj: FilterableProductVariant, obj2: FilterableProductVariant) => 0 | 1 | -1)
            | undefined;

        if (options.randomSort) {
            randomOrderingFunction = () => (Math.random() > 0.5 ? 1 : -1);
        }

        let cursor = 0;
        if (_.isNumber(options.cursor)) {
            cursor = +options.cursor;
        }

        const startTime = Date.now();
        let timeToFilter = -1;
        let timeToSlice = -1;
        let totalCount = -1;
        let sliceCount = -1;

        try {
            const query: LokiQuery<FilterableProductVariant> = { $and: [] };

            const mergedFilters = _.cloneDeep(filters);

            const mappedOptionsFilter = [
                FilterType.Basic,
                FilterType.Size,
                FilterType.ShoeSize,
                FilterType.Material,
                FilterType.Colour,
                FilterType.Vendor,
                FilterType.Tag,
                FilterType.ProductType,
            ];

            const metafieldOptionsFilter = [FilterType.ShopifyMetafield];

            //Merge child and parent values
            for (const filter of mergedFilters) {
                if (filter.definitionId === filter.mergeDefinitionId && _.includes(mappedOptionsFilter, filter.type)) {
                    const childFilters = _.filter(
                        mergedFilters,
                        f => f.mergeDefinitionId === filter.definitionId && f.mergeDefinitionId !== f.definitionId,
                    );

                    const childValues = _.flatten(
                        _.map(childFilters, childFilter =>
                            _.map(childFilter.values, childValue => {
                                return `${childFilter.name}:${childValue}`;
                            }),
                        ),
                    );
                    const parentValues = _.map(filter.values, parentValue => {
                        return `${filter.name}:${parentValue}`;
                    });

                    filter.values = _.uniq([...parentValues, ...childValues]);

                    _.map(childFilters, childFilter => {
                        childFilter.values = filter.values;
                    });
                }
            }

            //exclude product customizer product from results
            query.$and.push({ productHandle: { $ne: 'product-customizer-item-customizations' } });

            for (const filter of mergedFilters) {
                if (filter.type === FilterType.Images) {
                    query.$and.push({ images: { $ne: null } });
                    query.$and.push({ images: { $size: { $gt: 0 } } });
                } else if (filter.type === FilterType.CategoryHandle) {
                    if (filter.values[0] !== 'INTERNAL_ALL') {
                        query.$and.push({
                            categoryHandles: { $containsAny: filter.values },
                        });
                    }
                } else if (filter.type === FilterType.CategoryId) {
                    if (filter.values[0] !== 'INTERNAL_ALL') {
                        query.$and.push({
                            categoryIds: { $containsAny: filter.values },
                        });
                    }
                } else if (filter.type === FilterType.ProductTitle) {
                    const searchRegex = generateMatchingAllInputRegex(filter.values[0]);

                    query.$and.push({
                        $or: _.compact([
                            ...[
                                {
                                    productTitle: { $regex: searchRegex },
                                },
                            ],
                            ...[
                                {
                                    productDescription: { $regex: searchRegex },
                                },
                            ],
                            ...[
                                {
                                    mappedOptions: { $regex: searchRegex },
                                },
                            ],
                            ...[
                                (this._configService.config()?.filters.filter(x => x.type === FilterType.Tag) ?? [])
                                    .length > 0
                                    ? {
                                          productTags: { $regex: searchRegex },
                                      }
                                    : null,
                            ],
                            ...[
                                (
                                    this._configService
                                        .config()
                                        ?.filters.filter(x => x.type === FilterType.ShopifyMetafield) ?? []
                                ).length > 0
                                    ? {
                                          metafieldValues: { $regex: searchRegex },
                                      }
                                    : null,
                            ],
                            ...[
                                (
                                    this._configService
                                        .config()
                                        ?.pdpBlocks.filter(x => x.unionTypeName === 'PDPBlockMetafield') ?? []
                                ).length > 0
                                    ? {
                                          metafieldValues: { $regex: searchRegex },
                                      }
                                    : null,
                            ],
                        ]),
                    });
                } else if (filter.type === FilterType.ProductHandle) {
                    const searchRegex = new RegExp(filter.values[0], 'i');
                    query.$and.push({ productHandle: { $regex: searchRegex } });
                } else if (filter.type === FilterType.Price) {
                    query.$and.push({
                        price: {
                            $and: [{ $gte: _.toNumber(filter.values[0]) }, { $lte: _.toNumber(filter.values[1]) }],
                        },
                    });
                } else if (filter.type === FilterType.Promotions) {
                    query.$and.push({ hasSalePrice: { $eq: true } });
                    query.$and.push({ originalPrice: { $gt: 0 } });
                } else if (filter.type === FilterType.StockLevel) {
                    const includeInStock = _.includes(filter.values, 'In Stock');
                    const includeOrderOnly = _.includes(filter.values, 'Order Only');
                    const includeOutOfSock = _.includes(filter.values, 'Out of Stock');

                    stockFilterFunction = (obj: FilterableProductVariant) => {
                        if (includeOutOfSock && !obj.availableForSale) {
                            return true;
                        }

                        if (includeInStock && obj.availableForSale && !obj.currentlyNotInStock) {
                            return true;
                        }

                        if (includeOrderOnly && obj.availableForSale && obj.currentlyNotInStock) {
                            return true;
                        }

                        return false;
                    };
                } else if (_.includes(mappedOptionsFilter, filter.type)) {
                    if (filter.definitionId === filter.mergeDefinitionId) {
                        //parent
                        const childFilters = _.filter(
                            mergedFilters,
                            f => f.mergeDefinitionId === filter.definitionId && f.mergeDefinitionId !== f.definitionId,
                        );
                        query.$and.push({
                            $or: _.map([...childFilters, filter], mappedFilter => {
                                return { mappedOptions: { $containsAny: mappedFilter.values } };
                            }),
                        });
                    }
                } else if (_.includes(metafieldOptionsFilter, filter.type)) {
                    if (filter.definitionId === filter.mergeDefinitionId) {
                        //parent
                        const childFilters = _.filter(
                            mergedFilters,
                            f => f.mergeDefinitionId === filter.definitionId && f.mergeDefinitionId !== f.definitionId,
                        );
                        query.$and.push({
                            $or: _.map([...childFilters, filter], mappedFilter => {
                                return { metafieldValues: { $containsAny: mappedFilter.values } };
                            }),
                        });
                    }
                }
            }

            const queryBuilder = this._lokiProductCache.chain().find(query).where(stockFilterFunction);

            if (randomOrderingFunction) {
                queryBuilder.sort(randomOrderingFunction);
            }

            queryBuilder.compoundsort(options.sort);

            const filtered = queryBuilder.data();

            // Remembers what the previous selected filter was before this one, so each time a
            // new filter is selected for the first time we will know
            const changedFilters = _.filter(filters, filter => {
                return (
                    filter.type !== FilterType.CategoryHandle &&
                    // filter.type !== FilterType.StockLevel &&
                    !_.find(this._previousFilterSelection, mf => mf.name === filter.name)
                );
            });
            this._previousFilterSelection = filters;

            for (const changedFilter of changedFilters) {
                if (changedFilter) {
                    let filterForWhomWeNeedToRememberTheAttributes = _.find(
                        this._meaningFULFilters,
                        f => f.ecommProviderFieldName === changedFilter.name,
                    );
                    if (!filterForWhomWeNeedToRememberTheAttributes) {
                        // A merged filter
                        const allFiltersIncludingNonVisible = this._configService.config()?.filters ?? [];
                        const child = _.find(
                            allFiltersIncludingNonVisible,
                            f => f.ecommProviderFieldName === changedFilter.name,
                        );

                        if (child) {
                            filterForWhomWeNeedToRememberTheAttributes = _.find(
                                allFiltersIncludingNonVisible,
                                f => f.id === child.parentId,
                            );
                        }
                    }

                    if (filterForWhomWeNeedToRememberTheAttributes) {
                        const matchingMeaningfulFilter = _.find(this._meaningFULFilters, f => {
                            return (
                                f.ecommProviderFieldName ===
                                filterForWhomWeNeedToRememberTheAttributes?.ecommProviderFieldName
                            );
                        });
                        if (matchingMeaningfulFilter) {
                            this._overrideMeaningfulAttributeValues.push({
                                filterName: changedFilter.name,
                                visibleAttributeValues: matchingMeaningfulFilter.attributeValues,
                            });
                        }
                    }
                }
            }

            // Filter out any overrides that are no longer relevant in filters
            this._overrideMeaningfulAttributeValues = _.compact(
                _.filter(
                    this._overrideMeaningfulAttributeValues,
                    ov =>
                        this._preselection.find(f => f.name === ov.filterName) ||
                        (_.find(filters, f => f.name === ov.filterName) ?? null),
                ),
            ) as { filterName: string; visibleAttributeValues: AttributeValue[] }[];

            // Find the name of the filter that just changed by finding an element (if it exists) that isn't present
            // in meaningFULfilters
            const returnedAttributeValues: { [key: string]: string[] } = {};
            const priceMap: { [categoryId: string]: number[] } = {};
            for (const productVariant of filtered) {
                // For each mapped attribute, if the key exists in returnedAttributeValues, append the value to the
                // array, otherwise create a new array with the value
                for (const mappedAttribute of productVariant.mappedOptions) {
                    // Split the attribute on ':' - the key is the first part and the value is the second. If value is
                    // empty, ignore the attribute
                    // const [key, value] = mappedAttribute.split(':');
                    const splitValues = mappedAttribute.split(':');
                    const key = splitValues.length > 1 ? splitValues[0] : undefined;
                    if (!key) {
                        continue;
                    }
                    const valueSplits = _.cloneDeep(splitValues);
                    valueSplits.shift();
                    const value = valueSplits.join(':');
                    if (value) {
                        if (returnedAttributeValues[key]) {
                            returnedAttributeValues[key].push(value);
                        } else {
                            returnedAttributeValues[key] = [value];
                        }
                    }
                }

                // Price
                // For each categoryid this productvariant is part of, check priceMap and update the min and max values
                // if necessary
                for (const categoryId of productVariant.categoryIds) {
                    if (priceMap[categoryId]) {
                        priceMap[categoryId][0] = Math.min(priceMap[categoryId][0], productVariant.price);
                        priceMap[categoryId][1] = Math.max(priceMap[categoryId][1], productVariant.price);
                    } else {
                        priceMap[categoryId] = [productVariant.price, productVariant.price];
                    }
                }

                // Promotions
                if (productVariant.hasSalePrice && productVariant.originalPrice > 0) {
                    if (!returnedAttributeValues['Promotions']) {
                        returnedAttributeValues['Promotions'] = ['On Sale'];
                    }
                }

                // StockLevel
                if (productVariant.availableForSale) {
                    if (productVariant.currentlyNotInStock) {
                        if (!returnedAttributeValues['Stock']) {
                            returnedAttributeValues['Stock'] = ['Order Only'];
                        } else if (!_.includes(returnedAttributeValues['StockLevel'], 'Order Only')) {
                            returnedAttributeValues['Stock'].push('Order Only');
                        }
                    } else {
                        if (!returnedAttributeValues['Stock']) {
                            returnedAttributeValues['Stock'] = ['In Stock'];
                        } else if (!_.includes(returnedAttributeValues['Stock'], 'In Stock')) {
                            returnedAttributeValues['Stock'].push('In Stock');
                        }
                    }
                } else {
                    if (!returnedAttributeValues['Stock']) {
                        returnedAttributeValues['Stock'] = ['Out of Stock'];
                    } else if (!_.includes(returnedAttributeValues['Stock'], 'Out of Stock')) {
                        returnedAttributeValues['Stock'].push('Out of Stock');
                    }
                }

                //metafields
                if (productVariant.productMetafields && productVariant.productMetafields.length > 0) {
                    _.map(productVariant.productMetafields, metafield => {
                        const ecommName = `${metafield.namespace}-${metafield.key}`;
                        if (!returnedAttributeValues[ecommName]) {
                            returnedAttributeValues[ecommName] = [metafield.value];
                        } else if (!_.includes(returnedAttributeValues[ecommName], metafield.value)) {
                            returnedAttributeValues[ecommName].push(metafield.value);
                        }
                    });
                }
            }
            // If pricemap has any keys
            if (Object.keys(priceMap).length > 0) {
                returnedAttributeValues['Price'] = ['.']; // dummy value
            }

            // For each key in returnedAttributeValues, _.uniq the array value
            for (const key in returnedAttributeValues) {
                returnedAttributeValues[key] = _.uniq(returnedAttributeValues[key]);
            }

            const allFilters = this._configService.displayableFilters;

            // For each key in returned, find the matching filter object from allFilters using ecommProviderFieldName,
            // and construct a new filter object using the old filter id and new values
            const returned: CloudshelfEngineFilter[] = [
                buildOrderByFilter(this._configService.categories.map(c => c.id)),
            ];
            for (const key in returnedAttributeValues) {
                // Find the matching filter object from allFilters using ecommProviderFieldName and key
                // (from returnedAttributeValues)
                let filter = _.find(allFilters, f => f.ecommProviderFieldName === key);
                if (!filter) {
                    // A merged filter
                    const allFiltersIncludingNonVisible = this._configService.config()?.filters ?? [];
                    const child = _.find(allFiltersIncludingNonVisible, f => f.ecommProviderFieldName === key);
                    if (!child) {
                        continue;
                    }
                    filter = _.find(allFiltersIncludingNonVisible, f => f.id === child.parentId);
                }
                if (filter) {
                    // If we have a filterselection override with the same name as the key, use the overridden attribute
                    // values from filter. Otherwise, use the attribute values from returnedAttributeValues. This lets
                    // us "remember" what the filter looked like when it was first selected
                    let attributeValues: AttributeValue[] = [];

                    if (
                        key === 'Price' &&
                        !_.find(this._overrideMeaningfulAttributeValues, f => f.filterName === 'Price')
                    ) {
                        // Create two attributevalues for each categoryid in priceMap; one for min, one for max
                        for (const categoryId in priceMap) {
                            // Min
                            attributeValues.push({
                                categoryIds: [categoryId],
                                value: priceMap[categoryId][0].toString(),
                                priority: -1,
                            });
                            // Max
                            attributeValues.push({
                                categoryIds: [categoryId],
                                value: priceMap[categoryId][1].toString(),
                                priority: 0,
                            });
                        }
                    }

                    const overrideAttributes = _.find(
                        this._overrideMeaningfulAttributeValues,
                        o => o.filterName === key,
                    )?.visibleAttributeValues;

                    if (overrideAttributes) {
                        attributeValues = overrideAttributes;
                    } else {
                        if (key !== 'Price') {
                            attributeValues = _.filter(filter.attributeValues, v =>
                                _.includes(returnedAttributeValues[key], v.value),
                            );
                        }
                    }

                    const existingFilter = _.find(returned, f => f.id === filter?.id);
                    if (existingFilter) {
                        const mergedAttributeValues = _.concat(existingFilter.attributeValues, attributeValues);
                        // deduplicate mergedAttributeValues by joining the categoryIds and values, and parentFilterId
                        existingFilter.attributeValues = _.uniqBy(
                            mergedAttributeValues,
                            v => `${v.categoryIds.join(',')}-${v.value}-${v.priority}-${v.parentFilterId}`,
                        );
                    } else {
                        const newFilter = {
                            ...filter,
                            attributeValues,
                        };
                        returned.push(newFilter);
                    }
                }
            }

            // Ensure each override filter is present in meaningful
            for (const overrideFilter of this._overrideMeaningfulAttributeValues) {
                const existingFilter = _.find(returned, f => f.ecommProviderFieldName === overrideFilter.filterName);
                if (!existingFilter) {
                    const filter = _.find(allFilters, f => f.ecommProviderFieldName === overrideFilter.filterName);
                    if (filter) {
                        const newFilter = {
                            ...filter,
                            attributeValues: overrideFilter.visibleAttributeValues,
                        };
                        returned.push(newFilter);
                    }
                }
            }

            if (setMeaningful) {
                this._meaningFULFilters = _.sortBy(returned, r => r.priority);
                this._meaningfulChangedSubject.next();
            }

            //UniqBy product id, not variants as that is what we show in the UI
            const uniqFiltered = _.uniqBy(filtered, f => f.shopifyProductId);
            timeToFilter = Date.now() - startTime;

            const preSlice = Date.now();
            const slice = _.slice(uniqFiltered, cursor, (cursor ?? 0) + options.limit);
            sliceCount = slice.length;
            timeToSlice = Date.now() - preSlice;

            totalCount = uniqFiltered.length;
            return makeSearchResult({
                hasMore: (cursor ?? 0) + options.limit < totalCount,
                totalCount,
                products: _.map(slice, (obj, index): ProductSearchResultWithCursor => {
                    const allVariantsForProduct = _.filter(
                        filtered,
                        filtered => filtered.shopifyProductId === obj.shopifyProductId,
                    );

                    const minPrice = _.minBy(allVariantsForProduct, v => v.price)?.price ?? 0;
                    const maxPrice = _.maxBy(allVariantsForProduct, v => v.price)?.price ?? 0;
                    const minPriceOriginal = _.minBy(allVariantsForProduct, v => v.originalPrice)?.originalPrice ?? 0;
                    const maxPriceOriginal = _.maxBy(allVariantsForProduct, v => v.originalPrice)?.originalPrice ?? 0;

                    return {
                        cursor: (cursor ?? 0) + index,
                        id: obj.shopifyProductId,
                        handle: obj.productHandle,
                        vendor: obj.productVendor,
                        featuredImage: obj.featuredImage,
                        title: obj.productTitle,
                        availableForSale: obj.availableForSale,
                        productType: obj.productType,
                        minPrice,
                        maxPrice,
                        minPriceOriginal,
                        maxPriceOriginal,
                        limitedAvailability: !_.every(allVariantsForProduct, v => v.availableForSale),
                        orderOnly: _.every(allVariantsForProduct, v => v.currentlyNotInStock),
                        images: obj.images,
                    };
                }),
            });
        } catch (e) {
            //if error occurs filtering products we want to throw the error so we request all products from Shopify.
            Sentry.captureException(e, {
                extra: {
                    operationName: 'filterProducts',
                },
            });
            throw e;
        } finally {
            LogUtil.Log(
                `Filter Products (Reason: ${filterReason}) took in total ${
                    Date.now() - startTime
                }ms. (${timeToFilter} to filter, ${timeToSlice} to slice), and returned ${sliceCount} products out of a possible ${totalCount} results.`,
            );
        }
        return makeSearchResult();
    }
}
