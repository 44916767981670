import React, { ReactElement, useCallback, useEffect } from 'react';
import { FilterSelection, ProductFilteringService } from '../ProductFilteringService';
import ButtonSelectable from '../../../components/shared/inputs/ButtonSelectable/ButtonSelectable';
import { AttributeValue } from '../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import _ from 'lodash';
import useStateRef from 'react-usestateref';
import { CloudshelfEngineFilter } from '../../ConfigurationService/types/filters/CloudshelfEngineFilter';
import { ButtonSize } from '../../../components/shared/inputs/Button/Button';
import InStockIcon from '../../../components/icons/in_stock';
import LocalShippingIcon from '../../../components/icons/local_shipping';
import ShoppingCartIcon from '../../../components/icons/shopping_cart';
import RemoveShoppingCartIcon from '../../../components/icons/remove_shopping_cart';
import OrderIcon from '../../../components/icons/order';
import OutOfStockIcon from '../../../components/icons/out_of_stock';

export interface FiltersViewButtonProps {
    definition: CloudshelfEngineFilter;
    filterValue: { attributeValue: AttributeValue; displayName: string };
    handleSelection: (filterValue: { attributeValue: AttributeValue; displayName: string }) => void;
    filteringService: ProductFilteringService;
    initialFilterSelection: FilterSelection[];
}

export const FiltersViewButton: React.FC<FiltersViewButtonProps> = React.memo(props => {
    const [selected, setSelected, selectedRef] = useStateRef(false);

    const updateSelection = useCallback(
        (selection: FilterSelection[]) => {
            const shouldBeSelected = !!_.find(selection, selection => {
                return (
                    (selection.definitionId === props.definition.id &&
                        _.includes(selection.values, props.filterValue.attributeValue.value)) ||
                    (selection.mergeDefinitionId === props.definition.id &&
                        _.includes(selection.values, props.filterValue.attributeValue.value))
                );
            });

            if (selectedRef.current !== shouldBeSelected) {
                setSelected(shouldBeSelected);
            }
        },
        [selectedRef.current],
    );

    useEffect(() => {
        const filterViewStateObserver = props.filteringService.observeFilterViewSelectionState();

        const filterViewStateObserverSubscription = filterViewStateObserver.subscribe(
            async (selection: FilterSelection[]) => {
                updateSelection(selection);
            },
        );

        updateSelection(props.initialFilterSelection);
        return () => filterViewStateObserverSubscription.unsubscribe();
    }, []);

    let buttonIcon: ReactElement | null = null;
    if (props.filterValue.displayName === 'In Stock' && !selected) {
        buttonIcon = <ShoppingCartIcon />;
    } else if (props.filterValue.displayName === 'Order Only' && !selected) {
        buttonIcon = <LocalShippingIcon />;
    } else if (props.filterValue.displayName === 'Out of Stock' && !selected) {
        buttonIcon = <RemoveShoppingCartIcon />;
    } else if (props.filterValue.displayName === 'In Stock' && selected) {
        buttonIcon = <InStockIcon />;
    } else if (props.filterValue.displayName === 'Order Only' && selected) {
        buttonIcon = <OrderIcon />;
    } else if (props.filterValue.displayName === 'Out of Stock' && selected) {
        buttonIcon = <OutOfStockIcon />;
    }

    return (
        <ButtonSelectable
            size={ButtonSize.SM}
            forId={props.definition.id}
            className="DiscreteFilterInput__option"
            name={props.definition.ecommProviderFieldName}
            label={props.filterValue.displayName}
            icon={buttonIcon}
            type="checkbox"
            value={props.filterValue.attributeValue.value}
            selected={selected}
            onSelected={() => props.handleSelection(props.filterValue)}
            outlined={true}
        />
    );
});
