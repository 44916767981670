import { FC } from 'react';

const RequiredStyles: FC = () => {
    return (
        <>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro&display=swap" />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css" />
        </>
    );
};

export default RequiredStyles;
