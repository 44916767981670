import React from 'react';
import Button, { ButtonOutlineVariant, ButtonVariant } from '../Button/Button';
import './CouponInput.scss';
import { useInjection } from '../../../../dependancyInjection/DependencyContext';
import { BasketService } from '../../../../services/BasketService/BasketService';
import DependencyType from '../../../../dependancyInjection/DependencyType';
import { BasketMode } from '../../../../services/BasketService/BasketMode';
import TextInput from '../TextInput/TextInput';
import { useTranslation } from 'react-i18next';

const CouponInput = () => {
    const [isEditing, setIsEditing] = React.useState(false);
    const [coupon, setCoupon] = React.useState('');
    const [previousCoupon, setPreviousCoupon] = React.useState('');
    const [couponValid, setCouponValid] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const basketService = useInjection<BasketService>(DependencyType.BasketService);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const { t } = useTranslation();

    const validateCoupon = async (forceValue?: string) => {
        setIsLoading(true);
        try {
            const isValid = await basketService.setCouponCode(forceValue !== undefined ? forceValue : coupon);
            setCouponValid(isValid);
        } catch {
            setCouponValid(false);
        } finally {
            setIsLoading(false);
        }
    };

    const beginEditing = () => {
        setPreviousCoupon(coupon);
        setIsEditing(true);
    };

    const endEditing = (isCancel: boolean) => {
        if (isCancel) {
            setCoupon(previousCoupon);
        } else {
            void validateCoupon();
        }
        setIsEditing(false);
    };

    const clearCoupon = () => {
        if (!isLoading) {
            // If not editing, actually remove the coupon
            if (!isEditing) {
                void validateCoupon('');
                setPreviousCoupon('');
            }

            // Set display to ''
            setCoupon('');

            // If editing, retain focus
            if (isEditing && inputRef.current) {
                inputRef.current.focus();
            }
        }
    };

    if (basketService.basketMode !== BasketMode.ShopifyCart) {
        return null;
    }

    const isErrorState = !isEditing && !couponValid && !isLoading && coupon !== '';
    const isSuccessState = !isEditing && couponValid;

    return (
        <div className="CouponInput">
            <TextInput
                variant="dashed"
                placeholder={t('promotions.add_code')}
                errorText={isErrorState ? t('promotions.invalid_code') : undefined}
                showSuccessState={isSuccessState}
                endIconName="fa-solid fa-tag"
                disabled={isLoading}
                onFocus={beginEditing}
                onChange={value => setCoupon(value)}
                onClear={clearCoupon}
                ref={inputRef}
            />
            <div
                className={`CouponInput__buttons ${
                    isEditing ? 'CouponInput__buttons__show' : 'CouponInput__buttons__hide'
                }`}
            >
                <Button
                    outline={ButtonOutlineVariant.PRIMARY}
                    variant={ButtonVariant.PRIMARY}
                    onClick={() => endEditing(true)}
                    translate
                >
                    {t('common.cancel')}
                </Button>
                <Button variant={ButtonVariant.PRIMARY} onClick={() => endEditing(false)} translate>
                    {t('common.apply')}
                </Button>
            </div>
        </div>
    );
};

export default CouponInput;
