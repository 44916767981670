import { FC } from 'react';
import useStateRef from 'react-usestateref';
import DisplayOnlyLoadingScreen from '../DisplayOnlyLoadingScreen/DisplayOnlyLoadingScreen';

const DisplayOnlySetupWrapper: FC = props => {
    const [setupComplete, setSetupComplete] = useStateRef(false);

    return (
        <>
            {setupComplete ? (
                props.children
            ) : (
                <>
                    <DisplayOnlyLoadingScreen type="products" onSetupComplete={() => setSetupComplete(true)} />
                </>
            )}
        </>
    );
};

export default DisplayOnlySetupWrapper;
