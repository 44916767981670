import { CloudshelfEngineConfig, DisplayOnlyEngineConfig } from './types/config/CloudshelfEngineConfig';
import { Provider } from './types/config/Provider';
import {
    ClearSalesPersonRule,
    CloudshelfThemeAlignment,
    CloudshelfThemeCallToActionSize,
    CloudshelfThemeImageAnchor,
    CloudshelfThemeSize,
    CloudshelfThemeTileAnimation,
    CloudshelfThemeTouchIndicator,
    CloudshelfUserType,
    DeviceMode,
    DisplayModeBannerDisplayMode,
    DisplayOnlyCollectionType,
    InteractiveBannerDisplayMode,
    SalesPersonNameRule,
} from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';

export const EmptyDisplayOnlyConfig: DisplayOnlyEngineConfig = {
    displayLogo: false,
    collectionType: DisplayOnlyCollectionType.Random,
    timePerPhoto: 0,
    maxProductsPerCollection: 0,
    maxPhotosPerProduct: 0,
    logoSize: CloudshelfThemeSize.Small,
    includeProductName: false,
    includePrice: false,
    includeQRCode: false,
    includeStock: false,
};

export const EmptyConfig: CloudshelfEngineConfig = {
    deviceDPI: 96,
    screenSize: 7,
    tiles: [],
    banners: [],
    pdpBlocks: [],
    bannerDisplayRules: {
        interactive: {
            displayMode: InteractiveBannerDisplayMode.NoBanners,
            showEveryMinutes: 0,
            duration: 0,
        },
        display: {
            displayMode: DisplayModeBannerDisplayMode.NoBanners,
            duration: 0,
        },
    },
    showCloudshelfBranding: true,
    retailerRules: {
        allocateSalesToAssignedSalesPerson: false,
        clearSalesPerson: ClearSalesPersonRule.Never,
        salesPersonName: SalesPersonNameRule.FirstName,
    },
    teamMembers: [],
    device: {
        id: '',
        name: '',
        owner: {
            id: '',
            name: '',
        },
        location: {
            id: '',
            name: '',
        },
        debugMode: false,
        isCloudshelfInternalDevice: false,
    },
    deviceMode: DeviceMode.Interactive,
    forceDebugOverlay: false,
    couponsEnabled: false,
    inMaintenanceMode: false,
    inStockLabel: '',
    limitedAvailabilityLabel: '',
    outOfStockLabel: '',
    soldOutLabel: '',
    displayInStockLabel: false,
    displayLimitedLabel: false,
    displayOutOfStockLabel: false,
    displaySoldOutLabel: false,
    showTotalStockCount: false,
    includeProductsInStock: true,
    includeProductsLimitedAvailability: false,
    includeProductsOutOfStock: false,
    filters: [],
    id: '',
    inactivityTimeout: 0,
    name: '',
    normalizedName: '',
    ownerId: '',
    ownerName: '',
    provider: Provider.SHOPIFY,
    displayOnly: EmptyDisplayOnlyConfig,
    theme: {
        imageAnchor: CloudshelfThemeImageAnchor.Center,
        tileSizeModifier: 1,
        attractScreen: {
            callToAction: '',
            callToActionAlignment: CloudshelfThemeAlignment.Left,
            callToActionSize: CloudshelfThemeCallToActionSize.Regular,
            displayLogo: false,
            logoSize: CloudshelfThemeSize.Small,
            displayFrame: false,
            touchIndicator: CloudshelfThemeTouchIndicator.Disabled,
            tileAnimation: CloudshelfThemeTileAnimation.Flip,
            actionButton: '',
        },
        primaryColor: '',
        mainTextColor: '',
        purchaseTextColor: '',
        radius: { inputs: 0, drawer: 0, tiles: 0, modal: 0 },
        headingsFont: { isCustomFont: false, fontFamily: '', fontWeight: '' },
        subheadingsFont: { isCustomFont: false, fontFamily: '', fontWeight: '' },
        bodyFont: { isCustomFont: false, fontFamily: '', fontWeight: '' },
        useProductAnimations: false,
    },
    trackers: [],
    updatedAt: '',
    scopes: [],
    userType: CloudshelfUserType.Unknown,
};
