import React from 'react';
import { useInjection } from '../../../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../../../dependancyInjection/DependencyType';
import ProductVariant from '../../../../../../../services/ProductServices/variant/ProductVariant';
import ProductVariantHelperService from '../../../../../../../services/ProductServices/variant/ProductVariantHelperService';
import ProductVariantOptions from './ProductVariantOptions';
import _ from 'lodash';
import { ProductVariantAvailability } from '../../../../../../../services/ProductServices/ProductVariantAvailability';
import useStateRef from 'react-usestateref';

export type OnVariantSelected = (variant?: ProductVariant) => void;
export interface ProductVariantsProps {
    variants: ProductVariant[];
    onVariantSelected: OnVariantSelected;
    onMatchingPartialVariant: OnVariantSelected;
}

const ProductVariants = React.forwardRef<HTMLElement, ProductVariantsProps>((props, ref) => {
    const { variants, onVariantSelected, onMatchingPartialVariant } = props;
    const productVariantService = useInjection<ProductVariantHelperService>(DependencyType.ProductVariantHelperService);
    const [selectedVariantOptions, setSelectedVariantOptions, selectedVariantOptionsRef] = useStateRef<{
        [optionName: string]: string;
    }>({});
    const settings = productVariantService.getVariantSettings(variants);

    console.log('settings', settings);
    const onVariantOptionSelected = (optionName: string, optionValue: string): void => {
        let options = _.cloneDeep(selectedVariantOptionsRef.current);
        if (optionName in options && options[optionName] === optionValue) {
            // Remove the option if it is already selected
            delete options[optionName];
            setSelectedVariantOptions(options);
        } else {
            // Set the option
            options = { ...options, [optionName]: optionValue };
        }

        setSelectedVariantOptions(options);

        // Now we have to find the variant that matches the options most closely, and if all options are selected we set
        // the selected variant
        const optionKeys = Object.keys(options);
        const firstMatch = _.find(props.variants, variant => {
            // The variant can't possibly be selected if it's unavailable
            if (variant.availability === ProductVariantAvailability.Unavailable) {
                return false;
            }

            // Get the first variant that matches all the options that have been selected so far
            const variantOptions = variant.options;
            const foundOptions = _.map(optionKeys, optionKey =>
                _.find(variantOptions, vo => vo.name === optionKey && vo.value === options[optionKey]),
            );
            return _.every(foundOptions, fo => !!fo) && foundOptions.length > 0;
        });

        const selected = productVariantService.getSelectedVariant(variants, options);
        onMatchingPartialVariant(firstMatch);
        onVariantSelected(selected);
    };

    const internalContent = _.compact(
        Object.keys(settings).map(key => {
            const variantOptions = settings[key];
            if (
                variantOptions.length === 0 ||
                (variantOptions.length === 1 && variantOptions[0].toLowerCase() === 'default title')
            ) {
                return null;
            }
            return (
                <ProductVariantOptions
                    key={`product-variant-options-${key}`}
                    variantName={key}
                    variantOptions={variantOptions}
                    selectedVariantOptions={selectedVariantOptions}
                    variants={variants}
                    onVariantOptionSelected={onVariantOptionSelected}
                />
            );
        }),
    );

    if (internalContent.length === 0) {
        return null;
    }

    return (
        <section style={{ scrollMarginBottom: '220px' }} ref={ref}>
            {internalContent}
            <hr />
        </section>
    );
});

export default ProductVariants;
