import { RouteParams, Routes } from './Routes';
import { ProductSearchResultWithCursor } from '../../provider/cloudshelf/common/ProductSearchResultWithCursor';
import { TileConfig } from '../ConfigurationService/types/config/TileConfig';
import { Category } from '../CategoryService/entities/Category';

export class RoutesHelperService {
    static toCloudshelfBranding(): string {
        return Routes.BY_CLOUDSHELF;
    }

    static toCategories(): string {
        return Routes.CATEGORIES;
    }

    static toPowerTile({ id }: TileConfig): string {
        return Routes.POWER_TILE.replace(`:${RouteParams.POWER_TILE_ID}`, id);
    }

    static toCategoryProducts(category?: Category): string {
        let handle = 'INTERNAL_ALL';
        if (category) {
            handle = category.handle;
        }
        return this.toCategoryProductsViaHandle(handle);
    }

    static toCategoryProductsViaHandle(handle: string): string {
        return Routes.CATEGORY_PRODUCTS.replace(`:${RouteParams.CATEGORY}`, handle);
    }

    static toCategoryProductDetails(
        { handle: productHandle }: ProductSearchResultWithCursor,
        category?: Category,
    ): string {
        let categoryHandle = 'INTERNAL_ALL';
        if (category) {
            categoryHandle = category.handle;
        }

        return Routes.CATEGORY_PRODUCT.replace(`:${RouteParams.CATEGORY}`, categoryHandle).replace(
            `:${RouteParams.PRODUCT}`,
            productHandle,
        );
    }

    static toPairDevice(code: string): string {
        return Routes.PAIR_DEVICE.replace(`:${RouteParams.DEVICE_CODE}`, code);
    }

    static isFirstInteractiveRoute(currentRoute: string): boolean {
        if (currentRoute === '/' || currentRoute === '/categories' || currentRoute.endsWith('/categories')) {
            return true;
        }
        return false;
    }
}
