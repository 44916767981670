import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-http-backend';

const supportedWeglotLanguages = ['en', 'fr', 'de'];
i18n.use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(
        {
            detection: {
                order: ['querystring', 'navigator'],
                lookupQuerystring: 'lng',
            },
            backend: {
                loadPath: `${process.env.PUBLIC_URL ?? ''}/locales/{{lng}}.json`,
            },
            load: 'languageOnly',
            fallbackLng: 'en',
            interpolation: {
                escapeValue: false,
            },
        },
        () => {
            const currentWgLang = window.localStorage.getItem('wglang');

            const i18Lang = i18n.language;
            //i18n uses lang like en-GB, but we only need en. Copilot convert this for me
            const i18LangShort = i18Lang.split('-')[0];

            if (currentWgLang !== i18LangShort) {
                if (supportedWeglotLanguages.includes(i18LangShort)) {
                    console.warn(
                        'wg lang was not the same as i18n lang, updating',
                        `(${currentWgLang} => ${i18LangShort})`,
                    );
                    window.localStorage.setItem('wglang', i18LangShort);
                    window.location.reload();
                } else {
                    console.warn(
                        'wg lang was not the same as i18n lang, but i18n lang is not supported by our Weglot plan, not updating',
                        `(${currentWgLang} => ${i18LangShort})`,
                    );
                    window.localStorage.setItem('wglang', 'en');
                }
            }
        },
    );

export default i18n;
