import React, { Suspense, useEffect, useState } from 'react';
import makeTrashable from 'trashable';
import Loader from '../components/shared/Loader/Loader';
import { useContainer } from '../dependancyInjection/DependencyContext';
import ProviderUtils from './ProviderUtils';
import { dependenciesContainer } from '../dependancyInjection/DependenciesInitializer';
import DependencyType from '../dependancyInjection/DependencyType';
import * as Sentry from '@sentry/react';
import FullPageErrorComponent from '../components/shared/FullPageError/FullPageError';
import { ConfigurationService } from '../services/ConfigurationService/ConfigurationService';

const ProviderModuleLoader: React.FC = ({ children }) => {
    const configService = dependenciesContainer.get<ConfigurationService>(DependencyType.ConfigurationService);
    const observer = configService.observe();
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasFailed, setHasFailed] = useState(false);

    observer.subscribe(() => {
        setIsLoaded(false);
        setHasFailed(false);
    });

    const container = useContainer();

    const onLoaded = (successful: boolean) => {
        setHasFailed(!successful);
        setIsLoaded(true);
    };

    useEffect(() => {
        if (!isLoaded) {
            const loadProviderPromise = makeTrashable(
                ProviderUtils.providerModule()
                    .then(providerModule => {
                        return container.load(providerModule);
                    })
                    .catch(),
            );
            loadProviderPromise
                .then(() => {
                    onLoaded(true);
                })
                .catch(ex => {
                    Sentry.captureException(ex, {
                        extra: {
                            operationName: 'loadProvider useEffect',
                        },
                    });
                    onLoaded(false);
                });

            return loadProviderPromise.trash;
        }
    }, [isLoaded]);

    if (!isLoaded) {
        return <Loader variant="stars" />;
    }

    return (
        <Suspense fallback={<Loader variant="stars" />}>
            {hasFailed ? <FullPageErrorComponent variant="unknown" /> : <>{children}</>}
        </Suspense>
    );
};

export default ProviderModuleLoader;
