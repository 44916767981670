import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export enum AppSubscriptionStatus {
  Accepted = 'Accepted',
  Active = 'Active',
  Cancelled = 'Cancelled',
  Declined = 'Declined',
  Expired = 'Expired',
  Frozen = 'Frozen',
  Pending = 'Pending'
}

export type AttractScreenOptionsUnion = CloudshelfThemeClassicAttractScreen | CloudshelfThemeMinimalAttractScreen;

export type AttributeValue = {
  __typename?: 'AttributeValue';
  categoryIds: Array<Scalars['String']>;
  parentFilterId?: Maybe<Scalars['String']>;
  priority: Scalars['Float'];
  value: Scalars['String'];
};

export type AttributeValueInput = {
  categoryIds: Array<Scalars['String']>;
  parentFilterId?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Float']>;
  value: Scalars['String'];
};

export type AttributeValueOverride = {
  __typename?: 'AttributeValueOverride';
  displayValue: Scalars['String'];
  originalValue: Scalars['String'];
  parentFilterId?: Maybe<Scalars['String']>;
};

export type AttributeValueOverrideInput = {
  displayValue: Scalars['String'];
  originalValue: Scalars['String'];
  parentFilterId?: Maybe<Scalars['String']>;
};

export type AuditLog = {
  __typename?: 'AuditLog';
  action: AuditLogAction;
  /** A numeric "Reason Code" for the action */
  code?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  resourceId?: Maybe<Scalars['String']>;
  resourceType?: Maybe<ResourceType>;
  store: ShopifyStore;
  updatedAt: Scalars['DateTime'];
  user: CloudshelfUser;
};

export enum AuditLogAction {
  Login = 'Login',
  ResourceCreation = 'ResourceCreation',
  ResourceDeletion = 'ResourceDeletion',
  ShopifySyncCancelled = 'ShopifySyncCancelled',
  ShopifySyncCollectionsProcessed = 'ShopifySyncCollectionsProcessed',
  ShopifySyncCollectionsReceived = 'ShopifySyncCollectionsReceived',
  ShopifySyncDataProcessed = 'ShopifySyncDataProcessed',
  ShopifySyncDataReceived = 'ShopifySyncDataReceived',
  ShopifySyncProductsProcessed = 'ShopifySyncProductsProcessed',
  ShopifySyncProductsReceived = 'ShopifySyncProductsReceived',
  ShopifySyncRequestedCollections = 'ShopifySyncRequestedCollections',
  ShopifySyncRequestedProducts = 'ShopifySyncRequestedProducts',
  ShopifySyncTriggerQueued = 'ShopifySyncTriggerQueued',
  ShopifySyncTriggerSent = 'ShopifySyncTriggerSent'
}

export type AuditLogEdge = {
  __typename?: 'AuditLogEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AuditLog>;
};

export type AuditLogPageInfo = {
  __typename?: 'AuditLogPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type AuditLogsResponse = {
  __typename?: 'AuditLogsResponse';
  edges?: Maybe<Array<AuditLogEdge>>;
  pageInfo?: Maybe<AuditLogPageInfo>;
  totalCount: Scalars['Int'];
};

export type BannerConfig = {
  __typename?: 'BannerConfig';
  config: CloudshelfConfig;
  displayOnlyDisplayMode: DisplayModeBannerDisplayMode;
  displayOnlyShowBannerDurationInSeconds: Scalars['Float'];
  id: Scalars['ID'];
  interactiveDisplayMode: InteractiveBannerDisplayMode;
  interactiveShowBannerDurationInSeconds: Scalars['Float'];
  interactiveShowBannerEveryXMinutes: Scalars['Float'];
};

export enum CapitalisationStyle {
  Capitalised = 'CAPITALISED',
  Original = 'ORIGINAL',
  Uppercase = 'UPPERCASE'
}

export type CategoriesResponse = {
  __typename?: 'CategoriesResponse';
  edges?: Maybe<Array<CategoryEdge>>;
  pageInfo?: Maybe<CategoryPageInfo>;
  totalCount: Scalars['Int'];
};

export type Category = {
  __typename?: 'Category';
  deletedAt?: Maybe<Scalars['DateTime']>;
  handle: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  overrides: Array<CategoryOverride>;
  priority: Scalars['Float'];
  productCount: Scalars['Int'];
  shopifyId: Scalars['String'];
  shopifyStorefrontId: Scalars['String'];
  synchronising: SyncStatus;
  title: Scalars['String'];
};


export type CategoryOverridesArgs = {
  cloudshelfId?: Maybe<Scalars['ID']>;
};

export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Category>;
};

export type CategoryOverride = {
  __typename?: 'CategoryOverride';
  cloudshelf: Cloudshelf;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  priority: Scalars['Float'];
  title?: Maybe<Scalars['String']>;
  useImage: Scalars['Boolean'];
};

export type CategoryPageInfo = {
  __typename?: 'CategoryPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type CategoryPowerTile = {
  __typename?: 'CategoryPowerTile';
  category?: Maybe<Category>;
  categoryId: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  pinned: Scalars['Boolean'];
  title?: Maybe<Scalars['String']>;
  useImage: Scalars['Boolean'];
};

export type CategoryPowerTileInput = {
  categoryId: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  pinned: Scalars['Boolean'];
  powerTileId?: Maybe<Scalars['String']>;
  priority: Scalars['Float'];
  title?: Maybe<Scalars['String']>;
  useImage: Scalars['Boolean'];
};

export type Checkout = {
  __typename?: 'Checkout';
  checkoutValue?: Maybe<Scalars['Float']>;
  cloudshelf: Cloudshelf;
  createdAt: Scalars['DateTime'];
  currencyCode?: Maybe<Scalars['String']>;
  device: Device;
  id: Scalars['ID'];
  order?: Maybe<Order>;
  session: Session;
  sessionId: Scalars['String'];
  shopifyCheckoutId: Scalars['String'];
  trackedURL?: Maybe<TrackedUrl>;
  updatedAt: Scalars['DateTime'];
};

export type CheckoutCreateInput = {
  checkoutCurrencyCode: Scalars['String'];
  checkoutId: Scalars['String'];
  checkoutValue: Scalars['Float'];
  cloudshelfId: Scalars['ID'];
  deviceId: Scalars['ID'];
  sessionId: Scalars['ID'];
};

export enum ClearSalesPersonRule {
  Daily = 'DAILY',
  Never = 'NEVER',
  SessionEnd = 'SESSION_END'
}

export type Cloudshelf = {
  __typename?: 'Cloudshelf';
  actions: CloudshelfActions;
  banners?: Maybe<Array<CloudshelfBanner>>;
  categories: Array<Category>;
  checkouts: Array<Checkout>;
  config: CloudshelfConfig;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  devices: Array<Device>;
  engagements: Array<Session>;
  filters?: Maybe<Array<Filter>>;
  id: Scalars['ID'];
  owner: CloudshelfUser;
  permalinkCheckouts?: Maybe<Array<PermalinkCheckout>>;
  powerTiles: Array<PowerTile>;
  tileClicks: Array<TileClick>;
  updatedAt: Scalars['DateTime'];
};


export type CloudshelfEngagementsArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type CloudshelfActions = {
  __typename?: 'CloudshelfActions';
  isCTACompleted: Scalars['Boolean'];
  isCollectionsCompleted: Scalars['Boolean'];
  isConfettiShown: Scalars['Boolean'];
  isDiscountingCompleted: Scalars['Boolean'];
  isDisplayOnlyCompleted: Scalars['Boolean'];
  isFiltersCompleted: Scalars['Boolean'];
  isNameCompleted: Scalars['Boolean'];
  isPreviewed: Scalars['Boolean'];
  isProductDetailsCompleted: Scalars['Boolean'];
  isStockCompleted: Scalars['Boolean'];
  isThemeCompleted: Scalars['Boolean'];
};

export type CloudshelfBanner = {
  __typename?: 'CloudshelfBanner';
  backgroundColour: Scalars['String'];
  backgroundImageHorizontal?: Maybe<Scalars['String']>;
  backgroundImageVertical?: Maybe<Scalars['String']>;
  backgroundType: CloudshelfBannerBackgroundType;
  id: Scalars['ID'];
  linkCollection?: Maybe<Scalars['String']>;
  linkProduct?: Maybe<Scalars['String']>;
  linkText?: Maybe<Scalars['String']>;
  linkType: CloudshelfBannerLinkType;
  linkURL?: Maybe<Scalars['String']>;
  position: Scalars['Float'];
  text: Scalars['String'];
};

export enum CloudshelfBannerBackgroundType {
  Image = 'IMAGE',
  SolidColour = 'SOLID_COLOUR'
}

export type CloudshelfBannerInput = {
  backgroundColour: Scalars['String'];
  backgroundImageHorizontal?: Maybe<Scalars['String']>;
  backgroundImageVertical?: Maybe<Scalars['String']>;
  backgroundType: CloudshelfBannerBackgroundType;
  id?: Maybe<Scalars['String']>;
  linkCollection?: Maybe<Scalars['String']>;
  linkProduct?: Maybe<Scalars['String']>;
  linkText?: Maybe<Scalars['String']>;
  linkType: CloudshelfBannerLinkType;
  linkURL?: Maybe<Scalars['String']>;
  position: Scalars['Float'];
  text: Scalars['String'];
};

export enum CloudshelfBannerLinkType {
  Close = 'CLOSE',
  Collection = 'COLLECTION',
  None = 'NONE',
  Product = 'PRODUCT',
  Url = 'URL'
}

export type CloudshelfConfig = {
  __typename?: 'CloudshelfConfig';
  bannerConfig: BannerConfig;
  categories: Array<Category>;
  cloudshelf: Cloudshelf;
  couponsEnabled: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  displayInStockLabel: Scalars['Boolean'];
  displayLimitedLabel: Scalars['Boolean'];
  displayOnlyConfig: DisplayOnlyConfig;
  displayOutOfStockLabel: Scalars['Boolean'];
  displaySoldOutLabel: Scalars['Boolean'];
  forceDebugOverlay: Scalars['Boolean'];
  handoffProductHandle?: Maybe<Scalars['String']>;
  handoffProductOptionId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  inStockLabel?: Maybe<Scalars['String']>;
  inactivityTimeout: Scalars['Float'];
  includeProductsInStock: Scalars['Boolean'];
  includeProductsLimitedAvailability: Scalars['Boolean'];
  includeProductsOutOfStock: Scalars['Boolean'];
  includedFilterConfig: Array<CloudshelfIncludableFilter>;
  limitedAvailabilityLabel?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  normalizedName: Scalars['String'];
  outOfStockLabel?: Maybe<Scalars['String']>;
  pdpBlocks: Array<PdpBlockUnion>;
  providerConfig: CloudshelfProviderConfig;
  scopes: Array<Scalars['String']>;
  showTotalStockCount: Scalars['Boolean'];
  soldOutLabel?: Maybe<Scalars['String']>;
  subdomain: Scalars['String'];
  theme: CloudshelfTheme;
  trackers: Array<CloudshelfTracker>;
  updatedAt: Scalars['DateTime'];
  userType: CloudshelfUserType;
};

export type CloudshelfConfigResult = {
  __typename?: 'CloudshelfConfigResult';
  bigFormatDisplay: Scalars['Boolean'];
  config?: Maybe<CloudshelfConfig>;
  device?: Maybe<Device>;
  deviceMode: DeviceMode;
  deviceOwnersName: Scalars['String'];
  inMaintenanceMode: Scalars['Boolean'];
  status: CloudshelfConfigStatus;
  store?: Maybe<ShopifyStore>;
  teamMembers: Array<RetailerTeamMember>;
};

export enum CloudshelfConfigStatus {
  Cached = 'CACHED',
  CloudshelfPreview = 'CLOUDSHELF_PREVIEW',
  DeviceNoCloudshelf = 'DEVICE_NO_CLOUDSHELF',
  DeviceRemoved = 'DEVICE_REMOVED',
  DeviceWithoutLocation = 'DEVICE_WITHOUT_LOCATION',
  DeviceWithCloudshelf = 'DEVICE_WITH_CLOUDSHELF',
  Frozen = 'FROZEN',
  MobileHandoff = 'MOBILE_HANDOFF',
  Notfound = 'NOTFOUND'
}

export type CloudshelfDebugInput = {
  forceDebugOverlay: Scalars['Boolean'];
  imageAnchor: CloudshelfThemeImageAnchor;
};

export type CloudshelfDetailsInput = {
  description: Scalars['String'];
  name: Scalars['String'];
};

export type CloudshelfDisplayOnlyInput = {
  collectionType: DisplayOnlyCollectionType;
  displayLogo: Scalars['Boolean'];
  includeLandscapeImages: Scalars['Boolean'];
  includePortraitImages: Scalars['Boolean'];
  includePrice: Scalars['Boolean'];
  includeProductName: Scalars['Boolean'];
  includeQRCode: Scalars['Boolean'];
  includeSquareImages: Scalars['Boolean'];
  includeStock: Scalars['Boolean'];
  logoSize: Scalars['String'];
  maxPhotosPerProduct: Scalars['Float'];
  maxProductsPerCollection: Scalars['Float'];
  minimumImageQuality: DisplayOnlyImageQuality;
  timePerPhoto: Scalars['Float'];
};

export type CloudshelfEdge = {
  __typename?: 'CloudshelfEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Cloudshelf>;
};

export type CloudshelfIncludableFilter = {
  __typename?: 'CloudshelfIncludableFilter';
  extractionStatus: FilterExtractionStatus;
  filterType: Scalars['String'];
  shopifyMetafieldKey?: Maybe<Scalars['String']>;
  shopifyMetafieldNamespace?: Maybe<Scalars['String']>;
};

export type CloudshelfIncludableFilterInput = {
  extractionStatus: FilterExtractionStatus;
  filterType: Scalars['String'];
  shopifyMetafieldKey?: Maybe<Scalars['String']>;
  shopifyMetafieldNamespace?: Maybe<Scalars['String']>;
};

export type CloudshelfIncludablePdpBlock = {
  __typename?: 'CloudshelfIncludablePDPBlock';
  metafieldKey?: Maybe<Scalars['String']>;
  metafieldNamespace?: Maybe<Scalars['String']>;
  type: PdpBlockIncludableTypeEnum;
};

export type CloudshelfIncludablePdpBlockInput = {
  metafieldKey?: Maybe<Scalars['String']>;
  metafieldNamespace?: Maybe<Scalars['String']>;
  type: PdpBlockIncludableTypeEnum;
};

export type CloudshelfPageInfo = {
  __typename?: 'CloudshelfPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type CloudshelfProviderConfig = {
  __typename?: 'CloudshelfProviderConfig';
  eCommerceProvider: ECommerceProvider;
  item1?: Maybe<Scalars['String']>;
  item2?: Maybe<Scalars['String']>;
};

export type CloudshelfStockOptionsInput = {
  displayInStockLabel: Scalars['Boolean'];
  displayLimitedLabel: Scalars['Boolean'];
  displayOutOfStockLabel: Scalars['Boolean'];
  displaySoldOutLabel: Scalars['Boolean'];
  inStockLabel?: Maybe<Scalars['String']>;
  includeProductsInStock: Scalars['Boolean'];
  includeProductsLimitedAvailability: Scalars['Boolean'];
  includeProductsOutOfStock: Scalars['Boolean'];
  limitedAvailabilityLabel?: Maybe<Scalars['String']>;
  outOfStockLabel?: Maybe<Scalars['String']>;
  showTotalStockCount: Scalars['Boolean'];
  soldOutLabel?: Maybe<Scalars['String']>;
};

export type CloudshelfTheme = {
  __typename?: 'CloudshelfTheme';
  attractScreen: AttractScreenOptionsUnion;
  bodyFont: CloudshelfThemeFont;
  config: CloudshelfConfig;
  headingFont: CloudshelfThemeFont;
  id: Scalars['ID'];
  imageAnchor: CloudshelfThemeImageAnchor;
  logoUrl?: Maybe<Scalars['String']>;
  mainTextColor: Scalars['String'];
  primaryColor: Scalars['String'];
  purchaseTextColor: Scalars['String'];
  radius: CloudshelfThemeRadius;
  removeCloudshelfBranding: Scalars['Boolean'];
  subheadingFont: CloudshelfThemeFont;
  tileSizeModifier: Scalars['Float'];
  useProductAnimations: Scalars['Boolean'];
};

export enum CloudshelfThemeAlignment {
  Center = 'CENTER',
  Left = 'LEFT',
  Right = 'RIGHT'
}

export enum CloudshelfThemeCallToActionSize {
  Large = 'LARGE',
  Regular = 'REGULAR'
}

export type CloudshelfThemeClassicAttractScreen = {
  __typename?: 'CloudshelfThemeClassicAttractScreen';
  callToAction: Scalars['String'];
  callToActionAlignment: CloudshelfThemeAlignment;
  callToActionSize: CloudshelfThemeCallToActionSize;
  displayFrame: Scalars['Boolean'];
  displayLogo: Scalars['Boolean'];
  logoSize: CloudshelfThemeSize;
  tileAnimation: CloudshelfThemeTileAnimation;
  touchIndicator: CloudshelfThemeTouchIndicator;
};

export type CloudshelfThemeClassicAttractScreenInput = {
  callToAction?: Maybe<Scalars['String']>;
  callToActionAlignment?: Maybe<CloudshelfThemeAlignment>;
  callToActionSize?: Maybe<CloudshelfThemeCallToActionSize>;
  displayFrame?: Maybe<Scalars['Boolean']>;
  displayLogo?: Maybe<Scalars['Boolean']>;
  logoSize?: Maybe<CloudshelfThemeSize>;
  tileAnimation?: Maybe<CloudshelfThemeTileAnimation>;
  touchIndicator?: Maybe<CloudshelfThemeTouchIndicator>;
};

export type CloudshelfThemeFont = {
  __typename?: 'CloudshelfThemeFont';
  fontFamily: Scalars['String'];
  fontFamilyCDN?: Maybe<Scalars['String']>;
  fontWeightDisplay: Scalars['String'];
  fontWeightIsCustom: Scalars['Boolean'];
  fontWeightValue: Scalars['String'];
  isCustomFont: Scalars['Boolean'];
};

export type CloudshelfThemeFontInput = {
  fontFamily?: Maybe<Scalars['String']>;
  fontFamilyCDN?: Maybe<Scalars['String']>;
  fontWeightDisplay: Scalars['String'];
  fontWeightIsCustom: Scalars['Boolean'];
  fontWeightValue: Scalars['String'];
  isCustomFont?: Maybe<Scalars['Boolean']>;
};

export enum CloudshelfThemeImageAnchor {
  Bottom = 'BOTTOM',
  Center = 'CENTER',
  Left = 'LEFT',
  Right = 'RIGHT',
  Top = 'TOP'
}

export type CloudshelfThemeMinimalAttractScreen = {
  __typename?: 'CloudshelfThemeMinimalAttractScreen';
  actionButton: Scalars['String'];
  callToAction: Scalars['String'];
  callToActionAlignment: CloudshelfThemeAlignment;
  callToActionSize: CloudshelfThemeCallToActionSize;
  displayLogo: Scalars['Boolean'];
  logoSize: CloudshelfThemeSize;
  touchIndicator: CloudshelfThemeTouchIndicator;
};

export type CloudshelfThemeMinimalAttractScreenInput = {
  actionButton?: Maybe<Scalars['String']>;
  callToAction?: Maybe<Scalars['String']>;
  callToActionAlignment?: Maybe<CloudshelfThemeAlignment>;
  callToActionSize?: Maybe<CloudshelfThemeCallToActionSize>;
  displayLogo?: Maybe<Scalars['Boolean']>;
  logoSize?: Maybe<CloudshelfThemeSize>;
  touchIndicator?: Maybe<CloudshelfThemeTouchIndicator>;
};

export type CloudshelfThemeRadius = {
  __typename?: 'CloudshelfThemeRadius';
  drawer: Scalars['Float'];
  inputs: Scalars['Float'];
  modal: Scalars['Float'];
  tiles: Scalars['Float'];
};

export enum CloudshelfThemeSize {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL'
}

export enum CloudshelfThemeTileAnimation {
  Fade = 'FADE',
  Flip = 'FLIP'
}

export enum CloudshelfThemeTouchIndicator {
  BouncingArrow = 'BOUNCING_ARROW',
  ClassicHandPointer = 'CLASSIC_HAND_POINTER',
  Disabled = 'DISABLED'
}

export type CloudshelfTracker = {
  __typename?: 'CloudshelfTracker';
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type CloudshelfUpdateFiltersInput = {
  cloudshelfId: Scalars['ID'];
  filters: Array<FilterInput>;
};

export type CloudshelfUser = {
  __typename?: 'CloudshelfUser';
  auditLogs: Array<AuditLog>;
  cloudshelves?: Maybe<Array<Cloudshelf>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  devices: Array<Device>;
  eCommerceProvider: ECommerceProvider;
  eCommerceProviderIdentifier?: Maybe<Scalars['String']>;
  eCommerceProviderPermissionsCheckInfo: Scalars['String'];
  eCommerceProviderPermissionsCheckPending: Scalars['Boolean'];
  eCommerceProviderPermissionsMissing: Scalars['Boolean'];
  email: Scalars['String'];
  firebaseId: Scalars['ID'];
  hasData: Scalars['Boolean'];
  id: Scalars['ID'];
  keycloakId?: Maybe<Scalars['ID']>;
  locations: Array<Location>;
  mfaAuths?: Maybe<Array<MfaAuth>>;
  name: Scalars['String'];
  originalEmail?: Maybe<Scalars['String']>;
  shopifyStore?: Maybe<ShopifyStore>;
  subdomain?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userType: CloudshelfUserType;
};


export type CloudshelfUserAuditLogsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type CloudshelfUserEdge = {
  __typename?: 'CloudshelfUserEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<CloudshelfUser>;
};

export type CloudshelfUserPageInfo = {
  __typename?: 'CloudshelfUserPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export enum CloudshelfUserType {
  PrivateRetailer = 'PrivateRetailer',
  ShopifyStoreRetailer = 'ShopifyStoreRetailer',
  SuperAdmin = 'SuperAdmin',
  SupportTeam = 'SupportTeam',
  Unknown = 'Unknown'
}

export type CloudshelfUsersResponse = {
  __typename?: 'CloudshelfUsersResponse';
  edges?: Maybe<Array<CloudshelfUserEdge>>;
  pageInfo?: Maybe<CloudshelfUserPageInfo>;
  totalCount: Scalars['Int'];
};

export type CloudshelvesResponse = {
  __typename?: 'CloudshelvesResponse';
  edges?: Maybe<Array<CloudshelfEdge>>;
  pageInfo?: Maybe<CloudshelfPageInfo>;
  totalCount: Scalars['Int'];
};

export type CombinedResultsResponse = {
  __typename?: 'CombinedResultsResponse';
  results: Array<WappalyzerQueryCombinedResults>;
  totalCount: Scalars['Int'];
};

export type CreateRetailerInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  provider: Scalars['String'];
  shopifyStore: ShopifyRetailerInput;
};

export type CreateTeamMemberInput = {
  admin: Scalars['Boolean'];
  email: Scalars['String'];
  name: Scalars['String'];
};

export type DashboardSession = {
  __typename?: 'DashboardSession';
  addedToBasket: Scalars['Boolean'];
  basketCurrency: Scalars['String'];
  basketScanned: Scalars['Boolean'];
  basketValue: Scalars['Float'];
  checkoutCompleted: Scalars['Boolean'];
  cloudshelf: Scalars['String'];
  device: Scalars['String'];
  id: Scalars['ID'];
  location: Scalars['String'];
  occurredAt: Scalars['DateTime'];
  orderStatus?: Maybe<Scalars['String']>;
  orderUrl?: Maybe<Scalars['String']>;
  salesPerson?: Maybe<Scalars['String']>;
  sessionDuration: Scalars['Float'];
  viewedCheckoutQR: Scalars['Boolean'];
};

export type DashboardSessionEdge = {
  __typename?: 'DashboardSessionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<DashboardSession>;
};

export type DashboardSessionPageInfo = {
  __typename?: 'DashboardSessionPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export enum DashboardSessionType {
  AddedToBasket = 'ADDED_TO_BASKET',
  CreatedCheckout = 'CREATED_CHECKOUT',
  Interacted = 'INTERACTED',
  Purchased = 'PURCHASED',
  SentToCheckout = 'SENT_TO_CHECKOUT'
}

export type DashboardSessionsInput = {
  endDate?: Maybe<Scalars['DateTime']>;
  queryString?: Maybe<Scalars['String']>;
  sessionTypes: Array<DashboardSessionType>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type DashboardSessionsResponse = {
  __typename?: 'DashboardSessionsResponse';
  edges?: Maybe<Array<DashboardSessionEdge>>;
  pageInfo?: Maybe<DashboardSessionPageInfo>;
  totalCount: Scalars['Int'];
};

export type Dataset = {
  __typename?: 'Dataset';
  csvData: Scalars['String'];
  labels: Array<Scalars['String']>;
};


export type Device = {
  __typename?: 'Device';
  auditRecords: Array<DeviceAuditRecord>;
  bigFormatDisplay: Scalars['Boolean'];
  checkouts: Array<Checkout>;
  cloudshelf?: Maybe<Cloudshelf>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  debugMode: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  engineInfo: Scalars['String'];
  id: Scalars['ID'];
  info: Scalars['String'];
  lastPing?: Maybe<Scalars['DateTime']>;
  location?: Maybe<Location>;
  mode: DeviceMode;
  name: Scalars['String'];
  orientationInfo: DeviceOrientation;
  owner?: Maybe<CloudshelfUser>;
  ownerType: DeviceOwnerType;
  registered: Scalars['Boolean'];
  screenSizeInches: Scalars['Float'];
  sizeInfo: Scalars['String'];
  status: DeviceStatus;
  tags: Array<Scalars['String']>;
  tileClicks: Array<TileClick>;
  updatedAt: Scalars['DateTime'];
  uptimeRecords: Array<Uptime>;
};

export type DeviceAuditRecord = {
  __typename?: 'DeviceAuditRecord';
  categoryName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  device?: Maybe<Device>;
  engineVersion?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  recordType: DeviceAuditRecordType;
  updatedAt: Scalars['DateTime'];
};

export type DeviceAuditRecordEdge = {
  __typename?: 'DeviceAuditRecordEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<DeviceAuditRecord>;
};

export type DeviceAuditRecordPageInfo = {
  __typename?: 'DeviceAuditRecordPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export enum DeviceAuditRecordType {
  DisplayContent = 'DISPLAY_CONTENT',
  PageLoad = 'PAGE_LOAD'
}

export type DeviceAuditRecordsResponseResponse = {
  __typename?: 'DeviceAuditRecordsResponseResponse';
  edges?: Maybe<Array<DeviceAuditRecordEdge>>;
  pageInfo?: Maybe<DeviceAuditRecordPageInfo>;
  totalCount: Scalars['Int'];
};

export type DeviceContentLoadInput = {
  categoryName: Scalars['String'];
  deviceId: Scalars['ID'];
  imageURL: Scalars['String'];
  productName: Scalars['String'];
};

export type DeviceEdge = {
  __typename?: 'DeviceEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Device>;
};

export enum DeviceMode {
  DisplayOnly = 'DISPLAY_ONLY',
  Interactive = 'INTERACTIVE'
}

export enum DeviceOrientation {
  Horizontal = 'HORIZONTAL',
  Portrait = 'PORTRAIT',
  Square = 'SQUARE',
  Unknown = 'UNKNOWN'
}

export enum DeviceOwnerType {
  CloudshelfInternal = 'CLOUDSHELF_INTERNAL',
  Retailer = 'RETAILER'
}

export type DevicePageInfo = {
  __typename?: 'DevicePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export enum DeviceStatus {
  Live = 'LIVE',
  NoCloudshelf = 'NO_CLOUDSHELF',
  Offline = 'OFFLINE',
  Updating = 'UPDATING'
}

export type DevicesResponse = {
  __typename?: 'DevicesResponse';
  edges?: Maybe<Array<DeviceEdge>>;
  pageInfo?: Maybe<DevicePageInfo>;
  totalCount: Scalars['Int'];
};

export type DisplayModeBannerConfigInput = {
  displayMode: DisplayModeBannerDisplayMode;
  showBannerDurationInSeconds: Scalars['Float'];
};

export enum DisplayModeBannerDisplayMode {
  AfterCollection = 'AFTER_COLLECTION',
  AfterLoop = 'AFTER_LOOP',
  NoBanners = 'NO_BANNERS'
}

export enum DisplayOnlyCollectionType {
  Cheapest = 'CHEAPEST',
  MostExpensive = 'MOST_EXPENSIVE',
  Random = 'RANDOM',
  Recent = 'RECENT'
}

export type DisplayOnlyConfig = {
  __typename?: 'DisplayOnlyConfig';
  collectionType: DisplayOnlyCollectionType;
  config: CloudshelfConfig;
  displayLogo: Scalars['Boolean'];
  id: Scalars['ID'];
  includeLandscapeImages: Scalars['Boolean'];
  includePortraitImages: Scalars['Boolean'];
  includePrice: Scalars['Boolean'];
  includeProductName: Scalars['Boolean'];
  includeQRCode: Scalars['Boolean'];
  includeSquareImages: Scalars['Boolean'];
  includeStock: Scalars['Boolean'];
  logoSize: CloudshelfThemeSize;
  maxPhotosPerProduct: Scalars['Float'];
  maxProductsPerCollection: Scalars['Float'];
  minimumImageQuality: DisplayOnlyImageQuality;
  timePerPhoto: Scalars['Float'];
};

export enum DisplayOnlyImageQuality {
  Q2K = 'Q2K',
  Q4K = 'Q4K',
  Q8K = 'Q8K',
  Q540 = 'Q540',
  Q720 = 'Q720',
  Q1080 = 'Q1080',
  QLow = 'QLow'
}

export type EngineImageWithVariantInfo = {
  __typename?: 'EngineImageWithVariantInfo';
  url: Scalars['String'];
  variantId?: Maybe<Scalars['String']>;
};

export type EngineProductWithAdditionalInfo = {
  __typename?: 'EngineProductWithAdditionalInfo';
  availableForSale: Scalars['Boolean'];
  categoryHandles: Array<Scalars['String']>;
  categoryIds: Array<Scalars['String']>;
  descriptionHtml: Scalars['String'];
  featuredImageUrl?: Maybe<Scalars['String']>;
  handle: Scalars['String'];
  images: Array<EngineImageWithVariantInfo>;
  metafields: Array<ShopifyMetafield>;
  remoteUpdatedAt: Scalars['DateTime'];
  shopifyId: Scalars['ID'];
  tags: Array<Scalars['String']>;
  title: Scalars['String'];
  type: Scalars['String'];
  variants: Array<Variant>;
  vendor: Scalars['String'];
};

export type EngineProductWithAdditionalInfoEdge = {
  __typename?: 'EngineProductWithAdditionalInfoEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<EngineProductWithAdditionalInfo>;
};

export type EngineProductWithAdditionalInfoPageInfo = {
  __typename?: 'EngineProductWithAdditionalInfoPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type EngineProductWithAdditionalInfoResponse = {
  __typename?: 'EngineProductWithAdditionalInfoResponse';
  edges?: Maybe<Array<EngineProductWithAdditionalInfoEdge>>;
  pageInfo?: Maybe<EngineProductWithAdditionalInfoPageInfo>;
  totalCount: Scalars['Int'];
};

export type Filter = {
  __typename?: 'Filter';
  attributeValues: Array<AttributeValue>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  ecommProviderFieldName: Scalars['String'];
  expandedByDefault: Scalars['Boolean'];
  hiddenAttributeValues: Array<Scalars['String']>;
  id: Scalars['ID'];
  isHidden: Scalars['Boolean'];
  isMergedChild: Scalars['Boolean'];
  isSubCategory: Scalars['Boolean'];
  mergedInFilters: Array<MergedInFilter>;
  metafieldKey?: Maybe<Scalars['String']>;
  metafieldNamespace?: Maybe<Scalars['String']>;
  options?: Maybe<FilterOptions>;
  parentId?: Maybe<Scalars['String']>;
  priority: Scalars['Float'];
  type: Scalars['String'];
  valueOverrides: Array<AttributeValueOverride>;
};

export enum FilterExtractionStatus {
  Extracted = 'EXTRACTED',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN'
}

export type FilterInput = {
  attributeValues: Array<AttributeValueInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  ecommProviderFieldName: Scalars['String'];
  expandedByDefault?: Maybe<Scalars['Boolean']>;
  hiddenAttributeValues?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  isHidden?: Maybe<Scalars['Boolean']>;
  isMergedChild: Scalars['Boolean'];
  isSubCategory?: Maybe<Scalars['Boolean']>;
  mergedInFilters: Array<MergedInFilterInput>;
  metafieldKey?: Maybe<Scalars['String']>;
  metafieldNamespace?: Maybe<Scalars['String']>;
  options?: Maybe<FilterOptionsInput>;
  parentId?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
  valueOverrides?: Maybe<Array<AttributeValueOverrideInput>>;
};

export type FilterOptions = {
  __typename?: 'FilterOptions';
  baseShoesizeUnit?: Maybe<Scalars['String']>;
  capitalisationStyle?: Maybe<CapitalisationStyle>;
  orderType: FilterOrderType;
  swatches?: Maybe<Array<Swatch>>;
};

export type FilterOptionsInput = {
  baseShoesizeUnit?: Maybe<Scalars['String']>;
  capitalisationStyle?: Maybe<CapitalisationStyle>;
  orderType: FilterOrderType;
  swatches?: Maybe<Array<SwatchInput>>;
};

export enum FilterOrderType {
  Alphabetical = 'ALPHABETICAL',
  Ascending = 'ASCENDING',
  Chromatic = 'CHROMATIC',
  Custom = 'CUSTOM',
  Descending = 'DESCENDING'
}

export enum FilterType {
  Basic = 'BASIC',
  CategoryHandle = 'CATEGORY_HANDLE',
  CategoryId = 'CATEGORY_ID',
  Colour = 'COLOUR',
  Images = 'IMAGES',
  Material = 'MATERIAL',
  Price = 'PRICE',
  ProductHandle = 'PRODUCT_HANDLE',
  ProductTitle = 'PRODUCT_TITLE',
  ProductType = 'PRODUCT_TYPE',
  Promotions = 'PROMOTIONS',
  ShoeSize = 'SHOE_SIZE',
  ShopifyMetafield = 'SHOPIFY_METAFIELD',
  Size = 'SIZE',
  SortOrder = 'SORT_ORDER',
  StockLevel = 'STOCK_LEVEL',
  Tag = 'TAG',
  Vendor = 'VENDOR'
}

export type FullProductsSearchResponse = {
  __typename?: 'FullProductsSearchResponse';
  edges?: Maybe<Array<ProductEdge>>;
  pageInfo?: Maybe<ProductPageInfo>;
  totalCount: Scalars['Int'];
};

export type Identifier = {
  __typename?: 'Identifier';
  id: Scalars['ID'];
};

export type IdentifierEdge = {
  __typename?: 'IdentifierEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Identifier>;
};

export type IdentifierPageInfo = {
  __typename?: 'IdentifierPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type InteractiveBannerConfigInput = {
  displayMode: InteractiveBannerDisplayMode;
  showBannerDurationInSeconds: Scalars['Float'];
  showBannerEveryXMinutes: Scalars['Float'];
};

export enum InteractiveBannerDisplayMode {
  BannersAndAttractLoop = 'BANNERS_AND_ATTRACT_LOOP',
  BannersUntilInteraction = 'BANNERS_UNTIL_INTERACTION',
  NoBanners = 'NO_BANNERS'
}

export type KnownVersion = {
  __typename?: 'KnownVersion';
  versionString: Scalars['String'];
  versionType: Scalars['String'];
};

export type LineItem = {
  __typename?: 'LineItem';
  quantity: Scalars['Float'];
  shopifyLineItemId: Scalars['String'];
  shopifyProductId: Scalars['String'];
  shopifyVariantId: Scalars['String'];
};

export type Location = {
  __typename?: 'Location';
  allowSelection: Scalars['Boolean'];
  contactDetail: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  devices: Array<Device>;
  eCommerceProvider: ECommerceProvider;
  eCommerceProviderIdentifier: Scalars['String'];
  formattedAddress: Array<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  /** This field is only populated, when the location is loaded via liveLocations */
  numberOfDevices?: Maybe<Scalars['Float']>;
  owner: CloudshelfUser;
  retailerTeamMembers: Array<RetailerTeamMember>;
  updatedAt: Scalars['DateTime'];
};

export type LocationEdge = {
  __typename?: 'LocationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Location>;
};

export type LocationPageInfo = {
  __typename?: 'LocationPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type LocationsResponse = {
  __typename?: 'LocationsResponse';
  edges?: Maybe<Array<LocationEdge>>;
  pageInfo?: Maybe<LocationPageInfo>;
  totalCount: Scalars['Int'];
};

export type MfaAuth = {
  __typename?: 'MFAAuth';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  owner: CloudshelfUser;
  updatedAt: Scalars['DateTime'];
};

export type MfaCreateResponse = {
  __typename?: 'MFACreateResponse';
  qr: Scalars['String'];
  secret: Scalars['String'];
  uri: Scalars['String'];
};

export type MlInferResult = {
  __typename?: 'MLInferResult';
  confidence: Scalars['Float'];
  index: Scalars['Float'];
  inferTime: Scalars['Float'];
  label: Scalars['String'];
};

export type MlModel = {
  __typename?: 'MLModel';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  labels: Array<Scalars['String']>;
  name: MlModelType;
  updatedAt: Scalars['DateTime'];
  useVersion: Scalars['String'];
};

export type MlModelMetadata = {
  __typename?: 'MLModelMetadata';
  inputShape: Array<Scalars['Int']>;
  name: Scalars['String'];
  outputShape: Array<Scalars['Int']>;
  version: Scalars['Float'];
};

export enum MlModelType {
  ImageType = 'ImageType',
  Include = 'Include',
  Person = 'Person'
}

export type MlTagDefinition = {
  __typename?: 'MLTagDefinition';
  createdAt: Scalars['DateTime'];
  displayType: MlTagDefinitionDisplayType;
  id: Scalars['ID'];
  name: Scalars['String'];
  options: Array<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** The display type of the ML Tag */
export enum MlTagDefinitionDisplayType {
  Autocomplete = 'autocomplete',
  Radio = 'radio'
}

export type MlTagDefinitionInput = {
  displayType: MlTagDefinitionDisplayType;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  options: Array<Scalars['String']>;
};

export type MergedInFilter = {
  __typename?: 'MergedInFilter';
  displayName: Scalars['String'];
  id: Scalars['ID'];
};

export type MergedInFilterInput = {
  displayName: Scalars['String'];
  id: Scalars['ID'];
};

export type MlImageTag = {
  __typename?: 'MlImageTag';
  confidence: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isHumanConfirmed: Scalars['Boolean'];
  mlVersion: Scalars['String'];
  productImage: ProductImage;
  tagName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type MlImageTagInput = {
  imageId: Scalars['String'];
  tagName: Scalars['String'];
  value: Scalars['String'];
};

export type MobileHandoff = {
  __typename?: 'MobileHandoff';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isCanceled: Scalars['Boolean'];
  isScanned: Scalars['Boolean'];
  productOptionId: Scalars['Float'];
  shopifyProductHandle: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uploadedImageUrl?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addFiltersToBeIncludedInCloudshelf: Array<CloudshelfIncludableFilter>;
  cancelHandoff: MobileHandoff;
  convertUserType: Scalars['Boolean'];
  createCheckout: Checkout;
  createMobileHandoff: MobileHandoff;
  createNewCloudshelf: Cloudshelf;
  createRetailer: CloudshelfUser;
  createTeamMember: CloudshelfUser;
  createTrackedURL: TrackedUrlResponse;
  deleteCloudshelfUsers: Scalars['Boolean'];
  deleteCloudshelves: Scalars['Boolean'];
  deleteDevices: Scalars['Boolean'];
  deleteLocations: Scalars['Boolean'];
  deleteMLTagDefinition: Scalars['Boolean'];
  deleteOldProdWebhooks: Scalars['Boolean'];
  deleteRetailerTeamMembers: Scalars['Boolean'];
  deleteSession: Scalars['Boolean'];
  deleteTask: Scalars['Boolean'];
  duplicateCloudshelves: Array<Cloudshelf>;
  editSubscription: SubscriptionEntity;
  endSession: Session;
  flagImage: Scalars['Boolean'];
  forceBulkSync: Scalars['String'];
  forceFilterExtraction: Scalars['String'];
  forceSyncAllLocations: Scalars['String'];
  forceWebhookRecreation: Scalars['Boolean'];
  generateMFARequest: Scalars['String'];
  generateMFASecret: MfaCreateResponse;
  healthCheckNotifier: Scalars['Boolean'];
  installShopifyHooks: Scalars['Boolean'];
  launchSearch: Scalars['ID'];
  launchWappalyzerQuery: Scalars['Boolean'];
  logWebhooks: Scalars['Boolean'];
  mergeCloudshelfFilter: Cloudshelf;
  migrateCollectionsToPowerTiles: Scalars['Boolean'];
  migrateToCloudflareImages: Scalars['String'];
  newSession: Session;
  pairDevice: Device;
  promoteTask: Scalars['Boolean'];
  recheckPermissions: Scalars['Boolean'];
  regenerateStorefrontToken: Scalars['Boolean'];
  removeFilterToBeIncludedInCloudshelf: Array<CloudshelfIncludableFilter>;
  removeWebhooks: Scalars['Boolean'];
  reportContentLoad: Scalars['Boolean'];
  reportTileClick: TileClick;
  resetUsersMFA: Scalars['Boolean'];
  restartLongRunningJobs: Scalars['Boolean'];
  restartTask: Scalars['Boolean'];
  runPostUpdateFullSync: Scalars['String'];
  runPostUpdatePermissionChecks: Scalars['String'];
  runPostUpdateWebhooksTool: Scalars['String'];
  saveMLTagDefinition: MlTagDefinition;
  saveTaggedImage: Scalars['Boolean'];
  scheduleSyncsForStoresOverOneDayOld: Scalars['Boolean'];
  sendResetCloudshelfUsersPasswordEmail: Scalars['Boolean'];
  sendResetPasswordEmail: Scalars['Boolean'];
  setActiveVersion: Scalars['Boolean'];
  setClaimsAndDisplayNameForFirebaseUid: Scalars['Boolean'];
  setHandoffImageUrl: MobileHandoff;
  setPausedNobleQueuesByType: Scalars['Boolean'];
  subscribe: Scalars['String'];
  synchroniseCategories: Array<Category>;
  synchroniseWithCloudshelfUsersProvider: Scalars['Boolean'];
  toggleDeviceDebugMode: Device;
  toggleInMaintenanceMode: Scalars['Boolean'];
  toggleNoblePaused: Scalars['Boolean'];
  unmergeCloudshelfFilter: Cloudshelf;
  unsubscribe: Scalars['Boolean'];
  updateCloudshelfAttractScreen: Cloudshelf;
  updateCloudshelfDebug: Cloudshelf;
  updateCloudshelfDetails: Cloudshelf;
  updateCloudshelfDiscounting: Cloudshelf;
  updateCloudshelfDisplayOnly: Cloudshelf;
  updateCloudshelfFilters: Cloudshelf;
  updateCloudshelfPDPBlocks: Cloudshelf;
  updateCloudshelfPowerTiles: Cloudshelf;
  updateCloudshelfPreviewState: Cloudshelf;
  updateCloudshelfStockOptions: Cloudshelf;
  updateCloudshelfTheme: Cloudshelf;
  updateDeviceCloudshelf: Device;
  updateDeviceDetails: Device;
  updateDeviceLocation: Device;
  updateRetailerRules: ShopifyStore;
  updateSession: Session;
  upsertLocation: Location;
  upsertMLModel: MlModel;
  upsertRetailerTeamMember: RetailerTeamMember;
  uptimePing: Scalars['Boolean'];
  utilDeduplicateProducts: Scalars['Int'];
  verifyMFARequest: Scalars['String'];
};


export type MutationAddFiltersToBeIncludedInCloudshelfArgs = {
  cloudshelfId: Scalars['String'];
  filtersToAdd: Array<CloudshelfIncludableFilterInput>;
};


export type MutationCancelHandoffArgs = {
  id: Scalars['ID'];
};


export type MutationConvertUserTypeArgs = {
  cloudshelfUserId: Scalars['ID'];
  newUserType: CloudshelfUserType;
};


export type MutationCreateCheckoutArgs = {
  checkoutCreateInput: CheckoutCreateInput;
};


export type MutationCreateMobileHandoffArgs = {
  cloudshelfId: Scalars['ID'];
  productHandle: Scalars['String'];
  productOptionId: Scalars['Int'];
};


export type MutationCreateNewCloudshelfArgs = {
  forUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  wizardGenerated: Scalars['Boolean'];
};


export type MutationCreateRetailerArgs = {
  input: CreateRetailerInput;
};


export type MutationCreateTeamMemberArgs = {
  input: CreateTeamMemberInput;
};


export type MutationCreateTrackedUrlArgs = {
  checkoutId?: Maybe<Scalars['ID']>;
  cloudshelfId: Scalars['ID'];
  urlToTrack: Scalars['String'];
};


export type MutationDeleteCloudshelfUsersArgs = {
  cloudshelfUserIds: Array<Scalars['ID']>;
};


export type MutationDeleteCloudshelvesArgs = {
  cloudshelfIds: Array<Scalars['ID']>;
};


export type MutationDeleteDevicesArgs = {
  deviceIds: Array<Scalars['ID']>;
};


export type MutationDeleteLocationsArgs = {
  locationIds: Array<Scalars['ID']>;
};


export type MutationDeleteMlTagDefinitionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOldProdWebhooksArgs = {
  accessToken: Scalars['String'];
  domain: Scalars['String'];
};


export type MutationDeleteRetailerTeamMembersArgs = {
  retailerTeamMemberIds: Array<Scalars['ID']>;
};


export type MutationDeleteSessionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTaskArgs = {
  id: Scalars['String'];
};


export type MutationDuplicateCloudshelvesArgs = {
  cloudshelfIds: Array<Scalars['ID']>;
};


export type MutationEditSubscriptionArgs = {
  features: SubscriptionFeaturesInput;
  forUserId?: Maybe<Scalars['ID']>;
};


export type MutationEndSessionArgs = {
  id: Scalars['ID'];
  interactions: Scalars['Int'];
};


export type MutationFlagImageArgs = {
  flag: Scalars['Boolean'];
  imageId: Scalars['ID'];
};


export type MutationForceBulkSyncArgs = {
  storeDomain: Scalars['String'];
};


export type MutationForceFilterExtractionArgs = {
  cloudshelfId: Scalars['ID'];
  runWithoutQueue?: Maybe<Scalars['Boolean']>;
};


export type MutationForceWebhookRecreationArgs = {
  accessToken: Scalars['String'];
  domain: Scalars['String'];
};


export type MutationHealthCheckNotifierArgs = {
  key: Scalars['String'];
};


export type MutationInstallShopifyHooksArgs = {
  storeId: Scalars['String'];
};


export type MutationLaunchSearchArgs = {
  searchInput: SearchInput;
};


export type MutationLaunchWappalyzerQueryArgs = {
  queryInput: QueryInput;
};


export type MutationLogWebhooksArgs = {
  accessToken: Scalars['String'];
  domain: Scalars['String'];
};


export type MutationMergeCloudshelfFilterArgs = {
  childFilterIds: Array<Scalars['ID']>;
  cloudshelfId: Scalars['ID'];
  parentFilterId: Scalars['ID'];
};


export type MutationNewSessionArgs = {
  deviceId: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  salesAssistantId?: Maybe<Scalars['ID']>;
};


export type MutationPairDeviceArgs = {
  cloudshelfInternalDevice?: Maybe<Scalars['Boolean']>;
  forUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pairingCode: Scalars['String'];
};


export type MutationPromoteTaskArgs = {
  id: Scalars['String'];
};


export type MutationRecheckPermissionsArgs = {
  cloudshelfUserId?: Maybe<Scalars['ID']>;
};


export type MutationRegenerateStorefrontTokenArgs = {
  storeDomain: Scalars['String'];
};


export type MutationRemoveFilterToBeIncludedInCloudshelfArgs = {
  cloudshelfId: Scalars['String'];
  filterId?: Maybe<Scalars['String']>;
  filterToRemove?: Maybe<CloudshelfIncludableFilterInput>;
};


export type MutationRemoveWebhooksArgs = {
  accessToken: Scalars['String'];
  domain: Scalars['String'];
};


export type MutationReportContentLoadArgs = {
  input: DeviceContentLoadInput;
};


export type MutationReportTileClickArgs = {
  deviceId: Scalars['ID'];
  powerTileId: Scalars['ID'];
  sessionId: Scalars['ID'];
};


export type MutationResetUsersMfaArgs = {
  emailAddress: Scalars['String'];
};


export type MutationRestartLongRunningJobsArgs = {
  key: Scalars['String'];
};


export type MutationRestartTaskArgs = {
  id: Scalars['String'];
};


export type MutationSaveMlTagDefinitionArgs = {
  input: MlTagDefinitionInput;
};


export type MutationSaveTaggedImageArgs = {
  input: MlImageTagInput;
};


export type MutationScheduleSyncsForStoresOverOneDayOldArgs = {
  key: Scalars['String'];
};


export type MutationSendResetCloudshelfUsersPasswordEmailArgs = {
  cloudshelfUserIds: Array<Scalars['ID']>;
};


export type MutationSendResetPasswordEmailArgs = {
  email: Scalars['String'];
};


export type MutationSetActiveVersionArgs = {
  key: Scalars['String'];
  type: VersionType;
  versionString: Scalars['String'];
};


export type MutationSetClaimsAndDisplayNameForFirebaseUidArgs = {
  claims: Array<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  firebaseUid: Scalars['String'];
};


export type MutationSetHandoffImageUrlArgs = {
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
};


export type MutationSetPausedNobleQueuesByTypeArgs = {
  types: Array<TrackedTaskType>;
};


export type MutationSubscribeArgs = {
  features: SubscriptionFeaturesInput;
  forUserId?: Maybe<Scalars['ID']>;
};


export type MutationSynchroniseWithCloudshelfUsersProviderArgs = {
  cloudshelfUserId: Scalars['ID'];
};


export type MutationToggleDeviceDebugModeArgs = {
  deviceId: Scalars['ID'];
};


export type MutationUnmergeCloudshelfFilterArgs = {
  cloudshelfId: Scalars['ID'];
  parentFilterId: Scalars['ID'];
};


export type MutationUnsubscribeArgs = {
  forUserId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateCloudshelfAttractScreenArgs = {
  bannerConfig: InteractiveBannerConfigInput;
  classicAttractScreen?: Maybe<CloudshelfThemeClassicAttractScreenInput>;
  cloudshelfId: Scalars['ID'];
  minimalAttractScreen?: Maybe<CloudshelfThemeMinimalAttractScreenInput>;
};


export type MutationUpdateCloudshelfDebugArgs = {
  cloudshelfId: Scalars['ID'];
  input: CloudshelfDebugInput;
};


export type MutationUpdateCloudshelfDetailsArgs = {
  cloudshelfId: Scalars['ID'];
  details: CloudshelfDetailsInput;
};


export type MutationUpdateCloudshelfDiscountingArgs = {
  cloudshelfId: Scalars['ID'];
  couponsEnabled: Scalars['Boolean'];
};


export type MutationUpdateCloudshelfDisplayOnlyArgs = {
  bannerConfig: DisplayModeBannerConfigInput;
  cloudshelfId: Scalars['ID'];
  input: CloudshelfDisplayOnlyInput;
};


export type MutationUpdateCloudshelfFiltersArgs = {
  input: CloudshelfUpdateFiltersInput;
};


export type MutationUpdateCloudshelfPdpBlocksArgs = {
  blocks: Array<PdpBlocksInputType>;
  cloudshelfId: Scalars['ID'];
};


export type MutationUpdateCloudshelfPowerTilesArgs = {
  banners: Array<CloudshelfBannerInput>;
  categoryPowerTiles: Array<CategoryPowerTileInput>;
  cloudshelfId: Scalars['ID'];
  qrPowerTiles: Array<QrCodePowerTileInput>;
};


export type MutationUpdateCloudshelfPreviewStateArgs = {
  cloudshelfId: Scalars['ID'];
  confettiShown?: Maybe<Scalars['Boolean']>;
  isPreviewed?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateCloudshelfStockOptionsArgs = {
  cloudshelfId: Scalars['ID'];
  stockOptions: CloudshelfStockOptionsInput;
};


export type MutationUpdateCloudshelfThemeArgs = {
  bodyFont: CloudshelfThemeFontInput;
  cloudshelfId: Scalars['ID'];
  headingsFont: CloudshelfThemeFontInput;
  image?: Maybe<Scalars['String']>;
  imageAnchor: CloudshelfThemeImageAnchor;
  inputsRounded: Scalars['Boolean'];
  mainTextColour: Scalars['String'];
  primaryColour: Scalars['String'];
  purchaseTextColour: Scalars['String'];
  radiusRounded: Scalars['Boolean'];
  removeBranding: Scalars['Boolean'];
  subheadingsFont: CloudshelfThemeFontInput;
  tileSizeModifier: Scalars['Float'];
  useProductAnimations: Scalars['Boolean'];
};


export type MutationUpdateDeviceCloudshelfArgs = {
  input: UpdateDeviceCloudshelfInput;
};


export type MutationUpdateDeviceDetailsArgs = {
  input: UpdateDeviceDetailsInput;
};


export type MutationUpdateDeviceLocationArgs = {
  input: UpdateDeviceLocationInput;
};


export type MutationUpdateRetailerRulesArgs = {
  forUserId?: Maybe<Scalars['String']>;
  input: RetailerRulesInput;
};


export type MutationUpdateSessionArgs = {
  addedToBasket: Scalars['Boolean'];
  basketCurrencyCode: Scalars['String'];
  basketValue: Scalars['Float'];
  id: Scalars['ID'];
  interactions: Scalars['Int'];
  salesAssistantId?: Maybe<Scalars['ID']>;
};


export type MutationUpsertLocationArgs = {
  forUserId?: Maybe<Scalars['String']>;
  input: UpsertLocationInput;
};


export type MutationUpsertMlModelArgs = {
  labels: Array<Scalars['String']>;
  name: MlModelType;
  version: Scalars['String'];
};


export type MutationUpsertRetailerTeamMemberArgs = {
  forUserId?: Maybe<Scalars['String']>;
  input: UpsertRetailerTeamMemberInput;
};


export type MutationUptimePingArgs = {
  deviceId: Scalars['ID'];
  engineVersion: Scalars['String'];
  screenHeight: Scalars['Int'];
  screenWidth: Scalars['Int'];
  userAgent: Scalars['String'];
};


export type MutationVerifyMfaRequestArgs = {
  authcode: Scalars['String'];
  requestId: Scalars['String'];
};

export type NobleHealth = {
  __typename?: 'NobleHealth';
  numberOfJobsAddedInTheLastHour: Scalars['Int'];
  numberOfJobsCompletedInTheLastHour: Scalars['Int'];
  numberOfJobsFailedInTheLastHour: Scalars['Int'];
  numberOfRetailersSyncErrors: Scalars['Int'];
  status: NobleHealthStatus;
};

export enum NobleHealthStatus {
  Degraded = 'DEGRADED',
  Error = 'ERROR',
  Ok = 'OK'
}

export type NobleTask = {
  __typename?: 'NobleTask';
  beingProcessedBy?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  data: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  errors?: Maybe<Array<Scalars['String']>>;
  failed: Scalars['Boolean'];
  finishTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isComplete: Scalars['Boolean'];
  logMessages?: Maybe<Array<Scalars['String']>>;
  priority: Scalars['Float'];
  processingOnInstance?: Maybe<Scalars['String']>;
  retries: Scalars['Float'];
  scheduledStart?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['DateTime']>;
  status: NobleTaskStatus;
  storeId?: Maybe<Scalars['ID']>;
  taskType: TrackedTaskType;
  updatedAt: Scalars['DateTime'];
};

export type NobleTaskEdge = {
  __typename?: 'NobleTaskEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<NobleTask>;
};

export type NobleTaskPageInfo = {
  __typename?: 'NobleTaskPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type NobleTaskResponse = {
  __typename?: 'NobleTaskResponse';
  edges?: Maybe<Array<NobleTaskEdge>>;
  pageInfo?: Maybe<NobleTaskPageInfo>;
  totalCount: Scalars['Int'];
};

export enum NobleTaskStatus {
  All = 'All',
  Complete = 'Complete',
  Failed = 'Failed',
  InProgress = 'InProgress',
  Pending = 'Pending'
}

export type Order = {
  __typename?: 'Order';
  checkout: Checkout;
  createdAt: Scalars['DateTime'];
  currencyCode: Scalars['String'];
  id: Scalars['ID'];
  lineItems: Array<LineItem>;
  location?: Maybe<Location>;
  status: Scalars['String'];
  totalValue: Scalars['Float'];
  totalValueUsd: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type OrderInput = {
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PdpBlockDescription = {
  __typename?: 'PDPBlockDescription';
  displayText: Scalars['String'];
  id: Scalars['ID'];
  position: Scalars['Int'];
  removeThemeShortcodes: Scalars['Boolean'];
  style: PdpBlockStyle;
  unionTypeName: Scalars['String'];
};

export type PdpBlockMetafield = {
  __typename?: 'PDPBlockMetafield';
  displayText: Scalars['String'];
  id: Scalars['ID'];
  key: Scalars['String'];
  metafieldDisplayType: PdpBlockMetafieldDisplayType;
  namespace: Scalars['String'];
  position: Scalars['Int'];
  style: PdpBlockStyle;
  unionTypeName: Scalars['String'];
};

export enum PdpBlockMetafieldDisplayType {
  Image = 'IMAGE',
  Text = 'TEXT',
  YoutubeVideo = 'YOUTUBE_VIDEO'
}

export type PdpBlockProductData = {
  __typename?: 'PDPBlockProductData';
  displayText: Scalars['String'];
  id: Scalars['ID'];
  position: Scalars['Int'];
  productDataType: PdpBlockProductDataType;
  style: PdpBlockStyle;
  unionTypeName: Scalars['String'];
};

export enum PdpBlockProductDataType {
  Sku = 'SKU',
  Vendor = 'VENDOR'
}

export type PdpBlockSpacer = {
  __typename?: 'PDPBlockSpacer';
  displayText: Scalars['String'];
  id: Scalars['ID'];
  position: Scalars['Int'];
  style: PdpBlockStyle;
  unionTypeName: Scalars['String'];
};

export enum PdpBlockStyle {
  Collapsible = 'COLLAPSIBLE',
  Expandable = 'EXPANDABLE',
  Static = 'STATIC'
}

export type PdpBlockUnion = PdpBlockDescription | PdpBlockMetafield | PdpBlockProductData | PdpBlockSpacer;

export type PdpBlocksInputType = {
  displayText: Scalars['String'];
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  metafieldDisplayType?: Maybe<PdpBlockMetafieldDisplayType>;
  namespace?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  productDataType?: Maybe<PdpBlockProductDataType>;
  removeThemeShortcodes?: Maybe<Scalars['Boolean']>;
  style?: Maybe<PdpBlockStyle>;
  unionTypeName: Scalars['String'];
};

export enum PdpBlockIncludableTypeEnum {
  Metafield = 'METAFIELD',
  ProductDataDescription = 'PRODUCT_DATA_DESCRIPTION',
  ProductDataSku = 'PRODUCT_DATA_SKU',
  ProductDataVendor = 'PRODUCT_DATA_VENDOR',
  Spacer = 'SPACER'
}

export type PermalinkCheckout = {
  __typename?: 'PermalinkCheckout';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  status: Scalars['String'];
  token: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PermissionsResponse = {
  __typename?: 'PermissionsResponse';
  adminPermissions: Array<ShopifyPermission>;
  storefrontPermissions: Array<ShopifyPermission>;
};

export type PowerTile = {
  __typename?: 'PowerTile';
  cloudshelf: Cloudshelf;
  configurationIssues: Array<PowerTileConfigurationIssue>;
  createdAt: Scalars['DateTime'];
  definition: PowerTilesUnion;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  pinned: Scalars['Boolean'];
  priority: Scalars['Float'];
  tileClicks: Array<TileClick>;
  type: PowerTileType;
  updatedAt: Scalars['DateTime'];
};

export enum PowerTileBackgroundType {
  Gradient = 'GRADIENT',
  Image = 'IMAGE',
  SolidColour = 'SOLID_COLOUR',
  Transparent = 'TRANSPARENT'
}

export enum PowerTileConfigurationIssue {
  Cta = 'CTA',
  QrUrl = 'QR_URL',
  Text = 'TEXT'
}

export enum PowerTileType {
  Category = 'CATEGORY',
  Qrcode = 'QRCODE'
}

export type PowerTilesUnion = CategoryPowerTile | QrCodePowerTile;

export type Product = {
  __typename?: 'Product';
  availableForSale: Scalars['Boolean'];
  categories?: Maybe<Array<Category>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  descriptionHtml: Scalars['String'];
  domain: Scalars['String'];
  featuredImageUrl?: Maybe<Scalars['String']>;
  handle: Scalars['String'];
  id: Scalars['ID'];
  remoteUpdatedAt: Scalars['DateTime'];
  shopifyId: Scalars['ID'];
  sku: Scalars['String'];
  storefrontId: Scalars['ID'];
  tags: Array<Scalars['String']>;
  title: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vendor: Scalars['String'];
};

export type ProductEdge = {
  __typename?: 'ProductEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Product>;
};

export type ProductFilter = {
  options?: Maybe<Array<SelectedFilterOption>>;
};

export type ProductImage = {
  __typename?: 'ProductImage';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isMLFlagged: Scalars['Boolean'];
  mlTags: Array<MlImageTag>;
  product: Product;
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export type ProductPageInfo = {
  __typename?: 'ProductPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type ProductsSearchResponse = {
  __typename?: 'ProductsSearchResponse';
  edges?: Maybe<Array<IdentifierEdge>>;
  pageInfo?: Maybe<IdentifierPageInfo>;
  totalCount: Scalars['Int'];
};

export type QrCodePowerTile = {
  __typename?: 'QRCodePowerTile';
  backgroundImage: Scalars['String'];
  backgroundPrimaryColor: Scalars['String'];
  backgroundSecondaryColor: Scalars['String'];
  backgroundType: PowerTileBackgroundType;
  callToAction: Scalars['String'];
  icon: Scalars['String'];
  pinned: Scalars['Boolean'];
  qrCodeText: Scalars['String'];
  qrCodeURL: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  useIcon: Scalars['Boolean'];
};

export type QrCodePowerTileInput = {
  backgroundImage: Scalars['String'];
  backgroundPrimaryColor: Scalars['String'];
  backgroundSecondaryColor: Scalars['String'];
  backgroundType: PowerTileBackgroundType;
  callToAction: Scalars['String'];
  icon: Scalars['String'];
  pinned: Scalars['Boolean'];
  powerTileId?: Maybe<Scalars['String']>;
  priority: Scalars['Float'];
  qrCodeText: Scalars['String'];
  qrCodeURL: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  useIcon: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  allShopifyStores: Array<ShopifyStore>;
  benchmark: Scalars['Int'];
  canRegisterDevice: Scalars['Boolean'];
  categories: Array<Category>;
  checkAllPermissionsForCloudshelfUser: PermissionsResponse;
  checkGrantedPermissionForCloudshelfUser: PermissionsResponse;
  cloudshelf: Cloudshelf;
  cloudshelfCategories: CategoriesResponse;
  cloudshelfCategoryById: Category;
  cloudshelfProductSearch: FullProductsSearchResponse;
  cloudshelfUser: CloudshelfUser;
  cloudshelfUsers: CloudshelfUsersResponse;
  cloudshelves: CloudshelvesResponse;
  currentSubscription?: Maybe<SubscriptionEntity>;
  currentUser: CloudshelfUser;
  dashboardSessions: DashboardSessionsResponse;
  dateTest: Scalars['String'];
  device?: Maybe<Device>;
  deviceAuditRecords: DeviceAuditRecordsResponseResponse;
  devices: DevicesResponse;
  engagements: Array<Session>;
  engineProducts: EngineProductWithAdditionalInfoResponse;
  getCloudshelfConfig: CloudshelfConfigResult;
  getCustomTokenFromShopifySessionToken: Scalars['String'];
  getFiltersIncludedInCloudshelf: Array<CloudshelfIncludableFilter>;
  getFiltersNotIncludedInCloudshelf: Array<CloudshelfIncludableFilter>;
  getFirstUseInformation: StoreFirstUseResponse;
  getGoogleGenderDataset: Scalars['String'];
  getGoogleProductTypeDataset: Scalars['String'];
  getMLDataset: Dataset;
  getMobileHandoff?: Maybe<MobileHandoff>;
  getModelMetadata?: Maybe<MlModelMetadata>;
  getPDPBlocksNotIncludedOnCloudshelf: Array<CloudshelfIncludablePdpBlock>;
  getPresignedCustomisationUrl: Scalars['String'];
  getProviderDetails?: Maybe<CloudshelfProviderConfig>;
  getSession?: Maybe<Session>;
  getVersionByType: KnownVersion;
  imageTagNotifier: Scalars['Boolean'];
  infer: MlInferResult;
  isCloudshelfNameUsed: Scalars['Boolean'];
  isInMaintenanceMode: Scalars['Boolean'];
  isNobleEnabled: Scalars['Boolean'];
  isTrackedURLScanned: Scalars['Boolean'];
  liveCloudshelves: Array<Cloudshelf>;
  liveLocations: Array<Location>;
  location: Location;
  locations: LocationsResponse;
  mlModels: Array<MlModel>;
  mlTagDefinitions: Array<MlTagDefinition>;
  nobleHealth: NobleHealth;
  nobleQueueByTaskType: TaskQueue;
  nobleQueues: Array<TaskQueue>;
  nobleTask?: Maybe<NobleTask>;
  nobleTasksByTypes: NobleTaskResponse;
  orders: Array<Order>;
  pausedNobleQueues: Array<TrackedTaskType>;
  permalinkCheckoutUrls: Array<PermalinkCheckout>;
  productIds: ProductsSearchResponse;
  retailerTeamMember: RetailerTeamMember;
  retailerTeamMembers: RetailerTeamMembersResponse;
  shopifyStore?: Maybe<ShopifyStore>;
  shopifyStoreById: ShopifyStore;
  shopifyStores: ShopifyStoresResponse;
  storeFinderCSVData: Scalars['String'];
  storeFinderSearchCombinedResults: CombinedResultsResponse;
  storeFinderSearchProgress: Scalars['String'];
  storeScopesValid: Scalars['Boolean'];
  subscriptionById: SubscriptionEntity;
  subscriptionPlans: Array<SubscriptionPlan>;
  taggedImage?: Maybe<ProductImage>;
  taggedImages: Array<ProductImage>;
  tensorflowOnline: Scalars['Boolean'];
  testChunk: Scalars['Boolean'];
  uptimeRecords: Array<UptimePercentageTotalResponse>;
};


export type QueryBenchmarkArgs = {
  batchSize: Scalars['Float'];
  count: Scalars['Float'];
};


export type QueryCanRegisterDeviceArgs = {
  forUserId?: Maybe<Scalars['ID']>;
};


export type QueryCategoriesArgs = {
  forUserId?: Maybe<Scalars['String']>;
};


export type QueryCheckAllPermissionsForCloudshelfUserArgs = {
  retailerId: Scalars['ID'];
};


export type QueryCheckGrantedPermissionForCloudshelfUserArgs = {
  retailerId: Scalars['ID'];
};


export type QueryCloudshelfArgs = {
  cloudshelfId: Scalars['ID'];
};


export type QueryCloudshelfCategoriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  cloudshelfId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCloudshelfCategoryByIdArgs = {
  categoryId: Scalars['String'];
};


export type QueryCloudshelfProductSearchArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  cloudshelfId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  input?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCloudshelfUserArgs = {
  cloudshelfUserId: Scalars['ID'];
};


export type QueryCloudshelfUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<UserListOrderInput>;
  searchQuery?: Maybe<Scalars['String']>;
  userTypes: Array<CloudshelfUserType>;
};


export type QueryCloudshelvesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forUserId?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderInput>;
  searchQuery?: Maybe<Scalars['String']>;
};


export type QueryCurrentSubscriptionArgs = {
  forUserId?: Maybe<Scalars['ID']>;
};


export type QueryCurrentUserArgs = {
  cloudshelfUserId?: Maybe<Scalars['ID']>;
};


export type QueryDashboardSessionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forUserId?: Maybe<Scalars['ID']>;
  input: DashboardSessionsInput;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderInput>;
};


export type QueryDateTestArgs = {
  date: Scalars['DateTime'];
};


export type QueryDeviceArgs = {
  deviceId: Scalars['String'];
};


export type QueryDeviceAuditRecordsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  deviceId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderInput>;
};


export type QueryDevicesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forUserId?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderInput>;
  searchQuery?: Maybe<Scalars['String']>;
};


export type QueryEngagementsArgs = {
  cloudshelfId?: Maybe<Scalars['ID']>;
  deviceId?: Maybe<Scalars['ID']>;
  endDate: Scalars['DateTime'];
  includeIncomplete?: Maybe<Scalars['Boolean']>;
  retailerIds?: Maybe<Array<Scalars['ID']>>;
  startDate: Scalars['DateTime'];
};


export type QueryEngineProductsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  cloudshelfId: Scalars['ID'];
  explicitProductHandle?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  includeMetafieldNamespaces?: Maybe<Array<Scalars['String']>>;
  isDisplayMode: Scalars['Boolean'];
  last?: Maybe<Scalars['Int']>;
};


export type QueryGetCloudshelfConfigArgs = {
  cloudshelfName: Scalars['String'];
  engineVersion?: Maybe<Scalars['String']>;
  reportPageLoad?: Maybe<Scalars['Boolean']>;
  retailerSubdomain: Scalars['String'];
};


export type QueryGetCustomTokenFromShopifySessionTokenArgs = {
  sessionToken: Scalars['String'];
};


export type QueryGetFiltersIncludedInCloudshelfArgs = {
  cloudshelfId: Scalars['String'];
};


export type QueryGetFiltersNotIncludedInCloudshelfArgs = {
  cloudshelfId: Scalars['String'];
};


export type QueryGetGoogleProductTypeDatasetArgs = {
  level: Scalars['Int'];
};


export type QueryGetMlDatasetArgs = {
  tagDefinitionId: Scalars['ID'];
};


export type QueryGetMobileHandoffArgs = {
  id: Scalars['ID'];
};


export type QueryGetModelMetadataArgs = {
  name: MlModelType;
  version: Scalars['String'];
};


export type QueryGetPdpBlocksNotIncludedOnCloudshelfArgs = {
  cloudshelfId: Scalars['ID'];
};


export type QueryGetPresignedCustomisationUrlArgs = {
  type: Scalars['String'];
};


export type QueryGetProviderDetailsArgs = {
  cloudshelfConfigId: Scalars['ID'];
  provider: ECommerceProvider;
};


export type QueryGetSessionArgs = {
  id: Scalars['ID'];
};


export type QueryGetVersionByTypeArgs = {
  type: VersionType;
};


export type QueryImageTagNotifierArgs = {
  key: Scalars['String'];
};


export type QueryInferArgs = {
  name: MlModelType;
  url: Scalars['String'];
};


export type QueryIsCloudshelfNameUsedArgs = {
  forUserId?: Maybe<Scalars['String']>;
  ignoreCloudshelfId: Scalars['ID'];
  name: Scalars['String'];
};


export type QueryIsTrackedUrlScannedArgs = {
  id: Scalars['String'];
};


export type QueryLiveCloudshelvesArgs = {
  forUserId?: Maybe<Scalars['String']>;
};


export type QueryLiveLocationsArgs = {
  forUserId?: Maybe<Scalars['String']>;
};


export type QueryLocationArgs = {
  locationId: Scalars['String'];
};


export type QueryLocationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forUserId?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderInput>;
  searchQuery?: Maybe<Scalars['String']>;
};


export type QueryNobleQueueByTaskTypeArgs = {
  taskType: TrackedTaskType;
};


export type QueryNobleTaskArgs = {
  id: Scalars['String'];
};


export type QueryNobleTasksByTypesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  searchTypes?: Maybe<Array<NobleTaskStatus>>;
  types?: Maybe<Array<TrackedTaskType>>;
};


export type QueryOrdersArgs = {
  forUserId?: Maybe<Scalars['ID']>;
};


export type QueryPermalinkCheckoutUrlsArgs = {
  cloudshelfId: Array<Scalars['ID']>;
};


export type QueryProductIdsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  cloudshelfId: Scalars['ID'];
  filter: ProductFilter;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryRetailerTeamMemberArgs = {
  retailerTeamMemberId: Scalars['ID'];
};


export type QueryRetailerTeamMembersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forUserId?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderInput>;
  searchQuery?: Maybe<Scalars['String']>;
};


export type QueryShopifyStoreByIdArgs = {
  storeId: Scalars['ID'];
};


export type QueryShopifyStoresArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryStoreFinderCsvDataArgs = {
  storeFinderSearchId?: Maybe<Scalars['String']>;
};


export type QueryStoreFinderSearchCombinedResultsArgs = {
  amountToSkip: Scalars['Float'];
  searchTerm: Scalars['String'];
};


export type QueryStoreFinderSearchProgressArgs = {
  storeFinderSearchId: Scalars['String'];
};


export type QueryStoreScopesValidArgs = {
  domain: Scalars['String'];
};


export type QuerySubscriptionByIdArgs = {
  id: Scalars['ID'];
};


export type QueryTaggedImageArgs = {
  imageId: Scalars['ID'];
};


export type QueryTaggedImagesArgs = {
  complete: Scalars['Boolean'];
  flagged: Scalars['Boolean'];
  includeUnconfirmed: Scalars['Boolean'];
  limit?: Maybe<Scalars['Int']>;
  retailerId: Scalars['ID'];
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTensorflowOnlineArgs = {
  key: Scalars['String'];
};


export type QueryUptimeRecordsArgs = {
  date: Scalars['DateTime'];
  retailerId?: Maybe<Scalars['ID']>;
  toDate?: Maybe<Scalars['DateTime']>;
};

export type QueryInput = {
  email: Scalars['String'];
  storeFinderSearchId: Scalars['String'];
};

export enum ResourceType {
  Cloudshelf = 'Cloudshelf',
  Device = 'Device',
  Location = 'Location',
  RetailerTeamMember = 'RetailerTeamMember',
  ShopifySync = 'ShopifySync'
}

export type RetailerRulesInput = {
  displaySalesPersonAllocationSelection: Scalars['Boolean'];
  salesPersonClearingRule: ClearSalesPersonRule;
  salesPersonNameRule: SalesPersonNameRule;
};

export type RetailerTeamMember = {
  __typename?: 'RetailerTeamMember';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  internalReference: Scalars['String'];
  lastName: Scalars['String'];
  locations?: Maybe<Array<Location>>;
  store?: Maybe<ShopifyStore>;
  updatedAt: Scalars['DateTime'];
};

export type RetailerTeamMemberEdge = {
  __typename?: 'RetailerTeamMemberEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<RetailerTeamMember>;
};

export type RetailerTeamMemberPageInfo = {
  __typename?: 'RetailerTeamMemberPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type RetailerTeamMembersResponse = {
  __typename?: 'RetailerTeamMembersResponse';
  edges?: Maybe<Array<RetailerTeamMemberEdge>>;
  pageInfo?: Maybe<RetailerTeamMemberPageInfo>;
  totalCount: Scalars['Int'];
};

export enum SalesPersonNameRule {
  FirstName = 'FIRST_NAME',
  FullName = 'FULL_NAME',
  Reference = 'REFERENCE'
}

export type SearchInput = {
  coordinates: Scalars['String'];
  email: Scalars['String'];
  locationString: Scalars['String'];
  radius: Scalars['Float'];
  storeTypes: Array<Scalars['String']>;
};

export type SelectedFilterOption = {
  ecommProviderName: Scalars['String'];
  filterType: FilterType;
  selectedValues: Array<Scalars['String']>;
};

export type Session = {
  __typename?: 'Session';
  addedToBasket: Scalars['Boolean'];
  basketCurrencyCode: Scalars['String'];
  basketValue: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  duration: Scalars['Float'];
  endedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  interactions: Scalars['Float'];
  isComplete: Scalars['Boolean'];
  isInvalid: Scalars['Boolean'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  tileClicks: Array<TileClick>;
  updatedAt: Scalars['DateTime'];
};

export type ShopifyMetafield = {
  __typename?: 'ShopifyMetafield';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  namespace: Scalars['String'];
  remoteCreatedAt: Scalars['DateTime'];
  remoteUpdatedAt: Scalars['DateTime'];
  resourceGid: Scalars['String'];
  shopifyGid: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type ShopifyPermission = {
  __typename?: 'ShopifyPermission';
  handle: Scalars['String'];
  level: Scalars['String'];
};

export type ShopifyRetailerInput = {
  accessToken: Scalars['String'];
  domain: Scalars['String'];
  sharedSecret: Scalars['String'];
};

export type ShopifyStore = {
  __typename?: 'ShopifyStore';
  auditLogs: AuditLogsResponse;
  categories?: Maybe<Array<Category>>;
  cloudshelves?: Maybe<Array<Cloudshelf>>;
  createdAt: Scalars['DateTime'];
  currentSubscription?: Maybe<SubscriptionEntity>;
  currentlySyncing: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displaySalesPersonAllocationSelection: Scalars['Boolean'];
  domain: Scalars['String'];
  eCommerceProviderIssue?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  hasCorrectScopes: Scalars['Boolean'];
  id: Scalars['ID'];
  isTrialEligible: Scalars['Boolean'];
  lastSync?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  nobleTasks: Array<NobleTask>;
  owner: CloudshelfUser;
  salesPersonClearingRule: ClearSalesPersonRule;
  salesPersonNameRule: SalesPersonNameRule;
  scopesVersionNumber: Scalars['Float'];
  teamMembers?: Maybe<Array<RetailerTeamMember>>;
  updatedAt: Scalars['DateTime'];
};


export type ShopifyStoreAuditLogsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderInput>;
};

export type ShopifyStoreEdge = {
  __typename?: 'ShopifyStoreEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ShopifyStore>;
};

export type ShopifyStorePageInfo = {
  __typename?: 'ShopifyStorePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type ShopifyStoresResponse = {
  __typename?: 'ShopifyStoresResponse';
  edges?: Maybe<Array<ShopifyStoreEdge>>;
  pageInfo?: Maybe<ShopifyStorePageInfo>;
  totalCount: Scalars['Int'];
};

/** Allow ordering a query's results */
export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StoreFirstUseResponse = {
  __typename?: 'StoreFirstUseResponse';
  hasAtLeastOneCloudshelf: Scalars['Boolean'];
  hasProcessedInformation: Scalars['Boolean'];
  hasReceivedInformation: Scalars['Boolean'];
  hasSentInformationRequest: Scalars['Boolean'];
};

export type SubscriptionChange = {
  __typename?: 'SubscriptionChange';
  field: Scalars['String'];
  newValue: Scalars['String'];
  oldValue: Scalars['String'];
};

export type SubscriptionChangelog = {
  __typename?: 'SubscriptionChangelog';
  changes: Array<SubscriptionChange>;
  cloudshelfUser?: Maybe<CloudshelfUser>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  newSubscription: Scalars['Boolean'];
  shopifySubscriptionGid: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SubscriptionEntity = {
  __typename?: 'SubscriptionEntity';
  accessToDisplayScheduling: Scalars['Boolean'];
  accessToImageBanners: Scalars['Boolean'];
  accessToProductCustomisation: Scalars['Boolean'];
  accessToSalesPersonAttribution: Scalars['Boolean'];
  accessToStoreAttribution: Scalars['Boolean'];
  accessToVideoBanners: Scalars['Boolean'];
  amountUSD: Scalars['Float'];
  canRemoveCloudshelfBranding: Scalars['Boolean'];
  changelog: Array<SubscriptionChangelog>;
  createdAt: Scalars['DateTime'];
  currentPeriodEnd?: Maybe<Scalars['DateTime']>;
  devicesPerLocation: Scalars['Float'];
  freeLocations: Scalars['Float'];
  hubspotDealNumber?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  interval: SubscriptionInterval;
  liveLocations: Scalars['Float'];
  plan: SubscriptionPlan;
  shopifyCreatedAt: Scalars['DateTime'];
  shopifyGid: Scalars['String'];
  shopifyStatus: AppSubscriptionStatus;
  shopifyStore: ShopifyStore;
  shopifyUpdatedAt?: Maybe<Scalars['DateTime']>;
  subscriptionType: SubscriptionType;
  updatedAt: Scalars['DateTime'];
  usage: SubscriptionPlanUsage;
};

export type SubscriptionFeaturesInput = {
  accessToDisplayScheduling?: Maybe<Scalars['Boolean']>;
  accessToImageBanners?: Maybe<Scalars['Boolean']>;
  accessToProductCustomisation?: Maybe<Scalars['Boolean']>;
  accessToSalesPersonAttribution?: Maybe<Scalars['Boolean']>;
  accessToStoreAttribution?: Maybe<Scalars['Boolean']>;
  accessToVideoBanners?: Maybe<Scalars['Boolean']>;
  bypassEcommerceProvider?: Maybe<Scalars['Boolean']>;
  canRemoveCloudshelfBranding?: Maybe<Scalars['Boolean']>;
  devicesPerLocation?: Maybe<Scalars['Float']>;
  freeLocations?: Maybe<Scalars['Float']>;
  hubspotDealNumber?: Maybe<Scalars['String']>;
  liveLocations: Scalars['Float'];
  overridePrice?: Maybe<Scalars['Float']>;
  planId: Scalars['String'];
};

export enum SubscriptionInterval {
  Annually = 'Annually',
  Monthly = 'Monthly'
}

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  accessToDisplayScheduling: Scalars['Boolean'];
  accessToImageBanners: Scalars['Boolean'];
  accessToProductCustomisation: Scalars['Boolean'];
  accessToSalesPersonAttribution: Scalars['Boolean'];
  accessToStoreAttribution: Scalars['Boolean'];
  accessToVideoBanners: Scalars['Boolean'];
  cloudshelfBranded: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  displayName: Scalars['String'];
  displayOrder: Scalars['Int'];
  englishName: Scalars['String'];
  id: Scalars['String'];
  information: Array<Scalars['String']>;
  maxNumDevicesPerLocation: Scalars['Int'];
  pricePerMonthPerLocation: Scalars['Int'];
  retailerCanSelectThisPlan: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type SubscriptionPlanUsage = {
  __typename?: 'SubscriptionPlanUsage';
  id: Scalars['String'];
  numberOfAllocatedLocations: Scalars['Int'];
  numberOfLiveDevices: Scalars['Int'];
};

export enum SubscriptionType {
  Custom = 'Custom',
  IndependentPremium = 'IndependentPremium',
  Plan = 'Plan',
  SmallRetailer = 'SmallRetailer',
  Unknown = 'Unknown'
}

export type Swatch = {
  __typename?: 'Swatch';
  displayName: Scalars['String'];
  imageUrl: Scalars['String'];
};

export type SwatchInput = {
  displayName: Scalars['String'];
  imageUrl: Scalars['String'];
};

export enum SyncStatus {
  None = 'None',
  Pending = 'Pending',
  Syncing = 'Syncing'
}

export type TaskQueue = {
  __typename?: 'TaskQueue';
  activeWorkers: Scalars['Int'];
  concurrency: Scalars['Float'];
  name: Scalars['String'];
  noTasksDelay: Scalars['Float'];
  retries: Scalars['Float'];
  taskDelay: Scalars['Float'];
  taskType: TrackedTaskType;
  tasks: NobleTaskResponse;
};


export type TaskQueueTasksArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  searchTypes?: Maybe<Array<NobleTaskStatus>>;
};

export type TileClick = {
  __typename?: 'TileClick';
  cloudshelf: Cloudshelf;
  createdAt: Scalars['DateTime'];
  device: Device;
  id: Scalars['ID'];
  powerTile: PowerTile;
  session: Session;
  updatedAt: Scalars['DateTime'];
};

export enum TrackedTaskType {
  BulkOpTrigger = 'BulkOpTrigger',
  CollectionSyncBulk = 'CollectionSyncBulk',
  Debug = 'Debug',
  DebugError = 'DebugError',
  FilterExtraction = 'FilterExtraction',
  ImageProcessing = 'ImageProcessing',
  MlProcessing = 'MLProcessing',
  MetafieldUpdate = 'MetafieldUpdate',
  ProductCollection = 'ProductCollection',
  StorefinderSearch = 'StorefinderSearch',
  SubscriptionUpdate = 'SubscriptionUpdate',
  SyncCollections = 'SyncCollections',
  SyncCollectionsTrigger = 'SyncCollectionsTrigger',
  SyncProducts = 'SyncProducts',
  SyncProductsTrigger = 'SyncProductsTrigger',
  Tools = 'Tools',
  WappalyzerQuery = 'WappalyzerQuery'
}

export type TrackedUrl = {
  __typename?: 'TrackedURL';
  checkout?: Maybe<Checkout>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export type TrackedUrlResponse = {
  __typename?: 'TrackedURLResponse';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type UpdateDeviceCloudshelfInput = {
  cloudshelfId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type UpdateDeviceDetailsInput = {
  bigFormatDisplay: Scalars['Boolean'];
  cloudshelfId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  locationId?: Maybe<Scalars['ID']>;
  mode: Scalars['String'];
  name: Scalars['String'];
  screenSizeInches: Scalars['Float'];
};

export type UpdateDeviceLocationInput = {
  id: Scalars['ID'];
  locationId?: Maybe<Scalars['String']>;
};

export type UpsertLocationInput = {
  contactDetail: Scalars['String'];
  formattedAddress: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type UpsertRetailerTeamMemberInput = {
  firstName: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  internalReference: Scalars['String'];
  lastName: Scalars['String'];
  locationIds?: Maybe<Array<Scalars['ID']>>;
};

export type Uptime = {
  __typename?: 'Uptime';
  count: Scalars['Float'];
  date: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  device: Device;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type UptimePercentageResponse = {
  __typename?: 'UptimePercentageResponse';
  date: Scalars['DateTime'];
  percentage: Scalars['Float'];
};

export type UptimePercentageTotalResponse = {
  __typename?: 'UptimePercentageTotalResponse';
  averagePercentage: Scalars['Float'];
  deviceName: Scalars['String'];
  records: Array<UptimePercentageResponse>;
};

export type UserListOrderInput = {
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type Variant = {
  __typename?: 'Variant';
  availableForSale: Scalars['Boolean'];
  currentlyNotInStock: Scalars['Boolean'];
  hasSalePrice?: Maybe<Scalars['Boolean']>;
  optionsJson?: Maybe<Scalars['String']>;
  originalPrice: Scalars['Float'];
  price: Scalars['Float'];
  sellableOnlineQuantity: Scalars['Float'];
  shopifyGid: Scalars['String'];
  sku: Scalars['String'];
  title: Scalars['String'];
};

export enum VersionType {
  Backend = 'BACKEND',
  Engine = 'ENGINE',
  Manager = 'MANAGER',
  Storefinder = 'STOREFINDER',
  Worker = 'WORKER'
}

export type WappalyzerQueryCombinedResults = {
  __typename?: 'WappalyzerQueryCombinedResults';
  createdAt: Scalars['DateTime'];
  csvData: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['String'];
  locationName: Scalars['String'];
  maxResultTypes: Array<Scalars['String']>;
  numberOfEcommerceResults: Scalars['Float'];
  numberOfMatchingProvidersFound: Scalars['Float'];
  numberOfSearchResults: Scalars['Float'];
  queryDate: Scalars['DateTime'];
  queryStatus: Scalars['String'];
  searchCoordinates: Scalars['String'];
  searchRadius: Scalars['Float'];
  storeFinderSearchId: Scalars['String'];
  storeTypes: Array<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  wappalyzerQueryId: Scalars['String'];
};

export enum ECommerceProvider {
  Cloudshelf = 'Cloudshelf',
  None = 'None',
  Shopify = 'Shopify'
}

export type CloudshelfCheckoutCreateMutationVariables = Exact<{
  shopifyCheckoutGid: Scalars['String'];
  deviceId: Scalars['ID'];
  cloudshelfId: Scalars['ID'];
  sessionId: Scalars['ID'];
  checkoutValue: Scalars['Float'];
  currencyCode: Scalars['String'];
}>;


export type CloudshelfCheckoutCreateMutation = (
  { __typename?: 'Mutation' }
  & { createCheckout: (
    { __typename?: 'Checkout' }
    & Pick<Checkout, 'id'>
  ) }
);

export type ReportContentLoadMutationVariables = Exact<{
  input: DeviceContentLoadInput;
}>;


export type ReportContentLoadMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reportContentLoad'>
);

export type GenerateTrackedUrlMutationVariables = Exact<{
  cloudshelfId: Scalars['ID'];
  url: Scalars['String'];
  checkoutId?: Maybe<Scalars['ID']>;
}>;


export type GenerateTrackedUrlMutation = (
  { __typename?: 'Mutation' }
  & { createTrackedURL: (
    { __typename?: 'TrackedURLResponse' }
    & Pick<TrackedUrlResponse, 'id' | 'url'>
  ) }
);

export type CreateMobileHandoffMutationVariables = Exact<{
  cloudshelfId: Scalars['ID'];
  productHandle: Scalars['String'];
  productOptionId: Scalars['Int'];
}>;


export type CreateMobileHandoffMutation = (
  { __typename?: 'Mutation' }
  & { createMobileHandoff: (
    { __typename?: 'MobileHandoff' }
    & Pick<MobileHandoff, 'id' | 'productOptionId' | 'shopifyProductHandle' | 'uploadedImageUrl' | 'createdAt' | 'updatedAt' | 'isScanned' | 'isCanceled'>
  ) }
);

export type SetHandoffImageUrlMutationVariables = Exact<{
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
}>;


export type SetHandoffImageUrlMutation = (
  { __typename?: 'Mutation' }
  & { setHandoffImageUrl: (
    { __typename?: 'MobileHandoff' }
    & Pick<MobileHandoff, 'id' | 'productOptionId' | 'shopifyProductHandle' | 'uploadedImageUrl' | 'createdAt' | 'updatedAt' | 'isScanned' | 'isCanceled'>
  ) }
);

export type CancelHandoffMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CancelHandoffMutation = (
  { __typename?: 'Mutation' }
  & { cancelHandoff: (
    { __typename?: 'MobileHandoff' }
    & Pick<MobileHandoff, 'id' | 'productOptionId' | 'shopifyProductHandle' | 'uploadedImageUrl' | 'createdAt' | 'updatedAt' | 'isScanned' | 'isCanceled'>
  ) }
);

export type EndSessionMutationVariables = Exact<{
  id: Scalars['ID'];
  interactions: Scalars['Int'];
}>;


export type EndSessionMutation = (
  { __typename?: 'Mutation' }
  & { endSession: (
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  ) }
);

export type NewSessionMutationVariables = Exact<{
  deviceId: Scalars['ID'];
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  salesAssistantId?: Maybe<Scalars['ID']>;
}>;


export type NewSessionMutation = (
  { __typename?: 'Mutation' }
  & { newSession: (
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  ) }
);

export type UpdateSessionMutationVariables = Exact<{
  id: Scalars['ID'];
  interactions: Scalars['Int'];
  basketValue: Scalars['Float'];
  basketCurrencyCode: Scalars['String'];
  addedToBasket: Scalars['Boolean'];
  salesAssistantId?: Maybe<Scalars['ID']>;
}>;


export type UpdateSessionMutation = (
  { __typename?: 'Mutation' }
  & { updateSession: (
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  ) }
);

export type ReportTileClicksMutationVariables = Exact<{
  deviceId: Scalars['ID'];
  sessionId: Scalars['ID'];
  powerTileId: Scalars['ID'];
}>;


export type ReportTileClicksMutation = (
  { __typename?: 'Mutation' }
  & { reportTileClick: (
    { __typename?: 'TileClick' }
    & Pick<TileClick, 'id'>
  ) }
);

export type PingBackendMutationVariables = Exact<{
  deviceId: Scalars['ID'];
  userAgent: Scalars['String'];
  engineVersion: Scalars['String'];
  screenHeight: Scalars['Int'];
  screenWidth: Scalars['Int'];
}>;


export type PingBackendMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uptimePing'>
);

export type GetEngineProductsQueryVariables = Exact<{
  cloudshelfId: Scalars['ID'];
  isDisplayMode: Scalars['Boolean'];
  includeMetafieldNamespaces: Array<Scalars['String']> | Scalars['String'];
  explicitProductHandle?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type GetEngineProductsQuery = (
  { __typename?: 'Query' }
  & { engineProducts: (
    { __typename?: 'EngineProductWithAdditionalInfoResponse' }
    & Pick<EngineProductWithAdditionalInfoResponse, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'EngineProductWithAdditionalInfoEdge' }
      & Pick<EngineProductWithAdditionalInfoEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'EngineProductWithAdditionalInfo' }
        & Pick<EngineProductWithAdditionalInfo, 'shopifyId' | 'title' | 'descriptionHtml' | 'categoryHandles' | 'categoryIds' | 'availableForSale' | 'featuredImageUrl' | 'handle' | 'remoteUpdatedAt' | 'tags' | 'type' | 'vendor'>
        & { images: Array<(
          { __typename?: 'EngineImageWithVariantInfo' }
          & Pick<EngineImageWithVariantInfo, 'url' | 'variantId'>
        )>, metafields: Array<(
          { __typename?: 'ShopifyMetafield' }
          & Pick<ShopifyMetafield, 'namespace' | 'key' | 'value'>
        )>, variants: Array<(
          { __typename?: 'Variant' }
          & Pick<Variant, 'availableForSale' | 'currentlyNotInStock' | 'hasSalePrice' | 'optionsJson' | 'originalPrice' | 'price' | 'sellableOnlineQuantity' | 'shopifyGid' | 'title' | 'sku'>
        )> }
      )> }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'EngineProductWithAdditionalInfoPageInfo' }
      & Pick<EngineProductWithAdditionalInfoPageInfo, 'hasNextPage' | 'endCursor'>
    )> }
  ) }
);

export type FilterProductsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  productFilter: ProductFilter;
  cloudshelfId: Scalars['ID'];
}>;


export type FilterProductsQuery = (
  { __typename?: 'Query' }
  & { productIds: (
    { __typename?: 'ProductsSearchResponse' }
    & Pick<ProductsSearchResponse, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'IdentifierEdge' }
      & Pick<IdentifierEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Identifier' }
        & Pick<Identifier, 'id'>
      )> }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'IdentifierPageInfo' }
      & Pick<IdentifierPageInfo, 'hasNextPage'>
    )> }
  ) }
);

export type GetCloudshelfConfigQueryVariables = Exact<{
  subdomain: Scalars['String'];
  cloudshelfName: Scalars['String'];
  reportPageLoad: Scalars['Boolean'];
  engineVersion: Scalars['String'];
}>;


export type GetCloudshelfConfigQuery = (
  { __typename?: 'Query' }
  & { getCloudshelfConfig: (
    { __typename?: 'CloudshelfConfigResult' }
    & Pick<CloudshelfConfigResult, 'status' | 'deviceMode' | 'inMaintenanceMode' | 'deviceOwnersName'>
    & { device?: Maybe<(
      { __typename?: 'Device' }
      & Pick<Device, 'id' | 'name' | 'mode' | 'debugMode' | 'ownerType' | 'screenSizeInches'>
      & { location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'id' | 'name'>
      )>, owner?: Maybe<(
        { __typename?: 'CloudshelfUser' }
        & Pick<CloudshelfUser, 'id' | 'name'>
      )> }
    )>, teamMembers: Array<(
      { __typename?: 'RetailerTeamMember' }
      & Pick<RetailerTeamMember, 'id' | 'firstName' | 'lastName' | 'internalReference'>
    )>, store?: Maybe<(
      { __typename?: 'ShopifyStore' }
      & Pick<ShopifyStore, 'id' | 'salesPersonClearingRule' | 'salesPersonNameRule' | 'displaySalesPersonAllocationSelection'>
    )>, config?: Maybe<(
      { __typename?: 'CloudshelfConfig' }
      & Pick<CloudshelfConfig, 'id' | 'forceDebugOverlay' | 'couponsEnabled' | 'scopes' | 'userType' | 'handoffProductHandle' | 'handoffProductOptionId' | 'updatedAt' | 'subdomain' | 'name' | 'normalizedName' | 'inStockLabel' | 'includeProductsInStock' | 'includeProductsOutOfStock' | 'includeProductsLimitedAvailability' | 'limitedAvailabilityLabel' | 'soldOutLabel' | 'outOfStockLabel' | 'displayInStockLabel' | 'displayLimitedLabel' | 'displaySoldOutLabel' | 'displayOutOfStockLabel' | 'showTotalStockCount' | 'inactivityTimeout' | 'createdAt'>
      & { pdpBlocks: Array<(
        { __typename?: 'PDPBlockDescription' }
        & Pick<PdpBlockDescription, 'id' | 'position' | 'unionTypeName' | 'displayText' | 'style' | 'removeThemeShortcodes'>
      ) | (
        { __typename?: 'PDPBlockMetafield' }
        & Pick<PdpBlockMetafield, 'id' | 'position' | 'unionTypeName' | 'displayText' | 'style' | 'namespace' | 'key' | 'metafieldDisplayType'>
      ) | (
        { __typename?: 'PDPBlockProductData' }
        & Pick<PdpBlockProductData, 'id' | 'position' | 'unionTypeName' | 'displayText' | 'style' | 'productDataType'>
      ) | (
        { __typename?: 'PDPBlockSpacer' }
        & Pick<PdpBlockSpacer, 'id' | 'position' | 'unionTypeName'>
      )>, bannerConfig: (
        { __typename?: 'BannerConfig' }
        & Pick<BannerConfig, 'id' | 'interactiveDisplayMode' | 'interactiveShowBannerEveryXMinutes' | 'interactiveShowBannerDurationInSeconds' | 'displayOnlyDisplayMode' | 'displayOnlyShowBannerDurationInSeconds'>
      ), displayOnlyConfig: (
        { __typename?: 'DisplayOnlyConfig' }
        & Pick<DisplayOnlyConfig, 'displayLogo' | 'maxProductsPerCollection' | 'collectionType' | 'maxPhotosPerProduct' | 'timePerPhoto' | 'logoSize' | 'includeProductName' | 'includePrice' | 'includeStock' | 'includeQRCode'>
      ), cloudshelf: (
        { __typename?: 'Cloudshelf' }
        & Pick<Cloudshelf, 'id'>
        & { banners?: Maybe<Array<(
          { __typename?: 'CloudshelfBanner' }
          & Pick<CloudshelfBanner, 'id' | 'position' | 'text' | 'backgroundType' | 'backgroundColour' | 'backgroundImageVertical' | 'backgroundImageHorizontal' | 'linkType' | 'linkText' | 'linkURL' | 'linkCollection' | 'linkProduct'>
        )>>, powerTiles: Array<(
          { __typename: 'PowerTile' }
          & Pick<PowerTile, 'id' | 'priority' | 'type' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'configurationIssues' | 'pinned'>
          & { definition: (
            { __typename: 'CategoryPowerTile' }
            & Pick<CategoryPowerTile, 'title' | 'categoryId' | 'useImage' | 'image'>
            & { category?: Maybe<(
              { __typename?: 'Category' }
              & Pick<Category, 'id' | 'image' | 'title' | 'shopifyId' | 'shopifyStorefrontId' | 'handle' | 'priority'>
            )> }
          ) | (
            { __typename: 'QRCodePowerTile' }
            & Pick<QrCodePowerTile, 'backgroundType' | 'backgroundPrimaryColor' | 'backgroundSecondaryColor' | 'backgroundImage' | 'title' | 'icon' | 'useIcon' | 'callToAction' | 'qrCodeURL' | 'qrCodeText'>
          ) }
        )>, filters?: Maybe<Array<(
          { __typename?: 'Filter' }
          & Pick<Filter, 'id' | 'isMergedChild' | 'displayName' | 'ecommProviderFieldName' | 'expandedByDefault' | 'hiddenAttributeValues' | 'parentId' | 'isHidden' | 'type' | 'priority' | 'metafieldNamespace' | 'metafieldKey'>
          & { attributeValues: Array<(
            { __typename?: 'AttributeValue' }
            & Pick<AttributeValue, 'value' | 'categoryIds' | 'priority' | 'parentFilterId'>
          )>, options?: Maybe<(
            { __typename?: 'FilterOptions' }
            & Pick<FilterOptions, 'baseShoesizeUnit' | 'capitalisationStyle' | 'orderType'>
            & { swatches?: Maybe<Array<(
              { __typename?: 'Swatch' }
              & Pick<Swatch, 'displayName' | 'imageUrl'>
            )>> }
          )>, valueOverrides: Array<(
            { __typename?: 'AttributeValueOverride' }
            & Pick<AttributeValueOverride, 'displayValue' | 'originalValue'>
          )> }
        )>> }
      ), providerConfig: (
        { __typename?: 'CloudshelfProviderConfig' }
        & Pick<CloudshelfProviderConfig, 'eCommerceProvider' | 'item1' | 'item2'>
      ), trackers: Array<(
        { __typename?: 'CloudshelfTracker' }
        & Pick<CloudshelfTracker, 'id' | 'name' | 'type'>
      )>, theme: (
        { __typename?: 'CloudshelfTheme' }
        & Pick<CloudshelfTheme, 'id' | 'primaryColor' | 'mainTextColor' | 'purchaseTextColor' | 'imageAnchor' | 'tileSizeModifier' | 'removeCloudshelfBranding' | 'logoUrl' | 'useProductAnimations'>
        & { radius: (
          { __typename?: 'CloudshelfThemeRadius' }
          & Pick<CloudshelfThemeRadius, 'inputs' | 'drawer' | 'modal' | 'tiles'>
        ), headingFont: (
          { __typename?: 'CloudshelfThemeFont' }
          & Pick<CloudshelfThemeFont, 'isCustomFont' | 'fontFamily' | 'fontFamilyCDN' | 'fontWeightValue'>
        ), subheadingFont: (
          { __typename?: 'CloudshelfThemeFont' }
          & Pick<CloudshelfThemeFont, 'isCustomFont' | 'fontFamily' | 'fontFamilyCDN' | 'fontWeightValue'>
        ), bodyFont: (
          { __typename?: 'CloudshelfThemeFont' }
          & Pick<CloudshelfThemeFont, 'isCustomFont' | 'fontFamily' | 'fontFamilyCDN' | 'fontWeightValue'>
        ), attractScreen: (
          { __typename: 'CloudshelfThemeClassicAttractScreen' }
          & Pick<CloudshelfThemeClassicAttractScreen, 'callToAction' | 'displayFrame' | 'tileAnimation' | 'callToActionSize' | 'callToActionAlignment' | 'touchIndicator' | 'displayLogo' | 'logoSize'>
        ) | (
          { __typename: 'CloudshelfThemeMinimalAttractScreen' }
          & Pick<CloudshelfThemeMinimalAttractScreen, 'callToAction' | 'actionButton' | 'callToActionSize' | 'callToActionAlignment' | 'touchIndicator' | 'displayLogo' | 'logoSize'>
        ) }
      ) }
    )> }
  ) }
);

export type GetDeviceInfoQueryVariables = Exact<{
  deviceId: Scalars['String'];
}>;


export type GetDeviceInfoQuery = (
  { __typename?: 'Query' }
  & { device?: Maybe<(
    { __typename?: 'Device' }
    & Pick<Device, 'id' | 'code' | 'registered' | 'bigFormatDisplay'>
    & { owner?: Maybe<(
      { __typename?: 'CloudshelfUser' }
      & Pick<CloudshelfUser, 'id' | 'name'>
    )> }
  )> }
);

export type GetLatestVersionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLatestVersionQuery = (
  { __typename?: 'Query' }
  & { getVersionByType: (
    { __typename?: 'KnownVersion' }
    & Pick<KnownVersion, 'versionString'>
  ) }
);

export type GetMobileHandoffQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetMobileHandoffQuery = (
  { __typename?: 'Query' }
  & { getMobileHandoff?: Maybe<(
    { __typename?: 'MobileHandoff' }
    & Pick<MobileHandoff, 'id' | 'productOptionId' | 'shopifyProductHandle' | 'uploadedImageUrl' | 'createdAt' | 'updatedAt' | 'isScanned' | 'isCanceled'>
  )> }
);

export type IsTrackedUrlScannedQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type IsTrackedUrlScannedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isTrackedURLScanned'>
);

export type GetPresignedCustomisationUrlQueryVariables = Exact<{
  type: Scalars['String'];
}>;


export type GetPresignedCustomisationUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPresignedCustomisationUrl'>
);


export const CloudshelfCheckoutCreateDocument = gql`
    mutation CloudshelfCheckoutCreate($shopifyCheckoutGid: String!, $deviceId: ID!, $cloudshelfId: ID!, $sessionId: ID!, $checkoutValue: Float!, $currencyCode: String!) {
  createCheckout(
    checkoutCreateInput: {cloudshelfId: $cloudshelfId, deviceId: $deviceId, checkoutId: $shopifyCheckoutGid, sessionId: $sessionId, checkoutValue: $checkoutValue, checkoutCurrencyCode: $currencyCode}
  ) {
    id
  }
}
    `;
export type CloudshelfCheckoutCreateMutationFn = Apollo.MutationFunction<CloudshelfCheckoutCreateMutation, CloudshelfCheckoutCreateMutationVariables>;

/**
 * __useCloudshelfCheckoutCreateMutation__
 *
 * To run a mutation, you first call `useCloudshelfCheckoutCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloudshelfCheckoutCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloudshelfCheckoutCreateMutation, { data, loading, error }] = useCloudshelfCheckoutCreateMutation({
 *   variables: {
 *      shopifyCheckoutGid: // value for 'shopifyCheckoutGid'
 *      deviceId: // value for 'deviceId'
 *      cloudshelfId: // value for 'cloudshelfId'
 *      sessionId: // value for 'sessionId'
 *      checkoutValue: // value for 'checkoutValue'
 *      currencyCode: // value for 'currencyCode'
 *   },
 * });
 */
export function useCloudshelfCheckoutCreateMutation(baseOptions?: Apollo.MutationHookOptions<CloudshelfCheckoutCreateMutation, CloudshelfCheckoutCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloudshelfCheckoutCreateMutation, CloudshelfCheckoutCreateMutationVariables>(CloudshelfCheckoutCreateDocument, options);
      }
export type CloudshelfCheckoutCreateMutationHookResult = ReturnType<typeof useCloudshelfCheckoutCreateMutation>;
export type CloudshelfCheckoutCreateMutationResult = Apollo.MutationResult<CloudshelfCheckoutCreateMutation>;
export type CloudshelfCheckoutCreateMutationOptions = Apollo.BaseMutationOptions<CloudshelfCheckoutCreateMutation, CloudshelfCheckoutCreateMutationVariables>;
export const ReportContentLoadDocument = gql`
    mutation ReportContentLoad($input: DeviceContentLoadInput!) {
  reportContentLoad(input: $input)
}
    `;
export type ReportContentLoadMutationFn = Apollo.MutationFunction<ReportContentLoadMutation, ReportContentLoadMutationVariables>;

/**
 * __useReportContentLoadMutation__
 *
 * To run a mutation, you first call `useReportContentLoadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportContentLoadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportContentLoadMutation, { data, loading, error }] = useReportContentLoadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportContentLoadMutation(baseOptions?: Apollo.MutationHookOptions<ReportContentLoadMutation, ReportContentLoadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportContentLoadMutation, ReportContentLoadMutationVariables>(ReportContentLoadDocument, options);
      }
export type ReportContentLoadMutationHookResult = ReturnType<typeof useReportContentLoadMutation>;
export type ReportContentLoadMutationResult = Apollo.MutationResult<ReportContentLoadMutation>;
export type ReportContentLoadMutationOptions = Apollo.BaseMutationOptions<ReportContentLoadMutation, ReportContentLoadMutationVariables>;
export const GenerateTrackedUrlDocument = gql`
    mutation GenerateTrackedURL($cloudshelfId: ID!, $url: String!, $checkoutId: ID) {
  createTrackedURL(
    cloudshelfId: $cloudshelfId
    urlToTrack: $url
    checkoutId: $checkoutId
  ) {
    id
    url
  }
}
    `;
export type GenerateTrackedUrlMutationFn = Apollo.MutationFunction<GenerateTrackedUrlMutation, GenerateTrackedUrlMutationVariables>;

/**
 * __useGenerateTrackedUrlMutation__
 *
 * To run a mutation, you first call `useGenerateTrackedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateTrackedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateTrackedUrlMutation, { data, loading, error }] = useGenerateTrackedUrlMutation({
 *   variables: {
 *      cloudshelfId: // value for 'cloudshelfId'
 *      url: // value for 'url'
 *      checkoutId: // value for 'checkoutId'
 *   },
 * });
 */
export function useGenerateTrackedUrlMutation(baseOptions?: Apollo.MutationHookOptions<GenerateTrackedUrlMutation, GenerateTrackedUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateTrackedUrlMutation, GenerateTrackedUrlMutationVariables>(GenerateTrackedUrlDocument, options);
      }
export type GenerateTrackedUrlMutationHookResult = ReturnType<typeof useGenerateTrackedUrlMutation>;
export type GenerateTrackedUrlMutationResult = Apollo.MutationResult<GenerateTrackedUrlMutation>;
export type GenerateTrackedUrlMutationOptions = Apollo.BaseMutationOptions<GenerateTrackedUrlMutation, GenerateTrackedUrlMutationVariables>;
export const CreateMobileHandoffDocument = gql`
    mutation CreateMobileHandoff($cloudshelfId: ID!, $productHandle: String!, $productOptionId: Int!) {
  createMobileHandoff(
    cloudshelfId: $cloudshelfId
    productHandle: $productHandle
    productOptionId: $productOptionId
  ) {
    id
    productOptionId
    shopifyProductHandle
    uploadedImageUrl
    createdAt
    updatedAt
    isScanned
    isCanceled
  }
}
    `;
export type CreateMobileHandoffMutationFn = Apollo.MutationFunction<CreateMobileHandoffMutation, CreateMobileHandoffMutationVariables>;

/**
 * __useCreateMobileHandoffMutation__
 *
 * To run a mutation, you first call `useCreateMobileHandoffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMobileHandoffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMobileHandoffMutation, { data, loading, error }] = useCreateMobileHandoffMutation({
 *   variables: {
 *      cloudshelfId: // value for 'cloudshelfId'
 *      productHandle: // value for 'productHandle'
 *      productOptionId: // value for 'productOptionId'
 *   },
 * });
 */
export function useCreateMobileHandoffMutation(baseOptions?: Apollo.MutationHookOptions<CreateMobileHandoffMutation, CreateMobileHandoffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMobileHandoffMutation, CreateMobileHandoffMutationVariables>(CreateMobileHandoffDocument, options);
      }
export type CreateMobileHandoffMutationHookResult = ReturnType<typeof useCreateMobileHandoffMutation>;
export type CreateMobileHandoffMutationResult = Apollo.MutationResult<CreateMobileHandoffMutation>;
export type CreateMobileHandoffMutationOptions = Apollo.BaseMutationOptions<CreateMobileHandoffMutation, CreateMobileHandoffMutationVariables>;
export const SetHandoffImageUrlDocument = gql`
    mutation SetHandoffImageUrl($id: ID!, $imageUrl: String!) {
  setHandoffImageUrl(id: $id, imageUrl: $imageUrl) {
    id
    productOptionId
    shopifyProductHandle
    uploadedImageUrl
    createdAt
    updatedAt
    isScanned
    isCanceled
  }
}
    `;
export type SetHandoffImageUrlMutationFn = Apollo.MutationFunction<SetHandoffImageUrlMutation, SetHandoffImageUrlMutationVariables>;

/**
 * __useSetHandoffImageUrlMutation__
 *
 * To run a mutation, you first call `useSetHandoffImageUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetHandoffImageUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setHandoffImageUrlMutation, { data, loading, error }] = useSetHandoffImageUrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *      imageUrl: // value for 'imageUrl'
 *   },
 * });
 */
export function useSetHandoffImageUrlMutation(baseOptions?: Apollo.MutationHookOptions<SetHandoffImageUrlMutation, SetHandoffImageUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetHandoffImageUrlMutation, SetHandoffImageUrlMutationVariables>(SetHandoffImageUrlDocument, options);
      }
export type SetHandoffImageUrlMutationHookResult = ReturnType<typeof useSetHandoffImageUrlMutation>;
export type SetHandoffImageUrlMutationResult = Apollo.MutationResult<SetHandoffImageUrlMutation>;
export type SetHandoffImageUrlMutationOptions = Apollo.BaseMutationOptions<SetHandoffImageUrlMutation, SetHandoffImageUrlMutationVariables>;
export const CancelHandoffDocument = gql`
    mutation CancelHandoff($id: ID!) {
  cancelHandoff(id: $id) {
    id
    productOptionId
    shopifyProductHandle
    uploadedImageUrl
    createdAt
    updatedAt
    isScanned
    isCanceled
  }
}
    `;
export type CancelHandoffMutationFn = Apollo.MutationFunction<CancelHandoffMutation, CancelHandoffMutationVariables>;

/**
 * __useCancelHandoffMutation__
 *
 * To run a mutation, you first call `useCancelHandoffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelHandoffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelHandoffMutation, { data, loading, error }] = useCancelHandoffMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelHandoffMutation(baseOptions?: Apollo.MutationHookOptions<CancelHandoffMutation, CancelHandoffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelHandoffMutation, CancelHandoffMutationVariables>(CancelHandoffDocument, options);
      }
export type CancelHandoffMutationHookResult = ReturnType<typeof useCancelHandoffMutation>;
export type CancelHandoffMutationResult = Apollo.MutationResult<CancelHandoffMutation>;
export type CancelHandoffMutationOptions = Apollo.BaseMutationOptions<CancelHandoffMutation, CancelHandoffMutationVariables>;
export const EndSessionDocument = gql`
    mutation EndSession($id: ID!, $interactions: Int!) {
  endSession(id: $id, interactions: $interactions) {
    id
  }
}
    `;
export type EndSessionMutationFn = Apollo.MutationFunction<EndSessionMutation, EndSessionMutationVariables>;

/**
 * __useEndSessionMutation__
 *
 * To run a mutation, you first call `useEndSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endSessionMutation, { data, loading, error }] = useEndSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      interactions: // value for 'interactions'
 *   },
 * });
 */
export function useEndSessionMutation(baseOptions?: Apollo.MutationHookOptions<EndSessionMutation, EndSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndSessionMutation, EndSessionMutationVariables>(EndSessionDocument, options);
      }
export type EndSessionMutationHookResult = ReturnType<typeof useEndSessionMutation>;
export type EndSessionMutationResult = Apollo.MutationResult<EndSessionMutation>;
export type EndSessionMutationOptions = Apollo.BaseMutationOptions<EndSessionMutation, EndSessionMutationVariables>;
export const NewSessionDocument = gql`
    mutation NewSession($deviceId: ID!, $lat: Float, $long: Float, $salesAssistantId: ID) {
  newSession(
    deviceId: $deviceId
    latitude: $lat
    longitude: $long
    salesAssistantId: $salesAssistantId
  ) {
    id
  }
}
    `;
export type NewSessionMutationFn = Apollo.MutationFunction<NewSessionMutation, NewSessionMutationVariables>;

/**
 * __useNewSessionMutation__
 *
 * To run a mutation, you first call `useNewSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newSessionMutation, { data, loading, error }] = useNewSessionMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      lat: // value for 'lat'
 *      long: // value for 'long'
 *      salesAssistantId: // value for 'salesAssistantId'
 *   },
 * });
 */
export function useNewSessionMutation(baseOptions?: Apollo.MutationHookOptions<NewSessionMutation, NewSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewSessionMutation, NewSessionMutationVariables>(NewSessionDocument, options);
      }
export type NewSessionMutationHookResult = ReturnType<typeof useNewSessionMutation>;
export type NewSessionMutationResult = Apollo.MutationResult<NewSessionMutation>;
export type NewSessionMutationOptions = Apollo.BaseMutationOptions<NewSessionMutation, NewSessionMutationVariables>;
export const UpdateSessionDocument = gql`
    mutation UpdateSession($id: ID!, $interactions: Int!, $basketValue: Float!, $basketCurrencyCode: String!, $addedToBasket: Boolean!, $salesAssistantId: ID) {
  updateSession(
    id: $id
    interactions: $interactions
    basketValue: $basketValue
    basketCurrencyCode: $basketCurrencyCode
    addedToBasket: $addedToBasket
    salesAssistantId: $salesAssistantId
  ) {
    id
  }
}
    `;
export type UpdateSessionMutationFn = Apollo.MutationFunction<UpdateSessionMutation, UpdateSessionMutationVariables>;

/**
 * __useUpdateSessionMutation__
 *
 * To run a mutation, you first call `useUpdateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSessionMutation, { data, loading, error }] = useUpdateSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      interactions: // value for 'interactions'
 *      basketValue: // value for 'basketValue'
 *      basketCurrencyCode: // value for 'basketCurrencyCode'
 *      addedToBasket: // value for 'addedToBasket'
 *      salesAssistantId: // value for 'salesAssistantId'
 *   },
 * });
 */
export function useUpdateSessionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSessionMutation, UpdateSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSessionMutation, UpdateSessionMutationVariables>(UpdateSessionDocument, options);
      }
export type UpdateSessionMutationHookResult = ReturnType<typeof useUpdateSessionMutation>;
export type UpdateSessionMutationResult = Apollo.MutationResult<UpdateSessionMutation>;
export type UpdateSessionMutationOptions = Apollo.BaseMutationOptions<UpdateSessionMutation, UpdateSessionMutationVariables>;
export const ReportTileClicksDocument = gql`
    mutation ReportTileClicks($deviceId: ID!, $sessionId: ID!, $powerTileId: ID!) {
  reportTileClick(
    deviceId: $deviceId
    sessionId: $sessionId
    powerTileId: $powerTileId
  ) {
    id
  }
}
    `;
export type ReportTileClicksMutationFn = Apollo.MutationFunction<ReportTileClicksMutation, ReportTileClicksMutationVariables>;

/**
 * __useReportTileClicksMutation__
 *
 * To run a mutation, you first call `useReportTileClicksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportTileClicksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportTileClicksMutation, { data, loading, error }] = useReportTileClicksMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      sessionId: // value for 'sessionId'
 *      powerTileId: // value for 'powerTileId'
 *   },
 * });
 */
export function useReportTileClicksMutation(baseOptions?: Apollo.MutationHookOptions<ReportTileClicksMutation, ReportTileClicksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportTileClicksMutation, ReportTileClicksMutationVariables>(ReportTileClicksDocument, options);
      }
export type ReportTileClicksMutationHookResult = ReturnType<typeof useReportTileClicksMutation>;
export type ReportTileClicksMutationResult = Apollo.MutationResult<ReportTileClicksMutation>;
export type ReportTileClicksMutationOptions = Apollo.BaseMutationOptions<ReportTileClicksMutation, ReportTileClicksMutationVariables>;
export const PingBackendDocument = gql`
    mutation PingBackend($deviceId: ID!, $userAgent: String!, $engineVersion: String!, $screenHeight: Int!, $screenWidth: Int!) {
  uptimePing(
    deviceId: $deviceId
    userAgent: $userAgent
    engineVersion: $engineVersion
    screenHeight: $screenHeight
    screenWidth: $screenWidth
  )
}
    `;
export type PingBackendMutationFn = Apollo.MutationFunction<PingBackendMutation, PingBackendMutationVariables>;

/**
 * __usePingBackendMutation__
 *
 * To run a mutation, you first call `usePingBackendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePingBackendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pingBackendMutation, { data, loading, error }] = usePingBackendMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      userAgent: // value for 'userAgent'
 *      engineVersion: // value for 'engineVersion'
 *      screenHeight: // value for 'screenHeight'
 *      screenWidth: // value for 'screenWidth'
 *   },
 * });
 */
export function usePingBackendMutation(baseOptions?: Apollo.MutationHookOptions<PingBackendMutation, PingBackendMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PingBackendMutation, PingBackendMutationVariables>(PingBackendDocument, options);
      }
export type PingBackendMutationHookResult = ReturnType<typeof usePingBackendMutation>;
export type PingBackendMutationResult = Apollo.MutationResult<PingBackendMutation>;
export type PingBackendMutationOptions = Apollo.BaseMutationOptions<PingBackendMutation, PingBackendMutationVariables>;
export const GetEngineProductsDocument = gql`
    query GetEngineProducts($cloudshelfId: ID!, $isDisplayMode: Boolean!, $includeMetafieldNamespaces: [String!]!, $explicitProductHandle: String, $first: Int, $after: String) {
  engineProducts(
    cloudshelfId: $cloudshelfId
    isDisplayMode: $isDisplayMode
    includeMetafieldNamespaces: $includeMetafieldNamespaces
    explicitProductHandle: $explicitProductHandle
    first: $first
    after: $after
  ) {
    totalCount
    edges {
      cursor
      node {
        shopifyId
        title
        descriptionHtml
        categoryHandles
        categoryIds
        availableForSale
        featuredImageUrl
        handle
        images {
          url
          variantId
        }
        remoteUpdatedAt
        tags
        type
        vendor
        metafields {
          namespace
          key
          value
        }
        variants {
          availableForSale
          currentlyNotInStock
          hasSalePrice
          optionsJson
          originalPrice
          price
          sellableOnlineQuantity
          shopifyGid
          title
          sku
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    `;

/**
 * __useGetEngineProductsQuery__
 *
 * To run a query within a React component, call `useGetEngineProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEngineProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEngineProductsQuery({
 *   variables: {
 *      cloudshelfId: // value for 'cloudshelfId'
 *      isDisplayMode: // value for 'isDisplayMode'
 *      includeMetafieldNamespaces: // value for 'includeMetafieldNamespaces'
 *      explicitProductHandle: // value for 'explicitProductHandle'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetEngineProductsQuery(baseOptions: Apollo.QueryHookOptions<GetEngineProductsQuery, GetEngineProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEngineProductsQuery, GetEngineProductsQueryVariables>(GetEngineProductsDocument, options);
      }
export function useGetEngineProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEngineProductsQuery, GetEngineProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEngineProductsQuery, GetEngineProductsQueryVariables>(GetEngineProductsDocument, options);
        }
export type GetEngineProductsQueryHookResult = ReturnType<typeof useGetEngineProductsQuery>;
export type GetEngineProductsLazyQueryHookResult = ReturnType<typeof useGetEngineProductsLazyQuery>;
export type GetEngineProductsQueryResult = Apollo.QueryResult<GetEngineProductsQuery, GetEngineProductsQueryVariables>;
export const FilterProductsDocument = gql`
    query filterProducts($first: Int, $after: String, $productFilter: ProductFilter!, $cloudshelfId: ID!) {
  productIds(
    first: $first
    after: $after
    filter: $productFilter
    cloudshelfId: $cloudshelfId
  ) {
    edges {
      cursor
      node {
        id
      }
    }
    pageInfo {
      hasNextPage
    }
    totalCount
  }
}
    `;

/**
 * __useFilterProductsQuery__
 *
 * To run a query within a React component, call `useFilterProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterProductsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      productFilter: // value for 'productFilter'
 *      cloudshelfId: // value for 'cloudshelfId'
 *   },
 * });
 */
export function useFilterProductsQuery(baseOptions: Apollo.QueryHookOptions<FilterProductsQuery, FilterProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilterProductsQuery, FilterProductsQueryVariables>(FilterProductsDocument, options);
      }
export function useFilterProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilterProductsQuery, FilterProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilterProductsQuery, FilterProductsQueryVariables>(FilterProductsDocument, options);
        }
export type FilterProductsQueryHookResult = ReturnType<typeof useFilterProductsQuery>;
export type FilterProductsLazyQueryHookResult = ReturnType<typeof useFilterProductsLazyQuery>;
export type FilterProductsQueryResult = Apollo.QueryResult<FilterProductsQuery, FilterProductsQueryVariables>;
export const GetCloudshelfConfigDocument = gql`
    query GetCloudshelfConfig($subdomain: String!, $cloudshelfName: String!, $reportPageLoad: Boolean!, $engineVersion: String!) {
  getCloudshelfConfig(
    retailerSubdomain: $subdomain
    cloudshelfName: $cloudshelfName
    reportPageLoad: $reportPageLoad
    engineVersion: $engineVersion
  ) {
    status
    deviceMode
    inMaintenanceMode
    deviceOwnersName
    device {
      id
      name
      mode
      location {
        id
        name
      }
      owner {
        id
        name
      }
      debugMode
      ownerType
      screenSizeInches
    }
    teamMembers {
      id
      firstName
      lastName
      internalReference
    }
    store {
      id
      salesPersonClearingRule
      salesPersonNameRule
      displaySalesPersonAllocationSelection
    }
    config {
      id
      pdpBlocks {
        ... on PDPBlockDescription {
          id
          position
          unionTypeName
          displayText
          style
          removeThemeShortcodes
        }
        ... on PDPBlockMetafield {
          id
          position
          unionTypeName
          displayText
          style
          namespace
          key
          metafieldDisplayType
        }
        ... on PDPBlockProductData {
          id
          position
          unionTypeName
          displayText
          style
          productDataType
        }
        ... on PDPBlockSpacer {
          id
          position
          unionTypeName
        }
      }
      bannerConfig {
        id
        interactiveDisplayMode
        interactiveShowBannerEveryXMinutes
        interactiveShowBannerDurationInSeconds
        displayOnlyDisplayMode
        displayOnlyShowBannerDurationInSeconds
      }
      forceDebugOverlay
      couponsEnabled
      scopes
      userType
      handoffProductHandle
      handoffProductOptionId
      displayOnlyConfig {
        displayLogo
        maxProductsPerCollection
        collectionType
        maxPhotosPerProduct
        timePerPhoto
        logoSize
        includeProductName
        includePrice
        includeStock
        includeQRCode
      }
      cloudshelf {
        id
        banners {
          id
          position
          text
          backgroundType
          backgroundColour
          backgroundImageVertical
          backgroundImageHorizontal
          linkType
          linkText
          linkURL
          linkCollection
          linkProduct
        }
        powerTiles {
          __typename
          id
          priority
          type
          createdAt
          updatedAt
          deletedAt
          configurationIssues
          pinned
          definition {
            __typename
            ... on CategoryPowerTile {
              title
              category {
                id
                image
                title
                shopifyId
                shopifyStorefrontId
                handle
                priority
              }
              categoryId
              useImage
              image
            }
            ... on QRCodePowerTile {
              backgroundType
              backgroundPrimaryColor
              backgroundSecondaryColor
              backgroundImage
              title
              icon
              useIcon
              callToAction
              qrCodeURL
              qrCodeText
            }
          }
        }
        filters {
          id
          isMergedChild
          attributeValues {
            value
            categoryIds
            priority
            parentFilterId
          }
          displayName
          ecommProviderFieldName
          expandedByDefault
          hiddenAttributeValues
          parentId
          isHidden
          type
          priority
          metafieldNamespace
          metafieldKey
          options {
            baseShoesizeUnit
            capitalisationStyle
            orderType
            swatches {
              displayName
              imageUrl
            }
          }
          valueOverrides {
            displayValue
            originalValue
          }
        }
      }
      updatedAt
      subdomain
      name
      normalizedName
      inStockLabel
      includeProductsInStock
      includeProductsOutOfStock
      includeProductsLimitedAvailability
      limitedAvailabilityLabel
      soldOutLabel
      outOfStockLabel
      displayInStockLabel
      displayLimitedLabel
      displaySoldOutLabel
      displayOutOfStockLabel
      showTotalStockCount
      providerConfig {
        eCommerceProvider
        item1
        item2
      }
      trackers {
        id
        name
        type
      }
      inactivityTimeout
      theme {
        id
        primaryColor
        mainTextColor
        purchaseTextColor
        imageAnchor
        tileSizeModifier
        removeCloudshelfBranding
        radius {
          inputs
          drawer
          modal
          tiles
        }
        headingFont {
          isCustomFont
          fontFamily
          fontFamilyCDN
          fontWeightValue
        }
        subheadingFont {
          isCustomFont
          fontFamily
          fontFamilyCDN
          fontWeightValue
        }
        bodyFont {
          isCustomFont
          fontFamily
          fontFamilyCDN
          fontWeightValue
        }
        logoUrl
        useProductAnimations
        attractScreen {
          __typename
          ... on CloudshelfThemeClassicAttractScreen {
            callToAction
            displayFrame
            tileAnimation
            callToActionSize
            callToActionAlignment
            touchIndicator
            displayLogo
            logoSize
          }
          ... on CloudshelfThemeMinimalAttractScreen {
            callToAction
            actionButton
            callToActionSize
            callToActionAlignment
            touchIndicator
            displayLogo
            logoSize
          }
        }
      }
      createdAt
    }
  }
}
    `;

/**
 * __useGetCloudshelfConfigQuery__
 *
 * To run a query within a React component, call `useGetCloudshelfConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCloudshelfConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCloudshelfConfigQuery({
 *   variables: {
 *      subdomain: // value for 'subdomain'
 *      cloudshelfName: // value for 'cloudshelfName'
 *      reportPageLoad: // value for 'reportPageLoad'
 *      engineVersion: // value for 'engineVersion'
 *   },
 * });
 */
export function useGetCloudshelfConfigQuery(baseOptions: Apollo.QueryHookOptions<GetCloudshelfConfigQuery, GetCloudshelfConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCloudshelfConfigQuery, GetCloudshelfConfigQueryVariables>(GetCloudshelfConfigDocument, options);
      }
export function useGetCloudshelfConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCloudshelfConfigQuery, GetCloudshelfConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCloudshelfConfigQuery, GetCloudshelfConfigQueryVariables>(GetCloudshelfConfigDocument, options);
        }
export type GetCloudshelfConfigQueryHookResult = ReturnType<typeof useGetCloudshelfConfigQuery>;
export type GetCloudshelfConfigLazyQueryHookResult = ReturnType<typeof useGetCloudshelfConfigLazyQuery>;
export type GetCloudshelfConfigQueryResult = Apollo.QueryResult<GetCloudshelfConfigQuery, GetCloudshelfConfigQueryVariables>;
export const GetDeviceInfoDocument = gql`
    query GetDeviceInfo($deviceId: String!) {
  device(deviceId: $deviceId) {
    id
    code
    registered
    bigFormatDisplay
    owner {
      id
      name
    }
  }
}
    `;

/**
 * __useGetDeviceInfoQuery__
 *
 * To run a query within a React component, call `useGetDeviceInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceInfoQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useGetDeviceInfoQuery(baseOptions: Apollo.QueryHookOptions<GetDeviceInfoQuery, GetDeviceInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviceInfoQuery, GetDeviceInfoQueryVariables>(GetDeviceInfoDocument, options);
      }
export function useGetDeviceInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceInfoQuery, GetDeviceInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviceInfoQuery, GetDeviceInfoQueryVariables>(GetDeviceInfoDocument, options);
        }
export type GetDeviceInfoQueryHookResult = ReturnType<typeof useGetDeviceInfoQuery>;
export type GetDeviceInfoLazyQueryHookResult = ReturnType<typeof useGetDeviceInfoLazyQuery>;
export type GetDeviceInfoQueryResult = Apollo.QueryResult<GetDeviceInfoQuery, GetDeviceInfoQueryVariables>;
export const GetLatestVersionDocument = gql`
    query GetLatestVersion {
  getVersionByType(type: ENGINE) {
    versionString
  }
}
    `;

/**
 * __useGetLatestVersionQuery__
 *
 * To run a query within a React component, call `useGetLatestVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestVersionQuery(baseOptions?: Apollo.QueryHookOptions<GetLatestVersionQuery, GetLatestVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestVersionQuery, GetLatestVersionQueryVariables>(GetLatestVersionDocument, options);
      }
export function useGetLatestVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestVersionQuery, GetLatestVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestVersionQuery, GetLatestVersionQueryVariables>(GetLatestVersionDocument, options);
        }
export type GetLatestVersionQueryHookResult = ReturnType<typeof useGetLatestVersionQuery>;
export type GetLatestVersionLazyQueryHookResult = ReturnType<typeof useGetLatestVersionLazyQuery>;
export type GetLatestVersionQueryResult = Apollo.QueryResult<GetLatestVersionQuery, GetLatestVersionQueryVariables>;
export const GetMobileHandoffDocument = gql`
    query GetMobileHandoff($id: ID!) {
  getMobileHandoff(id: $id) {
    id
    productOptionId
    shopifyProductHandle
    uploadedImageUrl
    createdAt
    updatedAt
    isScanned
    isCanceled
  }
}
    `;

/**
 * __useGetMobileHandoffQuery__
 *
 * To run a query within a React component, call `useGetMobileHandoffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobileHandoffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobileHandoffQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMobileHandoffQuery(baseOptions: Apollo.QueryHookOptions<GetMobileHandoffQuery, GetMobileHandoffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMobileHandoffQuery, GetMobileHandoffQueryVariables>(GetMobileHandoffDocument, options);
      }
export function useGetMobileHandoffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMobileHandoffQuery, GetMobileHandoffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMobileHandoffQuery, GetMobileHandoffQueryVariables>(GetMobileHandoffDocument, options);
        }
export type GetMobileHandoffQueryHookResult = ReturnType<typeof useGetMobileHandoffQuery>;
export type GetMobileHandoffLazyQueryHookResult = ReturnType<typeof useGetMobileHandoffLazyQuery>;
export type GetMobileHandoffQueryResult = Apollo.QueryResult<GetMobileHandoffQuery, GetMobileHandoffQueryVariables>;
export const IsTrackedUrlScannedDocument = gql`
    query isTrackedURLScanned($id: String!) {
  isTrackedURLScanned(id: $id)
}
    `;

/**
 * __useIsTrackedUrlScannedQuery__
 *
 * To run a query within a React component, call `useIsTrackedUrlScannedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsTrackedUrlScannedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsTrackedUrlScannedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIsTrackedUrlScannedQuery(baseOptions: Apollo.QueryHookOptions<IsTrackedUrlScannedQuery, IsTrackedUrlScannedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsTrackedUrlScannedQuery, IsTrackedUrlScannedQueryVariables>(IsTrackedUrlScannedDocument, options);
      }
export function useIsTrackedUrlScannedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsTrackedUrlScannedQuery, IsTrackedUrlScannedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsTrackedUrlScannedQuery, IsTrackedUrlScannedQueryVariables>(IsTrackedUrlScannedDocument, options);
        }
export type IsTrackedUrlScannedQueryHookResult = ReturnType<typeof useIsTrackedUrlScannedQuery>;
export type IsTrackedUrlScannedLazyQueryHookResult = ReturnType<typeof useIsTrackedUrlScannedLazyQuery>;
export type IsTrackedUrlScannedQueryResult = Apollo.QueryResult<IsTrackedUrlScannedQuery, IsTrackedUrlScannedQueryVariables>;
export const GetPresignedCustomisationUrlDocument = gql`
    query GetPresignedCustomisationUrl($type: String!) {
  getPresignedCustomisationUrl(type: $type)
}
    `;

/**
 * __useGetPresignedCustomisationUrlQuery__
 *
 * To run a query within a React component, call `useGetPresignedCustomisationUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPresignedCustomisationUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPresignedCustomisationUrlQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetPresignedCustomisationUrlQuery(baseOptions: Apollo.QueryHookOptions<GetPresignedCustomisationUrlQuery, GetPresignedCustomisationUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPresignedCustomisationUrlQuery, GetPresignedCustomisationUrlQueryVariables>(GetPresignedCustomisationUrlDocument, options);
      }
export function useGetPresignedCustomisationUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPresignedCustomisationUrlQuery, GetPresignedCustomisationUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPresignedCustomisationUrlQuery, GetPresignedCustomisationUrlQueryVariables>(GetPresignedCustomisationUrlDocument, options);
        }
export type GetPresignedCustomisationUrlQueryHookResult = ReturnType<typeof useGetPresignedCustomisationUrlQuery>;
export type GetPresignedCustomisationUrlLazyQueryHookResult = ReturnType<typeof useGetPresignedCustomisationUrlLazyQuery>;
export type GetPresignedCustomisationUrlQueryResult = Apollo.QueryResult<GetPresignedCustomisationUrlQuery, GetPresignedCustomisationUrlQueryVariables>;