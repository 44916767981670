const DependencyType = {
    AnalyticsService: Symbol.for('AnalyticsService'),
    ApolloClient: Symbol.for('ApolloClient'),
    BasketService: Symbol.for('BasketService'),
    CategoryAssemblerService: Symbol.for('CategoryAssemblerService'),
    CategoryService: Symbol.for('CategoryService'),
    CloudshelfProductFilterAssemblerService: Symbol.for('CloudshelfProductFilterAssemblerService'),
    FiltersRepository: Symbol.for('FiltersRepository'),
    LocalisationService: Symbol.for('LocalisationService'),
    PriceService: Symbol.for('PriceService'),
    ProductVariantHelperService: Symbol.for('ProductVariantHelperService'),
    ProviderPurchaseUrlGeneratorService: Symbol.for('ProviderPurchaseUrlGeneratorService'),
    CloudshelfPurchaseUrlGeneratorService: Symbol.for('CloudshelfPurchaseUrlGeneratorService'),
    StoreService: Symbol.for('StoreService'),
    ShopifyVariantService: Symbol.for('ShopifyVariantService'),
    SessionManagementService: Symbol.for('SessionManagementService'),
    ConfigurationService: Symbol.for('ConfigurationService'),
    DeviceService: Symbol.for('DeviceService'),
    TileAnimationOrchestratorService: Symbol.for('TileAnimationOrchestratorService'),
    DisplayOnlyOrchestratorService: Symbol.for('DisplayOnlyOrchestratorService'),
    CloudshelfTrackedURLService: Symbol.for('CloudshelfTrackedURLService'),
    ProductFilteringService: Symbol.for('ProductFilteringService'),
    StorageService: Symbol.for('StorageService'),
    EventsService: Symbol.for('EventsService'),
    CloudflareImageService: Symbol.for('CloudflareImageService'),
    TileClicksService: Symbol.for('TileClicksService'),
    HandoffService: Symbol.for('HandoffService'),
    GeoLocationService: Symbol.for('GeoLocationService'),
    InteractiveOrchestratorService: Symbol.for('InteractiveOrchestratorService'),
    MenuService: Symbol.for('MenuService'),
};

export default DependencyType;
