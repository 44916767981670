import { CSSProperties, FC } from 'react';
import './BouncingArrow.scss';

export interface BouncingArrowProps {
    className?: string;
    rotate?: number;
    bottomPadding?: number;
}

export const BouncingArrowComponent: FC<BouncingArrowProps> = props => {
    const svgStyle: CSSProperties = {
        transform: `rotate(${props.rotate}deg)`,
        transition: `transform 1s ease-in-out`,
    };

    return (
        <div className={`${props.className} BouncingArrow`} style={{ paddingBottom: '0.75em' }}>
            <svg
                width="40"
                height="15"
                viewBox="0 0 40 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={svgStyle}
            >
                <path
                    d="M36.5312 11.5312L20 2.5L3.46875 11.5312"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

BouncingArrowComponent.defaultProps = {
    rotate: 0,
};
