import React, { useEffect, useRef, useState } from 'react';
import { useInjection } from '../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../dependancyInjection/DependencyType';
import { FilterInputWrapper } from './wrappers/FilterInputWrapper';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
    AttributeValue,
    CapitalisationStyle,
} from '../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { ProductFilteringService } from '../../ProductFilteringService';
import { FiltersViewButton } from '../FiltersViewButton';
import { Base64 } from 'js-base64';
import './DiscreteFilterInput.scss';
import { Flipped } from 'react-flip-toolkit';
import useStateRef from 'react-usestateref';
import { CloudshelfEngineFilter } from '../../../ConfigurationService/types/filters/CloudshelfEngineFilter';
import { getUniqAttributeItems } from '../../../../utils/EngineFilter.Util';
import StyledText, { TextSize, TextStyle } from '../../../../components/shared/StyledText/StyledText';
import RoundedArrowUpIcon from '../../../../components/icons/rounded_arrow_up';
import RoundedArrowDownIcon from '../../../../components/icons/rounded_arrow_down';

export interface DiscreteFilterItemProps {
    definition: CloudshelfEngineFilter;
    label: string;
    icon: React.ReactElement | string | null;
    categoryId: string;
    forceSingleValue?: boolean;
}

export const DiscreteFilterInput: React.FC<DiscreteFilterItemProps> = React.memo(
    ({ definition, label, categoryId, forceSingleValue }) => {
        const closedHeight = 250;
        const [, setHasOverflow, hasOverflowRef] = useStateRef<boolean>(true);
        const wrapperRef = useRef<HTMLDivElement>(null);
        const listRef = useRef<HTMLUListElement>(null);
        const filteringService = useInjection<ProductFilteringService>(DependencyType.ProductFilteringService);
        const [calculatedHeight, setCalculatedHeight, calculatedHeightRef] = useStateRef(closedHeight);
        const { t } = useTranslation();
        const [isExpanded, setIsExpanded] = useState<boolean>(false);

        const handleSelection = (filterValue: { attributeValue: AttributeValue; displayName: string }) => {
            const filterDefinition = definition;

            if (forceSingleValue) {
                const mergedChildDefinitions = filteringService.findChildDefinitionsByParentAndValue(
                    filterDefinition.id,
                    filterValue.attributeValue.value,
                );

                for (const childDefinition of mergedChildDefinitions) {
                    filteringService.toggleSingleValue(
                        definition.id,
                        childDefinition.id,
                        childDefinition.ecommProviderFieldName,
                        childDefinition.type,
                        filterValue.attributeValue.value,
                    );
                }

                filteringService.toggleSingleValue(
                    definition.id,
                    filterDefinition.id,
                    definition.ecommProviderFieldName,
                    definition.type,
                    filterValue.attributeValue.value,
                );
            } else {
                const mergedChildDefinitions = filteringService.findChildDefinitionsByParentAndValue(
                    filterDefinition.id,
                    filterValue.attributeValue.value,
                );

                for (const childDefinition of mergedChildDefinitions) {
                    filteringService.toggleValue(
                        definition.id,
                        childDefinition.id,
                        childDefinition.ecommProviderFieldName,
                        childDefinition.type,
                        filterValue.attributeValue.value,
                    );
                }

                filteringService.toggleValue(
                    definition.id,
                    filterDefinition.id,
                    filterDefinition.ecommProviderFieldName,
                    filterDefinition.type,
                    filterValue.attributeValue.value,
                );
            }
        };

        const getDisplayName = (internalValue: string) => {
            const attributeValue = _.find(definition.attributeValues, av => av.value === internalValue);
            if (!attributeValue) {
                return '';
            }
            const override = _.find(
                definition.valueOverrides,
                vo => vo.originalValue === attributeValue.value && vo.displayValue !== '',
            );
            let value = attributeValue.value;
            if (override) {
                value = override.displayValue;
            }
            // Capitalisation
            if (definition.options?.capitalisationStyle) {
                switch (definition.options.capitalisationStyle) {
                    case CapitalisationStyle.Capitalised:
                        value = _.startCase(_.toLower(value));
                        break;
                    case CapitalisationStyle.Uppercase:
                        value = _.upperCase(value);
                        break;
                    case CapitalisationStyle.Original:
                        // We don't need to do anything
                        break;
                }
            }

            return value;
        };

        const filterSelection = filteringService.getCurrentSelection();
        const options = _.chain(getUniqAttributeItems(definition))
            .map(val => ({ attributeValue: val, displayName: getDisplayName(val.value) }))
            .sortBy(val => [val.attributeValue.priority, val.attributeValue.value], 'asc')
            .map(filterValue => {
                if (
                    definition.hiddenAttributeValues.includes(filterValue.attributeValue.value) ||
                    (categoryId !== '' && !_.includes(filterValue.attributeValue.categoryIds, categoryId))
                ) {
                    return null;
                }

                const b64 = Base64.encode(
                    `discreteFilter-${filterValue.attributeValue.value}-${filterValue.displayName}`,
                );
                return (
                    <Flipped flipId={b64} key={b64}>
                        <li className="DiscreteFilterInput__optionsListItem">
                            <FiltersViewButton
                                definition={definition}
                                filterValue={filterValue}
                                handleSelection={handleSelection}
                                filteringService={filteringService}
                                initialFilterSelection={filterSelection}
                            />
                        </li>
                    </Flipped>
                );
            })
            .compact()
            .value();

        useEffect(() => {
            if (listRef.current && wrapperRef.current) {
                if (hasOverflowRef.current !== listRef.current.clientHeight > wrapperRef.current.clientHeight) {
                    setHasOverflow(listRef.current.clientHeight > wrapperRef.current.clientHeight);
                }
            }
        }, [wrapperRef, options]);

        useEffect(() => {
            if (!isExpanded) {
                if (calculatedHeightRef.current !== closedHeight) {
                    setCalculatedHeight(closedHeight);
                }
            } else {
                if (listRef.current) {
                    if (calculatedHeightRef.current !== listRef.current.clientHeight) {
                        setCalculatedHeight(listRef.current.clientHeight);
                    }
                }
            }
        }, [isExpanded]);

        if (options.length === 0) {
            return null;
        }

        return (
            <FilterInputWrapper label={label} expandedByDefault={true} flipId={definition.id}>
                <div
                    ref={wrapperRef}
                    className={`DiscreteFilterInput ${isExpanded ? 'DiscreteFilterInput__expanded' : ''}`}
                    style={{ maxHeight: calculatedHeight }}
                >
                    <ul className="DiscreteFilterInput__optionsList" ref={listRef}>
                        {options}
                    </ul>
                </div>
                {hasOverflowRef.current && (
                    <button className="DiscreteFilterInput__showMore" onClick={() => setIsExpanded(!isExpanded)}>
                        <StyledText style={TextStyle.Body} size={TextSize.Small} translate>
                            {isExpanded ? t('filters_view.showLess') : t('filters_view.showMore')}
                        </StyledText>
                        {isExpanded ? <RoundedArrowUpIcon /> : <RoundedArrowDownIcon />}
                    </button>
                )}
            </FilterInputWrapper>
        );
    },
);
