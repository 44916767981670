import StyledText, { TextSize, TextStyle } from '../../../../../shared/StyledText/StyledText';
import StockLabel from '../../StockLabel/StockLabel';
import React, { FC } from 'react';
import { ProductVariantAvailability } from '../../../../../../services/ProductServices/ProductVariantAvailability';
import ProductVariant from '../../../../../../services/ProductServices/variant/ProductVariant';
import { useInjection } from '../../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../../dependancyInjection/DependencyType';
import { ProductDetails } from '../../../../../../services/ProductServices/variant/ProductVariantService';
import styles from './ProductAvailability.module.scss';
import { ConfigurationService } from '../../../../../../services/ConfigurationService/ConfigurationService';
import _ from 'lodash';

export interface ProductAvailabilityProps {
    productDetails?: ProductDetails;
    selectedVariant?: ProductVariant;
    allVariantsUnavailable: boolean;
}
const ProductAvailability: FC<ProductAvailabilityProps> = props => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    let availabilityLabel: ProductVariantAvailability;

    let productAvailability: ProductVariantAvailability = ProductVariantAvailability.Unavailable;

    if (props.productDetails) {
        if (!props.productDetails.availableForSale) {
            productAvailability = ProductVariantAvailability.Unavailable;
        } else if (props.productDetails.limitedAvailability) {
            productAvailability = ProductVariantAvailability.LimitedAvailability;
        } else if (props.productDetails.orderOnly) {
            productAvailability = ProductVariantAvailability.OnOrder;
        } else if (props.productDetails.availableForSale) {
            productAvailability = ProductVariantAvailability.InStock;
        }
    }

    if (props.selectedVariant !== undefined) {
        availabilityLabel = props.selectedVariant.availability;
    } else {
        availabilityLabel = productAvailability;
    }

    const classNames = [styles.ProductAvailability__sectionStock];
    if (props.selectedVariant?.inventory === 0) {
        classNames.push(styles.ProductAvailability__unavailable);
    } else if (
        productAvailability === ProductVariantAvailability.LimitedAvailability &&
        !props.allVariantsUnavailable &&
        props.selectedVariant === undefined
    ) {
        classNames.push(styles.ProductAvailability__limited);
    } else if (props.selectedVariant?.availability === ProductVariantAvailability.InStock) {
        classNames.push(styles.ProductAvailability__inStock);
    } else if (!props.selectedVariant && productAvailability === ProductVariantAvailability.InStock) {
        classNames.push(styles.ProductAvailability__inStock);
    } else if (!props.selectedVariant) {
        classNames.push(styles.ProductAvailability__unavailable);
    } else if (productAvailability === ProductVariantAvailability.OnOrder) {
        classNames.push(styles.ProductAvailability__unavailable);
    }

    let content = [];

    if (configService.config()?.showTotalStockCount) {
        content.push(
            <StyledText className={classNames.join(' ')} style={TextStyle.Subheading} size={TextSize.Small} translate>
                <span className={'NeverTranslate'}>
                    {props.selectedVariant ? props.selectedVariant.inventory : props.productDetails?.totalInventory}{' '}
                </span>
                available
            </StyledText>,
        );

        content.push(<StockLabel availability={availabilityLabel} />);
    }

    content = _.compact(content);

    if (content.length === 0) {
        return null;
    }

    return (
        <>
            <div className={styles.ProductAvailability__flex}>{content}</div>
            <hr />
        </>
    );
};

export default ProductAvailability;
