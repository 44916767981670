import { FC, useState } from 'react';
import { useInjection } from '../../../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../../../dependancyInjection/DependencyType';
import { ProductFilteringService } from '../../../../../../../services/ProductServices/ProductFilteringService';
import { StorageKey } from '../../../../../../../services/StorageService/StorageKeys.enum';
import { StorageService } from '../../../../../../../services/StorageService/StorageService';
import { ConfigurationService } from '../../../../../../../services/ConfigurationService/ConfigurationService';

const ProductBinaryOverlay: FC = () => {
    const [forceReload, setForceReload] = useState<boolean>(false);
    const productFilteringService = useInjection<ProductFilteringService>(DependencyType.ProductFilteringService);
    const storageService = useInjection<StorageService>(DependencyType.StorageService);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);

    const thisCS = configService.cloudshelfId;
    const cacheForCS = storageService.get(StorageKey.CLOUDSHELF_ID);
    const variantsInCache = productFilteringService.totalNumberOfProductsInCache();
    const productsCurrentlyMatched = productFilteringService.matchingProductCount;
    const currentFilters = JSON.stringify(productFilteringService.getCurrentSelection());

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const handleReload = e => {
        setForceReload(!forceReload);
        e.stopPropagation();
        e.nativeEvent.stopPropagation();
    };

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 20, overflow: 'scroll' }}>
                <p>Cache is valid for this CS: {thisCS === cacheForCS ? 'Yes' : 'No'}</p>
                <p>Variants in cache: {variantsInCache}</p>
                <p>Products matching filters: {productsCurrentlyMatched}</p>
                <button onClick={handleReload}>Refresh</button>
            </div>
            <br />
            <div style={{ display: 'flex', flexDirection: 'row', gap: 20, overflow: 'scroll' }}>
                <p>
                    Current filters:
                    <br />
                    <br />
                    {currentFilters}
                </p>
            </div>
        </div>
    );
};

export default ProductBinaryOverlay;
