import React from 'react';

import './PanelHeader.scss';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import ButtonIcon from '../../../../shared/inputs/ButtonIcon/ButtonIcon';
import { ButtonSize, ButtonVariant } from '../../../../shared/inputs/Button/Button';
import CloseIcon from '../../../../icons/close';

export type CloseButtonCallback = () => void;

export interface PurchaseHeaderProps {
    bottomBorder?: boolean;
    className?: string;
    onCloseButtonClicked: CloseButtonCallback;
    title?: string;
    translate?: boolean;
    leftAlignTitle?: boolean;
}

export const PanelHeader: React.FC<PurchaseHeaderProps> = ({
    onCloseButtonClicked,
    className,
    bottomBorder = false,
    title,
    leftAlignTitle,
    children,
}) => {
    return (
        <div className={`PanelHeader ${className || ''} ${bottomBorder ? 'PanelHeader--withBorder' : ''}`}>
            <div className={`PanelHeader__body ${leftAlignTitle && 'PanelHeader__body__leftAlign'}`}>
                {title ? (
                    <StyledText
                        style={TextStyle.Subheading}
                        size={TextSize.Medium}
                        className="PanelHeader__title"
                        translate
                    >
                        {title}
                    </StyledText>
                ) : (
                    children
                )}
            </div>
            <ButtonIcon
                className="PanelHeader__closeButton"
                icon={<CloseIcon />}
                size={ButtonSize.AUTO}
                onClick={onCloseButtonClicked}
            />
        </div>
    );
};
