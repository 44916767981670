import React, { FC, useEffect, useState, memo, CSSProperties } from 'react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { BackButtonMode, MenuService, MenuStyle } from '../../../../../services/MenuService/MenuService';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import { BasketService } from '../../../../../services/BasketService/BasketService';
import { ProductFilteringService } from '../../../../../services/ProductServices/ProductFilteringService';
import { EventsService } from '../../../../../services/EventsService/EventsService';
import { usePurchase } from '../../../../../hooks/contexts/Purchase/Purchase';
import {
    CloudshelfConfigStatus,
    DeviceMode,
} from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import './Menu.scss';
import { NameFilterInput } from '../../../../../services/ProductServices/filters/inputs/NameFilterInput';

import TextTransition from 'react-text-transition/lib/components/TextTransition';
import { getRefRule } from '../../../../../utils/Responsive.Util';
import CloseWhiteIcon from '../../../../icons/close_white';
import ArrowBackIcon from '../../../../icons/arrow_back';
import FilterIcon from '../../../../icons/filter';
import ShoppingCartIcon from '../../../../icons/shopping_cart';

export const Menu: FC = memo(() => {
    const menuService = useInjection<MenuService>(DependencyType.MenuService);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const basketService = useInjection<BasketService>(DependencyType.BasketService);
    const filteringService = useInjection<ProductFilteringService>(DependencyType.ProductFilteringService);
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);

    const purchaseContext = usePurchase();

    const [menuStyle, setMenuStyle] = useState(MenuStyle.BUTTON_EXPANDABLE_SEARCH);
    const [backButtonMode, setBackButtonMode] = useState(BackButtonMode.NONE);
    const [searchOrFilterButtonVisible, setSearchOrFilterButtonVisible] = useState(true);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [searchBarExtended, setSearchBarExtended] = useState(false);
    const [contentAreaHeight, setContentAreaHeight] = useState(0);

    const isHandoff = configService.status() === CloudshelfConfigStatus.MobileHandoff;

    const contentStyle: CSSProperties = {
        justifyContent: 'space-between',
    };

    if (menuStyle === MenuStyle.BUTTON_EXPANDABLE_SEARCH) {
        contentStyle.justifyContent = 'flex-end';
    }
    const rightStyle: CSSProperties = {
        marginLeft: '2.7vw',
    };

    if (menuStyle === MenuStyle.BUTTON_EXPANDABLE_SEARCH) {
        rightStyle.width = `${searchBarExtended ? '100%' : totalQuantity > 0 ? '45%' : '30%'}`;
        rightStyle.transition = '0.5s width ease';
        rightStyle.marginLeft = '0';
    }

    useEffect(() => {
        const searchOrFilterVisibilitySubscriber = menuService
            .observeFilterVisibilityChange()
            .subscribe(shouldBeVisible => {
                setSearchOrFilterButtonVisible(shouldBeVisible);
            });
        const backButtonModeSubscriber = menuService.observeBackButtonModeChange().subscribe(mode => {
            setBackButtonMode(mode);
        });
        const menuStyleSubscriber = menuService.observeMenuStyleChange().subscribe(style => {
            setMenuStyle(style);
        });
        const expandedSearchSubscriber = menuService.observeExpandedSearchChange().subscribe(expanded => {
            setSearchBarExtended(expanded);
        });
        return () => {
            searchOrFilterVisibilitySubscriber.unsubscribe();
            backButtonModeSubscriber.unsubscribe();
            menuStyleSubscriber.unsubscribe();
            expandedSearchSubscriber.unsubscribe();
        };
    }, [menuService]);

    useEffect(() => {
        const subscriber = basketService.observeBasket().subscribe(() => {
            setTotalQuantity(basketService.totalQuantity());
        });
        return () => {
            subscriber.unsubscribe();
        };
    }, [basketService]);

    const onBackButtonClicked = () => {
        if (backButtonMode === BackButtonMode.BACK) {
            eventsService.setOpenCategory(undefined);
            filteringService.clearSelection(true);
        }
        menuService.fireBackTapped();
    };

    const onFilterButtonClicked = () => {
        menuService.fireFilterTapped();
    };

    const onSearchButtonClicked = () => {
        menuService.setExpandedSearchOpen(true);
    };

    if (configService.deviceMode === DeviceMode.DisplayOnly || isHandoff) {
        return null;
    }

    return (
        <nav className="Menu">
            <div className="Menu__content" style={contentStyle}>
                {menuStyle === MenuStyle.BAR_AND_FILTER_BUTTON && (
                    <>
                        <div className="Menu__content__left">
                            {backButtonMode !== BackButtonMode.NONE && (
                                <button className="Menu__roundButton" onClick={onBackButtonClicked}>
                                    {backButtonMode === BackButtonMode.CLOSE ? <CloseWhiteIcon /> : <ArrowBackIcon />}
                                </button>
                            )}
                        </div>

                        <div className="Menu__content__center Menu__fixed">
                            {searchOrFilterButtonVisible && (
                                <NameFilterInput
                                    initialValue={filteringService.searchValue}
                                    onChange={() => {}}
                                    isOnScreen={true}
                                    short={false}
                                    commitFilters
                                />
                            )}
                        </div>
                    </>
                )}
                <div className="Menu__content__right" style={rightStyle}>
                    {searchOrFilterButtonVisible && menuStyle === MenuStyle.BAR_AND_FILTER_BUTTON && (
                        <button
                            className="Menu__roundButton Menu__roundButton__retailerStyled"
                            onClick={onFilterButtonClicked}
                        >
                            <FilterIcon />
                        </button>
                    )}
                    {searchOrFilterButtonVisible && menuStyle === MenuStyle.BUTTON_EXPANDABLE_SEARCH && (
                        <div className="Menu__fixed" onClick={onSearchButtonClicked}>
                            <NameFilterInput
                                initialValue=""
                                onChange={() => {}}
                                isOnScreen={true}
                                short={!searchBarExtended}
                            />
                        </div>
                    )}
                    {totalQuantity > 0 && (
                        <button
                            className="Menu__roundButton Menu__roundButton__smallerIcon Menu__roundButton__retailerStyled Menu__checkout"
                            onClick={purchaseContext.checkout}
                        >
                            <ShoppingCartIcon />
                            <TextTransition>{totalQuantity}</TextTransition>
                        </button>
                    )}
                </div>
            </div>
        </nav>
    );
});

export function getMenuHeight(): number {
    const ruleSizes = getRefRule();
    let menuHeight = ruleSizes.minHeight;
    if (ruleSizes.smallestDimension > menuHeight) {
        menuHeight = ruleSizes.smallestDimension;
    }

    return menuHeight;
}
