import React, { FC } from 'react';

const RoundedArrowDownIcon: FC = props => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.4022 11.3837L12.5365 14.1587C12.3764 14.2953 12.1916 14.3625 12.0067 14.3625C11.8218 14.3625 11.6374 14.2947 11.4964 14.1592L8.63072 11.3842C8.40423 11.1864 8.34244 10.8872 8.45414 10.6292C8.56583 10.3712 8.82914 10.2 9.12067 10.2H14.8724C15.1641 10.2 15.4275 10.3686 15.5393 10.6281C15.6512 10.8876 15.6097 11.1864 15.4022 11.3837Z"
                fill="#A4A4A4"
            />
        </svg>
    );
};
export default RoundedArrowDownIcon;
