import React, { CSSProperties, useRef } from 'react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import _ from 'lodash';
import { Skeleton } from '../../../../shared/Skeleton/Skeleton';
import './ProductCard.scss';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import { useComputedStyle } from '../../../../../hooks/UseComputedStyle';

export type OnClicked = () => void;

export type BannerType = 'inStock' | 'limitedAvailability' | 'onOrder' | 'soldOut';

export interface ProductCardProps {
    handle: string;
    imageUrl?: string;
    title: string;
    price: string;
    originalPrice?: string;
    onClicked: OnClicked;
    bannerType?: BannerType;
    loading?: boolean;
}

const ProductCard: React.FC<ProductCardProps> = React.memo(
    ({ imageUrl, title, price, onClicked, originalPrice, bannerType, loading }) => {
        const [firstImageLoaded, setFirstImageLoaded] = React.useState<boolean>(false);
        const [firstImageError, setFirstImageError] = React.useState<boolean>(false);
        const ref = useRef<HTMLDivElement>(null);
        const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
        const imageWidth = useComputedStyle(ref, 'width');

        const imageContainerStyle: CSSProperties = {
            width: imageWidth === 0 ? '100%' : `${imageWidth}px`,
            height: imageWidth === 0 ? '100%' : `${imageWidth}px`,
        };

        imageContainerStyle.height =
            imageContainerStyle.height === '100%'
                ? '100%'
                : `${imageWidth * (configService.config()?.theme.tileSizeModifier ?? 1)}px`;

        const style: CSSProperties = {
            backgroundImage: imageUrl ? `url(${imageUrl})` : '',
            width: '100%',
            height: '100%',
        };

        const contentAreaSize = imageWidth * 0.22;
        const textAreaGapSize = imageWidth * 0.01;
        const productNameSize = contentAreaSize * 0.35;
        const priceSize = contentAreaSize * 0.3;

        const calculateLineHeight = (fontSize: number) => {
            return `${fontSize * 1.2}px`;
        };

        const displaySoldOutLabel = configService.config()?.displaySoldOutLabel;
        const displayLimitedLabel = configService.config()?.displayLimitedLabel;
        const displayOnOrderLabel = configService.config()?.displayOutOfStockLabel;
        const displayInStockLabel = configService.config()?.displayInStockLabel;

        let stockLabel: string | undefined = undefined;

        if (bannerType === 'soldOut') {
            if (displaySoldOutLabel) {
                stockLabel = _.trim(configService.config()?.soldOutLabel ?? '') || 'Sold out';
            }
        } else if (bannerType === 'limitedAvailability') {
            if (displayLimitedLabel) {
                stockLabel = _.trim(configService.config()?.limitedAvailabilityLabel ?? '') || 'Limited selection';
            }
        } else if (bannerType === 'onOrder') {
            if (displayOnOrderLabel) {
                stockLabel = _.trim(configService.config()?.outOfStockLabel ?? '') || 'On order';
            }
        } else if (bannerType === 'inStock') {
            if (displayInStockLabel) {
                stockLabel = _.trim(configService.config()?.inStockLabel ?? '');
            }
        }

        return (
            <div className="ProductCard" onClick={onClicked} role="button" onKeyPress={() => {}} tabIndex={0}>
                <div className="ProductCard__imageContainer" style={imageContainerStyle}>
                    {loading || (!firstImageLoaded && imageUrl) ? (
                        <>
                            <div className="ProductCard__image" ref={ref} style={style}>
                                <Skeleton type={'rectangle'} />
                                {imageUrl !== undefined && (
                                    <img
                                        alt=""
                                        src={imageUrl}
                                        style={{ display: 'none' }}
                                        onLoad={() => {
                                            setFirstImageLoaded(true);
                                            setFirstImageError(false);
                                        }}
                                        onError={() => {
                                            setFirstImageLoaded(true);
                                            setFirstImageError(true);
                                        }}
                                    />
                                )}
                            </div>
                        </>
                    ) : (
                        <div className="ProductCard__image" ref={ref} style={style}>
                            {firstImageError && (
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <p>Unable to load image</p>
                                </div>
                            )}

                            {bannerType && stockLabel && <div className="ProductCard__banner">{stockLabel}</div>}
                        </div>
                    )}
                </div>

                <div className="ProductCard__content" style={{ height: contentAreaSize }}>
                    <p className="ProductCard__text NeverTranslate" style={{ gap: textAreaGapSize }}>
                        <span
                            className="ProductCard__title"
                            style={{ fontSize: productNameSize, lineHeight: calculateLineHeight(productNameSize) }}
                        >
                            {title}
                        </span>
                        <span style={{ fontSize: priceSize, lineHeight: calculateLineHeight(priceSize) }}>
                            {originalPrice && (
                                <span
                                    className="ProductCard__saleContainer"
                                    style={{ fontSize: priceSize, lineHeight: calculateLineHeight(priceSize) }}
                                >
                                    <span className="ProductCard__originalPrice">{originalPrice}</span>
                                    <span className="ProductCard__newPrice">{price}</span>
                                </span>
                            )}
                            {!originalPrice && <span>{price}</span>}
                        </span>
                    </p>
                </div>
            </div>
        );
    },
);

export default ProductCard;
