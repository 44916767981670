import {
    AttributeValue,
    CapitalisationStyle,
    FilterOrderType,
    FilterType,
} from '../provider/cloudshelf/graphql/generated/cloudshelf_types';
import _ from 'lodash';
import { CloudshelfEngineFilter } from '../services/ConfigurationService/types/filters/CloudshelfEngineFilter';
import { FilterValueType } from '../services/ConfigurationService/types/filters/FilterValueType';
import { FilterableProductVariant } from '../services/ProductServices/LocalProduct';

export function getUniqAttributeItems(thisFilter: CloudshelfEngineFilter): AttributeValue[] {
    return _.uniqBy(thisFilter.attributeValues, av => av.value);
}

export function getPriceRange(values: string[], places: number): string {
    if (values.length < 2) {
        if (values.length === 1) {
            return `£${parseFloat(values[0]).toFixed(places)}`;
        } else {
            return 'Unknown Price';
        }
    } else {
        return `£${parseFloat(values[0]).toFixed(places)}-£${parseFloat(values[1]).toFixed(places)}`;
    }
}

export function buildOrderByFilter(categoryIds: string[]): CloudshelfEngineFilter {
    return {
        attributeValues: [
            {
                categoryIds: categoryIds,
                priority: 0,
                value: 'relevance',
                parentFilterId: null,
            },
            {
                categoryIds: categoryIds,
                priority: 1,
                value: 'price-desc',
                parentFilterId: null,
            },
            {
                categoryIds: categoryIds,
                priority: 1,
                value: 'price-asc',
                parentFilterId: null,
            },
        ],
        displayName: 'Sort By',
        hiddenAttributeValues: [],
        ecommProviderFieldName: 'Sort By',
        expandedByDefault: true,
        id: 'sort-by',
        type: FilterType.SortOrder,
        isHidden: false,
        isMergedChild: false,
        parentId: undefined,
        valueType: FilterValueType.DISCRETE,
        options: {
            __typename: 'FilterOptions',
            swatches: null,
            baseShoesizeUnit: null,
            capitalisationStyle: CapitalisationStyle.Original,
            orderType: FilterOrderType.Custom,
        },
        valueOverrides: [
            {
                __typename: 'AttributeValueOverride',
                displayValue: 'Relevance',
                originalValue: 'relevance',
                parentFilterId: null,
            },
            {
                __typename: 'AttributeValueOverride',
                displayValue: 'Price (High to Low)',
                originalValue: 'price-desc',
                parentFilterId: null,
            },
            {
                __typename: 'AttributeValueOverride',
                displayValue: 'Price (Low to High)',
                originalValue: 'price-asc',
                parentFilterId: null,
            },
        ],
        priority: -10,
    };
}

export function buildBuiltInSortOrder_Relevance(): [keyof FilterableProductVariant, boolean][] {
    return [
        ['availableForSale', true],
        ['remoteUpdatedAt', true],
    ];
}

export function buildBuiltInSortOrder_PriceAsc(): [keyof FilterableProductVariant, boolean][] {
    return [['price', false]];
}

export function buildBuiltInSortOrder_PriceDesc(): [keyof FilterableProductVariant, boolean][] {
    return [['price', true]];
}

export function buildBuiltInSortOrder_MostRecent(): [keyof FilterableProductVariant, boolean][] {
    return [['remoteUpdatedAt', true]];
}
