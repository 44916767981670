import { FilterableProductVariant } from '../services/ProductServices/LocalProduct';

const PRODUCT_VARIANT_LABELS_TO_UPPERCASE = [
    '3xs',
    'xxxs',
    'xxs',
    'xs',
    's',
    'm',
    'l',
    'xl',
    'xxl',
    'xxxl',
    '3xl',
    '4xl',
    '5xl',
    '6xl',
    '7xl',
    'o/s',
    'os',
];

export function forceOptionLabelCorrectness(value: string): string {
    if (PRODUCT_VARIANT_LABELS_TO_UPPERCASE.includes(value.toLowerCase())) {
        return value.toUpperCase();
    }
    return value;
}

export function variantIsOnSale(variant: FilterableProductVariant): boolean {
    return variant.originalPrice !== variant.price && variant.originalPrice > 0;
}
