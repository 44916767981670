import StyledText, { TextSize, TextStyle } from '../../../../../shared/StyledText/StyledText';
import { FC } from 'react';
import { PdpBlockMetafieldDisplayType } from '../../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import DOMPurify from 'dompurify';
import { getYoutubeVideoId } from '../../../../../../utils/Youtube.Util';
import { ResponsiveYoutubePlayer } from '../../../../../shared/ResponsiveYoutubePlayer';

import styles from './ProductMetafield.module.scss';

export interface ProductMetafieldProps {
    content?: string;
    displayType: PdpBlockMetafieldDisplayType;
}

const ProductMetafield: FC<ProductMetafieldProps> = props => {
    //This is hacky, but it works for now, later we will have a "Table type" metafield
    function convertTextToTable(text: string): string {
        const rows = text.split('\n');
        let table =
            '<div style="padding-left: 1px; padding-top: 1px"><table width="95%" style="border: 1px solid; border-collapse: collapse">';

        rows.forEach(row => {
            const columns = row.split(':|');
            let tableRow = '<tr style="border: 1px solid;">';

            columns.forEach((column, index) => {
                let cellData = column;
                if (index === 0) {
                    cellData = `<strong>${cellData}</strong>`;
                }
                const tableCell = `<td style="border: 1px solid; padding: 5px; vertical-align: top;">${cellData}</td>`;
                tableRow += tableCell;
            });

            tableRow += '</tr>';
            table += tableRow;
        });

        table += '</table></div>';
        return table;
    }

    const cleanText = (text?: string): string => {
        let originalText = text ?? '';

        if (originalText.includes(':|')) {
            originalText = convertTextToTable(originalText);
        }

        originalText = originalText?.replace(/(?:\r\n|\r|\n)/g, '<br />');

        return originalText ?? '';
    };

    const cleaned = DOMPurify.sanitize(cleanText(props.content));

    if (props.displayType === PdpBlockMetafieldDisplayType.Text) {
        return (
            <StyledText
                style={TextStyle.Body}
                size={TextSize.Small}
                className={styles.ProductMetafield__AllowDefaultStyles}
            >
                <span dangerouslySetInnerHTML={{ __html: cleaned }} />
            </StyledText>
        );
    } else if (props.displayType === PdpBlockMetafieldDisplayType.Image) {
        return <img src={props.content} alt="" width="100%" />;
    } else if (props.displayType === PdpBlockMetafieldDisplayType.YoutubeVideo) {
        const { isYoutube, videoId } = getYoutubeVideoId(props.content ?? '');
        if (isYoutube) {
            return <ResponsiveYoutubePlayer videoId={videoId} />;
        } else {
            console.info(`Metafield Content is not a youtube video: "${props.content}"`);
            return (
                <StyledText
                    style={TextStyle.Body}
                    size={TextSize.Small}
                    className={styles.ProductMetafield__AllowDefaultStyles}
                >
                    Sorry, we are unable to show this video content at the moment.
                </StyledText>
            );
        }
    }

    return null;
};

export default ProductMetafield;
