import React, { FC, useEffect, useState } from 'react';
import StarsBackground from '../../../shared/StarsBackground/StarsBackground';
import NonResponsiveStyledText, {
    NonResponsiveTextSize,
    NonResponsiveTextStyle,
} from '../../../shared/StyledText/NonResponsiveStyledText';
import Loader from '../../../shared/Loader/Loader';
import './CompatibilityStyles.scss';
import _ from 'lodash';
import useStateRef from 'react-usestateref';
import i18n from '../../../../services/I18n/I18n';
import { gzipCompress, gzipDecompress } from '../../../../utils/Compression.Util';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../../services/RoutesService/Routes';

export enum CompatibilityScreenDisplayMode {
    Basic,
    Advanced,
}
export enum CompatibilityTestResultType {
    Pending = 'pending',
    Checking = 'checking',
    Success = 'success',
    Failure = 'failure',
}

export interface CompatibilityTestResult {
    type: CompatibilityTestResultType;
    error?: string;
}

export interface CompatibilityTest {
    name: string;
    function: () => Promise<CompatibilityTestResultType.Success | CompatibilityTestResultType.Failure>;
}

export const ALL_COMPATIBILITY_TESTS: CompatibilityTest[] = [
    {
        name: 'CSS Supports Function',
        function: async () => {
            return CSS && typeof CSS.supports === 'function'
                ? CompatibilityTestResultType.Success
                : CompatibilityTestResultType.Failure;
        },
    },
    {
        name: 'Visual Viewport',
        function: async () => {
            return window.visualViewport !== undefined
                ? CompatibilityTestResultType.Success
                : CompatibilityTestResultType.Failure;
        },
    },
    {
        name: 'Intl -> Format To Parts',
        function: async () => {
            if (!window.Intl || typeof window.Intl !== 'object' || typeof window.Intl.NumberFormat !== 'function') {
                return CompatibilityTestResultType.Failure;
            }
            const gbpNumberFormat = new Intl.NumberFormat(i18n.language, {
                style: 'currency',
                currency: 'GBP',
            });

            if (!gbpNumberFormat || typeof gbpNumberFormat.formatToParts !== 'function') {
                return CompatibilityTestResultType.Failure;
            }
            return CompatibilityTestResultType.Success;
        },
    },
    {
        name: 'CSS: min/max',
        function: async () => {
            return CSS.supports('width: min(1px, 2px)') && CSS.supports('width: max(1px, 2px)')
                ? CompatibilityTestResultType.Success
                : CompatibilityTestResultType.Failure;
        },
    },
    {
        name: 'CSS: Flexbox including direction column-reverse',
        function: async () => {
            return CSS.supports('flex-direction: column-reverse')
                ? CompatibilityTestResultType.Success
                : CompatibilityTestResultType.Failure;
        },
    },
    {
        name: 'Encryption / Decryption',
        function: async () => {
            const originalText = 'This is a test';
            const compressedText = gzipCompress(originalText);
            const decompressedText = gzipDecompress(compressedText);

            if (originalText === decompressedText && compressedText !== originalText) {
                return CompatibilityTestResultType.Success;
            }
            return CompatibilityTestResultType.Failure;
        },
    },
];

const CheckCompatibilityComponent: FC = () => {
    const history = useHistory();
    const [overallStatus, setOverallStatus, overallStatusRef] = useStateRef<
        CompatibilityTestResultType.Checking | CompatibilityTestResultType.Failure | CompatibilityTestResultType.Success
    >(CompatibilityTestResultType.Checking);
    const [tests, setTests] = React.useState<CompatibilityTest[]>(ALL_COMPATIBILITY_TESTS);
    const testResults = React.useRef<CompatibilityTestResult[]>(
        _.map(tests, () => {
            return { type: CompatibilityTestResultType.Pending };
        }),
    );
    const [renderer, setRenderer] = React.useState<boolean>(false);
    const [displayMode, setDisplayMode] = useState<CompatibilityScreenDisplayMode>(
        CompatibilityScreenDisplayMode.Basic,
    );

    useEffect(() => {
        setTimeout(() => {
            setRenderer(!renderer);

            //check if all testResults are success
            if (testResults.current.every(testResult => testResult.type === CompatibilityTestResultType.Success)) {
                setOverallStatus(CompatibilityTestResultType.Success);
                setTimeout(() => {
                    history.push(Routes.PAIR_DEVICE);
                }, 1500);
            }
        }, 250);
    }, [renderer]);

    React.useEffect(() => {
        // Run the tests asynchronously, so that one does not block the other
        const startAllTest = async () => {
            //We wait at least 500ms before starting the tests
            await new Promise(resolve => setTimeout(resolve, 500));
            tests.forEach(async (test, testIndex) => {
                //We assume its going to fail, so that the overall status is failure is anything goes wrong
                let currentTestResult = CompatibilityTestResultType.Failure;
                try {
                    currentTestResult = await test.function();
                    testResults.current[testIndex].type = currentTestResult;
                } catch (e) {
                    testResults.current[testIndex].type = CompatibilityTestResultType.Failure;
                    testResults.current[testIndex].type = (e as any).message ?? 'Unknown error';
                    setOverallStatus(CompatibilityTestResultType.Failure);
                }

                if (
                    currentTestResult === CompatibilityTestResultType.Failure &&
                    overallStatusRef.current !== CompatibilityTestResultType.Failure
                ) {
                    //This test has failed, and the overall status is not failure, so we set it to failure
                    setOverallStatus(CompatibilityTestResultType.Failure);
                }
            });
        };

        void startAllTest();
    }, [tests]);

    return (
        <StarsBackground>
            <NonResponsiveStyledText
                style={NonResponsiveTextStyle.CloudshelfBold}
                size={NonResponsiveTextSize.Small}
                center
                translate
                className={'white'}
            >
                Checking Device Compatibility
            </NonResponsiveStyledText>
            {displayMode === CompatibilityScreenDisplayMode.Basic && (
                <>
                    {overallStatus === CompatibilityTestResultType.Checking && <Loader />}
                    {overallStatus === CompatibilityTestResultType.Failure && (
                        <>
                            <NonResponsiveStyledText
                                style={NonResponsiveTextStyle.Cloudshelf}
                                size={NonResponsiveTextSize.ExtraSmall}
                                center
                                translate
                                className={'white'}
                            >
                                This device can’t currently run Cloudshelf.
                                <br />
                                Please update your browser and try again.
                            </NonResponsiveStyledText>
                            <button
                                className={'compatibilityScreenDisplayModeButton'}
                                onClick={() => {
                                    setDisplayMode(CompatibilityScreenDisplayMode.Advanced);
                                }}
                            >
                                See Details
                            </button>
                        </>
                    )}
                    {overallStatus === CompatibilityTestResultType.Success && (
                        <NonResponsiveStyledText
                            style={NonResponsiveTextStyle.Cloudshelf}
                            size={NonResponsiveTextSize.ExtraSmall}
                            center
                            translate
                            className={'testResult_success'}
                        >
                            This device meets the minimum requirements to run cloudshelf.
                        </NonResponsiveStyledText>
                    )}
                </>
            )}

            {displayMode === CompatibilityScreenDisplayMode.Advanced && (
                <div className={'advancedCompatibilityScreen'}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            gap: '5px',
                            width: '100%',
                        }}
                    >
                        <NonResponsiveStyledText
                            style={NonResponsiveTextStyle.Cloudshelf}
                            size={NonResponsiveTextSize.ExtraExtraSmall}
                            center
                        >
                            Please post a photo of this screen to support@cloudshelf.ai
                            <hr />
                        </NonResponsiveStyledText>
                        <button
                            className={'closeButton'}
                            onClick={() => setDisplayMode(CompatibilityScreenDisplayMode.Basic)}
                        >
                            Close
                        </button>
                        {tests.map((test, textIndex) => {
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        gap: '10px',
                                    }}
                                >
                                    <NonResponsiveStyledText
                                        style={NonResponsiveTextStyle.Cloudshelf}
                                        size={NonResponsiveTextSize.ExtraExtraSmall}
                                        center
                                    >
                                        {test.name}
                                    </NonResponsiveStyledText>
                                    <NonResponsiveStyledText
                                        style={NonResponsiveTextStyle.Cloudshelf}
                                        size={NonResponsiveTextSize.ExtraExtraSmall}
                                        center
                                        className={`testResult_${testResults.current[textIndex].type}`}
                                    >
                                        {testResults.current[textIndex].type === CompatibilityTestResultType.Pending &&
                                            'Pending'}
                                        {testResults.current[textIndex].type === CompatibilityTestResultType.Checking &&
                                            'Checking...'}
                                        {testResults.current[textIndex].type === CompatibilityTestResultType.Success &&
                                            'Passed'}
                                        {testResults.current[textIndex].type === CompatibilityTestResultType.Failure &&
                                            'Failed'}
                                    </NonResponsiveStyledText>
                                </div>
                            );
                        })}
                        <br />
                        <br />
                        <NonResponsiveStyledText
                            style={NonResponsiveTextStyle.Cloudshelf}
                            size={NonResponsiveTextSize.ExtraExtraSmall}
                            center
                        >
                            Information about this device:
                            <br />
                            <br />
                            User Agent: {window.navigator.userAgent}
                            <br />
                            Data: {JSON.stringify((window.navigator as any).userAgentData ?? 'No UserAgent Data Found')}
                            <br />
                            <br />
                            Hardware Concurrency: {window.navigator.hardwareConcurrency}
                            <br />
                            Browser Vendor: {window.navigator.vendor}
                            <br />
                            Max Touch Points: {window.navigator.maxTouchPoints}
                            <br />
                            Supports Apple Pay: {typeof (window as any).ApplePaySession !== 'undefined' ? 'Yes' : 'No'}
                        </NonResponsiveStyledText>
                    </div>
                </div>
            )}
        </StarsBackground>
    );
};

export default CheckCompatibilityComponent;
