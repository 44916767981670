import { Record, RecordOf } from 'immutable';
import { ProductSearchResultWithCursor } from '../../common/ProductSearchResultWithCursor';

export interface CloudshelfProductsSearchResultParams {
    hasMore: boolean;
    totalCount: number;
    products: ProductSearchResultWithCursor[];
}

export type CloudshelfProductsSearchResult = RecordOf<CloudshelfProductsSearchResultParams>;

export const makeSearchResult: Record.Factory<CloudshelfProductsSearchResultParams> = Record({
    hasMore: false as boolean,
    totalCount: 0,
    products: [] as ProductSearchResultWithCursor[],
});
