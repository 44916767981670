import React, { FC, memo, useEffect } from 'react';
import DisplayOnlyQR from '../../apps/DisplayApp/components/DisplayOnlyQR/DisplayOnlyQR';
import { CSSTransition } from 'react-transition-group';
import TimeBar from '../../apps/DisplayApp/components/TimeBar/TimeBar';
import './BannerPane.scss';
import { useInjection } from '../../../dependancyInjection/DependencyContext';
import { ConfigurationService } from '../../../services/ConfigurationService/ConfigurationService';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { DisplayOnlyTrackedHandleType } from '../../../services/TrackedURLService/DisplayOnlyTrackedHandleType.enum';
import { Banner } from '../../../services/ConfigurationService/types/config/CloudshelfEngineConfig';
import {
    CloudshelfBannerBackgroundType,
    CloudshelfBannerLinkType,
} from '../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { RoutesHelperService } from '../../../services/RoutesService/RoutesHelperService';
import { EventsService } from '../../../services/EventsService/EventsService';
import { getSizedImageURL } from '../../../utils/ImageURL.Util';
import { Textfit } from 'react-textfit';
export interface BannerPaneProps {
    banner: Banner;
    active: boolean;
    interactive?: boolean;
    onTapped?: () => void;
    className?: string;
}

const BannerPane: FC<BannerPaneProps> = memo(props => {
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    let qrHandleType: DisplayOnlyTrackedHandleType | undefined;
    let qrHandleLink: string | undefined;
    let imageBackgroundSource: string | undefined;
    const history = useHistory();
    let timerBarLengthInMilliseconds: number | undefined = 0;

    if (props.interactive) {
        timerBarLengthInMilliseconds = (configService.config()?.bannerDisplayRules.interactive.duration ?? 1) * 1000;
    } else {
        timerBarLengthInMilliseconds = (configService.config()?.bannerDisplayRules.display.duration ?? 1) * 1000;
    }

    if (!props.interactive || (props.interactive && props.banner.linkType === CloudshelfBannerLinkType.Url)) {
        if (props.banner.linkType === CloudshelfBannerLinkType.Url) {
            qrHandleType = DisplayOnlyTrackedHandleType.BANNER_LINK;
            qrHandleLink = props.banner.linkURL;
        } else if (props.banner.linkType === CloudshelfBannerLinkType.Collection) {
            qrHandleType = DisplayOnlyTrackedHandleType.CATEGORY;
            qrHandleLink = props.banner.linkCollection;
        } else if (props.banner.linkType === CloudshelfBannerLinkType.Product) {
            qrHandleType = DisplayOnlyTrackedHandleType.PRODUCT;
            qrHandleLink = props.banner.linkProduct;
        }
    }

    const isLandscape = window.innerHeight < window.innerWidth;
    if (props.banner.backgroundType === CloudshelfBannerBackgroundType.Image) {
        if (isLandscape) {
            imageBackgroundSource = props.banner.backgroundImageHorizontal ?? props.banner.backgroundImageVertical;
        } else {
            imageBackgroundSource = props.banner.backgroundImageVertical ?? props.banner.backgroundImageHorizontal;
        }
    }

    const windowHeight = window.innerHeight;
    const minFontSize = windowHeight / 20;
    const maxFontSize = windowHeight / 5;

    const handleInteractiveButtonClick = () => {
        if (props.banner.linkType === CloudshelfBannerLinkType.Url) {
            window.open(props.banner.linkURL, '_blank');
        } else if (props.banner.linkType === CloudshelfBannerLinkType.Collection) {
            const collection = configService.categories.find(c => c.handle === props.banner.linkCollection);
            eventsService.setOpenCategory(collection);
            history.push(RoutesHelperService.toCategoryProductsViaHandle(props.banner.linkCollection ?? ''));
        } else if (props.banner.linkType === CloudshelfBannerLinkType.Product) {
            eventsService.setOpenProduct(props.banner.linkProduct);
        } else if (props.banner.linkType === CloudshelfBannerLinkType.Close) {
            props.onTapped?.();
        }
    };

    return (
        <div onClick={props.onTapped}>
            <CSSTransition
                in={props.active}
                timeout={500}
                appear
                unmountOnExit
                mountOnEnter
                classNames={
                    props.interactive
                        ? `${props.className} BannerPane BannerPane__interactive BannerPane__interactive`
                        : `${props.className} BannerPane BannerPane`
                }
            >
                <div
                    className="BannerPane__wrapper"
                    style={{
                        backgroundColor:
                            props.banner.backgroundType === CloudshelfBannerBackgroundType.SolidColour
                                ? props.banner.backgroundColour
                                : 'inherit',
                    }}
                >
                    {(!props.interactive || (configService.config()?.banners ?? []).length > 1) && (
                        <TimeBar
                            numberOfBlocks={1}
                            millisecondsPerBlock={timerBarLengthInMilliseconds}
                            activeBlock={0}
                            forceFullWidth
                        />
                    )}

                    {props.banner.backgroundType === CloudshelfBannerBackgroundType.Image && (
                        <>
                            <img
                                src={getSizedImageURL(imageBackgroundSource)}
                                alt="Banner Image"
                                className="BannerPane__background__blur"
                            />
                            <img
                                src={getSizedImageURL(imageBackgroundSource)}
                                alt="Banner Image"
                                className="BannerPane__background"
                            />
                        </>
                    )}
                    <div className="BannerPane__content">
                        {!qrHandleType || !qrHandleLink ? null : (
                            <DisplayOnlyQR type={qrHandleType} handle={qrHandleLink} reducedMargin={true} />
                        )}
                        {props.interactive &&
                            ((props.banner.linkType === CloudshelfBannerLinkType.Product &&
                                !_.isEmpty(_.trim(props.banner.linkProduct))) ||
                                (props.banner.linkType === CloudshelfBannerLinkType.Collection &&
                                    !_.isEmpty(_.trim(props.banner.linkCollection))) ||
                                props.banner.linkType === CloudshelfBannerLinkType.Close) && (
                                <div className={'BannerPane__content__interactiveSection'}>
                                    <div className={'BannerPane__content__interactiveSection__wrapper'}>
                                        <div className="BannerPane__content__interactiveSection__pulse" />
                                        <button
                                            onClick={handleInteractiveButtonClick}
                                            className={'BannerPane__content__interactiveSection__button'}
                                        >
                                            {props.banner.linkText || 'Tap Here'}
                                        </button>
                                    </div>
                                </div>
                            )}
                        <div className="BannerPane__content__title">
                            <Textfit min={minFontSize} max={maxFontSize}>
                                <p>{props.banner.text}</p>
                            </Textfit>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
});

export default BannerPane;
