import React, { FC } from 'react';
const MinusIcon: FC = props => {
    return (
        <svg width="20" height="4" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.5 3.25H2.5C1.8125 3.25 1.25 2.6875 1.25 2C1.25 1.3125 1.8125 0.75 2.5 0.75H17.5C18.1875 0.75 18.75 1.3125 18.75 2C18.75 2.6875 18.1875 3.25 17.5 3.25Z"
                fill="black"
                stroke="black"
            />
        </svg>
    );
};
export default MinusIcon;
