import React, { FC, useEffect, useRef } from 'react';
import StyledText, { TextStyle } from './StyledText';
import styles from './AutoSizedStyledText.module.scss';

export interface AutoSizedValues {
    fontSize: number;
    lineHeight: number;
}

export interface AutoSizedStyledTextProps {
    style?: TextStyle;
    enforce?: AutoSizedValues;
    enforcedOnly?: boolean;
    reportSize?: (value: AutoSizedValues) => void;
    clamp?: boolean;
    //If you need to force a resize, pass in a new key to force a re-render
    resizeKey?: string;
    translate?: boolean;
}

const AutoSizedStyledText: FC<AutoSizedStyledTextProps> = props => {
    const devRef = useRef<HTMLDivElement>(null);

    const isOverflown = ({ clientWidth, clientHeight, scrollWidth, scrollHeight }: HTMLElement) => {
        return scrollWidth > clientWidth || scrollHeight > clientHeight;
    };

    const resizeText = ({ element, minSize = 10, maxSize = 512, step = 1, unit = 'px' }: any) => {
        let i = minSize;
        let overflow = false;

        const parent = element.parentNode;

        while (!overflow && i < maxSize) {
            element.style.fontSize = `${i - step}${unit}`;
            element.style.lineHeight = `${i - step}${unit}`;
            overflow = isOverflown(parent);

            if (!overflow) {
                i += step;
            }
        }

        // revert to last state where no overflow happened
        const fontSize = i - step - 1;
        const lineHeight = i - step + 3;
        element.style.fontSize = `${fontSize}${unit}`;
        element.style.lineHeight = `${lineHeight}${unit}`;

        if (props.reportSize) {
            const values: AutoSizedValues = {
                fontSize,
                lineHeight,
            };
            props.reportSize(values);
        }
    };

    const handleResize = () => {
        const element = devRef.current;
        if (element === null) {
            return;
        } else {
            if (props.enforce !== undefined && props.enforce !== null) {
                element.style.fontSize = `${props.enforce?.fontSize}px`;
                element.style.lineHeight = `${props.enforce?.lineHeight}px`;
            } else if (!props.enforcedOnly) {
                resizeText({
                    element,
                    step: 0.5,
                });
            }
        }
    };

    useEffect(() => {
        window.addEventListener('resize', () => {
            handleResize();
        });

        handleResize();
    }, []);

    useEffect(() => {
        handleResize();
    }, [props.resizeKey, props.enforcedOnly, props.enforce]);

    return (
        <StyledText
            style={props.style}
            className={`${styles.fill} ${styles.allowOverflow}`}
            translate={props.translate}
        >
            <span ref={devRef} className={props.clamp ? styles.clamp : ''}>
                {props.children}
            </span>
        </StyledText>
    );
};

AutoSizedStyledText.defaultProps = {
    style: TextStyle.Body,
    translate: false,
};

export default AutoSizedStyledText;
