import { FC } from 'react';
import StarsBackground from '../../../../shared/StarsBackground/StarsBackground';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import useStateRef from 'react-usestateref';
import ProductsLoader from '../../../../shared/ProductsLoader/ProductsLoader';
import styles from './SetupWrapper.module.css';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import { InteractiveOrchestratorService } from '../../../../../services/InteractiveOrchestratorService/InteractiveOrchestratorService';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { EventsService } from '../../../../../services/EventsService/EventsService';

const SetupWrapper: FC = props => {
    const [setupComplete, setSetupComplete] = useStateRef(false);
    const interactiveOrchestratorService = useInjection<InteractiveOrchestratorService>(
        DependencyType.InteractiveOrchestratorService,
    );
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);

    return (
        <>
            {setupComplete ? (
                props.children
            ) : (
                <StarsBackground>
                    <StyledText style={TextStyle.Heading} size={TextSize.Large} className={styles.SetupWrapper__Text}>
                        Just a moment
                    </StyledText>
                    <StyledText
                        style={TextStyle.Subheading}
                        size={TextSize.Small}
                        className={`${styles.SetupWrapper__Text} ${styles.SetupWrapper__Info}`}
                    >
                        Cloudshelf is loading your products
                    </StyledText>
                    <div style={{ marginTop: '3vh' }}>
                        <ProductsLoader
                            onComplete={() => {
                                interactiveOrchestratorService
                                    .populateDisplayState()
                                    .then(() => {
                                        eventsService.loadingComplete();
                                        setSetupComplete(true);
                                    })
                                    .catch(() => {});
                            }}
                            onCachePopulated={() => {
                                // filteringService
                                //     .categoryPreloaderInteractiveMode()
                                //     .then(() => {
                                //         LogUtil.Log('Categories preloaded');
                                //     })
                                //     .catch(() => {
                                //         LogUtil.Log('Error preloading categories', LogLevel.Error);
                                //     })
                                //     .finally(() => {
                                //         setTimeout(() => {
                                //             setPreloadReady(true);
                                //         }, 500);
                                //     });
                            }}
                        />
                    </div>
                </StarsBackground>
            )}
        </>
    );
};

export default SetupWrapper;
