import { inject, injectable } from 'inversify';
import { Observable, Subject } from 'rxjs';
import { ConfigurationService } from '../ConfigurationService/ConfigurationService';
import { Banner } from '../ConfigurationService/types/config/CloudshelfEngineConfig';
import { LogUtil } from '../../utils/Logging.Util';
import { InteractiveBannerDisplayMode } from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import * as Sentry from '@sentry/react';
import DependencyType from '../../dependancyInjection/DependencyType';
import { ApolloClient, InMemoryCache } from '@apollo/client';

export interface InteractiveDisplayState {
    availableBanners: Banner[];
    bannerIndex: number;
    shouldShowBanner: boolean;
}

@injectable()
export class InteractiveOrchestratorService {
    private configService: ConfigurationService;
    private configServiceObserver: Observable<void>;
    private _availableBanners: Banner[] = [];
    private _displayState: InteractiveDisplayState = {
        availableBanners: [],
        bannerIndex: -1,
        shouldShowBanner: false,
    };
    private _subject: Subject<void> = new Subject();
    private _interval?: NodeJS.Timeout;
    private _nextAllowedAt?: Date;
    private _shouldDisplayBanner = false;
    private _wentToBannerLastTime = false;

    constructor(
        private readonly _configurationService: ConfigurationService,
        @inject(DependencyType.ApolloClient)
        private readonly apolloClient: ApolloClient<InMemoryCache>,
    ) {
        this.configService = _configurationService;
        this.configServiceObserver = this.configService.observe();
        this.setupQAHelpers();
    }

    setupQAHelpers() {
        (window as any).qaHelper_InteractiveOrchestratorToggle = () => {
            if (this._interval) {
                this.stop();
                console.info('Asked InteractiveOrchestratorService to stop');
            } else {
                this.start();
                console.info('Asked InteractiveOrchestratorService to start');
            }
        };
    }

    get displayState(): InteractiveDisplayState {
        return this._displayState;
    }

    start() {
        if (this._interval) {
            console.info('InteractiveOrchestratorService, start called but already started');
            return;
        }

        if (this._availableBanners.length === 0) {
            console.info('InteractiveOrchestratorService, start called but no categories');
            this._subject.next();
            return;
        }
        this._displayState.shouldShowBanner = false;
        this.displayState.bannerIndex = -1;
        this._nextAllowedAt = new Date();

        this._interval = setInterval(() => {
            this.handleNext();
        }, 50);
    }

    stop() {
        if (this._interval) {
            clearInterval(this._interval);
        }
        this._interval = undefined;
        this._nextAllowedAt = undefined;
    }

    async populateDisplayState() {
        if (
            this.configService.config()?.bannerDisplayRules.interactive.displayMode ===
            InteractiveBannerDisplayMode.NoBanners
        ) {
            await this.setBanners([]);
        } else {
            await this.setBanners(this.configService.config()?.banners ?? []);
        }
        this._subject.next();
    }

    async setBanners(banners: Banner[]) {
        try {
            //We might want to do some sanity checks here like we do for categories, but for now that hasn't been spec'd out.
            LogUtil.Log('Setting banners to: ' + JSON.stringify(banners));
            this._availableBanners = banners;
        } catch (err) {
            Sentry.captureException(err, {
                extra: {
                    operationName: 'InteractiveOrchestratorService.setBanners',
                },
            });
            console.error('InteractiveOrchestratorService, setBanners', err);
        }
    }

    observe(): Observable<void> {
        return this._subject.asObservable();
    }

    async handleNext() {
        try {
            if (
                this.configService.config()?.bannerDisplayRules.interactive.displayMode ===
                    InteractiveBannerDisplayMode.NoBanners ||
                this.configService.config()?.banners.length === 0
            ) {
                //We should never show banners, so lets just return and do nothing.
                return;
            }

            if (this._nextAllowedAt && this._nextAllowedAt > new Date()) {
                return;
            }

            this._displayState.availableBanners = this._availableBanners;

            let timeModifier =
                (this.configService.config()?.bannerDisplayRules.interactive.showEveryMinutes ?? 1) * 60000;

            if (
                this.configService.config()?.bannerDisplayRules.interactive.displayMode ===
                InteractiveBannerDisplayMode.BannersUntilInteraction
            ) {
                //We should always be showing a banner here.
                this._shouldDisplayBanner = true;

                timeModifier = (this.configService.config()?.bannerDisplayRules.interactive.duration ?? 1) * 1000;
            } else if (
                this.configService.config()?.bannerDisplayRules.interactive.displayMode ===
                InteractiveBannerDisplayMode.BannersAndAttractLoop
            ) {
                //We might need to show a banner here, or we might not.
                //This cloudshelf should show banner, then attract, then banner, then attract, in a loop.
                if (this._shouldDisplayBanner) {
                    //We were displaying a banner previously, so we shouldn't now.
                    this._shouldDisplayBanner = false;
                } else {
                    //We were not showing a banner last time, so we should this time.
                    this._shouldDisplayBanner = true;
                }

                if (this._wentToBannerLastTime) {
                    timeModifier =
                        (this.configService.config()?.bannerDisplayRules.interactive.showEveryMinutes ?? 1) * 60000;
                    this._wentToBannerLastTime = false;
                } else {
                    timeModifier = (this.configService.config()?.bannerDisplayRules.interactive.duration ?? 1) * 1000;
                    this._wentToBannerLastTime = true;
                }
            }

            //Let's ensure the display state is accurate, as the banner rules or index might have changed.
            this._displayState.shouldShowBanner = this._shouldDisplayBanner;
            //Now lets move to the next banner in the list (or back to the start if we are at the end).
            if (this._shouldDisplayBanner) {
                if (this._displayState.bannerIndex < this._availableBanners.length - 1) {
                    this._displayState.bannerIndex++;
                } else {
                    this._displayState.bannerIndex = 0;
                }
            }

            //Let's set the time for the next update
            this._nextAllowedAt = new Date(Date.now() + timeModifier);

            //And finally let the subscribers know that we have updated the display state.
            this._subject.next();
        } catch (err) {
            console.log('Error in interactive only service');
            Sentry.captureException(err, {
                extra: {
                    operationName: 'InteractiveOrchestratorService.handleNext',
                },
            });
        }
    }
}
