import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import { BasketService } from '../../../../../services/BasketService/BasketService';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { BasketList } from '../Basket/BasketList';
import { PanelHeader } from '../PanelHeader/PanelHeader';
import { Basket } from '../../../../../services/BasketService/Basket.type';
import ProductVariant from '../../../../../services/ProductServices/variant/ProductVariant';
import { LogUtil } from '../../../../../utils/Logging.Util';
import { PurchaseCallback } from '../Purchase/PurchaseCallback';
import { CheckoutTotalOverlay } from './CheckoutTotalOverlay';
import { CustomAttributes } from '../ProductDetailsView/buildingBlocks/customisation/ProductCustomiserSection';

import './CheckoutView.scss';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';

export interface CheckoutViewProps {
    isOpen: boolean;
    onClose: () => void;
    setLoading: (loading: boolean) => void;
    loading: boolean;
    onPurchase: PurchaseCallback;
}

export const CheckoutView: React.FC<CheckoutViewProps> = ({ onClose, setLoading, onPurchase, loading, isOpen }) => {
    const { t } = useTranslation();
    const basketService = useInjection<BasketService>(DependencyType.BasketService);
    const [checkoutBasket, setCheckoutBasket] = useState<Basket>();
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const productCustomiserPriceModifierVariant = configService.productCustomiserPriceModifierVariant;

    useEffect(() => {
        const subscriber = basketService.observeBasket().subscribe(basket => {
            setCheckoutBasket(basket);
        });
        return () => {
            subscriber.unsubscribe();
        };
    }, [basketService]);

    const handleQuantityChange = async (
        item: ProductVariant,
        customAttributes: CustomAttributes,
        newQuantity: number,
        oldQuantity: number,
    ) => {
        await basketService.setItemQuantity(item, newQuantity, customAttributes);

        if (productCustomiserPriceModifierVariant) {
            const qty = await basketService.getItemQuantity(productCustomiserPriceModifierVariant.id, customAttributes);
            if (qty > 0) {
                const qtyPerItem = qty / oldQuantity;
                const newQty = qtyPerItem * newQuantity;
                await basketService.setItemQuantity(productCustomiserPriceModifierVariant, newQty, customAttributes);
            }
        }

        setCheckoutBasket(basketService.basket);
        LogUtil.LogObject(['handleQuantityChange', basketService.basket]);
    };

    const handleBasketItemRemoved = async (item: ProductVariant, customAttributes: CustomAttributes) => {
        await basketService.removeItem(item, customAttributes);
        if (productCustomiserPriceModifierVariant) {
            await basketService.removeItem(productCustomiserPriceModifierVariant, customAttributes);
        }
    };

    useEffect(() => {
        if (checkoutBasket?.lineItems.length === 0) {
            onClose();
        }
    }, [checkoutBasket, onClose]);

    return (
        <div className="CheckoutView">
            <PanelHeader
                className="CheckoutView__header"
                onCloseButtonClicked={onClose}
                title={t('checkout_view.title')}
                bottomBorder
                translate
            />
            {checkoutBasket && (
                <BasketList
                    isOpen={isOpen}
                    basket={checkoutBasket}
                    className="CheckoutView__basketList"
                    onQuantityChanged={handleQuantityChange}
                    onBasketItemRemoved={handleBasketItemRemoved}
                    setLoading={setLoading}
                />
            )}
            <CheckoutTotalOverlay onPurchase={onPurchase} loading={loading} />
        </div>
    );
};
