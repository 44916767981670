import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useInjection } from '../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { AnalyticsService } from '../AnalyticsService';

export const PageViewTracker: React.FC = ({ children }) => {
    const analyticsService = useInjection<AnalyticsService>(DependencyType.AnalyticsService);
    const location = useLocation();

    useEffect(() => {
        analyticsService.recordPageView(location.pathname + location.search);
    }, [analyticsService, location]);

    return <>{children}</>;
};
