import StyledText, { TextSize, TextStyle } from '../../../../../shared/StyledText/StyledText';
import { FC } from 'react';

export interface ProductTitleProps {
    content?: string;
}

const ProductTitle: FC<ProductTitleProps> = props => {
    return (
        <StyledText style={TextStyle.Heading} size={TextSize.Small}>
            {props.content}
        </StyledText>
    );
};

export default ProductTitle;
