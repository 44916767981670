import { FC } from 'react';
import { useInjection } from '../../../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../../../dependancyInjection/DependencyType';
import { useDeviceInfo } from '../../../../../../../hooks/UseDeviceInfo';
import { ConfigurationService } from '../../../../../../../services/ConfigurationService/ConfigurationService';

const DeviceOverlayComponent: FC = () => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const [deviceInfo] = useDeviceInfo();

    return (
        <>
            Is Device: {configService.isDevice() ? 'Yes' : 'No'}
            <br />
            Is Internal Device: {configService.isInternalDevice() ? 'Yes' : 'No'}
            <br />
            {configService.isDevice() ? (
                <>
                    Device ID: {deviceInfo.id}
                    <br />
                    Device Code: {deviceInfo.code}
                    <br />
                    Device Registered: {deviceInfo.registered ? 'Yes' : 'No'}
                    <br />
                    Big Format: {deviceInfo.bigFormatDisplay ? 'Yes' : 'No'}
                    <br />
                    Debug Mode: {configService.config()?.device?.debugMode ? 'Yes' : 'No'}
                </>
            ) : (
                <>Device info not available in preview/handoff mode</>
            )}
        </>
    );
};

export default DeviceOverlayComponent;
